import { useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

export const unmaskFieldInfo = {
    contactPhone: {
        apiCall: (params) => API.customerMgmt.getContactPhone.apiCall(params),
        fieldName: 'contactPhone',
        dataKey: 'phone'
    },
    contactEmail: {
        apiCall: (params) => API.customerMgmt.getContactEmail.apiCall(params),
        fieldName: 'contactEmail',
        dataKey: 'email'
    },
    userPhone: {
        apiCall: (params) => API.user.getUserPhone.apiCall(params),
        fieldName: 'userPhone',
        dataKey: 'phone'
    },
    userEmail: {
        apiCall: (params) => API.user.getUserEmail.apiCall(params),
        fieldName: 'userEmail',
        dataKey: 'email'
    }
};

const useUnmaskFieldValue = (initUnmaskedValue = '') => {
    const [unmaskedValue, setUnmaskedValue] = useState(initUnmaskedValue);
    const [isFetchSuccess, setIsFetchSuccess] = useState(false);

    const getUnmaskFieldValue = (fieldName, params) => {
        if (!fieldName && isFetchSuccess) return;
        const { apiCall, dataKey } = unmaskFieldInfo[fieldName];
        handleAsyncCall({
            asyncCall: () => apiCall(params),
            handleCallSuccess: (res) => {
                if (typeof res.data[dataKey] !== 'undefined') {
                    setUnmaskedValue(res.data[dataKey]);
                    setIsFetchSuccess(true);
                }
            }
        });
    };

    return { unmaskedValue, getUnmaskFieldValue };
};

export default useUnmaskFieldValue;
