import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useIdentityInfo = () => {
    const { userId } = useSelector((state) => state.user);
    const [identityInfo, setIdentityInfo] = useState({});

    const getIdentityInfo = useCallback(() => {
        handleAsyncCall({
            asyncCall: () =>
                API.registration.getIdentityInformation.apiCall(userId),
            handleCallSuccess: (res) => setIdentityInfo(res.data.info)
        });
    }, [userId]);

    useEffect(() => {
        if (userId) {
            getIdentityInfo();
        }
    }, [getIdentityInfo, userId]);

    return { identityInfo, getIdentityInfo };
};

export default useIdentityInfo;
