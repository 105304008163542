import axios from 'axios';
import {
    KEY,
    getItemSync,
    setItemAsync,
    setItemSync
} from 'utils/localStorage';

const axiosConfig = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosConfig.defaults.params = {};

axiosConfig.interceptors.request.use((config) => {
    const authToken = getItemSync(KEY.CREDENTIAL, {}, 'true').authToken ?? '';
    config.headers.token = authToken;
    config.params['ln'] = getItemSync(KEY.LANG, {});

    return config;
});

axiosConfig.interceptors.response.use(
    async (response) => {
        const {
            data: { code, codeDesc },
            request: { responseURL }
        } = response;

        if (code === 401 || codeDesc === 'Unauthorized') {
            await setItemAsync(KEY.CREDENTIAL, {}, 'true');
            if (responseURL.indexOf('/logout') === -1) {
                window.location.reload();
            }
            return Promise.reject(response);
        }
        return Promise.resolve(response);
    },
    (error) => {
        if (
            error.response?.status === 403 ||
            error.response?.data?.msg === 'FORBIDDEN'
        ) {
            setItemSync(KEY.CREDENTIAL, {}, 'true');
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default axiosConfig;
