import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { ButtonIco, ButtonIcoCopy, PlainTextPlatformField } from 'components';
import { ActionGroup } from 'layouts';

import {
    CELL_GROUP_STICKY_TYPE,
    DEFAULT_TABLE_PAGE_SIZE
} from 'constants/appTableInfos';
import { USER_ROLE } from 'constants/authInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { PAGE_FULL_STATIC_PATH } from 'constants/routes';
import {
    useAccountLinkList,
    useAppTableQueryParams,
    useRestoreParamsFromPaths
} from 'hooks';

import { getAccountTypeLocale } from 'utils/client/accountType';
import { getTableFieldInfos } from 'utils/tableInfos';

import useDialogRemoveAccountLink from '../../pages/CustomerMgmt/AccountLinks/components/DialogRemoveAccountLink/useDialogRemoveAccountLink';
import {
    CopyIco,
    EditIco,
    QueryIco,
    RubbishBinIco
} from 'assets/images/actions';

const filterGroupId = 'FILTER_GROUP_ACCOUNT_LINK';

const defaultTableQueryParams = {
    tradeLoginId: VALUES_ALL_TRADE_LOGIN_IDS.APP,
    searchTradeLoginId: VALUES_ALL_TRADE_LOGIN_IDS.APP,
    pageCurrent: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE
};

const useTableAccountLink = (listType) => {
    const { t } = useTranslation();
    const { userRole } = useSelector((state) => state.user);

    const { accountLinkData, getAccountLinkList } =
        useAccountLinkList(listType);

    const { isRestoreCachedParams } = useRestoreParamsFromPaths(
        PAGE_FULL_STATIC_PATH.ReferralLink
    );

    const { tableQueryParams, updateTableQueryParams } = useAppTableQueryParams(
        filterGroupId + listType,
        defaultTableQueryParams,
        isRestoreCachedParams
    );

    useEffect(() => {
        if (isEmpty(tableQueryParams)) return;
        getAccountLinkList(tableQueryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableQueryParams]);

    const successRemoveCallBack = () => {
        getAccountLinkList(tableQueryParams);
    };

    const { openDialogRemoveAccountLink } = useDialogRemoveAccountLink(
        successRemoveCallBack
    );

    const renderActionGroup = ({
        id: inviteUrlId,
        url: inviteUrl,
        accountLinkName,
        canEdit
    }) => {
        const onClickRemove = () => {
            openDialogRemoveAccountLink({ inviteUrlId, accountLinkName });
        };

        return (
            <ActionGroup>
                <ButtonIcoCopy
                    copyValue={inviteUrl}
                    hoverTitle={t('common.action.copy')}
                    BtnIco={<CopyIco width={18} height={18} />}
                />

                <ButtonIco
                    linkProps={{
                        to: `${PAGE_FULL_STATIC_PATH.ReferralLink}/detail/${inviteUrlId}`
                    }}
                    hoverTitle={t('common.action.detail')}
                    BtnIco={<QueryIco width={18} height={18} />}
                />

                {canEdit && (
                    <ButtonIco
                        linkProps={{
                            to: `${PAGE_FULL_STATIC_PATH.ReferralLink}/edit/${inviteUrlId}`
                        }}
                        hoverTitle={t('common.action.edit')}
                        BtnIco={<EditIco />}
                    />
                )}

                {canEdit && (
                    <ButtonIco
                        onClick={onClickRemove}
                        hoverTitle={t('common.action.delete')}
                        BtnIco={<RubbishBinIco />}
                    />
                )}
            </ActionGroup>
        );
    };

    const tableAccountLinksColumnsInfos = {
        actionGroup: {
            ...getTableFieldInfos(t).actionGroup,
            renderCell: (data) => renderActionGroup(data),
            stickyType: CELL_GROUP_STICKY_TYPE.STICKY_LEFT
        },
        accountLinkName: {
            field: 'accountLinkName',
            label: t('customerMgmt.accountLink.linkName')
        },
        tradeLoginId: {
            field: 'tradeLoginId',
            label: t('common.tradeAccount.tradeLoginId')
        },
        accountTypeDisplay: {
            field: 'accountType',
            renderCell: (rowData) =>
                getAccountTypeLocale(rowData.accountType, t),
            label: t('customerMgmt.accountLink.customerAccountType')
        },
        platform: {
            field: 'platform',
            label: t('common.tradeAccount.platform'),
            renderCell: ({ platform }) => (
                <PlainTextPlatformField platform={platform} />
            )
        },
        permitInvite: {
            field: 'permitInvite',
            label: t('common.table.permitInvite'),
            renderCell: ({ permitInvite }) => {
                return (
                    <span>
                        {permitInvite
                            ? t(`common.general.true`)
                            : t(`common.general.false`)}
                    </span>
                );
            }
        },
        regCount: {
            field: 'regCount',
            label: t('common.table.regCount')
        },
        system: {
            field: 'system',
            label: t('common.table.system'),
            renderCell: ({ system }) => {
                return (
                    <span>
                        {t(
                            `common.general.${
                                system ? 'systemAdded' : 'manualAdded'
                            }`
                        )}
                    </span>
                );
            }
        }
    };

    const getTableAccountLinkInfos = () => {
        switch (userRole) {
            case USER_ROLE.MAJORDOMO:
            case USER_ROLE.MANAGER:
            case USER_ROLE.IB:
                return [
                    tableAccountLinksColumnsInfos.actionGroup,
                    tableAccountLinksColumnsInfos.accountLinkName,
                    tableAccountLinksColumnsInfos.tradeLoginId,
                    tableAccountLinksColumnsInfos.accountTypeDisplay,
                    tableAccountLinksColumnsInfos.platform,
                    tableAccountLinksColumnsInfos.permitInvite,
                    tableAccountLinksColumnsInfos.regCount,
                    tableAccountLinksColumnsInfos.system
                ];
            default:
                return [];
        }
    };

    return {
        accountLinkData,
        tableQueryParams,
        updateTableQueryParams,
        tableAccountLinksInfos: getTableAccountLinkInfos()
    };
};

export default useTableAccountLink;
