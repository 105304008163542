import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accountLinkInfoAccessType: null,
    accountLinkInfo: {},
    commissionList: [],
    mtAccountTypes: [],
    addAccountLinkOnceToken: null
};

const accountLinkSlice = createSlice({
    name: 'accountLink',
    initialState,
    reducers: {
        setAccountLinkInfoAccessType: (state, action) => {
            state.accountLinkInfoAccessType = action.payload;
        },
        setAccountLinkInfo: (state, action) => {
            state.accountLinkInfo = action.payload;
        },
        setCommissionList: (state, action) => {
            state.commissionList = action.payload;
        },
        setAddAccountLinkOnceToken: (state, action) => {
            state.addAccountLinkOnceToken = action.payload;
        },
        resetAccountLinkStore: () => ({ ...initialState })
    }
});

export default accountLinkSlice;

export const {
    setAccountLinkInfoAccessType,
    setAccountLinkInfo,
    setCommissionList,
    setMtAccountTypes,
    setAddAccountLinkOnceToken,
    resetAccountLinkStore
} = accountLinkSlice.actions;
