import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/material';

import './HeaderLineTop.scss';

const HeaderLineTop = ({ title, titleSecondLine = '' }) => {
    return (
        <Box className="headerLineTopWrapper">
            <Divider className="headerLineTop" />
            <Typography className="titleTxt">{title}</Typography>

            {titleSecondLine && (
                <Typography className="titleTxt">{titleSecondLine}</Typography>
            )}
        </Box>
    );
};

export default HeaderLineTop;
