import React, { Suspense, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { Loading } from 'pages';

import { AUDIT_PASSED_PATHS } from 'constants/appInfos';
import { REGISTRATION_STATUS } from 'constants/registration';
import useRegistrationStatus from 'hooks/useRegistrationStatus';
import { setPublicMsg } from 'store/user/slice';

import { getElementByRegStatus } from 'utils/registration';
import { checkIsUserAuthorized, getHomePathByRole } from 'utils/utils';

import { getRouteProps } from '../utils';
import useLoginRedirection from './hooks/useLoginRedirection';

const renderRoutes = (routes, userRole, isLogin, registrationStatus) => {
    return Object.values(routes).map((routeInfo) => {
        const { path, subRoutes, permissionFor, withoutRegStatusFallBack } =
            routeInfo;

        let isShowPage = checkIsUserAuthorized(permissionFor, userRole);

        if (!isShowPage) return null;

        if (isLogin && !withoutRegStatusFallBack) {
            routeInfo.element = getElementByRegStatus(
                routeInfo.element,
                registrationStatus
            );
        }

        const pathList = isArray(path) ? path : [path];

        return pathList.map((pathAlias) => {
            let subRoutesComponents = [];

            if (!isEmpty(subRoutes)) {
                subRoutesComponents = renderRoutes(
                    subRoutes,
                    userRole,
                    isLogin,
                    registrationStatus
                );
            }

            return (
                <Route
                    key={`route-${pathAlias}`}
                    {...getRouteProps({ ...routeInfo, path: pathAlias })}>
                    {isEmpty(subRoutesComponents) ? null : subRoutesComponents}
                </Route>
            );
        });
    });
};

const AppRouter = ({ routeInfos }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLogin, userRole, publicMsg } = useSelector((state) => state.user);
    const { queries } = useSelector((state) => state.registration);
    const registrationStatus = useRegistrationStatus();

    const { isCreatingTradingAccount, needRedirectionAfterLogin } =
        useLoginRedirection();

    useEffect(() => {
        if (!isLogin) return;

        const redirectPageByAccStatus = () => {
            switch (registrationStatus) {
                case REGISTRATION_STATUS.INCOMPLETE_INFORMATION:
                    return navigate('/status/not-enough-information');
                case REGISTRATION_STATUS.AUDITING:
                    return navigate('/status/auditing');
                case REGISTRATION_STATUS.AUDIT_FAILED:
                    return navigate('/status/audit-failed');
                case REGISTRATION_STATUS.AUDIT_PASSED:
                    return;
                default:
                    throw new Error(
                        `Registration status '${registrationStatus}' is not matched`
                    );
            }
        };

        if (AUDIT_PASSED_PATHS.some((path) => path === location.pathname)) {
            redirectPageByAccStatus();
        }
    }, [navigate, isLogin, location.pathname, registrationStatus]);

    useEffect(() => {
        if (
            isLogin &&
            (location.pathname === '/' || location.pathname === '/login') &&
            !isCreatingTradingAccount &&
            !needRedirectionAfterLogin
        ) {
            navigate(getHomePathByRole(userRole));
        }

        if (location.pathname === '/public-message') {
            if (!publicMsg) {
                dispatch(setPublicMsg(true));
            }
        } else {
            dispatch(setPublicMsg(false));
        }
    }, [
        navigate,
        isLogin,
        location.pathname,
        queries.inviteCode,
        userRole,
        needRedirectionAfterLogin,
        isCreatingTradingAccount,
        publicMsg,
        dispatch
    ]);

    const RouteComponents = useMemo(
        () => renderRoutes(routeInfos, userRole, isLogin, registrationStatus),
        [routeInfos, userRole, isLogin, registrationStatus]
    );

    return (
        <Suspense fallback={<Loading />}>
            <Routes>{RouteComponents}</Routes>
        </Suspense>
    );
};

export default AppRouter;
