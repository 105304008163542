import axiosConfig from 'axios/axiosConfig';

const fundingMgmt = {
    transferConfirm: {
        apiCall: (tradeLoginId, payload) =>
            axiosConfig.post(`/pay/${tradeLoginId}/preTransfer`, payload),
        queryKey: 'fundingMgmt.transferConfirm'
    },
    transferSubmit: {
        apiCall: (tradeLoginId, payload) =>
            axiosConfig.post(`/pay/${tradeLoginId}/transfer`, payload),
        queryKey: 'fundingMgmt.transferSubmit'
    },
    transferLimit: {
        apiCall: (tradeLoginId) =>
            axiosConfig.get(`/pay/${tradeLoginId}/transfer/limit`),
        queryKey: 'fundingMgmt.transferLimit'
    },
    getFundFlowRecords: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/transLogs`, { params }),
        queryKey: 'fundingMgmt.getFundFlowRecords'
    },
    fundFlowExport: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/transLogs/export`, {
                params,
                responseType: 'blob'
            }),
        queryKey: 'fundingMgmt.fundFlowExport'
    }
};

export default fundingMgmt;
