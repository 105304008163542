import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clientInfo: {},
    clientListData: { list: [] },
    clientListError: {},
    selectedGroupId: undefined,
    clientTypeKey: null,
    isFetching: false
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientInfo: (state, action) => {
            state.clientInfo = { ...state.clientInfo, ...action.payload };
        },
        resetClientInfo: (state) => {
            state.clientInfo = {};
        },
        setClientListData: (state, action) => {
            state.clientListData = action.payload;
        },
        setClientListError: (state, action) => {
            state.clientListError = action.payload;
        },
        setClientTypeKey: (state, action) => {
            state.clientTypeKey = action.payload;
        },
        setSelectedGroupId: (state, action) => {
            state.selectedGroupId = action.payload;
        },
        resetClientStore: () => ({ ...initialState }),
        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        }
    }
});

export default clientSlice;

export const {
    setClientInfo,
    resetClientInfo,
    setClientListData,
    setClientListError,
    setClientTypeKey,
    setSelectedGroupId,
    resetClientStore,
    setIsFetching
} = clientSlice.actions;
