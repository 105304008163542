import React from 'react';

import { Box, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import cx from 'classnames';

import { CELL_GROUP_STICKY_TYPE } from 'constants/appTableInfos';

import { Z_INDEX } from 'theme';

const StickyTableCellGroup = withStyles((theme) => ({
    head: {
        position: 'sticky',
        zIndex: Z_INDEX.Z_INDEX_WITHIN_COMPONENT_99,
        backgroundColor: theme.palette.common.white
    },
    body: {
        position: 'sticky',
        zIndex: Z_INDEX.Z_INDEX_WITHIN_COMPONENT_9,
        backgroundColor: theme.palette.common.white
    }
}))(TableCell);

const AppTableCellGroupStickyWrapper = ({
    cellGroupType,
    children,
    className = ''
}) => {
    const getCellGroupTypeStyle = () => {
        switch (cellGroupType) {
            case CELL_GROUP_STICKY_TYPE.STICKY_LEFT:
                return { left: 0 };
            case CELL_GROUP_STICKY_TYPE.STICKY_RIGHT:
                return { right: 0 };
            default:
                return {};
        }
    };

    return (
        <StickyTableCellGroup
            className={cx('appTableCellGroupStickyWrapper', className)}
            style={{
                ...getCellGroupTypeStyle()
            }}>
            <Box className="appTableCellGroupStickyInnerWrapper">
                {children}
            </Box>
        </StickyTableCellGroup>
    );
};

const AppTableCellGroupUnStickyWrapper = ({ children }) => {
    return <>{children}</>;
};

const AppTableCellGroupWrapper = ({
    cellGroupType,
    children,
    className = ''
}) => {
    const isStickyCell =
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_LEFT ||
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_RIGHT;

    const CellGroupWrapper = isStickyCell
        ? AppTableCellGroupStickyWrapper
        : AppTableCellGroupUnStickyWrapper;
    return (
        <CellGroupWrapper cellGroupType={cellGroupType} className={className}>
            {children}
        </CellGroupWrapper>
    );
};

export default AppTableCellGroupWrapper;
