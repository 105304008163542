import React from 'react';

import { ComboBoxField } from 'components';

const FilterComboBox = ({
    formikFieldName,
    label,
    optionValueKey,
    optionLabelKey,
    options = [],
    allowMultipleSelections = true
}) => {
    return (
        <ComboBoxField
            label={label}
            optionValueKey={optionValueKey}
            optionLabelKey={optionLabelKey}
            options={options}
            wrapperClassName="filterFieldWrapper"
            name={formikFieldName}
            allowMultipleSelections={allowMultipleSelections}
            fullWidth
        />
    );
};

export default FilterComboBox;
