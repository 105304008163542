import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { AppLogoBar } from 'layouts';
import 'pages/Authentication/Authentication.scss';

import { AuthBottomBgImg, AuthTopBgImg } from 'assets/images/authentication';

const PublicMessage = () => {
    const { t } = useTranslation();

    return (
        <Box className="authentication">
            <Box className="topBg">
                <img src={AuthTopBgImg} alt="" />
            </Box>
            <AppLogoBar theme="black" />

            <Box className="content center">
                <Typography className="desc">
                    {t('common.status.orderReturn')}
                </Typography>
            </Box>

            <Box className="bottomBg">
                <img src={AuthBottomBgImg} alt="" />
            </Box>
        </Box>
    );
};

export default PublicMessage;
