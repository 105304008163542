import { lazy } from 'react';

export { default as Loading } from './Loading/Loading';

const LazyPages = {
    // Home
    Dashboard: lazy(() => import('./Home/Dashboard/Dashboard')),
    IbDashboard: lazy(() => import('./Home/IbDashboard/IbDashboard')),
    ManagerDashboard: lazy(() =>
        import('./Home/ManagerDashboard/ManagerDashboard')
    ),
    SalesPerformance: lazy(() =>
        import('./Home/SalesPerformance/SalesPerformance')
    ),

    // CustomerMgmt
    AccountLink: lazy(() => import('./CustomerMgmt/AccountLinks/AccountLinks')),
    CreateAccountLink: lazy(() =>
        import('./CustomerMgmt/CreateAccountLink/CreateAccountLink')
    ),
    EditAccountLink: lazy(() =>
        import('./CustomerMgmt/EditAccountLink/EditAccountLink')
    ),

    AccountLinkDetail: lazy(() =>
        import(
            './CustomerMgmt/AccountLinks/components/DetailAccountLink/DetailAccountLink'
        )
    ),
    Leads: lazy(() => import('./CustomerMgmt/LeadClients/LeadClients')),
    LeadDetail: lazy(() =>
        import('./CustomerMgmt/LeadClientDetail/LeadClientDetail')
    ),

    RegisteredClients: lazy(() =>
        import('./CustomerMgmt/RegisteredClients/RegisteredClients')
    ),
    RegisteredClientDetail: lazy(() =>
        import('./CustomerMgmt/RegisteredClientDetail/RegisteredClientDetail')
    ),
    DepositedClients: lazy(() =>
        import('./CustomerMgmt/DepositedClients/DepositedClients')
    ),
    DepositedClientDetail: lazy(() =>
        import('./CustomerMgmt/DepositedClientDetail/DepositedClientDetail')
    ),

    ClientRelationTree: lazy(() =>
        import('./CustomerMgmt/ClientRelationTree/ClientRelationTree')
    ),
    // CommissionMgmt
    CommissionReport: lazy(() => import('./CommissionReport/CommissionReport')),
    CommissionDetail: lazy(() => import('./CommissionDetail/CommissionDetail')),

    // Trade Reports
    Orders: lazy(() => import('./TraderReports/Orders/Orders')),
    TradeHistory: lazy(() =>
        import('./TraderReports/TradeHistory/TradeHistory')
    ),
    TradeProduct: lazy(() =>
        import('./TraderReports/TradeProduct/TradeProduct')
    ),
    Statement: lazy(() => import('./TraderReports/Statement/Statement')),
    RollingAndHolidaySchedule: lazy(() =>
        import(
            './TraderReports/RollingAndHolidaySchedule/RollingAndHolidaySchedule'
        )
    ),

    // Funding Management
    FundFlow: lazy(() => import('./FundingManagement/FundFlow/FundFlow')),
    Deposit: lazy(() => import('./FundingManagement/Deposit/Deposit')),
    Withdraw: lazy(() => import('./FundingManagement/Withdraw/Withdraw')),
    Transfer: lazy(() => import('./FundingManagement/Transfer/Transfer')),

    Authentication: lazy(() => import('./Authentication/Authentication')),
    Registration: lazy(() => import('./Registration/Registration')),

    // User Info
    PersonalInformation: lazy(() =>
        import('./UserInfo/PersonalInformation/PersonalInformation')
    ),
    PasswordModification: lazy(() =>
        import('./UserInfo/PasswordModification/PasswordModification')
    ),
    EmailModification: lazy(() =>
        import('./UserInfo/EmailModification/EmailModification')
    ),
    RegistrationInformation: lazy(() =>
        import('./UserInfo/RegistrationInformation/RegistrationInformation')
    ),
    BankCard: lazy(() => import('./UserInfo/BankCard/BankCard')),
    Wallet: lazy(() => import('./UserInfo/Wallet/Wallet')),
    Autochartist: lazy(() => import('./UserInfo/Autochartist/Autochartist')),

    // Status
    NotEnoughInformation: lazy(() =>
        import('./Status/NotEnoughInformation/NotEnoughInformation')
    ),
    PageNotFound: lazy(() => import('./Status/PageNotFound/PageNotFound')),
    AuditFailed: lazy(() => import('./Status/AuditFailed/AuditFailed')),
    Auditing: lazy(() => import('./Status/Auditing/Auditing')),
    PublicMessage: lazy(() => import('./Status/PublicMessage/PublicMessage')),
    // Point Mall
    Reward: lazy(() => import('./Reward/Reward')),
    RewardRecordResult: lazy(() =>
        import('./Reward/RewardRecordResult/RewardRecordResult')
    )
};

export const LazyTabPages = {
    ClientFollowInfos: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientFollowInfos/ClientFollowInfos'
        )
    ),
    ClientTradeAccounts: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientTradeAccounts/ClientTradeAccounts'
        )
    ),
    ClientAccountInfos: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientAccountInfos/ClientAccountInfos'
        )
    ),
    ClientTradeReports: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientTradeReports/ClientTradeReports'
        )
    ),
    ClientAccountLink: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientAccountLink/ClientAccountLink'
        )
    ),
    ClientFundFlow: lazy(() =>
        import(
            './CustomerMgmt/components/PageTabClientDetail/ClientFundFlow/ClientFundFlow'
        )
    )
};

export default LazyPages;
