import axiosConfig from 'axios/axiosConfig';

const commonAPI = {
    // system
    getCountryList: {
        apiCall: () => axiosConfig.get('/system/countries'),
        queryKey: 'common.getCountryList'
    },
    getStatesList: {
        apiCall: (params) => axiosConfig.get('/system/states', { params }),
        queryKey: 'common.getStatesList'
    },
    getCityList: {
        apiCall: (params) => axiosConfig.get('/system/cities', { params }),
        queryKey: 'common.getCityList'
    },
    getEmploymentList: {
        apiCall: () => axiosConfig.get('/system/employments'),
        queryKey: 'common.getEmploymentList'
    },
    getBusinessList: {
        apiCall: () => axiosConfig.get('/system/businesses'),
        queryKey: 'common.getBusinessList'
    },
    getInvestmentExperienceList: {
        apiCall: () => axiosConfig.get('/system/investExps'),
        queryKey: 'common.getInvestmentExperienceList'
    },
    getBankList: {
        apiCall: (params) => axiosConfig.get('/system/banks', { params }),
        queryKey: 'common.getBankList'
    },
    getTitleList: {
        apiCall: (params) => axiosConfig.get('/system/titles', { params }),
        queryKey: 'common.getTitleList'
    },
    getIncomeSourceList: {
        apiCall: (params) =>
            axiosConfig.get('/system/incomeSources', { params }),
        queryKey: 'common.getIncomeSourceList'
    },
    getLeadClientContactRatings: {
        apiCall: () => axiosConfig.get('/system/contactRatings'),
        queryKey: 'common.getLeadClientContactRatings'
    },
    getRegisteredClientContactRatings: {
        apiCall: () => axiosConfig.get('/system/regClientRatings'),
        queryKey: 'common.getRegisteredClientContactRatings'
    },
    getDepositedClientContactRatings: {
        apiCall: () => axiosConfig.get('/system/depositClientRatings'),
        queryKey: 'common.getDepositedClientContactRatings'
    },
    getUserSources: {
        apiCall: () => axiosConfig.get('/system/userSources'),
        queryKey: 'common.getUserSources'
    },
    // user
    modifyLanguage: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/modifyLanguage`, payload),
        queryKey: 'common.modifyLanguage'
    },
    getTradeAccountList: {
        apiCall: (userId) =>
            axiosConfig.get(`/user/${userId}/tradeAccount/dropdown`),
        queryKey: 'common.getTradeAccountList'
    },
    getSubTradeAccountList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/tradeAccount/subTradeAccounts`, {
                params
            }),
        queryKey: 'common.getSubTradeAccountList'
    },
    getTradeAccountInfo: {
        apiCall: (tradeLoginId) =>
            axiosConfig.get(`/tradeAccount/${tradeLoginId}/info`),
        queryKey: 'common.getTradeAccountInfo'
    }
};

export default commonAPI;
