import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField as MuiTextField } from '@mui/material';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { FormControl, InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import FieldRightAdornmentContainer from '../Adornments/FieldRightAdornment/FieldRightAdornment';
import FieldFormikWrapper from '../FieldWrapper/FieldFormikWrapper';
import FieldPlainWrapper from '../FieldWrapper/FieldPlainWrapper';
import './TextField.scss';

const VALUE_EMPTY_FIELD = '';

const TextField = ({
    label = '',
    value = '',
    name = '',
    fullWidth = false,
    wrapperWidth = 'auto',
    inputType = 'text',
    helperText = '',
    helperTextProps = {},
    disabled = false,
    variant = 'filled',
    handleChange = null,
    handleAfterFormikChange = null,
    handleBlur = null,
    onClick = null,
    wrapperClassName = '',
    className = '',
    isReadOnly = false,
    error = false,
    inputProps = null,
    inputRef = null,
    InputProps = null,
    withoutFormikHook = false,
    placeholder = '',
    clearValue = () => {},
    isRequired = false,
    showDefaultRightAdornment = true,
    renderFieldEndComponent = null,
    children = null,
    ...props
}) => {
    const { t } = useTranslation();
    const inputLocalRef = useRef(null);
    const TextFieldWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;

    const inputFinalRef = inputRef ?? inputLocalRef;
    return (
        <TextFieldWrapper name={name} helperTextProps={helperTextProps}>
            {(wrapperProps) => {
                let InputPropsFinal = InputProps;

                const fieldValue = withoutFormikHook
                    ? value
                    : wrapperProps.value;

                const hasNoEndAdornment =
                    !InputProps || (InputProps && !InputProps.endAdornment);

                if (
                    showDefaultRightAdornment &&
                    !disabled &&
                    !isReadOnly &&
                    hasNoEndAdornment
                ) {
                    InputPropsFinal = {
                        ...InputProps,
                        endAdornment: (
                            <FieldRightAdornmentContainer
                                value={fieldValue}
                                clearValue={() => {
                                    name
                                        ? wrapperProps.clearValue()
                                        : clearValue();
                                    inputFinalRef.current.focus();
                                }}
                            />
                        )
                    };
                }

                return (
                    <AppFieldWrapper
                        className={cx('textField', wrapperClassName)}
                        width={wrapperWidth}>
                        <InputLabel label={label} isRequired={isRequired} />
                        <FormControl
                            fullWidth={fullWidth}
                            helperText={
                                withoutFormikHook
                                    ? helperText
                                    : wrapperProps.helperText
                            }
                            variant={variant}
                            className={cx(['inputField', className])}
                            error={!withoutFormikHook && wrapperProps.error}>
                            <MuiTextField
                                name={name}
                                value={
                                    isNil(fieldValue)
                                        ? VALUE_EMPTY_FIELD
                                        : fieldValue
                                }
                                onChange={(event) => {
                                    handleChange && handleChange(event);
                                    !withoutFormikHook &&
                                        wrapperProps.handleChange(event);
                                    handleAfterFormikChange &&
                                        handleAfterFormikChange(event);
                                }}
                                onBlur={(event) => {
                                    handleBlur && handleBlur(event);
                                    !withoutFormikHook &&
                                        wrapperProps.handleBlur(event);
                                }}
                                onClick={onClick}
                                disabled={disabled}
                                type={inputType}
                                inputProps={{
                                    ...inputProps,
                                    readOnly: isReadOnly
                                }}
                                inputRef={inputFinalRef}
                                InputProps={InputPropsFinal}
                                error={
                                    withoutFormikHook
                                        ? error
                                        : wrapperProps.error
                                }
                                placeholder={
                                    disabled || isReadOnly
                                        ? ''
                                        : placeholder === ''
                                        ? t('common.placeholder.pleaseEnter')
                                        : placeholder
                                }
                                {...props}
                            />
                            {typeof renderFieldEndComponent === 'function' &&
                                renderFieldEndComponent()}
                            {children}
                        </FormControl>
                    </AppFieldWrapper>
                );
            }}
        </TextFieldWrapper>
    );
};

export default TextField;
