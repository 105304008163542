import isEmpty from 'lodash/isEmpty';

import { useTradingPlatforms } from 'hooks';

const PlainTextPlatformField = ({ platform }) => {
    const { platforms } = useTradingPlatforms();

    if (isEmpty(platforms)) return '---';

    const platformLabel = platforms.find(({ key }) => platform === key);

    return platformLabel?.value ?? '---';
};

export default PlainTextPlatformField;
