import cx from 'classnames';

import { InputLabel, ScrollSelectTradeAccounts } from 'components';
import { AppFieldWrapper } from 'layouts';

const ScrollSelectTradeAccountField = ({
    label,
    tradeAccounts,
    defaultTradeLoginId = undefined,
    wrapperWidth,
    handleSelectTradeLoginId,
    wrapperClassName,
    showSelectAll = false,
    showCurrOnly = false,
    isTriggerInitHandleSelect = false
}) => {
    return (
        <AppFieldWrapper
            className={cx('textField', wrapperClassName)}
            width={wrapperWidth}>
            <InputLabel label={label} />
            <ScrollSelectTradeAccounts
                tradeAccounts={tradeAccounts}
                defaultTradeLoginId={defaultTradeLoginId}
                handleSelectTradeLoginId={handleSelectTradeLoginId}
                showSelectAll={showSelectAll}
                showCurrOnly={showCurrOnly}
                showEvenOnlyOneAccount
                isTriggerInitHandleSelect={isTriggerInitHandleSelect}
            />
        </AppFieldWrapper>
    );
};

export default ScrollSelectTradeAccountField;
