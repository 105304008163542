import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import { TextField } from 'components';
import AdornmentReveal from 'components/Fields/Adornments/AdornmentReveal/AdornmentReveal';

import PasswordFieldPlainWrapper from './PasswordFieldPlainWrapper/PasswordFieldPlainWrapper';
import PasswordFieldToolTipWrapper from './PasswordFieldToolTipWrapper/PasswordFieldToolTipWrapper';
import useUnmaskedValueFix from './hooks/useUnmaskedValueFix';
import { getStrengthLevel } from './utils';

const PasswordField = ({
    name,
    label,
    className,
    handleBlur = null,
    handleChange = null,
    onClick = null,
    showPasswordToolTip = false,
    wrapperClassName = '',
    isRequired = false,
    disableBrowserAutocomplete = false,
    inputProps = {},
    placeholder = '',
    ...props
}) => {
    const { t } = useTranslation();

    const [isPasswordUnmasked, setIsPasswordUnmasked] = useState(false);

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [isShowToolTip, setIsShowToolTip] = useState(false);

    useUnmaskedValueFix(name);

    const PasswordFieldWrapper = showPasswordToolTip
        ? PasswordFieldToolTipWrapper
        : PasswordFieldPlainWrapper;

    const onPasswordChange = (event) => {
        handleChange && handleChange(event);

        if (showPasswordToolTip) {
            const password = event.target.value;
            const nextPasswordStrength = getStrengthLevel(password);
            setIsShowToolTip(true);

            setPasswordStrength(nextPasswordStrength);
        }
    };
    return (
        <PasswordFieldWrapper
            passwordStrength={passwordStrength}
            isShowToolTip={isShowToolTip}
            className={cx('passwordField', wrapperClassName)}>
            <TextField
                name={name}
                className={className}
                label={label}
                inputType={isPasswordUnmasked ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <AdornmentReveal
                            isShowField={isPasswordUnmasked}
                            setIsShowField={setIsPasswordUnmasked}
                        />
                    )
                }}
                handleBlur={handleBlur}
                handleChange={onPasswordChange}
                onClick={onClick}
                isRequired={isRequired}
                inputProps={{
                    ...inputProps,
                    ...(disableBrowserAutocomplete && {
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off'
                        }
                    })
                }}
                placeholder={
                    placeholder === ''
                        ? t('common.placeholder.password')
                        : placeholder
                }
                {...props}
            />
        </PasswordFieldWrapper>
    );
};

export default PasswordField;
