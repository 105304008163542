import axiosConfig from 'axios/axiosConfig';

const reportAPI = {
    getPerformanceHistory: {
        apiCall: (userId, params) =>
            axiosConfig.get(`report/sales/${userId}/performance/history`, {
                params
            }),
        queryKey: 'report.getPerformanceHistory'
    },
    getPerformanceDetail: {
        apiCall: (userId, params) =>
            axiosConfig.get(
                `report/sales/${userId}/performance/history/detail`,
                { params }
            ),
        queryKey: 'report.getPerformanceDetail'
    },
    getWithdrawalHistory: {
        apiCall: (userId, params) =>
            axiosConfig.get(`report/sales/${userId}/withdrawalHistory`, {
                params
            }),
        queryKey: 'report.getWithdrawalHistory'
    },
    getPerformanceInfo: {
        apiCall: (userId) =>
            axiosConfig.get(`report/sales/${userId}/performance/info`),
        queryKey: 'report.getPerformanceInfo'
    },
    getVolumeInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/report/sales/${userId}/performance/volume/info`, {
                params
            }),
        queryKey: 'report.getVolumeInfo'
    },
    getVolumeHistory: {
        apiCall: (userId) =>
            axiosConfig.get(
                `/report/sales/${userId}/performance/volume/history`
            ),
        queryKey: 'report.getVolumeHistory'
    },
    getDepositInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(
                `/report/sales/${userId}/performance/deposit/info`,
                { params }
            ),
        queryKey: 'report.getDepositInfo'
    },
    getDepositHistory: {
        apiCall: (userId) =>
            axiosConfig.get(
                `/report/sales/${userId}/performance/deposit/history`
            ),
        queryKey: 'report.getDepositHistory'
    },
    getRankInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/report/sales/${userId}/performance/rank/info`, {
                params
            }),
        queryKey: 'report.getRankInfo'
    }
};

export default reportAPI;
