import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import cx from 'classnames';

import SectionDashLineWrapper from 'layouts/Sections/SectionDashLineWrapper/SectionDashLineWrapper';

import './AppTableFooterButton.scss';

const AppTableFooterButton = ({
    label,
    BtnIco = null,
    to = null,
    onClick = null,
    className,
    disabled = false,
    ...props
}) => {
    const SectionWrapper = to ? LinkWrapper : NormalWrapper;

    return (
        <SectionWrapper to={to}>
            <SectionDashLineWrapper
                onClick={onClick}
                className={cx(['btn', 'btnTableFooter', className])}
                disabled={disabled}
                {...props}>
                <Box className="icoWrapper">{BtnIco}</Box>
                <span className="labelTxt">{label}</span>
            </SectionDashLineWrapper>
        </SectionWrapper>
    );
};

const LinkWrapper = ({ to, children }) => {
    return <Link to={to}>{children}</Link>;
};

const NormalWrapper = ({ children }) => {
    return <>{children}</>;
};

export default AppTableFooterButton;
