import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppDashboard, AppPublic } from 'layouts';
import PublicMessage from 'pages/Status/PublicMessage/PublicMessage';

import AppProvider from './providers/AppProvider';

const RouteSwitch = () => {
    const { isLogin, authToken, publicMsg } = useSelector(
        (state) => state.user
    );

    return (
        <BrowserRouter>
            <AppProvider>
                {publicMsg ? (
                    <PublicMessage />
                ) : isLogin && authToken ? (
                    <AppDashboard />
                ) : (
                    <AppPublic />
                )}
            </AppProvider>
        </BrowserRouter>
    );
};

export default RouteSwitch;
