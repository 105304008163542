import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';

import { UPPER_LIMIT_OF_SET_INTERVAL_DELAY_VALUE } from 'constants/constants';

export const getToday = () => new Date();
export const getTomorrow = () => startOfDay(addDays(TODAY, 1));
export const getYesterday = () => startOfDay(addDays(TODAY, -1));

const getNextTodayAndTomorrowUpdateCounter = (millisecondsBeforeNextDay) => {
    TODAY = getToday();
    TOMORROW = getTomorrow();

    millisecondsBeforeNextDay = (TOMORROW - TODAY) * 1000;

    return Math.min(
        millisecondsBeforeNextDay,
        UPPER_LIMIT_OF_SET_INTERVAL_DELAY_VALUE
    );
};

let TODAY = getToday();
let TOMORROW = getTomorrow();
let YESTERDAY = getYesterday();

const getTodayAndTomorrow = () => {
    let millisecondsBeforeNextDay = (TOMORROW - TODAY) * 1000;

    let counter = getNextTodayAndTomorrowUpdateCounter(
        millisecondsBeforeNextDay
    );

    const updateTodayAndTomorrow = function () {
        counter = getNextTodayAndTomorrowUpdateCounter();
        setTimeout(updateTodayAndTomorrow, counter);
    };

    setTimeout(updateTodayAndTomorrow, counter);
};

getTodayAndTomorrow();

export { TODAY, TOMORROW, YESTERDAY };
