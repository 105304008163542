import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LANGUAGE_INFO } from 'constants/languageInfo';

import translationEN from 'locales/en.json';
import translationSC from 'locales/sc.json';
import translationTH from 'locales/th.json';
import translationVI from 'locales/vi.json';

export const LANG_URL_QUERY_STRING = 'lang';

const resources = {
    [LANGUAGE_INFO['en-US'].localeKey]: {
        translation: translationEN
    },
    [LANGUAGE_INFO['zh-CN'].localeKey]: {
        translation: translationSC
    },
    [LANGUAGE_INFO['th'].localeKey]: {
        translation: translationTH
    },
    [LANGUAGE_INFO['vi'].localeKey]: {
        translation: translationVI
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: Object.values(LANGUAGE_INFO).map(
            (langInfo) => langInfo.localeKey
        ),
        detection: {
            order: ['querystring', 'localStorage', 'navigator'],
            lookupQuerystring: LANG_URL_QUERY_STRING
        },
        fallbackLng: LANGUAGE_INFO['en-US'].localeKey,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        compatibilityJSON: 'v4'
    });

export default i18n;

export const CURRENT_LANGUAGE = i18n.resolvedLanguage;
