import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { NoContentBoard } from 'components';

import { DATE_FORMAT } from 'constants/dateFormats';
import { useInfiniteLoading } from 'hooks';

import DateSectionList from './DateSectionList';
import './InfiniteDateSectionList.scss';

const InfiniteDateSectionList = ({
    dateKey,
    renderSectionHeader,
    renderItem,
    getItems,
    keyFieldName = '',
    exceptKeyFieldName = false,
    renderListHeaderComponent = null,
    getListContentRef = null,
    parseDateFormat = DATE_FORMAT.yMd,
    itemNumberInRow = 1
}) => {
    const { t } = useTranslation();

    const { list, isLoading, refresh, fetchNextPage } = useInfiniteLoading({
        getItems,
        disableSnackbar: true
    });
    const isListEmpty = isEmpty(list);
    const isListEnd = !isLoading && !isListEmpty;
    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderListFooterComponent = () =>
        (isLoading || isListEnd) && (
            <Box className="listFooter">
                <Typography>
                    {isLoading
                        ? `${t('common.general.loading')}...`
                        : t('common.general.listEnd')}
                </Typography>
            </Box>
        );
    const renderListEmptyComponent = () =>
        !isLoading && <NoContentBoard labelType="noData" />;

    return (
        <DateSectionList
            className="infiniteDateSectionList"
            list={list}
            dateKey={dateKey}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            keyFieldName={keyFieldName}
            exceptKeyFieldName={exceptKeyFieldName}
            endReached={fetchNextPage}
            renderListHeaderComponent={renderListHeaderComponent}
            renderListFooterComponent={renderListFooterComponent}
            renderListEmptyComponent={renderListEmptyComponent}
            getListContentRef={getListContentRef}
            parseDateFormat={parseDateFormat}
            itemNumberInRow={itemNumberInRow}
        />
    );
};

export default InfiniteDateSectionList;
