import { useNavigate } from 'react-router-dom';

import { TableRow } from '@mui/material';
import cx from 'classnames';

import AppTableCellGroup from '../AppTableCellGroup/AppTableCellGroup';

const AppTableRow = ({
    tableCellGroupInfos,
    rowData,
    rowIndex = -1,
    className = '',
    highLighted = false,
    rowClickPath = '',
    rowClickQueryParam = '',
    viewMethod = () => {}
}) => {
    const navigate = useNavigate();

    return (
        <TableRow
            className={cx([
                'appTableRow',
                highLighted && 'appTableRowHighLighted',
                className
            ])}
            onClick={(e) => {
                e.preventDefault();
                if (rowClickPath && rowClickQueryParam) {
                    navigate(`${rowClickPath}/${rowData[rowClickQueryParam]}`);
                }
                viewMethod(rowData);
            }}>
            {tableCellGroupInfos.map((tableCellGroup, index) => (
                <AppTableCellGroup
                    key={index}
                    rowIndex={rowIndex}
                    tableCellGroup={tableCellGroup}
                    rowData={rowData}
                />
            ))}
        </TableRow>
    );
};

export default AppTableRow;
