import { useSelector } from 'react-redux';

import { getRegistrationStatus } from 'utils/registration';

const useRegistrationStatus = () => {
    const { userAccStatus, docStatus, infoStatus } = useSelector(
        (state) => state.user
    );

    return getRegistrationStatus(userAccStatus, docStatus, infoStatus);
};

export default useRegistrationStatus;
