import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useOpenSnackbar from './useOpenSnackbar';

const useSnackbarOnNetworkDisconnection = () => {
    const { t } = useTranslation();
    const { closeSnackbar, openErrorSnackbar } = useOpenSnackbar();

    useEffect(() => {
        const clearSnackbar = () => closeSnackbar();
        const showNetworkDisconnectionSnackbar = () => {
            openErrorSnackbar(t('common.status.networkError'), {
                persist: true,
                disableCloseButton: true
            });
        };
        window.addEventListener('online', clearSnackbar);
        window.addEventListener('offline', showNetworkDisconnectionSnackbar);

        return () => {
            window.removeEventListener('online', clearSnackbar);
            window.removeEventListener(
                'offline',
                showNetworkDisconnectionSnackbar
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useSnackbarOnNetworkDisconnection;
