import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { AppFormButton, AppFormSubmitButton, DetailCard } from 'components';
import { AppStatusContent } from 'layouts';

import './ConfirmBoard.scss';
import { StatusExclamationImg } from 'assets/images/status';

const ConfirmBoard = ({ confirmInfos, onEdit, reminders = [] }) => {
    const { t } = useTranslation();

    return (
        <Box className="confirmBoard">
            <AppStatusContent
                title={t('common.general.confirmBelowInfo')}
                contentMarginTop={0}
                StatusImgSrc={StatusExclamationImg}
                ButtonList={[
                    <AppFormSubmitButton
                        fullWidth
                        variant="contained"
                        label={t('common.action.confirm')}
                    />,
                    <AppFormButton
                        fullWidth
                        variant="contained"
                        color="secondary"
                        label={t('common.action.edit')}
                        onClick={onEdit}
                    />
                ]}>
                <DetailCard cardData={confirmInfos} />
                <Box>
                    {reminders.map((reminder, index) => (
                        <Typography key={index} className="reminderText">
                            {reminder}
                        </Typography>
                    ))}
                </Box>
            </AppStatusContent>
        </Box>
    );
};

export default ConfirmBoard;
