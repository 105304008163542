import { Box, Tooltip } from '@mui/material';

import { useWindowSize } from 'hooks';

import PasswordFieldStrengthRemind from '../PasswordFieldStrengthRemind/PasswordFieldStrengthRemind';
import { getStrengthInfo } from '../utils';
import './PasswordFieldToolTipWrapper.scss';

const PasswordFieldToolTipWrapper = ({
    passwordStrength,
    isShowToolTip,
    children
}) => {
    const strengthInfo = getStrengthInfo(passwordStrength);
    const { isDesktop } = useWindowSize();

    return isDesktop ? (
        <Tooltip
            open={isShowToolTip}
            id={'passwordFieldToolTip'}
            title={<PasswordFieldStrengthRemind strengthInfo={strengthInfo} />}
            components={{
                Arrow: () => <div className="tooltipArrow"></div>
            }}
            placement="right-start"
            arrow>
            <Box className="appFieldWrapper passwordField">{children}</Box>
        </Tooltip>
    ) : (
        <>
            <Box className="appFieldWrapper passwordField">{children}</Box>
            {isShowToolTip && (
                <PasswordFieldStrengthRemind strengthInfo={strengthInfo} />
            )}
        </>
    );
};

export default PasswordFieldToolTipWrapper;
