import React from 'react';

import { Box, Typography } from '@mui/material';

import { Button } from 'components';

import './DialogContentRemind.scss';

const DialogContentRemind = ({
    reminderMessage,
    confirmButtonLabel,
    cancelButtonLabel,
    onConfirm,
    onCancel,
    isCancelButtonHidden = false
}) => {
    return (
        <Box className="dialogContentRemind">
            <Typography className="message">{reminderMessage}</Typography>
            <Box className="buttonRow">
                {!isCancelButtonHidden && (
                    <Button
                        className="button"
                        color="secondary"
                        variant="contained"
                        label={cancelButtonLabel}
                        onClick={onCancel}
                    />
                )}
                <Button
                    className="button"
                    color="primary"
                    variant="contained"
                    label={confirmButtonLabel}
                    onClick={onConfirm}
                />
            </Box>
        </Box>
    );
};

export default DialogContentRemind;
