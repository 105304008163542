import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import cx from 'classnames';

import { FormControl, InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { useFormikFieldProps } from 'hooks';

import './RadioButtonField.scss';

const RadioButtonField = ({
    name,
    label,
    items,
    value = null,
    handleBlur = null,
    handleChange = null,
    onClick = null,
    isRow = true,
    variant = 'filled',
    fullWidth = false,
    disabled = false,
    wrapperClassName = '',
    className = '',
    isRequired = false
}) => {
    const fieldProps = useFormikFieldProps(name);

    const onChange = (event) => {
        fieldProps.setTouched(true);
        fieldProps.onChange && fieldProps.onChange(event);
        handleChange && handleChange(event);
    };

    const onBlur = (event) => {
        const { currentTarget, relatedTarget } = event;
        if (handleBlur && !currentTarget.contains(relatedTarget)) {
            handleBlur(event);
        }
    };

    return (
        <AppFieldWrapper className={cx('radioButtonField', wrapperClassName)}>
            <InputLabel label={label} isRequired={isRequired} />
            <FormControl
                fullWidth={fullWidth}
                helperText={fieldProps.helperText}
                error={fieldProps.error}
                variant={variant}
                disabled={disabled}
                className={className}>
                <RadioGroup
                    row={isRow}
                    name={name}
                    value={value ?? fieldProps.value}
                    onBlur={onBlur}
                    onChange={onChange}>
                    {items.map(({ itemValue, itemLabel }, index) => (
                        <FormControlLabel
                            key={index}
                            value={itemValue}
                            control={<Radio />}
                            label={itemLabel}
                            onClick={(event) =>
                                onClick && onClick(event, index)
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </AppFieldWrapper>
    );
};

export const createRadioButtonItem = ({ itemLabel, itemValue }) => ({
    itemLabel,
    itemValue
});

export default RadioButtonField;
