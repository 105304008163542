import React, { useRef, useState } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';

import { Box, TextField as MuiTextField, Popover } from '@mui/material';
import cx from 'classnames';

import { InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { DATE_FORMAT } from 'constants/dateFormats';
import { DATE_DEFINE, PICKER_START_DATE } from 'constants/dateInfos';
import { useDidUpdateEffect, useWindowSize } from 'hooks';

import { localeConversion } from 'utils/utils';

import styles from 'styles/styles.module.scss';

import './DateRangePickerField.scss';
import DateRangePickerWrapper from './DateRangePickerWrapper/DateRangePickerWrapper';
import { CalendarIco } from 'assets/images/common';

const DateRangePickerField = ({
    label = '',
    placeholder = '',
    wrapperClassName = '',
    onChange = null,
    value = null,
    formikFieldNames = [],
    withoutFormikHook = false,
    allowDisable = false
}) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverShown = Boolean(anchorEl);
    const [isFocused, setIsFocused] = useState(false);
    const startDateFieldRef = useRef(null);
    const startDateInputRef = useRef(null);
    const endDateInputRef = useRef(null);

    const { isMobile } = useWindowSize();

    useDidUpdateEffect(() => {
        if (
            document.activeElement === startDateInputRef.current ||
            document.activeElement === endDateInputRef.current ||
            isPopoverShown
        ) {
            setIsFocused(true);
        }

        if (!isPopoverShown) {
            setIsFocused(false);
        }
    }, [anchorEl]);

    const textFieldInputProps = {
        readOnly: true,
        endAdornment: <CalendarIco />
    };

    const handleFieldsClick = (event) => {
        setAnchorEl(startDateFieldRef.current);
    };

    const getStaticDateRanges = () => {
        const disableSelect = [
            {
                label: t('common.general.stopUse'),
                range: () => ({
                    startDate: DATE_DEFINE.UNSELECT_DATE.dateObj,
                    endDate: DATE_DEFINE.UNSELECT_DATE.dateObj
                })
            }
        ];

        return [
            ...createStaticRanges([
                {
                    label: t('common.date.today'),
                    range: () => ({
                        startDate: DATE_DEFINE.TODAY_START.dateObj,
                        endDate: DATE_DEFINE.TODAY_END.dateObj
                    })
                },
                {
                    label: t('common.date.7days'),
                    range: () => ({
                        startDate: DATE_DEFINE.LAST_SEVEN_DAY_START.dateObj,
                        endDate: DATE_DEFINE.TODAY_END.dateObj
                    })
                },
                {
                    label: t('common.date.15days'),
                    range: () => ({
                        startDate: DATE_DEFINE.LAST_FOURTEEN_DAY_START.dateObj,
                        endDate: DATE_DEFINE.TODAY_END.dateObj
                    })
                },
                {
                    label: t('common.date.30days'),
                    range: () => ({
                        startDate: DATE_DEFINE.LAST_THIRTY_DAY_START.dateObj,
                        endDate: DATE_DEFINE.TODAY_END.dateObj
                    })
                },
                {
                    label: t('common.date.1year'),
                    range: () => ({
                        startDate: DATE_DEFINE.LAST_YEAR_START.dateObj,
                        endDate: DATE_DEFINE.TODAY_END.dateObj
                    })
                },
                ...(allowDisable ? disableSelect : [])
            ])
        ];
    };

    return (
        <DateRangePickerWrapper
            startDateName={formikFieldNames[0]}
            endDateName={formikFieldNames[1]}
            withoutFormikHook={withoutFormikHook}
            value={value}
            onChange={onChange}
            setAnchorEl={setAnchorEl}>
            {({
                startDateString,
                endDateString,
                calendarDateRange,
                handleDateRangeChange,
                disabledCalendar
            }) => {
                const staticDateRanges = getStaticDateRanges();

                const onFieldsClick = (event) => {
                    if (disabledCalendar) {
                        const enableCalendar = {
                            selection: {
                                startDate: DATE_DEFINE.TODAY_END.dateObj,
                                endDate: DATE_DEFINE.TODAY_END.dateObj
                            }
                        };
                        handleDateRangeChange(enableCalendar);
                        handleFieldsClick(event);
                        return;
                    }

                    handleFieldsClick(event);
                };

                return (
                    <AppFieldWrapper
                        className={cx([
                            'dateRangePickerField',
                            wrapperClassName
                        ])}>
                        <InputLabel label={label} />
                        <Box className="fieldsWrapper">
                            <MuiTextField
                                ref={startDateFieldRef}
                                inputRef={startDateInputRef}
                                placeholder={
                                    placeholder ??
                                    t('common.placeholder.dateRange')
                                }
                                className={cx('startDate', {
                                    focused: isFocused
                                })}
                                InputProps={textFieldInputProps}
                                onClick={onFieldsClick}
                                value={
                                    disabledCalendar ? '---' : startDateString
                                }
                            />
                            <Box className="hyphen">—</Box>
                            <MuiTextField
                                inputRef={endDateInputRef}
                                placeholder={
                                    placeholder ??
                                    t('common.placeholder.dateRange')
                                }
                                className={cx('endDate', {
                                    focused: isFocused
                                })}
                                InputProps={textFieldInputProps}
                                onClick={onFieldsClick}
                                value={disabledCalendar ? '---' : endDateString}
                            />
                        </Box>

                        <Popover
                            open={isPopoverShown}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            className={cx([
                                'dateRangePopover',
                                { disabledCalendar }
                            ])}>
                            <DateRangePicker
                                format={DATE_FORMAT.yMd}
                                onChange={handleDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                showMonthAndYearPickers={true}
                                months={isMobile ? 1 : 2}
                                ranges={[calendarDateRange]}
                                rangeColors={[styles.THEME_COLOR_PRIMARY]}
                                direction="horizontal"
                                showDateDisplay={false}
                                staticRanges={staticDateRanges}
                                inputRanges={[]}
                                locale={localeConversion(locale)}
                                maxDate={DATE_DEFINE.TODAY_END.dateObj}
                                minDate={PICKER_START_DATE}
                            />
                        </Popover>
                    </AppFieldWrapper>
                );
            }}
        </DateRangePickerWrapper>
    );
};

export default DateRangePickerField;
