import React, { forwardRef, useCallback } from 'react';

import { Alert } from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';

const AppSnackbar = forwardRef(
    ({ id, message, severity, disableCloseButton = false }, ref) => {
        const { closeSnackbar } = useSnackbar();

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        return (
            <SnackbarContent ref={ref}>
                <Alert
                    severity={severity}
                    {...(!disableCloseButton && { onClose: handleDismiss })}>
                    {message}
                </Alert>
            </SnackbarContent>
        );
    }
);

export default AppSnackbar;
