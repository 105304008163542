import { useRef } from 'react';

import isEqual from 'lodash/isEqual';

// return identical object if value and ref.current(old value) deep compare are the same
const useDeepEqualMemo = (value) => {
    const ref = useRef(undefined);

    if (!isEqual(ref.current, value)) {
        ref.current = value;
    }

    return ref.current;
};

export default useDeepEqualMemo;
