import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import './AppFooter.scss';

const AppFooter = () => {
    const { t } = useTranslation();

    return (
        <Box component="footer">
            <Box className="footerWrapper">
                <Typography className="copyright">
                    {t('common.footer.copyright')}
                </Typography>
            </Box>
        </Box>
    );
};

export default AppFooter;
