import React, { useRef, useState } from 'react';

import { Box, TextField as MuiTextField, Popover } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { DATE_FORMAT } from 'constants/dateFormats';
import { DATE_DEFINE, PICKER_START_DATE } from 'constants/dateInfos';
import { useFormikFieldProps } from 'hooks';

import { dateObjToDateString, unixToDateString } from 'utils/dateConversion';
import { localeConversion } from 'utils/utils';

import './FilterCalendar.scss';
import { CalendarIco } from 'assets/images/common';

const FilterCalendar = ({ formikFieldName, label, view }) => {
    const locale = localStorage.getItem('i18nextLng');

    const [anchorEl, setAnchorEl] = useState(null);
    const calendarDateFieldRef = useRef(null);

    const defaultDate = DATE_DEFINE.TODAY_START.yMd;

    const fieldProps = useFormikFieldProps(formikFieldName);
    const date = fieldProps.value || defaultDate;

    const textFieldInputProps = {
        readOnly: true,
        endAdornment: <CalendarIco />
    };

    const handleFieldsClick = (event) => {
        setAnchorEl(calendarDateFieldRef.current);
    };
    return (
        <AppFieldWrapper className="filterFieldWrapper filterCalendar">
            <InputLabel label={label} />
            <Box className="fieldsWrapper">
                <MuiTextField
                    ref={calendarDateFieldRef}
                    className="date"
                    InputProps={textFieldInputProps}
                    onClick={handleFieldsClick}
                    value={unixToDateString(
                        date,
                        view === 'month' ? DATE_FORMAT.yM : DATE_FORMAT.yMd
                    )}
                />
            </Box>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className="calendarPopover">
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={localeConversion(locale)}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        views={view === 'month' ? ['year', 'month'] : ['day']}
                        openTo={view === 'month' ? 'month' : 'day'}
                        maxDate={new Date()}
                        minDate={PICKER_START_DATE}
                        onChange={(newValue) => {
                            fieldProps.setValue(
                                dateObjToDateString(newValue, DATE_FORMAT.yMd)
                            );
                        }}
                        value={new Date(fieldProps.value)}
                        renderInput={(params) => (
                            <MuiTextField {...params} helperText={null} />
                        )}
                    />
                </LocalizationProvider>
            </Popover>
        </AppFieldWrapper>
    );
};

export default FilterCalendar;
