import React from 'react';

import routeInfos from 'routes/publicRoutes';

import AppRouter from './AppRouter/AppRouter';

const PublicRouter = () => {
    return <AppRouter routeInfos={routeInfos} />;
};

export default PublicRouter;
