import { Box } from '@mui/material';
import cx from 'classnames';

import './AppFormStyleWrapper.scss';

const appFormStyleWrapper = ({
    children,
    classNames = 'singleLineFormWrapper' // 'singleLineFormWrapper' or 'doubleLinesFormWrapper'
}) => {
    return (
        <Box className={cx(['appFormStyleWrapper', classNames])}>
            {children}
        </Box>
    );
};

export default appFormStyleWrapper;
