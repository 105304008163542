export const groupTableColInfosByStickyType = (tableInfos) => {
    let prevStickyType = undefined;
    const tableCellGroupInfos = [];
    tableInfos.forEach((currCellInfo) => {
        const currStickyType = currCellInfo.stickyType;

        if (prevStickyType === currStickyType) {
            if (tableCellGroupInfos[tableCellGroupInfos.length - 1]) {
                tableCellGroupInfos[tableCellGroupInfos.length - 1].push(
                    currCellInfo
                );
            } else {
                tableCellGroupInfos.push([currCellInfo]);
            }
        } else {
            tableCellGroupInfos.push([currCellInfo]);
        }

        prevStickyType = currStickyType;
    });

    return tableCellGroupInfos;
};
