import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import format from 'date-fns/format';

import {
    NumFormat,
    PlainTextMaskableField,
    PlainTextPlatformField,
    PlainTextTradeCategoriesField,
    PlainTextTradeStatusesField,
    PlainTextTradeTypesField
} from 'components';

import { defaultTablePageValues } from 'constants/appTableInfos';
import { DATE_FORMAT } from 'constants/dateFormats';
import { defaultAppFilterDateRange } from 'constants/filterInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { unmaskFieldInfo, useFundFlowRecord, useOpenSnackbar } from 'hooks';

import API from 'services';
import { getAccountTypeLocale } from 'utils/client/accountType';
import {
    calDayDifferenceByDayStrings,
    formatDateToStringFromAPI
} from 'utils/dateConversion';
import { exportExcelFile } from 'utils/filterProcess/fileTransfer';
import { handleAsyncCall } from 'utils/utils';

const initPayload = {
    ...defaultTablePageValues,
    startDate: defaultAppFilterDateRange[0],
    endDate: defaultAppFilterDateRange[1]
};

const useTableFundFlow = (
    searchTradeLoginId = VALUES_ALL_TRADE_LOGIN_IDS.API_PAYLOAD
) => {
    const { t } = useTranslation();
    const { openSuccessSnackbar, openErrorSnackbar } = useOpenSnackbar();
    const { userId } = useSelector((state) => state.user);
    const { fundFlowData, getFundFlowRecords, isFetching, setIsFetching } =
        useFundFlowRecord([]);

    const [fundFlowPayload, setFundFlowPayload] = useState({
        ...initPayload,
        searchTradeLoginIds: searchTradeLoginId
    });

    useEffect(() => {
        getFundFlowRecords(fundFlowPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fundFlowPayload]);

    const exportExcel = () => {
        if (isFetching) return;

        const { startDate, endDate } = fundFlowPayload;

        const dateDifferent = calDayDifferenceByDayStrings([
            startDate,
            endDate
        ]);

        if ((!startDate && !endDate) || dateDifferent > 30) {
            openErrorSnackbar(t('common.snackbar.downloadFailedWithinMonthly'));
            return;
        }

        setIsFetching(true);

        handleAsyncCall({
            asyncCall: () =>
                API.fundingMgmt.fundFlowExport.apiCall(userId, fundFlowPayload),
            handleCallSuccess: (res) => {
                openSuccessSnackbar(t('common.snackbar.downloadSuccess'));

                const fileName = `${t(
                    'fundingMgmt.fundFlow.excelFileName'
                )}_${format(new Date(), DATE_FORMAT.ymdHms)}`;

                exportExcelFile(res, fileName);
                setIsFetching(false);
            },
            handleCallFailureError: (res) => {
                openErrorSnackbar(
                    res.message || t('common.snackbar.downloadFailed')
                );
                setIsFetching(false);
            }
        });
    };
    const fundFlowTableColumnInfos = {
        ticket: {
            field: 'ticket',
            label: t('common.table.orderNo')
        },
        displayName: {
            field: 'displayName',
            label: t('common.user.username.displayName')
        },
        tradeLoginId: {
            field: 'tradeLoginId',
            label: t('common.tradeAccount.tradeLoginId')
        },
        platform: {
            field: 'platform',
            label: t('common.tradeAccount.platform'),
            renderCell: ({ platform }) => (
                <PlainTextPlatformField platform={platform} />
            )
        },
        amount: {
            field: 'amount',
            label: t('common.table.amount'),
            renderCell: ({ amount }) => <NumFormat value={amount} />
        },
        tradeType: {
            field: 'tradeType',
            label: t('common.table.type'),
            renderCell: ({ tradeType }) => (
                <PlainTextTradeTypesField tradeType={tradeType} />
            )
        },
        category: {
            field: 'category',
            label: t('common.table.method'),
            renderCell: ({ category }) => (
                <PlainTextTradeCategoriesField tradeCategory={category} />
            )
        },
        status: {
            field: 'status',
            label: t('common.table.status'),
            renderCell: ({ status }) => (
                <PlainTextTradeStatusesField tradeStatus={status} />
            )
        },
        parentTradeLoginId: {
            field: 'parentTradeLoginId',
            label: t('common.table.parentIB')
        },
        mgrTradeLoginId: {
            field: 'mgrTradeLoginId',
            label: t('common.sales.mgrTradeLoginId')
        },
        createTime: {
            field: 'createTime',
            label: t('common.date.createTime'),
            renderCell: ({ createTime }) =>
                formatDateToStringFromAPI(createTime)
        },
        accountTypeDisplay: {
            field: 'accountType',
            label: t('common.table.accountType'),
            renderCell: ({ accountType }) =>
                getAccountTypeLocale(accountType, t)
        },
        currency: {
            field: 'currency',
            label: t('common.table.currency')
        },
        transLogId: {
            field: 'transLogId',
            label: t('common.table.serialNumber')
        },
        email: {
            field: 'email',
            label: t('common.user.communication.email'),
            renderCell: ({ email, subAccount, tradeLoginId }) => {
                return (
                    <PlainTextMaskableField
                        maskedValue={email}
                        fieldName={unmaskFieldInfo.userEmail.fieldName}
                        unmaskApiParam={{
                            tradeLoginId
                        }}
                        isUnmaskable={subAccount}
                    />
                );
            }
        },
        departmentName: {
            field: 'departmentName',
            label: t('common.user.work.department')
        },
        reference: {
            field: 'reference',
            label: t('common.table.comment'),
            renderCell: ({ reference }) => reference || '---'
        }
    };

    const totalStatFields = [
        {
            field: 'deposit',
            label: t('fundingMgmt.fundFlow.statistics.deposit'),
            color: 'orange'
        },
        {
            field: 'withdrawal',
            label: t('fundingMgmt.fundFlow.statistics.withdrawal'),
            color: 'blue'
        },
        {
            field: 'creditIn',
            label: t('fundingMgmt.fundFlow.statistics.creditIn'),
            color: 'green'
        },
        {
            field: 'creditOut',
            label: t('fundingMgmt.fundFlow.statistics.creditOut'),
            color: 'red'
        }
    ];

    const totalStatItems = totalStatFields.map(({ field, label, color }) => {
        return {
            label,
            value: fundFlowData[field],
            color
        };
    });

    return {
        fundFlowData,
        fundFlowTableColumnInfos,
        totalStatItems,
        fundFlowPayload,
        setFundFlowPayload,
        exportExcel,
        isFetching
    };
};

export default useTableFundFlow;
