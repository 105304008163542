import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const useAccStatusList = () => {
    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [API.system.getAccountStatusList.queryKey],
        apiCall: API.system.getAccountStatusList.apiCall
    });

    return {
        accStatusList: isSuccess ? getResponseList(data) : []
    };
};

export default useAccStatusList;
