export const WEEKDAY = {
    SUNDAY: {
        dayKey: 0,
        i18nKey: 'common.weekdays.sunday'
    },
    MONDAY: {
        dayKey: 1,
        i18nKey: 'common.weekdays.monday'
    },
    TUESDAY: {
        dayKey: 2,
        i18nKey: 'common.weekdays.tuesday'
    },
    WEDNESDAY: {
        dayKey: 3,
        i18nKey: 'common.weekdays.wednesday'
    },
    THURSDAY: {
        dayKey: 4,
        i18nKey: 'common.weekdays.thursday'
    },
    FRIDAY: {
        dayKey: 5,
        i18nKey: 'common.weekdays.friday'
    },
    SATURDAY: {
        dayKey: 6,
        i18nKey: 'common.weekdays.saturday'
    }
};
