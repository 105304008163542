import React from 'react';

import { Box, CardHeader, Typography } from '@mui/material';
import cx from 'classnames';

import './AppCardHeader.scss';

const AppCardHeader = ({ title, titleColor = '', RightActionGroup = null }) => {
    return (
        <CardHeader
            className="cardHeaderWrapper"
            title={
                title && (
                    <Typography
                        component="span"
                        className={cx(['titleWrapper', titleColor])}>
                        {title}
                    </Typography>
                )
            }
            action={
                typeof RightActionGroup === 'object' && (
                    <Box className="actionWrapper">{RightActionGroup}</Box>
                )
            }
        />
    );
};

export default AppCardHeader;
