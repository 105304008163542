import { useCallback, useEffect, useMemo, useState } from 'react';

import isNil from 'lodash/isNil';

import RowCollapsibleContext from './RowCollapsibleContext';

const RowCollapsibleProvider = ({
    rowsData,
    renderRowCollapsible,
    children
}) => {
    const hasData = rowsData.length > 0;
    const hasRowCollapsible = !isNil(renderRowCollapsible);

    const [collapsibleExpandingStates, setCollapsibleExpandingStates] =
        useState([]);

    useEffect(() => {
        if (hasData) {
            setCollapsibleExpandingStates(rowsData.map(() => false));
        }
    }, [hasData, rowsData]);

    const checkCollapsibleExpanded = useCallback(
        (dataIndex) => collapsibleExpandingStates[dataIndex],
        [collapsibleExpandingStates]
    );

    const toggleRowCollapsible = useCallback(
        (index) => {
            const newCollapsibleStates = [...collapsibleExpandingStates];
            newCollapsibleStates[index] = !newCollapsibleStates[index];
            setCollapsibleExpandingStates(newCollapsibleStates);
        },
        [collapsibleExpandingStates]
    );

    const providerValue = useMemo(
        () => ({
            collapsibleExpandingStates,
            hasRowCollapsible,
            setCollapsibleExpandingStates,
            toggleRowCollapsible,
            checkCollapsibleExpanded,
            renderRowCollapsible
        }),
        [
            checkCollapsibleExpanded,
            collapsibleExpandingStates,
            hasRowCollapsible,
            renderRowCollapsible,
            toggleRowCollapsible
        ]
    );

    return (
        <RowCollapsibleContext.Provider value={providerValue}>
            {children}
        </RowCollapsibleContext.Provider>
    );
};

export default RowCollapsibleProvider;
