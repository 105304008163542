import { IDENTITY_TYPE } from './serverInfos/serverInfos';

export const CERTIFICATE_TYPE = {
    ID_CARD: {
        i18nKey: 'registration.uploadCertificate.certificateType.idCard',
        value: IDENTITY_TYPE.ID_CARD.value
    },
    PASSPORT: {
        i18nKey: 'registration.uploadCertificate.certificateType.passport',
        value: IDENTITY_TYPE.PASSPORT.value
    },
    DRIVING_LICENSE: {
        i18nKey:
            'registration.uploadCertificate.certificateType.drivingLicense',
        value: IDENTITY_TYPE.DRIVING_LICENSE.value
    },
    OTHER: {
        i18nKey: 'registration.uploadCertificate.certificateType.other',
        value: IDENTITY_TYPE.OTHER.value
    }
};

export const MAX_UPLOAD_SIZE_IN_BYTES = 10_485_760;

export const ONE_TIME_TOKEN_KEY = 'token';

export const TERMS_AND_CONDITION_PATH = `${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}/document/en/terms_and_condition.pdf`;

export const PERSONAL_INFORMATION_AGREEMENTS = [
    [{ i18nKey: 'registration.personalInformation.agreement.statement1' }],
    [{ i18nKey: 'registration.personalInformation.agreement.statement2' }],
    [{ i18nKey: 'registration.personalInformation.agreement.statement3' }],
    [
        {
            i18nKey: 'registration.personalInformation.agreement.statement4-1',
            link: `${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}/document/en/pds.pdf`
        },
        {
            i18nKey: 'registration.personalInformation.agreement.statement4-2',
            link: TERMS_AND_CONDITION_PATH
        },
        {
            i18nKey: 'registration.personalInformation.agreement.statement4-3',
            link: `${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}/document/en/privacy_policy.pdf`
        }
    ]
];

export const DROPZONE_BACKGROUND_KEY = {
    ID_FRONT: 'idFront',
    ID_BACK: 'idBack',
    ADDRESS_DOC: 'addressDoc',
    PASSPORT: 'passport'
};

export const BIRTH_DATE_DEFAULT_YEAR = 21;

export const ADULTHOOD_AGE = 18;

export const REGISTRATION_TYPE = {
    NORMAL: 'normal',
    TRADING_ACCOUNT: 'tradingAccount',
    DEMO: 'demo'
};

export const REGISTRATION_STEP = {
    CREATE_ACCOUNT: 'createAccount',
    UPLOAD_CERTIFICATE: 'uploadCertificate',
    PERSONAL_INFORMATION: 'personalInformation',
    HANDLING: 'handling',
    REGISTER_COMPLETED: 'registerCompleted'
};

export const REGISTRATION_STATUS = {
    INCOMPLETE_INFORMATION: 'incompleteInformation',
    AUDITING: 'auditing',
    AUDIT_PASSED: 'auditPassed',
    AUDIT_FAILED: 'auditFailed'
};

export const PERMANENT_ID_EXPIRY_DATE = '9999-12-31';
