import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useTradeAccountList } from 'hooks';

export const FILTER_ACCOUNT_LISTS = 'accontList';

const useFilterAccountList = (filterName = FILTER_ACCOUNT_LISTS) => {
    const { t } = useTranslation();
    const { tradeAccounts } = useTradeAccountList();

    const tradeAccountItems = [];

    if (tradeAccounts) {
        tradeAccounts.forEach(({ tradeLoginId }) => {
            tradeAccountItems.push({
                label: tradeLoginId,
                value: tradeLoginId
            });
        });
    }

    const accountListsFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.tradeAccount.tradeLoginId'),
        items: tradeAccountItems,
        itemValueKey: 'value',
        itemLabelKey: 'label',
        defaultValue: ''
    };

    return { accountListsFilterInfo };
};

export default useFilterAccountList;
