import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useRewardItemInfo = ({ rewardId }) => {
    const { userId } = useSelector((state) => state.user);

    const [rewardItemInfo, setRewardItemInfo] = useState({});

    const getRewardItemInfo = useCallback(
        () =>
            handleAsyncCall({
                asyncCall: () =>
                    API.reward.getItemInfo.apiCall(userId, rewardId),
                handleCallSuccess: (res) =>
                    setRewardItemInfo(res.data.info ?? {})
            }),
        [rewardId, userId]
    );

    useEffect(() => {
        getRewardItemInfo();
    }, [getRewardItemInfo]);

    return {
        rewardItemInfo,
        getRewardItemInfo
    };
};

export default useRewardItemInfo;
