export const REWARD_CATEGORY = {
    ALL: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.all',
        serverKey: null
    },
    RED_PACKET: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.redPacket',
        serverKey: 'RED_POCKET'
    },
    AUSSIE_GIFTS: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.aussieGifts',
        serverKey: 'AUSSIE_GIFTS'
    },
    LATEST_TECHNOLOGY: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.latestTechnology',
        serverKey: 'LATEST_TECH'
    },
    EXTRAORDINARY: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.extraordinary',
        serverKey: 'EXTRAORDINARY'
    },
    LIMITED_GIFTS: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.rewardCategory.limitedGifts',
        serverKey: 'LIMITED_GIFTS'
    }
};

export const REWARD_ORDERING_METHOD = {
    CREATE_TIME_DESCENDING_ORDER: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.createTimeDescendingOrder',
        serverParams: {
            sortBy: 'createTime',
            orderBy: 'DESC'
        }
    },
    POINT_ASCENDING_ORDER: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.pointAscendingOrder',
        serverParams: {
            sortBy: 'point',
            orderBy: 'ASC'
        }
    },
    POINT_DESCENDING_ORDER: {
        i18nKey:
            'userInfo.reward.rewardHome.rewardsRedemption.pointDescendingOrder',
        serverParams: {
            sortBy: 'point',
            orderBy: 'DESC'
        }
    }
};
