import API from 'services';

const REGISTERED_CLIENT_API = {
    apiCallGetUserBasicInfo: API.user.getUserBasicInfo.apiCall,
    apiCallGetUserMarketInfo: API.user.getUserMarketInfo.apiCall,
    apiCallGetUserRegistrationInfo: API.user.getUserRegistrationInfo.apiCall,
    apiCallEditClientInfo: API.customerMgmt.editClientInfo.apiCall,
    apiCallAddClientInfo: () => null
};

export const CLIENT_TYPE_INFO = {
    LEAD_CLIENT: {
        key: 'LEAD_CLIENT',
        type: 1,
        apiCallGetClientInfo: API.customerMgmt.getLeadClientInfo.apiCall,
        apiCallEditClientInfo: API.customerMgmt.editLeadClientInfo.apiCall,
        apiCallAddClientInfo: API.customerMgmt.addLeadClientInfo.apiCall
    },
    REGISTERED_CLIENT: {
        key: 'REGISTERED_CLIENT',
        type: 2,
        ...REGISTERED_CLIENT_API
    },
    DEPOSITED_CLIENT: {
        key: 'DEPOSITED_CLIENT',
        type: 2,
        ...REGISTERED_CLIENT_API
    }
};

export const LEAD_CONTACT_INFO = {
    DIRECT_CLIENT: {
        key: 'DIRECT_CLIENT',
        type: 1,
        i18nKey: 'common.lead.leadTypes.directClient'
    },
    IB: {
        key: 'IB',
        type: 2,
        i18nKey: 'common.lead.leadTypes.IB'
    }
};
