import isEmpty from 'lodash/isEmpty';

import { useTradeCategories } from 'hooks';

const PlainTextTradeCategoriesField = ({ tradeCategory }) => {
    const { tradeCategories } = useTradeCategories();
    const tradeCategoriesLabel = tradeCategories.find(
        ({ key }) => tradeCategory === key
    );

    if (isEmpty(tradeCategories)) return '---';

    return tradeCategoriesLabel?.value ?? '---';
};

export default PlainTextTradeCategoriesField;
