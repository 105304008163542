import React, { useRef } from 'react';

import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import cx from 'classnames';

import { useWindowSize } from 'hooks';

import './AppDialog.scss';
import { HEADER_FONT_SIZE } from './constants.module.scss';
import { CloseIco } from 'assets/images/common';

const APP_DIALOG_VARIANT = {
    BLUE_HEADER: 'blueHeader'
};

const AppDialog = ({
    name,
    isShowCloseButton = true,
    children,
    title,
    subTitle,
    closeDialog,
    className,
    variant = '',
    ...props
}) => {
    const headerRef = useRef(null);
    const { isMobile } = useWindowSize();
    const handleHeaderShrinking = (event) => {
        const currentScrollLevel = event.currentTarget.scrollTop;

        if (!isMobile) {
            return;
        }
        if (currentScrollLevel > 50) {
            headerRef.current.style.fontSize = '20px';
        } else if (currentScrollLevel === 0) {
            headerRef.current.style.fontSize = `${HEADER_FONT_SIZE}`;
        }
    };

    return (
        <Dialog
            className={cx(['appDialog', name, className])}
            onClose={closeDialog}
            PaperProps={{ onScroll: handleHeaderShrinking }}
            {...props}>
            <Box className="appDialogInnerWrapper">
                <Box
                    className={cx('dialogHeader', {
                        headerBackground:
                            variant === APP_DIALOG_VARIANT.BLUE_HEADER
                    })}>
                    {title && (
                        <DialogTitle className="dialogTitle" ref={headerRef}>
                            {title}
                        </DialogTitle>
                    )}
                    {isShowCloseButton && (
                        <Box className="dialogBtnCloseWrapper">
                            <IconButton
                                className="btn btnClose"
                                onClick={closeDialog}>
                                <CloseIco />
                            </IconButton>
                        </Box>
                    )}
                    {subTitle && (
                        <Box className="dialogSubTitle">{subTitle}</Box>
                    )}
                </Box>
                <Box className="dialogContent">{children}</Box>
            </Box>
        </Dialog>
    );
};

export default AppDialog;
