import { OrangeDownArrowIco, OrangeRightArrowIco } from 'assets/images/home';

const DynamicArrowIco = ({ isPointingRight, ...props }) => {
    return isPointingRight ? (
        <OrangeRightArrowIco {...props} />
    ) : (
        <OrangeDownArrowIco {...props} />
    );
};

export default DynamicArrowIco;
