import { useRef } from 'react';

import * as yup from 'yup';

import { isYupSchema } from 'utils/yup';

const useAsyncValidations = ({
    validations,
    finalValidation = null,
    finalExpectedValues = [],
    finalErrorMessage = '',
    isFinalValidatedOnBlur = false
}) => {
    const isFieldBlurring = useRef(false);
    const validationResult = useRef(null);
    const previousError = useRef(null);

    const parsedValidation = yup.mixed().test(async (fieldValue, context) => {
        try {
            for (const validation of validations) {
                if (isYupSchema(validation)) {
                    await validation.validate(fieldValue);
                } else {
                    await validation(fieldValue);
                }
            }
            if (
                finalValidation &&
                ((isFinalValidatedOnBlur && isFieldBlurring.current) ||
                    !isFinalValidatedOnBlur)
            ) {
                isFieldBlurring.current = false;
                validationResult.current = isYupSchema(finalValidation)
                    ? await finalValidation.validate(fieldValue)
                    : await finalValidation(fieldValue);
                await new Promise((resolve, reject) => {
                    if (finalExpectedValues.length === 0) {
                        previousError.current = null;
                        resolve();
                    } else if (
                        finalExpectedValues.includes(validationResult.current)
                    ) {
                        previousError.current = null;
                        resolve();
                    } else {
                        previousError.current = Error(
                            finalErrorMessage !== '' ? finalErrorMessage : null
                        );
                        reject(previousError.current);
                    }
                });
            } else if (previousError.current) {
                await new Promise((resolve, reject) =>
                    reject(previousError.current)
                );
            }
            return true;
        } catch ({ message }) {
            return context.createError({ message });
        }
    });

    const handleFieldOnBlur = () => (isFieldBlurring.current = true);

    return [parsedValidation, handleFieldOnBlur];
};

export default useAsyncValidations;
