import { useTranslation } from 'react-i18next';

import html2canvas from 'html2canvas';

import useOpenSnackbar from './useOpenSnackbar';

const useDownloadPNGImage = () => {
    const { t } = useTranslation();
    const { openSnackbar, openErrorSnackbar } = useOpenSnackbar();

    const handleDownloadPNGImageFromHtmlElm = (htmlElm, fileName) => {
        const fileFullName = fileName + '.png';
        html2canvas(htmlElm, { backgroundColor: null })
            .then(function (canvas) {
                const link = document.createElement('a');
                link.download = fileFullName;
                link.href = canvas.toDataURL('image/png');
                link.click();
                link.remove();
                openSnackbar(t('common.snackbar.downloadSuccess'));
            })
            .catch(() => {
                openErrorSnackbar(t('common.snackbar.downloadFailed'));
            });
    };

    return { handleDownloadPNGImageFromHtmlElm };
};

export default useDownloadPNGImage;
