import React from 'react';

import cx from 'classnames';

import AppContainer from 'layouts/AppContainer/AppContainer';
import AppMainContainer from 'layouts/AppContainer/components/AppMainContainer/AppMainContainer';

import './PageLayoutMain.scss';

export const PageLayoutMainInner = ({ fullScreen, children, className }) => {
    return (
        <AppMainContainer
            fullScreen={fullScreen}
            className={cx(['PageLayoutMainInner', className])}>
            {children}
        </AppMainContainer>
    );
};

const PageLayoutMain = ({
    children,
    fullScreen = false,
    pageName = '',
    className = ''
}) => {
    return (
        <AppContainer
            pageName={pageName}
            className={cx('PageLayoutMain', className)}>
            <PageLayoutMainInner fullScreen={fullScreen}>
                {children}
            </PageLayoutMainInner>
        </AppContainer>
    );
};

export default PageLayoutMain;
