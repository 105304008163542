import axiosConfig from 'axios/axiosConfig';

const dashboardAPI = {
    resetTradePassword: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/preResetTradePassword`, payload),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    //Manager
    getCustomerDataStat: {
        apiCall: (params) =>
            axiosConfig.get('/user/customerDataStat', { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getPlatformPrice: {
        apiCall: () => axiosConfig.get('/usdt/platformPrice'),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getManagerStat: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/mgrStat`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getIbRank: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/ibRank`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getManagerRank: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/mgrRank`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getNetDepositStat: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/netDepositStat`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    getCreateAccountStat: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/createAccountStat`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    //IB
    getTransLogs: {
        apiCall: (params) => axiosConfig.get('/pay/transLogs', { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    }
};

export default dashboardAPI;
