import { useCallback, useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useStateList = (country) => {
    const [states, setStates] = useState([]);

    const getStates = useCallback(
        () =>
            handleAsyncCall({
                asyncCall: () => API.common.getStatesList.apiCall({ country }),
                handleCallSuccess: (res) => setStates(res.data.list)
            }),
        [country]
    );

    useEffect(() => {
        getStates();
    }, [getStates]);

    return { states, getStates };
};

export default useStateList;
