import React from 'react';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import './ConfirmCard.scss';

const ConfirmCard = ({ cardData = [], className = '' }) => {
    return (
        <Box className={cx(['confirmCard', className])}>
            {cardData.length > 0 &&
                cardData.map((item, index) => (
                    <Box className="confirmItm" key={index}>
                        <Typography className="label">{item.label}</Typography>
                        <Typography className="value">{item.value}</Typography>
                    </Box>
                ))}
        </Box>
    );
};

export default ConfirmCard;
