import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import cx from 'classnames';
import last from 'lodash/last';

import { defaultTablePageValues } from 'constants/appTableInfos';
import { defaultAppFilterDateRange } from 'constants/filterInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import useOpenSnackbar from 'hooks/useOpenSnackbar';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const initPayload = {
    ...defaultTablePageValues,
    startDate: defaultAppFilterDateRange[0],
    endDate: defaultAppFilterDateRange[1]
};

const useTableOrdersTotal = (
    searchTradeLoginIds = VALUES_ALL_TRADE_LOGIN_IDS.API_PAYLOAD
) => {
    const { t } = useTranslation();
    const { openErrorSnackbar } = useOpenSnackbar();
    const { userId } = useSelector((state) => state.user);
    const [totalList, setTotalList] = useState([]);
    const totalItem = last(totalList);

    const [totalListPayload, setTotalListPayload] = useState({
        ...initPayload,
        searchTradeLoginIds
    });

    const getTotalList = () => {
        handleAsyncCall({
            asyncCall: () =>
                API.traderReports.getOrdersStatistics.apiCall(
                    userId,
                    totalListPayload
                ),
            handleCallSuccess: (res) => {
                setTotalList(
                    [
                        ...res.data.list,
                        {
                            symbol: t('common.table.lotTotal'),
                            volume: res.data.total
                        }
                    ] || []
                );
            },
            handleCallFailureError: (res) => {
                openErrorSnackbar(
                    res.message || t('common.snackbar.readFiled')
                );
            }
        });
    };

    useEffect(() => {
        getTotalList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalListPayload]);

    const totalTableColumnsInfos = {
        symbol: {
            field: 'symbol',
            label: t('common.table.product'),
            renderCell: ({ symbol, volume }) => {
                return (
                    <Typography
                        component="span"
                        className={cx({ bold: volume === totalItem.volume })}>
                        {symbol}
                    </Typography>
                );
            }
        },
        volume: {
            field: 'volume',
            label: t('common.table.orderVolume'),
            renderCell: ({ volume }) => {
                return (
                    <Typography
                        component="span"
                        className={cx({ bold: volume === totalItem.volume })}>
                        {volume}
                    </Typography>
                );
            }
        }
    };

    return {
        totalList,
        totalTableColumnsInfos,
        totalListPayload,
        setTotalListPayload,
        totalInitPayload: initPayload
    };
};

export default useTableOrdersTotal;
