import { Box } from '@mui/material';

import './TableCellTwoContentsWrapper.scss';

const TableCellTwoContentsWrapper = ({ children }) => {
    const [ContentLeft, ContentRight] = children;
    return (
        <Box className="tableCellTwoContentsWrapper">
            <Box className="contentLeft">{ContentLeft}</Box>
            <Box className="contentRight">{ContentRight}</Box>
        </Box>
    );
};

export default TableCellTwoContentsWrapper;
