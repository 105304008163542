import { Input } from '@mui/material';

import { Button } from 'components';

const UploadButton = ({
    label,
    className = '',
    handleUpload,
    accept,
    variant = 'contained'
}) => {
    return (
        <label htmlFor="contained-button-file">
            <Input
                onChange={(event) => handleUpload(event.target.files[0])}
                onClick={(event) => {
                    event.target.value = null;
                }}
                className="uploadInput hide"
                accept={accept}
                id="contained-button-file"
                type="file"
            />

            <Button
                label={label}
                className={className}
                variant={variant}
                component="span"
            />
        </label>
    );
};

export default UploadButton;
