import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ClickAwayListener } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cx from 'classnames';
import isValid from 'date-fns/isValid';
import isNil from 'lodash/isNil';

import { TextField } from 'components';

import { DATE_FORMAT } from 'constants/dateFormats';
import { PICKER_START_DATE } from 'constants/dateInfos';
import { LANGUAGE_INFO } from 'constants/languageInfo';
import { DATE_PICKER_FORMAT } from 'constants/muiEnums';
import { usePrevious } from 'hooks';

import { dateObjToDateString } from 'utils/dateConversion';

import FieldFormikWrapper from '../FieldWrapper/FieldFormikWrapper';
import FieldPlainWrapper from '../FieldWrapper/FieldPlainWrapper';
import './DatepickerField.scss';
import { ArrowDownGreyIco } from 'assets/images/common';

const DatepickerField = ({
    label,
    name = '',
    format = DATE_PICKER_FORMAT['YYYY-MM-DD'],
    handleChange = null,
    handleBlur = null,
    onClick = null,
    disabled = false,
    wrapperClassName = '',
    isRequired = false,
    helperTextProps = {},
    children = null,
    withoutFormikHook = false,
    value = null,
    maxDate = null,
    isReadOnly = false,
    minDate = PICKER_START_DATE,
    ...props
}) => {
    const { i18n } = useTranslation();

    const FieldWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;
    const fieldPropsRef = useRef(null);
    const textFieldRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [currentPickerLocale, setCurrentPickerLocale] = useState(null);
    const [isPickerShown, setIsPickerShown] = useState(false);
    const oldIsPickerShown = usePrevious(isPickerShown);

    const inputValueRef = useRef('');
    const lastValidDateRef = useRef(null);

    useEffect(() => {
        setSelectedDate(
            withoutFormikHook
                ? new Date(value)
                : new Date(fieldPropsRef.current?.value)
        );
    }, [value, withoutFormikHook]);

    useEffect(() => {
        const ensureDateLength = () => {
            if (
                format === DATE_PICKER_FORMAT['YYYY-MM-DD'] &&
                fieldPropsRef.current?.value?.length > 10
            ) {
                fieldPropsRef.current?.setValue(
                    fieldPropsRef.current?.value?.substring(0, 10)
                );
            }
        };

        ensureDateLength();
    }, [format]);

    useEffect(() => {
        setCurrentPickerLocale(LANGUAGE_INFO[i18n.language].dateLocale);
    }, [i18n.language]);

    const parseNewDate = useCallback(() => {
        switch (format) {
            default:
            case DATE_PICKER_FORMAT['YYYY-MM-DD']:
                return selectedDate;
            case DATE_PICKER_FORMAT['YYYY-MM']:
                return dateObjToDateString(selectedDate, DATE_FORMAT.yM);
        }
    }, [format, selectedDate]);

    useEffect(() => {
        const isPickerClosing = oldIsPickerShown && !isPickerShown;

        if (isPickerClosing) {
            const newDate = parseNewDate();
            handleChange && handleChange(newDate);
        }
    }, [handleChange, isPickerShown, oldIsPickerShown, parseNewDate]);

    const handleDateChange = () => {
        if (isNil(selectedDate) || isPickerShown) {
            return;
        }

        if (isValid(selectedDate)) {
            lastValidDateRef.current = selectedDate;
        }
        if (!fieldPropsRef.current?.touched) {
            fieldPropsRef.current?.setTouched(true);
        }

        const newDate = parseNewDate();
        fieldPropsRef.current?.setValue(newDate);

        // Handle error caused by extra input when max-length valid date (i.e. length === 10) is entered
        if (
            !isValid(selectedDate) &&
            isValid(lastValidDateRef.current) &&
            dateObjToDateString(lastValidDateRef.current) ===
                inputValueRef.current
        ) {
            fieldPropsRef.current?.setValue(inputValueRef.current);
        }
    };

    const renderInput = (fieldProps) => (params) => {
        const inputProps = {
            endAdornment: <ArrowDownGreyIco className="arrowDownIcon" />
        };
        inputValueRef.current = params.inputProps.value;
        const handleClick = () => {
            setIsPickerShown((value) => !value);
            onClick && onClick();
            textFieldRef.current?.focus();
        };

        if (fieldProps.value === '') {
            params.inputProps.value = '';
        }
        if (disabled) {
            params.inputProps.placeholder = '';
        }

        return (
            <TextField
                isReadOnly={format === DATE_PICKER_FORMAT['YYYY-MM'] || isReadOnly}
                inputRef={textFieldRef}
                name={name}
                handleBlur={handleBlur}
                onClick={handleClick}
                wrapperClassName={wrapperClassName}
                isRequired={isRequired}
                onKeyDown={(event) =>
                    event.key === ' ' && event.preventDefault()
                }
                helperTextProps={helperTextProps}
                withoutFormikHook={withoutFormikHook}
                showDefaultRightAdornment={false}
                handleAfterFormikChange={handleDateChange}
                {...params}
                {...(format === DATE_PICKER_FORMAT['YYYY-MM'] && {
                    InputProps: inputProps
                })}
                {...props}>
                {children}
            </TextField>
        );
    };

    return (
        <Box
            className={cx('datepickerField', {
                pointerCursor: format === DATE_PICKER_FORMAT['YYYY-MM']
            })}>
            <ClickAwayListener
                touchEvent="onTouchStart"
                onClickAway={() => setIsPickerShown(false)}>
                <Box>
                    <FieldWrapper name={name}>
                        {(fieldProps) => {
                            if (!withoutFormikHook) {
                                fieldPropsRef.current = fieldProps;
                            }

                            return (
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={currentPickerLocale}>
                                    <DesktopDatePicker
                                        label={label}
                                        mask={format.MASK}
                                        inputFormat={format.INPUT_FORMAT}
                                        renderInput={renderInput(fieldProps)}
                                        name={name}
                                        value={selectedDate}
                                        disabled={disabled}
                                        onChange={(newDate) =>
                                            setSelectedDate(newDate)
                                        }
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        open={isPickerShown}
                                        {...(format ===
                                            DATE_PICKER_FORMAT['YYYY-MM'] && {
                                            views: ['year', 'month'],
                                            openTo: 'month',
                                            onClose: handleDateChange
                                        })}
                                        {...(format ===
                                            DATE_PICKER_FORMAT[
                                                'YYYY-MM-DD'
                                            ] && {
                                            views: ['year', 'day'],
                                            openTo: 'day',
                                            onClose: handleDateChange
                                        })}
                                    />
                                </LocalizationProvider>
                            );
                        }}
                    </FieldWrapper>
                </Box>
            </ClickAwayListener>
        </Box>
    );
};

export default DatepickerField;
