import React from 'react';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { AppForm, ButtonGroupProcess, CardTabControl } from 'components';

import { getAccountTypeLocale } from 'utils/client/accountType';
import { mixedValidation } from 'utils/yup';

import CommissionTable from '../CommissionTable/CommissionTable';
import './CommissionTableForm.scss';
import useProductsDetail from './hook';

const CommissionTableForm = ({
    onConfirm,
    onGoBack,
    initCommissionSettings = {},
    // initAccountLinkInfo = {},
    commissionValues = {},
    isReadOnly = false,
    accountLinkInfo,
    selectedAccountType = ''
}) => {
    const { t } = useTranslation();

    useProductsDetail(accountLinkInfo.accountType, initCommissionSettings);

    if (isEmpty(initCommissionSettings)) return null;
    const {
        canAddDiff,
        canAddRebate,
        commission1,
        commission2,
        rebates = [],
        diff
    } = initCommissionSettings;

    const rebateValueList = rebates.map(({ target }) => target);

    let initialFormValues =
        !commissionValues ||
        isEmpty(commissionValues) ||
        selectedAccountType !== accountLinkInfo.accountType
            ? {
                  ...(canAddRebate && { rebates: rebateValueList }),
                  //   incase ECN without commission
                  //   ...(canAddCommission && {
                  commission1: commission1.target,
                  commission2: commission2.target,
                  //   }),
                  ...(canAddDiff && {
                      diff: { target: diff.target, percent: diff.percent }
                  })
              }
            : commissionValues;
    let validationObject = {};

    if (canAddDiff) {
        validationObject = {
            diff: yup.object({
                target: mixedValidation,
                percent: mixedValidation
            })
        };
    }

    const validationSchema = yup.object(validationObject);
    const tabPageRouteList = [
        {
            value: accountLinkInfo.accountType,
            label: getAccountTypeLocale(accountLinkInfo.accountType, t),
            ContentComponent: (
                <AppForm
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    handleSubmit={onConfirm}
                    render={({ values }) => {
                        return (
                            <>
                                <CommissionTable
                                    accountType={accountLinkInfo.accountType}
                                    initCommissionSettings={
                                        initCommissionSettings
                                    }
                                    commissionValues={values}
                                    isReadOnly={isReadOnly}
                                />
                                {!isReadOnly && (
                                    <ButtonGroupProcess
                                        labelTop={t('common.action.confirm')}
                                        typeTop="submit"
                                        labelBottom={t('common.action.back')}
                                        onClickBottom={() => onGoBack(values)}
                                    />
                                )}
                            </>
                        );
                    }}
                />
            )
        }
    ];
    return (
        <CardTabControl
            appCardClassName="cardTableCommissionTable"
            tabInfoList={tabPageRouteList}
        />
    );
};

export default CommissionTableForm;
