import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { AppFormButton, TextField } from 'components';

import './VerifyCodeField.scss';
import { RefreshIco } from 'assets/images/common';

const VerifyCodeField = ({
    name,
    label = '',
    codeImageSource = null,
    onCodeRefetch = null,
    maxLength = -1,
    className = '',
    onBlur = null,
    onChange = null,
    onClick = null,
    refetchingSecond = 60,
    ...props
}) => {
    const { t } = useTranslation();

    const [coolDownSecond, setCoolDownSecond] = useState(0);
    useEffect(() => {
        if (coolDownSecond > 0) {
            setTimeout(() => {
                setCoolDownSecond((interval) => interval - 1);
            }, 1000);
        }
    }, [coolDownSecond]);

    const handleRefetchButtonClick = () => {
        onCodeRefetch();
        setCoolDownSecond(refetchingSecond);
    };
    const renderFieldEndComponent = () => (
        <Box
            className={cx('captchaWrapper', {
                alignWithButton: isNil(codeImageSource)
            })}>
            {isNil(codeImageSource) ? (
                <AppFormButton
                    color="primary"
                    variant="contained"
                    label={
                        coolDownSecond === 0
                            ? t('common.action.send')
                            : `${coolDownSecond}${t('common.symbol.second')}`
                    }
                    onClick={() => handleRefetchButtonClick()}
                    disabled={coolDownSecond !== 0}
                />
            ) : (
                <>
                    <Box className="captchaImg">
                        <img src={codeImageSource} alt="verifyCode" />
                    </Box>
                    <RefreshIco
                        onClick={(event) =>
                            typeof onCodeRefetch === 'function' &&
                            onCodeRefetch(event)
                        }
                    />
                </>
            )}
        </Box>
    );

    return (
        <Box className={cx('verifyCodeField', className)}>
            <TextField
                className={cx({ alignWithButton: isNil(codeImageSource) })}
                label={label}
                id={name}
                name={name}
                placeholder={t('common.security.verifyCode')}
                inputProps={{ ...(maxLength !== -1 && { maxLength }) }}
                handleBlur={onBlur}
                handleChange={onChange}
                onClick={onClick}
                renderFieldEndComponent={renderFieldEndComponent}
                {...props}
            />
        </Box>
    );
};

export default VerifyCodeField;
