import { useRouteHistory } from 'utils/providers/RouteHistoryProvider';

const useRestoreParamsFromPaths = (path) => {
    const { prevLocation } = useRouteHistory();
    const isRestoreCachedParams = prevLocation?.pathname.includes(path);

    return { isRestoreCachedParams };
};

export default useRestoreParamsFromPaths;
