import { useStaleFree } from 'hooks';

import { useDialog } from 'utils/providers/DialogProvider';

const useOpenDialog = (dialogProps) => {
    const { createDialog, closeDialog } = useDialog();
    const openDialogWithStale = () => {
        createDialog(dialogProps);
    };

    const openDialog = useStaleFree(() => openDialogWithStale());

    return {
        openDialog,
        closeDialog
    };
};

export default useOpenDialog;
