import React from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import cx from 'classnames';

import './ButtonIco.scss';

const ButtonIco = ({
    BtnIco = null,
    onClick = () => {},
    className,
    disabled = false,
    hoverTitle = '',
    linkProps = { to: null, state: {}, target: '_self' },
    ...props
}) => {
    const ButtonWrapper = linkProps.to ? LinkWrapper : NormalWrapper;

    return (
        <ButtonWrapper {...linkProps}>
            <IconButton
                title={hoverTitle}
                onClick={onClick}
                className={cx(['btn', 'btnIco', className])}
                disabled={disabled}
                {...props}>
                {BtnIco}
            </IconButton>
        </ButtonWrapper>
    );
};

const LinkWrapper = ({ to, state, target, children }) => {
    return (
        <Link to={to} state={state} target={target}>
            {children}
        </Link>
    );
};

const NormalWrapper = ({ children }) => {
    return <>{children}</>;
};

export default ButtonIco;
