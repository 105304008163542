import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOpenDialog } from 'hooks';

import ClientGroupSelect from './ClientGroupSelect';

const useDialogClientGroupSelect = ({
    handleSelectClientGroup,
    extraClientGroupItem = {}
}) => {
    const { t } = useTranslation();
    const [defaultGroupId, setDefaultGroupId] = useState(undefined);

    const { openDialog, closeDialog } = useOpenDialog({
        name: 'ClientGroupSelectDialog',
        title: t('customerMgmt.clientGroupForm.title'),
        children: (
            <ClientGroupSelect
                handleSelectClientGroup={handleSelectClientGroup}
                defaultGroupId={defaultGroupId}
                extraClientGroupItem={extraClientGroupItem}
            />
        )
    });

    const openClientGroupSelectDialog = (groupId) => {
        setDefaultGroupId(groupId);
        openDialog();
    };

    return {
        openClientGroupSelectDialog,
        closeClientGroupSelectDialog: closeDialog
    };
};

export default useDialogClientGroupSelect;
