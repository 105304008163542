import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useTitles = () => {
    const [titles, setTitles] = useState([]);

    const getTitles = () =>
        handleAsyncCall({
            asyncCall: () => API.common.getTitleList.apiCall(),
            handleCallSuccess: (res) => setTitles(res.data.list)
        });

    useEffect(() => {
        getTitles();
    }, []);

    return { titles, getTitles };
};

export default useTitles;
