import { useCacheableQuery } from 'hooks';

import API from 'services';
import { getResponseList } from 'utils/serverInfos';

const useRewardLevels = () => {
    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [API.reward.getLevelList.queryKey],
        apiCall: API.reward.getLevelList.apiCall
    });
    return {
        levelList: isSuccess ? getResponseList(data) : []
    };
};

export default useRewardLevels;
