import { useTranslation } from 'react-i18next';

import {
    FILTER_LEVEL,
    FILTER_TYPE,
    defaultAppFilterDateRange
} from 'constants/filterInfos';
import { useAppFilter, useFilterParentTrader } from 'hooks';
import { useFilterManager } from 'hooks/filters';

export const FILTER_NAMES_TRADE_HISTORY = {
    SEARCH_TRADE_LOGIN_IDS: 'searchTradeLoginIds',
    CLOSE_PERIOD: ['startDate', 'endDate'],
    PARENT_TRADE_LOGIN_ID: 'parentTradeLoginId',
    OPEN_PERIOD: ['openStartDate', 'openEndDate'],
    MANAGER_TRADE_LOGIN_ID: 'managerTradeLoginIds'
};

const useFilterGroupTradeHistory = ({
    filterGroupId = 'FILTER_GROUP_TRADE_HISTORY',
    filterNameLists
}) => {
    const { t } = useTranslation();

    const { parentTradeLoginIdFilterInfo } = useFilterParentTrader();
    const { managerTradeLoginIdFilterInfo } = useFilterManager();

    const filterListsInfos = {
        [FILTER_NAMES_TRADE_HISTORY.SEARCH_TRADE_LOGIN_IDS]: {
            filterName: FILTER_NAMES_TRADE_HISTORY.SEARCH_TRADE_LOGIN_IDS,
            filterType: FILTER_TYPE.TEXT_SEARCH,
            label: t('common.tradeAccount.tradeLoginId'),
            defaultValue: ''
        },
        [FILTER_NAMES_TRADE_HISTORY.OPEN_PERIOD]: {
            filterName: FILTER_NAMES_TRADE_HISTORY.OPEN_PERIOD,
            filterType: FILTER_TYPE.DATE_RANGE,
            label: t('common.date.openDate'),
            defaultValue: defaultAppFilterDateRange
        },
        [FILTER_NAMES_TRADE_HISTORY.PARENT_TRADE_LOGIN_ID]: {
            ...parentTradeLoginIdFilterInfo,
            filterName: FILTER_NAMES_TRADE_HISTORY.PARENT_TRADE_LOGIN_ID
        },
        [FILTER_NAMES_TRADE_HISTORY.CLOSE_PERIOD]: {
            filterName: FILTER_NAMES_TRADE_HISTORY.CLOSE_PERIOD,
            filterType: FILTER_TYPE.DATE_RANGE,
            label: t('common.date.closeDate'),
            defaultValue: defaultAppFilterDateRange
        },
        [FILTER_NAMES_TRADE_HISTORY.MANAGER_TRADE_LOGIN_ID]: {
            ...managerTradeLoginIdFilterInfo,
            filterName: FILTER_NAMES_TRADE_HISTORY.MANAGER_TRADE_LOGIN_ID
        }
    };

    const filterLists = {
        [FILTER_LEVEL.BASIC]: [],
        [FILTER_LEVEL.ADVANCED]: []
    };

    Object.entries(filterNameLists).forEach(([filterLevel, filterFields]) => {
        filterFields.forEach((filterName) => {
            filterLists[filterLevel].push(filterListsInfos[filterName]);
        });
    });

    useAppFilter({
        filterGroupId,
        filterLists
    });

    return null;
};

export default useFilterGroupTradeHistory;
