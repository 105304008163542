import { useState } from 'react';

import { Menu, MenuItem } from '@mui/material';

import { ButtonIco } from 'components';

import './ActionGroupPopOverMenu.scss';
import { DotsIco } from 'assets/images/common';

function ActionGroupPopoverMenu({ children = [] }) {
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onMenuItemClick = (event, index) => {
        setAnchor(null);
    };

    return (
        <>
            <ButtonIco onClick={openMenu} BtnIco={<DotsIco />} />

            <Menu
                // className="menuWrapper"
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}>
                {children.map((child, index) => (
                    <MenuItem
                        className="menuItem"
                        key={index}
                        onClick={(event) => onMenuItemClick(event, index)}>
                        {child}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default ActionGroupPopoverMenu;
