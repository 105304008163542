import { configureStore } from '@reduxjs/toolkit';

import accountLinkSlice from './accountLink/slice';
import clientSlice from './client/slice';
import filterSlice from './filter/slice';
import registrationSlice from './registration/slice';
import systemSlice from './system/slice';
import userSlice from './user/slice';

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        client: clientSlice.reducer,
        accountLink: accountLinkSlice.reducer,
        filter: filterSlice.reducer,
        registration: registrationSlice.reducer,
        system: systemSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    devTools: process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true'
});
