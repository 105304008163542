import { useRef } from 'react';

import { useFormikContext } from 'formik';

import { useDidUpdateEffect } from 'hooks';

const useUnmaskedValueFix = (fieldName) => {
    const passwordValueRef = useRef('');

    const {
        values: { password },
        setFieldValue
    } = useFormikContext();

    useDidUpdateEffect(() => {
        if (password === '' && passwordValueRef.current !== '') {
            setFieldValue(fieldName, passwordValueRef.current);
            passwordValueRef.current = '';
        }
    }, [fieldName, password, setFieldValue]);
};
export default useUnmaskedValueFix;
