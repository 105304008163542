import enUS from 'date-fns/locale/en-US';
import zhCN from 'date-fns/locale/zh-CN';
import th from 'date-fns/locale/th';
import vi from 'date-fns/locale/vi';
import { CURRENT_LANGUAGE } from 'i18nConfig';
import i18next from 'i18next';

import AUTH_ROUTES from 'pages/Authentication/routes';

import { USER_ROLE } from 'constants/authInfos';
import { LANGUAGE_INFO } from 'constants/languageInfo';
import { AVAILABLE_LANGUAGE } from 'constants/serverInfos/serverInfos';

export const translation = (i18nKey) => i18next.t(i18nKey);

export const handleAsyncCall = async ({
    asyncCall,
    handleCallSuccess = null,
    handleCallFailure = null,
    handleCallError = null,
    handleCallFailureError = null,
    handleCallFinal = null
}) => {
    let callbackResponse = null;

    try {
        if (typeof asyncCall === 'function') {
            const arrayBufferDecoder = new TextDecoder('utf-8');
            let parsedData = {};
            const response = await asyncCall();
            const { data: rawData, status } = response;

            if (rawData instanceof ArrayBuffer) {
                let tmpData = arrayBufferDecoder.decode(rawData);
                parsedData = JSON.parse(tmpData);
            } else {
                parsedData = rawData;
            }
            const { code } = parsedData;

            if (
                code === 200 ||
                rawData instanceof Blob ||
                (!code && status === 200)
            ) {
                if (typeof handleCallSuccess === 'function') {
                    callbackResponse = handleCallSuccess(parsedData);
                }
                return callbackResponse ?? parsedData;
            } else {
                if (typeof handleCallFailureError === 'function') {
                    callbackResponse = handleCallFailureError(parsedData);
                }
                if (typeof handleCallFailure === 'function') {
                    callbackResponse = handleCallFailure(parsedData);
                }

                return callbackResponse ?? parsedData;
            }
        }
    } catch (error) {
        if (typeof handleCallFailureError === 'function' && error.response) {
            callbackResponse = handleCallFailureError(error.response.data);
        }
        if (typeof handleCallError === 'function') {
            callbackResponse = handleCallError(error);
        }

        return callbackResponse ?? error;
    } finally {
        if (typeof handleCallFinal === 'function') {
            callbackResponse = handleCallFinal();
        }
    }
};

export const parseServerLocale = (appLocale) =>
    Object.values(AVAILABLE_LANGUAGE).find((localeValue) =>
        appLocale.includes(localeValue)
    );

export const checkIsUserAuthorized = (permissionFor, userRole) => {
    return (
        !permissionFor ||
        permissionFor.length === 0 ||
        permissionFor.includes(userRole)
    );
};

export const calSumFromFieldsList = (fieldName, list) => {
    const sum = list?.reduce(
        (currSum, fields) => Number(fields[fieldName]) + currSum,
        0
    );
    return sum;
};

export const getHomePathByRole = (userRole) => {
    switch (userRole) {
        case USER_ROLE.MAJORDOMO:
        case USER_ROLE.MANAGER:
            return '/home/manager-portal';
        case USER_ROLE.IB:
            return '/home/ib-portal';
        case USER_ROLE.CUSTOMER:
            return '/home/client-portal';
        default:
            return '#';
    }
};

export const displayUserName = (userName, t) => {
    return userName ? userName : t('common.user.username.valuedCustomer');
};

export const getIsRegistrationPage = (location) => {
    const registrationPath = '/registration';
    const isRegistrationParent =
        location.pathname.includes(registrationPath) &&
        location.pathname.length === registrationPath.length;
    const isRegistrationChildren = location.pathname.includes(
        `${registrationPath}/`
    );
    return isRegistrationParent || isRegistrationChildren;
};

export const getIsAuthenticationPage = (location) =>
    !!Object.values(AUTH_ROUTES).find(({ path }) =>
        location.pathname.includes(path)
    );

export const localeConversion = (locale) => {
    let parseLocale;
    switch (locale) {
        case LANGUAGE_INFO['zh-CN'].localeKey:
            parseLocale = zhCN;
            break;
        case LANGUAGE_INFO['th'].localeKey:
            parseLocale = th;
            break;
        case LANGUAGE_INFO['vi'].localeKey:
            parseLocale = vi;
            break;
        default:
            parseLocale = enUS;
            break;
    }

    return parseLocale;
};

export const getMT5AutochartistLink = () => {
    const autochartistWebLang =
        LANGUAGE_INFO[CURRENT_LANGUAGE].autochartistOfficialKey;
    return `https://www.autochartist.com/metatrader_${autochartistWebLang}/?lang=${autochartistWebLang}`;
};
