import { lazy } from 'react';

const Pages = {
    Completed: lazy(() => import('./Completed/Completed')),
    CreateAccount: lazy(() => import('./CreateAccount/CreateAccount')),
    PersonalInformation: lazy(() =>
        import('./PersonalInformation/PersonalInformation')
    ),
    UploadCertificate: lazy(() =>
        import('./UploadCertificate/UploadCertificate')
    ),
    CreateTradingAccount: lazy(() =>
        import('./CreateTradingAccount/CreateTradingAccount')
    )
};

const routes = {
    Completed: {
        path: '/registration/completed',
        element: <Pages.Completed />
    },
    CreateAccount: {
        path: '/registration/create-account',
        element: <Pages.CreateAccount />
    },
    PersonalInformation: {
        path: '/registration/personal-information',
        element: <Pages.PersonalInformation />
    },
    UploadCertificate: {
        path: '/registration/upload-certificate',
        element: <Pages.UploadCertificate />
    },
    CreateTradingAccount: {
        path: '/registration/create-trading-account',
        element: <Pages.CreateTradingAccount />
    },
    Registration: {
        path: '/registration'
    }
};

export default routes;
