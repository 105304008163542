import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';

import { DATE_FORMAT } from 'constants/dateFormats';

import {
    calDayDifferenceByDayStrings,
    dateObjToUnix
} from 'utils/dateConversion';
import { TODAY, YESTERDAY } from 'utils/dateInfos';

const MONTH_OF_TODAY_INDEX = TODAY.getMonth(); // 0 as January
const isLastMonthFromLastYear = MONTH_OF_TODAY_INDEX - 1 < 0;

export const DEFAULT_START_DATE = new Date('1900-01-01'); // 1900-01-01
export const PICKER_START_DATE = new Date('2007-01-01');

export const DATE_DEFINE = {
    TODAY_START: {
        type: 'TODAY_START',
        unix: dateObjToUnix(TODAY),
        yMd: format(startOfDay(TODAY), DATE_FORMAT.yMd),
        dateObj: startOfDay(TODAY)
    },
    TODAY_END: {
        type: 'TODAY_END',
        unix: dateObjToUnix(TODAY),
        yMd: format(endOfDay(TODAY), DATE_FORMAT.yMd),
        dateObj: endOfDay(TODAY)
    },
    YESTERDAY_END: {
        type: 'YESTERDAY_START',
        unix: dateObjToUnix(YESTERDAY),
        yMd: format(endOfDay(YESTERDAY), DATE_FORMAT.yMd),
        dateObj: endOfDay(YESTERDAY)
    },
    LAST_SEVEN_DAY_START: {
        type: 'LAST_SEVEN_DAY_START',
        unix: dateObjToUnix(startOfDay(addDays(TODAY, -6))),
        yMd: format(startOfDay(addDays(TODAY, -6)), DATE_FORMAT.yMd),
        dateObj: startOfDay(addDays(TODAY, -6))
    },
    LAST_FOURTEEN_DAY_START: {
        type: 'LAST_FOURTEEN_DAY_START',
        unix: dateObjToUnix(startOfDay(addDays(TODAY, -14))),
        yMd: format(startOfDay(addDays(TODAY, -14)), DATE_FORMAT.yMd),
        dateObj: startOfDay(addDays(TODAY, -14))
    },
    LAST_THIRTY_DAY_START: {
        type: 'LAST_THIRTY_DAY_START',
        unix: dateObjToUnix(startOfDay(addDays(TODAY, -29))),
        yMd: format(startOfDay(addDays(TODAY, -29)), DATE_FORMAT.yMd),
        dateObj: startOfDay(addDays(TODAY, -29))
    },
    LAST_YEAR_START: {
        type: 'LAST_YEAR_START',
        unix: dateObjToUnix(startOfDay(addDays(TODAY, -364))),
        yMd: format(startOfDay(addDays(TODAY, -364)), DATE_FORMAT.yMd),
        dateObj: startOfDay(addDays(TODAY, -364))
    },
    LAST_TWENTYONE_YEAR_START: {
        type: 'LAST_TWENTYONE_YEAR_START',
        unix: dateObjToUnix(startOfDay(addYears(TODAY, -20))),
        yMd: format(startOfDay(addYears(TODAY, -20)), DATE_FORMAT.yMd)
    },
    TODAY_MONTH_START: {
        type: 'TODAY_MONTH_START',
        unix: dateObjToUnix(
            new Date(TODAY.getFullYear(), MONTH_OF_TODAY_INDEX, 1)
        ),
        yMd: format(
            new Date(TODAY.getFullYear(), MONTH_OF_TODAY_INDEX, 1),
            DATE_FORMAT.yMd
        ),
        yM: format(
            new Date(TODAY.getFullYear(), MONTH_OF_TODAY_INDEX, 1),
            DATE_FORMAT.yM
        )
    },
    TODAY_MONTH_END: {
        type: 'TODAY_MONTH_END',
        unix: dateObjToUnix(
            new Date(TODAY.getFullYear(), MONTH_OF_TODAY_INDEX + 1, 0) // set 0 to get the last day of the previous month of MONTH_OF_TODAY_INDEX + 1
        ),
        yMd: format(
            new Date(TODAY.getFullYear(), MONTH_OF_TODAY_INDEX + 1, 0),
            DATE_FORMAT.yMd
        )
    },
    LAST_MONTH_START: {
        type: 'LAST_MONTH_START',
        unix: dateObjToUnix(
            new Date(
                TODAY.getFullYear() - (isLastMonthFromLastYear ? 1 : 0),
                isLastMonthFromLastYear ? 11 : MONTH_OF_TODAY_INDEX - 1,
                1
            )
        ),
        yMd: format(
            new Date(
                TODAY.getFullYear() - (isLastMonthFromLastYear ? 1 : 0),
                isLastMonthFromLastYear ? 11 : MONTH_OF_TODAY_INDEX - 1,
                1
            ),
            DATE_FORMAT.yMd
        ),
        yM: format(
            new Date(
                TODAY.getFullYear() - (isLastMonthFromLastYear ? 1 : 0),
                isLastMonthFromLastYear ? 11 : MONTH_OF_TODAY_INDEX - 1,
                1
            ),
            DATE_FORMAT.yM
        )
    },
    LAST_MONTH_END: {
        type: 'LAST_MONTH_END',
        unix: dateObjToUnix(
            new Date(
                TODAY.getFullYear() - (isLastMonthFromLastYear ? 1 : 0),
                isLastMonthFromLastYear ? 11 : MONTH_OF_TODAY_INDEX - 1,
                0
            )
        ),
        yMd: format(
            new Date(
                TODAY.getFullYear() - (isLastMonthFromLastYear ? 1 : 0),
                isLastMonthFromLastYear ? 11 : MONTH_OF_TODAY_INDEX - 1,
                0
            ),
            DATE_FORMAT.yMd
        )
    },
    UNSELECT_DATE: {
        type: 'UNSELECT_DATE',
        unix: DEFAULT_START_DATE,
        yMd: format(DEFAULT_START_DATE, DATE_FORMAT.yMd),
        dateObj: startOfDay(DEFAULT_START_DATE),
        payloadParse: ''
    }
};

export const DATE_RANGE_UNSELECT = [
    DATE_DEFINE.UNSELECT_DATE.payloadParse,
    DATE_DEFINE.UNSELECT_DATE.payloadParse
];

export const DATE_RANGE_LAST_THIRTY_DAY = [
    DATE_DEFINE.LAST_THIRTY_DAY_START.yMd,
    DATE_DEFINE.TODAY_END.yMd
];

export const DATE_RANGE_LAST_YEAR = [
    DATE_DEFINE.LAST_YEAR_START.yMd,
    DATE_DEFINE.TODAY_END.yMd
];

export const DATE_RANGE_LAST_MONTH = [
    DATE_DEFINE.LAST_MONTH_START.yMd,
    DATE_DEFINE.LAST_MONTH_END.yMd
];

export const DATE_RANGE_THIS_MONTH_UNTIL_TODAY = [
    DATE_DEFINE.TODAY_MONTH_START.yMd,
    DATE_DEFINE.TODAY_END.yMd,
    calDayDifferenceByDayStrings([
        DATE_DEFINE.TODAY_MONTH_START.yMd,
        DATE_DEFINE.TODAY_END.yMd
    ])
];

export const DEFAULT_DATE_RANGE = DATE_RANGE_LAST_THIRTY_DAY;
