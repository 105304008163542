export const downloadFileFromUrl = (url, filename = '') => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    if (filename !== '') {
        link.download = filename;
    }
    link.click();
    link.remove();
};

export const exportExcelFile = (fileData, fileName) => {
    const blob = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const blobUrl = URL.createObjectURL(blob);

    downloadFileFromUrl(blobUrl, fileName);
};

export const parseHumanFileSize = (bytes, si = false, dp = 0) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return `${bytes.toFixed(dp)}${units[u]}`;
};
