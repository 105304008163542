import { useTranslation } from 'react-i18next';

import { TableCellTwoContentsWrapper } from 'layouts';

import { useAccStatusRole } from 'hooks';

import { getRegisterStatusInfos } from 'utils/user/getRegisterStatusInfos';

import { BusinessCardIco, PaperSheetIco } from 'assets/images/accountInfo';

const useTableAccountOpeningStatus = (clientUserId = -1) => {
    const { t } = useTranslation();

    const { userAccStatus, docStatus, infoStatus } = useAccStatusRole({
        clientUserId
    });
    const { uploadCertInfo, personalInfo } = getRegisterStatusInfos(
        userAccStatus,
        docStatus,
        infoStatus
    );

    const rowsData = [
        {
            TitleIcon: <PaperSheetIco />,
            statusLabel: t('common.user.identity.identityDocument'),
            statusText: t(uploadCertInfo.i18nKey),
            StatusIcon: uploadCertInfo.StatusIcon
        },
        {
            TitleIcon: <BusinessCardIco />,
            statusLabel: t('common.registration.step.personalInformation'),
            statusText: t(personalInfo.i18nKey),
            StatusIcon: personalInfo.StatusIcon
        }
    ];

    const tableColumInfos = {
        registrationInfo: {
            field: 'registrationInfo',
            label: t('common.user.accountStatus.registrationInfo'),
            renderCell: ({ TitleIcon, statusLabel }) => {
                return (
                    <TableCellTwoContentsWrapper>
                        <>{TitleIcon}</>
                        <>{statusLabel}</>
                    </TableCellTwoContentsWrapper>
                );
            }
        },
        accountStatus: {
            field: 'accountStatus',
            label: t('common.general.status'),
            renderCell: ({ StatusIcon, statusText }) => {
                return (
                    <TableCellTwoContentsWrapper>
                        <>{StatusIcon}</>
                        <>{statusText}</>
                    </TableCellTwoContentsWrapper>
                );
            }
        }
    };

    return { rowsData, tableColumInfos };
};

export default useTableAccountOpeningStatus;
