import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTableWalletStatus } from 'hooks';

const withInitCall = false;

const useWalletCompleteModify = ({ fieldLabels = {} }) => {
    const navigate = useNavigate();
    const { userId } = useSelector((state) => state.user);
    const { walletAccountList, getWalletAccountList } = useTableWalletStatus(
        userId,
        withInitCall
    );
    const [resultedWalletAddress, setResultedWalletAddress] =
        useState(undefined);

    useEffect(() => {
        if (!resultedWalletAddress) return;
        getWalletAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultedWalletAddress]);

    const walletAccountInfo =
        walletAccountList.find(
            ({ walletAddress }) => walletAddress === resultedWalletAddress
        ) || {};

    const resultInfos = Object.entries(fieldLabels).map(([key, label]) => ({
        label,
        value: walletAccountInfo[key]
    }));

    const redirectWalletPage = () => {
        navigate('/user-info/wallet');
    };
    return {
        resultInfos,
        setResultedWalletAddress,
        redirectWalletPage
    };
};

export default useWalletCompleteModify;
