import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import { FormControl, InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { SELECT_DROPDOWN_TYPE } from 'constants/selectDropDown';
import { useWindowSize } from 'hooks';

import FieldFormikWrapper from '../FieldWrapper/FieldFormikWrapper';
import FieldPlainWrapper from '../FieldWrapper/FieldPlainWrapper';
import './SelectDropdownField.scss';
import MuiNativeSelect from './components/MuiNativeSelect/MuiNativeSelect';
import MuiSelect from './components/MuiSelect/MuiSelect';
import { selectTypesProps } from './constants';

const SelectDropdownField = ({
    selectType = SELECT_DROPDOWN_TYPE.DROPDOWN, // 'dropdown' or 'popup'
    label = '',
    value,
    handleChange = null,
    handleBlur = null,
    onClick = null,
    items = [],
    itemValueKey = null,
    itemLabelKey,
    renderLabel = null,
    name = '',
    fullWidth = false,
    wrapperWidth = 'auto',
    helperText = '',
    disabled = false,
    className = '',
    defaultValue = '',
    withoutFormikHook = false,
    isRequired = false,
    displayEmpty = true,
    forceMuiSelect = false,
    placeholder = ''
}) => {
    const SelectDropdownFieldWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;

    const selectTypeProps = selectTypesProps[selectType];

    const { isDesktop, isLaptop } = useWindowSize();
    const isLapTopOrUp = isDesktop || isLaptop;

    return (
        <SelectDropdownFieldWrapper name={name}>
            {(wrapperProps) => {
                const fieldValue = withoutFormikHook
                    ? value
                    : wrapperProps.value;

                return (
                    <AppFieldWrapper
                        width={wrapperWidth}
                        className={cx(
                            'selectDropdownField',
                            selectTypeProps.fieldClassName,
                            className
                        )}>
                        <InputLabel label={label} isRequired={isRequired} />
                        <FormControl
                            fullWidth={fullWidth}
                            helperText={
                                withoutFormikHook
                                    ? helperText
                                    : wrapperProps.helperText
                            }
                            error={
                                withoutFormikHook ? null : wrapperProps.error
                            }
                            variant="filled"
                            disabled={disabled}
                            {...selectTypeProps.formControlProps}>
                            <Box onClick={onClick}>
                                {forceMuiSelect || isLapTopOrUp ? (
                                    <MuiSelect
                                        name={name}
                                        fieldValue={fieldValue}
                                        defaultValue={defaultValue}
                                        wrapperProps={wrapperProps}
                                        itemLabelKey={itemLabelKey}
                                        itemValueKey={itemValueKey}
                                        items={items}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        renderLabel={renderLabel}
                                        withoutFormikHook={withoutFormikHook}
                                        displayEmpty={displayEmpty}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                    />
                                ) : (
                                    <MuiNativeSelect
                                        name={name}
                                        items={items}
                                        fieldValue={fieldValue}
                                        itemLabelKey={itemLabelKey}
                                        itemValueKey={itemValueKey}
                                        wrapperProps={wrapperProps}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        renderLabel={renderLabel}
                                        withoutFormikHook={withoutFormikHook}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                    />
                                )}
                            </Box>
                        </FormControl>
                    </AppFieldWrapper>
                );
            }}
        </SelectDropdownFieldWrapper>
    );
};

export default SelectDropdownField;
