import axiosConfig from 'axios/axiosConfig';

const authenticationAPI = {
    checkVerifyCode: {
        apiCall: (params) => axiosConfig.get('/user/login/verify', { params }),
        queryKey: 'authentication.checkVerifyCode'
    },

    loginUser: {
        apiCall: (payload) => axiosConfig.post('/user/login', payload),
        queryKey: 'authentication.loginUser'
    },
    requestSmsCode: {
        apiCall: (payload) => axiosConfig.post('/user/login/sms/send', payload),
        queryKey: 'authentication.requestSmsCode'
    },
    verifySmsCode: {
        apiCall: (payload) =>
            axiosConfig.post('/user/login/sms/verify', payload),
        queryKey: 'authentication.verifySmsCode'
    },

    logout: {
        apiCall: (userId) => axiosConfig.post(`/user/${userId}/logout`),
        queryKey: 'authentication.logout'
    },
    forgotPassword: {
        apiCall: (payload) =>
            axiosConfig.post('/user/preResetPassword', payload),
        queryKey: 'authentication.forgotPassword'
    },
    getSwitchDeviceToken: {
        apiCall: (userId) => axiosConfig.get(`/user/${userId}/device/switch`),
        queryKey: 'authentication.getSwitchDeviceToken'
    },
    getUserUploadCredential: {
        apiCall: (payload) => axiosConfig.post('/user/device/consume', payload),
        queryKey: 'authentication.getUserUploadCredential'
    },
    getResetPasswordInfo: {
        apiCall: (params) =>
            axiosConfig.get('/user/resetPassword/info', { params }),
        queryKey: 'authentication.getResetPasswordInfo'
    },
    resetPassword: {
        apiCall: (payload) => axiosConfig.post('/user/resetPassword', payload),
        queryKey: 'authentication.resetPassword'
    },
    getResetTradePassword: {
        apiCall: (params) =>
            axiosConfig.get('/user/resetTradePassword/info', { params }),
        queryKey: 'authentication.getResetTradePassword'
    },
    resetTradePassword: {
        apiCall: (payload) =>
            axiosConfig.post('/user/resetTradePassword', payload),
        queryKey: 'authentication.resetTradePassword'
    },
    modifyPassword: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/modifyPassword`, payload),
        queryKey: 'authentication.modifyPassword'
    },
    modifyEmail: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/modifyEmail`, payload),
        queryKey: 'authentication.modifyEmail'
    }
};

export default authenticationAPI;
