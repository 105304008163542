const { DATE_DEFINE } = require('constants/dateInfos');

export const checkIsDisabledCalendar = (startDateString, endDateString) => {
    return (
        startDateString === DATE_DEFINE.UNSELECT_DATE.yMd ||
        endDateString === DATE_DEFINE.UNSELECT_DATE.yMd ||
        !startDateString ||
        !endDateString
    );
};
