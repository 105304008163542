import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import './AppMainContainer.scss';

const AppMainContainer = ({ fullScreen = false, className = '', children }) => {
    return (
        <Box
            className={cx([
                'appMainContainer',
                {
                    fullScreen
                },
                className
            ])}>
            {children}
        </Box>
    );
};

export default AppMainContainer;
