import React from 'react';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import './TwoLevelOrderedList.scss';

const TwoLevelOrderedList = ({ contents, className }) => {
    return (
        <Box className={cx(['twoLevelOrderedList', className])}>
            {contents.map(({ title, sections }, index) => {
                return (
                    <Box className="listSection" key={index}>
                        {title && (
                            <Typography className="titleTxt">
                                {title}
                            </Typography>
                        )}
                        <Box className="content">
                            <ol type="a" className="outerLevel customMarker">
                                {sections.map(
                                    ({ subTitle, items }, outerIndex) => {
                                        const renderInnerList = () => {
                                            return (
                                                <ol
                                                    type="1"
                                                    className="innerLevel customMarker">
                                                    {items.map(
                                                        (item, innerIndex) => (
                                                            <li
                                                                key={
                                                                    innerIndex
                                                                }>
                                                                <span>
                                                                    {item}
                                                                </span>
                                                            </li>
                                                        )
                                                    )}
                                                </ol>
                                            );
                                        };

                                        return (
                                            <React.Fragment key={outerIndex}>
                                                {subTitle ? (
                                                    <li className="outerItem">
                                                        <span>{subTitle}</span>
                                                        {renderInnerList()}
                                                    </li>
                                                ) : (
                                                    renderInnerList()
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </ol>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default TwoLevelOrderedList;
