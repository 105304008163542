export const MUI_SEVERITY = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'
};

export const DATE_PICKER_FORMAT = {
    'YYYY-MM-DD': {
        INPUT_FORMAT: 'yyyy-MM-dd',
        MASK: '____-__-__'
    },
    'YYYY-MM': {
        INPUT_FORMAT: 'yyyy-MM',
        MASK: '____-__'
    }
};
