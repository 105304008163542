import { useTranslation } from 'react-i18next';

import { DATE_RANGE_UNSELECT } from 'constants/dateInfos';
import { FILTER_LEVEL, FILTER_TYPE } from 'constants/filterInfos';
import {
    useAppFilter,
    useFilterAccountTypes,
    useFilterParentTrader,
    useFilterTradeCategories,
    useFilterTradeTypes
} from 'hooks';
import { useFilterManager } from 'hooks/filters';

export const FILTER_NAMES_FUND_FLOW = {
    SEARCH_TRADE_LOGIN_IDS: 'searchTradeLoginIds',
    EMAIL: 'email',
    TRADE_TYPE: 'tradeType',
    CATEGORY: 'category',
    ACCOUNT_TYPE: 'accountType',
    CREATE_PERIOD: ['startDate', 'endDate'],
    PARENT_TRADE_LOGIN_ID: 'parentTradeLoginId',
    STATUS: 'status',
    MANAGER_TRADE_LOGIN_IDS: 'managerTradeLoginIds'
};

const useFilterGroupFundFlow = ({
    filterGroupId = 'FILTER_GROUP_FUND_FLOW',
    filterNameLists
}) => {
    const { t } = useTranslation();
    const { managerTradeLoginIdFilterInfo } = useFilterManager();

    const { tradeTypesFilterInfo } = useFilterTradeTypes();
    const { tradeCategoriesFilterInfo } = useFilterTradeCategories();
    const { accountTypesFilterInfo } = useFilterAccountTypes();
    const { parentTradeLoginIdFilterInfo } = useFilterParentTrader();

    const filterListsInfos = {
        [FILTER_NAMES_FUND_FLOW.SEARCH_TRADE_LOGIN_IDS]: {
            filterName: FILTER_NAMES_FUND_FLOW.SEARCH_TRADE_LOGIN_IDS,
            filterType: FILTER_TYPE.TEXT_SEARCH,
            label: t('common.tradeAccount.tradeLoginId'),
            defaultValue: ''
        },
        [FILTER_NAMES_FUND_FLOW.EMAIL]: {
            filterName: FILTER_NAMES_FUND_FLOW.EMAIL,
            filterType: FILTER_TYPE.TEXT_SEARCH,
            label: t('common.user.communication.userEmail'),
            defaultValue: ''
        },
        [FILTER_NAMES_FUND_FLOW.TRADE_TYPE]: {
            ...tradeTypesFilterInfo,
            filterName: FILTER_NAMES_FUND_FLOW.TRADE_TYPE
        },
        [FILTER_NAMES_FUND_FLOW.CATEGORY]: {
            ...tradeCategoriesFilterInfo,
            filterName: FILTER_NAMES_FUND_FLOW.CATEGORY
        },
        [FILTER_NAMES_FUND_FLOW.ACCOUNT_TYPE]: {
            ...accountTypesFilterInfo,
            filterName: FILTER_NAMES_FUND_FLOW.ACCOUNT_TYPE
        },
        [FILTER_NAMES_FUND_FLOW.CREATE_PERIOD]: {
            filterName: FILTER_NAMES_FUND_FLOW.CREATE_PERIOD,
            filterType: FILTER_TYPE.DATE_RANGE,
            label: t('common.date.createTime'),
            defaultValue: DATE_RANGE_UNSELECT,
            allowDisable: true
        },
        [FILTER_NAMES_FUND_FLOW.PARENT_TRADE_LOGIN_ID]: {
            ...parentTradeLoginIdFilterInfo,
            filterName: FILTER_NAMES_FUND_FLOW.PARENT_TRADE_LOGIN_ID
        },
        [FILTER_NAMES_FUND_FLOW.MANAGER_TRADE_LOGIN_IDS]: {
            ...managerTradeLoginIdFilterInfo,
            filterName: FILTER_NAMES_FUND_FLOW.MANAGER_TRADE_LOGIN_IDS
        }
    };

    const filterLists = {
        [FILTER_LEVEL.BASIC]: [],
        [FILTER_LEVEL.ADVANCED]: []
    };

    Object.entries(filterNameLists).forEach(([filterLevel, filterFields]) => {
        filterFields.forEach((filterName) => {
            filterLists[filterLevel].push(filterListsInfos[filterName]);
        });
    });

    useAppFilter({
        filterGroupId,
        filterLists
    });

    return null;
};

export default useFilterGroupFundFlow;
