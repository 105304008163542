import { useContext, useEffect, useState } from 'react';

import { Box, Collapse, TableRow } from '@mui/material';
import cx from 'classnames';

import RowCollapsibleContext from 'layouts/AppTableContainer/RowCollapsibleProvider/RowCollapsibleContext';

import AppTableCell from '../AppTableCell/AppTableCell';
import './RowCollapsible.scss';
import { usePrevious } from 'hooks';
import { ANIMATION_TIMEOUT } from './constants';

const RowCollapsible = ({ rowData, rowIndex }) => {
    const { checkCollapsibleExpanded, renderRowCollapsible } = useContext(
        RowCollapsibleContext
    );
    const isCollapsibleExpanded = checkCollapsibleExpanded(rowIndex);
    const oldIsCollapsibleExpanded = usePrevious(isCollapsibleExpanded);
    const [isCollapsibleCollapsing, setIsCollapsibleCollapsing] =
        useState(false);

    useEffect(() => {
        if (!isCollapsibleExpanded && oldIsCollapsibleExpanded) {
            setIsCollapsibleCollapsing(true);
            setTimeout(
                () => setIsCollapsibleCollapsing(false),
                ANIMATION_TIMEOUT
            );
        }
    }, [isCollapsibleExpanded, oldIsCollapsibleExpanded]);

    return (
        <TableRow className={cx('rowCollapsible', 'appTableRow')}>
            <AppTableCell colSpan={100}>
                <Collapse
                    in={isCollapsibleExpanded}
                    timeout={ANIMATION_TIMEOUT}
                    unmountOnExit>
                    <Box
                        className={cx({
                            expandedContent:
                                isCollapsibleExpanded || isCollapsibleCollapsing
                        })}>
                        {renderRowCollapsible(rowData)}
                    </Box>
                </Collapse>
            </AppTableCell>
        </TableRow>
    );
};

export default RowCollapsible;
