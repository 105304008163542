export const selectTypesProps = {
    dropdown: {
        fieldClassName: 'selectDropdownField',
        formControlProps: {}
    },
    popup: {
        fieldClassName: 'selectPopupField',
        formControlProps: {
            disabled: true
        }
    }
};

export const VALUE_UNSELECTED = '';
