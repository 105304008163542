import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';
import cx from 'classnames';

import { Button, SelectDropdownField } from 'components';
import { AppCard } from 'layouts';

import { USER_ROLE } from 'constants/authInfos';
import { PAGE_FULL_STATIC_PATH } from 'constants/routes';
import { useTradeAccountDetailList, useTradeAccountList } from 'hooks';
import { setQueryTradeLoginId } from 'store/user/slice';

import { getAccountTypeLocale } from 'utils/client/accountType';
import { getProfilePictureImage } from 'utils/layouts/sideContent';
import { displayUserName } from 'utils/utils';

import './BasicInfo.scss';
import Asset from './components/Asset/Asset';
import Shortcut from './components/Shortcut/Shortcut';
import { BookIco, LinkIco } from 'assets/images/common';

const BasicInfo = ({ displayFor = '', tradeAccountInfo = undefined }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { title, userId, displayName } = useSelector((state) => state.user);
    const { tradeAccounts } = useTradeAccountList();
    const [ibTradeAcSelected, setIbTradeAcSeleted] = useState('');
    const { tradeAccountDetails } = useTradeAccountDetailList(
        userId,
        ibTradeAcSelected
    );

    const IbTradeAccounts = tradeAccounts.filter(
        (account) => account.role === USER_ROLE.IB
    );

    useEffect(() => {
        if (IbTradeAccounts)
            setIbTradeAcSeleted(IbTradeAccounts[0]?.tradeLoginId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeAccountInfo]);

    useEffect(() => {
        if (ibTradeAcSelected !== undefined && ibTradeAcSelected !== '')
            dispatch(setQueryTradeLoginId(ibTradeAcSelected));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ibTradeAcSelected]);

    const ProfilePicImg = getProfilePictureImage(title);

    if (!tradeAccountInfo) return null;

    const { tradeLoginId, level, accountType, currency } = tradeAccountInfo;

    const accountLevel =
        displayFor === 'ibHome' ? tradeAccountDetails[0]?.level : level;
    return (
        <Box className={cx(['basicInfo', displayFor])}>
            <AppCard title={t('common.sidebar.basicInfo.title')}>
                <Box className="basicInfoContent">
                    <Box className="profilePic">
                        <img src={ProfilePicImg} alt="" />
                    </Box>
                    <Box className="greeting">
                        <Typography component="h4">
                            {t('common.sidebar.basicInfo.hello')}{' '}
                            {displayUserName(displayName, t)}!
                        </Typography>
                    </Box>
                    <Box className="tradeAccount">
                        {displayFor === 'ibHome' ? (
                            <Box className="tradeAcSelect">
                                <Typography component="span">
                                    {t('common.sidebar.basicInfo.tradeAccount')}
                                </Typography>
                                <SelectDropdownField
                                    withoutFormikHook
                                    name="tradeLoginId"
                                    items={IbTradeAccounts}
                                    itemValueKey="tradeLoginId"
                                    itemLabelKey="tradeLoginId"
                                    value={ibTradeAcSelected}
                                    handleChange={(event) => {
                                        setIbTradeAcSeleted(event.target.value);
                                    }}
                                />
                            </Box>
                        ) : (
                            <Typography className="currentTradeAc">
                                {t('common.sidebar.basicInfo.tradeAccount')}：
                                {tradeLoginId}
                            </Typography>
                        )}
                        {displayFor === 'managerHome' ? (
                            <Box className="quickBtnWrapper">
                                <Button
                                    variant="gradient"
                                    startIcon={<BookIco />}
                                    className={cx('quickBtn', 'managerButton')}
                                    linkProps={{
                                        to: PAGE_FULL_STATIC_PATH.SalesPerformance
                                    }}
                                    label={t(
                                        'common.sidebar.basicInfo.salesPerformance'
                                    )}
                                />
                                <Button
                                    variant="gradient"
                                    startIcon={<LinkIco />}
                                    className={cx('quickBtn', 'managerButton')}
                                    linkProps={{
                                        to: PAGE_FULL_STATIC_PATH.ReferralLink
                                    }}
                                    label={t(
                                        'common.navbar.clientMgmt.accountLink'
                                    )}
                                />
                            </Box>
                        ) : (
                            <Box className="tradeAcDetail">
                                <Typography component="span">
                                    {accountLevel ? `1:${accountLevel}` : '---'}
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />
                                <Typography component="span">
                                    {getAccountTypeLocale(
                                        displayFor === 'ibHome'
                                            ? tradeAccountDetails[0]
                                                  ?.accountType
                                            : accountType,
                                        t
                                    )}
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />
                                <Typography component="span">
                                    {displayFor === 'ibHome'
                                        ? tradeAccountDetails[0]?.currency
                                        : currency}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {displayFor !== 'trade' ? (
                    <Shortcut displayFor={displayFor} />
                ) : (
                    <Asset assetData={tradeAccountInfo} />
                )}
            </AppCard>
        </Box>
    );
};

export default BasicInfo;
