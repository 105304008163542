import { createSlice } from '@reduxjs/toolkit';

const initialSystemState = {
    fillOptionsGroup: {}
};

const systemSlice = createSlice({
    name: 'system',
    initialState: initialSystemState,
    reducers: {
        resetAllFillOptions: () => ({ ...initialSystemState }),
        setFillOptions: (state, action) => {
            state.fillOptionsGroup = {
                ...state.fillOptionsGroup,
                ...action.payload
            };
        }
    }
});

export default systemSlice;

export const { setFillOptions, resetAllFillOptions } = systemSlice.actions;
