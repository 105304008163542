import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { GradientCard, TabControl } from 'components';
import { AppCardHeader } from 'layouts';

import {
    CTRADER_DOWNLOAD_LINK,
    MT5_DOWNLOAD_LINK
} from 'constants/serverInfos/serverInfos';

import './TradingTool.scss';
import DownloadList from './components/DownloadList/DownloadList';
import {
    AndroidImg,
    IosImg,
    MacImg,
    PdfImg,
    WebImg,
    WindowsImg
} from 'assets/images/common';

const TradingTool = ({ animation = false }) => {
    const { t } = useTranslation();
    const { platforms } = useSelector((state) => state.user);

    const [tabIndex, setTabIndex] = useState(0);

    const tabInfoList = [
        {
            label: 'MT5',
            value: 'mt5'
        },
        // {
        //     label: 'cTrader',
        //     value: 'cTrader'
        // }
    ];

    const cTraderDownloadInfos = [
        {
            icon: WindowsImg,
            label: `${t(
                'common.sidebar.tradingTool.download'
            )} cTrader - Windows`,
            link: `${window.location.origin}${CTRADER_DOWNLOAD_LINK.WINDOWS}`
        },
        {
            icon: WebImg,
            label: `${t('common.sidebar.tradingTool.login')} cTrader - Web`,
            link: `${window.location.origin}${CTRADER_DOWNLOAD_LINK.MAC}`
        },
        {
            icon: IosImg,
            label: `${t('common.sidebar.tradingTool.download')} cTrader - iOS`,
            link: `${window.location.origin}${CTRADER_DOWNLOAD_LINK.IOS}`,
            isQrCode: true
        },
        {
            icon: AndroidImg,
            label: `${t(
                'common.sidebar.tradingTool.download'
            )} cTrader - Android`,
            link: `${window.location.origin}${CTRADER_DOWNLOAD_LINK.ANDROID}`,
            isQrCode: true
        },
        {
            icon: PdfImg,
            label: t('common.sidebar.tradingTool.ctraderUserGuide'),
            link: `${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}${CTRADER_DOWNLOAD_LINK.PDF}`
        }
    ];

    const mt5DownloadInfos = [
        {
            icon: IosImg,
            label: `${t('common.sidebar.tradingTool.download')} MT5 - iOS`,
            link: `${window.location.origin}${MT5_DOWNLOAD_LINK.IOS}`,
            isQrCode: true
        },
        {
            icon: AndroidImg,
            label: `${t('common.sidebar.tradingTool.download')} MT5 - Android`,
            link: `${window.location.origin}${MT5_DOWNLOAD_LINK.ANDROID}`,
            isQrCode: true
        },
        {
            icon: WindowsImg,
            label: `${t('common.sidebar.tradingTool.download')} MT5 - Windows`,
            link: `${window.location.origin}${MT5_DOWNLOAD_LINK.WINDOWS}`
        },
        {
            icon: MacImg,
            label: `${t('common.sidebar.tradingTool.download')} MT5 - Mac`,
            link: `${window.location.origin}${MT5_DOWNLOAD_LINK.MAC}`
        }
    ];

    const DynamicDownloadList = () => {
        if (platforms?.length > 1 || !platforms?.length) {
            if (tabIndex === 0) {
                return <DownloadList downloadInfos={mt5DownloadInfos} />;
            } else if (tabIndex === 1) {
                return <DownloadList downloadInfos={cTraderDownloadInfos} />;
            }
        } else {
            if (platforms?.includes(100)) {
                return <DownloadList downloadInfos={cTraderDownloadInfos} />;
            } else if (platforms.includes(5)) {
                return <DownloadList downloadInfos={mt5DownloadInfos} />;
            }
        }
    };

    const handleTabChange = (value, index) => setTabIndex(index);

    return (
        <Box className="tradingTool">
            <GradientCard animation={animation}>
                <Box className="title">
                    <AppCardHeader
                        title={t('common.sidebar.tradingTool.title')}
                        titleColor="white"
                    />
                </Box>
                {platforms?.length === 2 || !platforms?.length ? (
                    <Box className="tabWrapper">
                        <TabControl
                            tabInfoList={tabInfoList}
                            variant="segmentTab"
                            handleChange={handleTabChange}
                        />
                    </Box>
                ) : null}
                <DynamicDownloadList />
            </GradientCard>
        </Box>
    );
};

export default TradingTool;
