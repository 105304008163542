import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Button } from 'components';
import { AppCard, AppStatusContent } from 'layouts';

import { StatusCheckImg } from 'assets/images/status';

const CompleteModifyBoard = ({
    title,
    resultInfos,
    handleClickCheck,
    handleCloseDialog,
    showCheckButton = false
}) => {
    const { t } = useTranslation();

    let ButtonList = [
        <Button
            fullWidth
            variant="contained"
            label={t('common.action.close')}
            onClick={handleCloseDialog}
        />
    ];

    if (showCheckButton) {
        ButtonList = [
            ...ButtonList,
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                label={t('common.action.check')}
                onClick={() => {
                    handleClickCheck();
                    handleCloseDialog();
                }}
            />
        ];
    }

    return (
        <Box className="completeModifyBoardWrapper">
            <AppStatusContent
                title={title}
                contentMarginTop={0}
                StatusImgSrc={StatusCheckImg}
                ButtonList={ButtonList}>
                <AppCard className="infoCard">
                    {resultInfos.map(({ value, label }, index) => (
                        <Box key={index} className="info">
                            <Typography>{label}</Typography>
                            <Typography className="value">
                                {value || '---'}
                            </Typography>
                        </Box>
                    ))}
                </AppCard>
            </AppStatusContent>
        </Box>
    );
};

export default CompleteModifyBoard;
