import differentInYears from 'date-fns/differenceInYears';
import endOfYesterday from 'date-fns/endOfYesterday';
import * as yup from 'yup';

import { ADULTHOOD_AGE } from 'constants/registration';
import { WALLET_CHAIN } from 'constants/serverInfos/serverInfos';
import { YUP_SCHEMAS } from 'constants/yupInfos';

import { getToday } from 'utils/dateInfos';

export const isYupSchema = (object) =>
    YUP_SCHEMAS.filter((schema) => object instanceof schema).length > 0;

export const emailValidation = yup
    .string()
    .email('common.validate.emailInvalid');
export const emailRequiredValidation = emailValidation.required(
    'common.validate.fieldRequired'
);

export const passwordValidation = yup
    .string()
    .min(8, 'common.validate.passwordTooShort')
    .required('common.validate.fieldRequired');

export const newPasswordFormatValidation = yup
    .string()
    .min(8, 'common.validate.passwordFormatInvalid')
    .max(15, 'common.validate.passwordFormatInvalid')
    .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!#$%&*+,.:;<>?@^_~-])(?=)^[\x20-\x7E]{8,15}$/gm,
        'common.validate.passwordFormatInvalid'
    )
    .required('common.validate.fieldRequired');

export const parseRepeatedPasswordValidation = (passwordKey) =>
    yup
        .mixed()
        .oneOf([yup.ref(passwordKey)], 'common.validate.passwordNotMatched')
        .required('common.validate.fieldRequired');

export const parseRepeatedEmailValidation = (emailKey) =>
    yup
        .mixed()
        .oneOf([yup.ref(emailKey)], 'common.validate.emailNotMatched')
        .required('common.validate.fieldRequired');

export const verifyCodeValidation = yup
    .string()
    .matches(/^[0-9]*$/, 'common.validate.numberRequired')
    .min(6, 'common.validate.verifyCodeTooShort')
    .required('common.validate.fieldRequired');

export const mobileValidation = yup
    .string()
    .matches('^[0-9]*$', 'common.validate.numberRequired')
    .typeError('common.validate.numberRequired')
    .required('common.validate.fieldRequired');

export const nameNotReqValidation = yup
    .string()
    .matches(
        /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]*$/,
        'common.validate.nameInvalid'
    );
export const nameReqValidation = nameNotReqValidation.required(
    'common.validate.fieldRequired'
);

export const dateValidation = yup
    .date()
    .typeError('common.validate.dateInvalid')
    .required('common.validate.fieldRequired');

export const getExpiryDateValidation = () =>
    dateValidation
        .typeError('common.validate.expiryDateInvalid')
        .min(endOfYesterday(), 'common.validate.expiryDateExpired');

export const getDateAndAgeValidation = () =>
    yup.mixed().test(async (date, context) => {
        try {
            await dateValidation.validate(date);
            return differentInYears(getToday(), new Date(date)) < ADULTHOOD_AGE
                ? context.createError({
                      message: 'common.validate.minorNotAllowed'
                  })
                : true;
        } catch ({ message }) {
            return context.createError({ message });
        }
    });

export const numberValidation = yup
    .number()
    .typeError('common.validate.numberRequired')
    .required('common.validate.fieldRequired');

export const commonTextValidation = yup
    .string()
    .required('common.validate.fieldRequired');

export const mixedValidation = yup
    .mixed()
    .required('common.validate.fieldRequired');

export const arrayValidation = yup
    .array()
    .min(1, 'common.validate.fieldRequired');

export const amountValidation = yup
    .number()
    .typeError('common.validate.numberRequired')
    .required('common.validate.amountRequired');

export const trc20AddressValidation = yup
    .string()
    .matches(/^T[0-9A-Za-z]{33}$/g, 'common.validate.walletAddressInvalid')
    .required('common.validate.fieldRequired');

export const erc20AddressValidation = yup
    .string()
    .matches(/^0x[0-9A-Fa-f]{40}$/g, 'common.validate.walletAddressInvalid')
    .required('common.validate.fieldRequired');

export const parseWalletAddressValidation = (walletChainKey) =>
    yup.mixed().test(async (fieldValue, context) => {
        const lastIndex = context.from.length - 1;
        const walletChain = context.from[lastIndex].value[walletChainKey];
        let addressValidation = null;

        switch (walletChain) {
            case WALLET_CHAIN.TRC20.serverKey:
                addressValidation = trc20AddressValidation;
                break;
            case WALLET_CHAIN.ERC20.serverKey:
                addressValidation = erc20AddressValidation;
                break;
            default:
                addressValidation = commonTextValidation;
                break;
        }

        try {
            await addressValidation.validate(fieldValue);
            return true;
        } catch ({ message }) {
            return context.createError({ message });
        }
    });

export const parseBooleanValidation = (targetValue, errorI18nKey) =>
    yup.boolean().test((booleanValue, context) =>
        booleanValue === targetValue
            ? true
            : context.createError({
                  message: errorI18nKey
              })
    );

export const englishNameValidation = yup
    .string()
    .matches(/^[a-zA-Z ]+$/gm, 'common.validate.englishOnly')
    .required('common.validate.fieldRequired');
