import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { REWARD_ORDERING_METHOD } from 'pages/Reward/constants';

import { useDidUpdateEffect } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useRewardList = ({
    orderingMethod = REWARD_ORDERING_METHOD.CREATE_TIME_DESCENDING_ORDER,
    targetCategory = {}
} = {}) => {
    const { userId } = useSelector((state) => state.user);

    const isResettingPageRef = useRef(false);

    const [rewardList, setRewardList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getRewardList = useCallback(() => {
        const params = {
            category: targetCategory.serverKey,
            orderBy: orderingMethod.serverParams.orderBy,
            sortBy: orderingMethod.serverParams.sortBy,
            pageCurrent: currentPage,
            pageSize: pageSize
        };
        handleAsyncCall({
            asyncCall: () => API.reward.getItemList.apiCall(userId, params),
            handleCallSuccess: (res) => setRewardList(res.data ?? {})
        });
    }, [
        currentPage,
        orderingMethod.serverParams.orderBy,
        orderingMethod.serverParams.sortBy,
        pageSize,
        targetCategory.serverKey,
        userId
    ]);

    useDidUpdateEffect(() => {
        if (currentPage > 1) {
            isResettingPageRef.current = true;
            setCurrentPage(1);
        }
    }, [targetCategory]);

    useEffect(() => {
        if (!isResettingPageRef.current) {
            getRewardList();
        } else {
            isResettingPageRef.current = false;
        }
    }, [currentPage, getRewardList]);

    return {
        rewardList,
        getRewardList,
        setCurrentPage,
        setPageSize
    };
};

export default useRewardList;
