export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const DEFAULT_PAGE_CURRENT = 1;

export const CELL_GROUP_STICKY_TYPE = {
    STICKY_LEFT: 'STICKY_LEFT',
    STICKY_RIGHT: 'STICKY_RIGHT',
    UN_STICKY: 'UN_STICKY'
};

export const defaultTablePageValues = {
    pageCurrent: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE
};
