import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { NumFormat, PlainTextPlatformField } from 'components';

import { defaultTablePageValues } from 'constants/appTableInfos';
import { defaultAppFilterDateRange } from 'constants/filterInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { useOpenSnackbar } from 'hooks';

import API from 'services';
import { getAccountTypeLocale } from 'utils/client/accountType';
import { formatDateToStringFromAPI } from 'utils/dateConversion';
import { handleAsyncCall } from 'utils/utils';

const initPayload = {
    ...defaultTablePageValues,
    startDate: defaultAppFilterDateRange[0],
    endDate: defaultAppFilterDateRange[1]
};

const useTableOrders = (
    searchTradeLoginId = VALUES_ALL_TRADE_LOGIN_IDS.API_PAYLOAD
) => {
    const { t } = useTranslation();
    const { openErrorSnackbar } = useOpenSnackbar();
    const { userId } = useSelector((state) => state.user);
    const [orderData, setOrderData] = useState([]);
    const [orderPayload, setOrderPayload] = useState({
        ...initPayload,
        searchTradeLoginIds: searchTradeLoginId
    });
    const [isFetching, setIsFetching] = useState(false);

    const getOrderData = () => {
        setIsFetching(true);

        handleAsyncCall({
            asyncCall: () =>
                API.traderReports.getOrderList.apiCall(userId, orderPayload),
            handleCallSuccess: (res) => {
                setOrderData(res.data);
                setIsFetching(false);
            },
            handleCallFailureError: (err) => {
                openErrorSnackbar(err.message);
                setIsFetching(false);
            }
        });
    };

    useEffect(() => {
        getOrderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderPayload]);

    const renderType = (data) => {
        return (
            <Box>
                {Number(data.action) === 0
                    ? t('common.order.buy')
                    : t('common.order.sell')}
            </Box>
        );
    };

    const orderTableColumnInfos = {
        positionId: {
            field: 'positionId',
            label: t('common.table.orderNo')
        },
        accountTypeDisplay: {
            renderCell: ({ accountType }) =>
                getAccountTypeLocale(accountType, t),
            label: t('common.table.accountType')
        },
        currency: {
            field: 'currency',
            label: t('common.table.currency')
        },
        tradeLoginId: {
            field: 'tradeLoginId',
            label: t('common.tradeAccount.tradeLoginId')
        },
        platform: {
            field: 'platform',
            label: t('common.tradeAccount.platform'),
            renderCell: ({ platform }) => (
                <PlainTextPlatformField platform={platform} />
            )
        },
        createTime: {
            field: 'createTime',
            label: t('common.date.openDate'),
            renderCell: ({ createTime }) =>
                formatDateToStringFromAPI(createTime)
        },
        action: {
            field: 'action',
            label: t('common.table.type'),
            renderCell: renderType
        },
        symbol: {
            field: 'symbol',
            label: t('common.table.product')
        },
        volume: {
            field: 'volume',
            label: t('common.table.volume'),
            renderCell: ({ volume }) => <NumFormat value={volume} />
        },
        priceOpen: {
            field: 'priceOpen',
            label: t('common.table.openPrice'),
            renderCell: ({ priceOpen }) => <NumFormat value={priceOpen} />
        },
        priceSL: {
            field: 'priceSL',
            label: t('common.table.priceSL'),
            renderCell: ({ priceSL }) => (
                <NumFormat
                    value={priceSL}
                    fixedDecimalScale={priceSL === 0 ? true : false}
                    decimalScale={priceSL === 0 ? 2 : 5}
                />
            )
        },
        priceTP: {
            field: 'priceTP',
            label: t('common.table.priceTP'),
            renderCell: ({ priceTP }) => (
                <NumFormat
                    value={priceTP}
                    fixedDecimalScale={priceTP === 0 ? true : false}
                    decimalScale={priceTP === 0 ? 2 : 5}
                />
            )
        },
        priceCurrent: {
            field: 'priceCurrent',
            label: t('common.table.currentPrice'),
            renderCell: ({ priceCurrent }) => <NumFormat value={priceCurrent} />
        },
        storage: {
            field: 'storage',
            label: t('common.table.interest'),
            renderCell: ({ storage }) => (
                <NumFormat
                    value={storage}
                    fixedDecimalScale={true}
                    decimalScale={2}
                />
            )
        },
        profit: {
            field: 'profit',
            label: t('common.table.floatingProfit'),
            renderCell: ({ profit }) => (
                <NumFormat
                    value={profit}
                    fixedDecimalScale={true}
                    decimalScale={2}
                />
            )
        },
        comment: {
            field: 'comment',
            label: t('common.table.comment'),
            renderCell: ({ comment }) => comment || '---'
        },
        departmentName: {
            field: 'departmentName',
            label: t('common.user.work.department')
        },
        mgrTradeLoginId: {
            field: 'mgrTradeLoginId',
            label: t('common.sales.mgrTradeLoginId')
        }
    };

    const ordersStatsInfos = [
        {
            field: 'profit',
            label: t('common.tradeAccount.finance.floatingProfit'),
            renderCell: ({ profit }) => <NumFormat value={profit} />
        },
        {
            field: 'swap',
            label: t('common.tradeAccount.finance.interest'),
            renderCell: ({ swap }) => <NumFormat value={swap} />
        }
    ];

    const ordersStatItems = [];

    if (orderData) {
        ordersStatsInfos.forEach(({ field, label }) => {
            ordersStatItems.push({
                label,
                value: orderData[field]
            });
        });
    }

    return {
        orderData,
        orderTableColumnInfos,
        ordersStatItems,
        orderPayload,
        setOrderPayload,
        isFetching
    };
};

export default useTableOrders;
