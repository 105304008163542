import React from 'react';
import NumberFormat from 'react-number-format';

const NumFormat = ({ value, renderText = (value) => value, ...props }) => {
    if (isNaN(parseInt(value, 10))) return renderText('---');

    return (
        <NumberFormat
            displayType={'text'}
            thousandSeparator
            decimalScale={5}
            value={value}
            renderText={renderText}
            {...props}
        />
    );
};

export default NumFormat;
