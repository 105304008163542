import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setDocStatus,
    setInfoStatus,
    setUserAccStatus,
    setUserLevel,
    setUserRole
} from 'store/user/slice';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const clientStateReducer = (oldValue, newValue) => ({
    ...oldValue,
    ...newValue
});

const useAccStatusRole = ({
    clientUserId = -1,
    successCallback = () => {},
    failureErrorCallback = () => {}
} = {}) => {
    const dispatch = useDispatch();
    const {
        isLogin,
        userId: currentUserId,
        userAccStatus: currentUserAccStatus,
        userRole: currentUserRole,
        docStatus: currentUserDocStatus,
        infoStatus: currentUserInfoStatus
    } = useSelector((state) => state.user);
    const [clientState, setClientState] = useReducer(clientStateReducer, {});

    useEffect(() => {
        if (isLogin && currentUserId) {
            const getAccStatusRole = async () => {
                handleAsyncCall({
                    asyncCall: () =>
                        API.user.getAccStatus.apiCall(
                            clientUserId === -1 ? currentUserId : clientUserId
                        ),
                    handleCallSuccess: (res) => {
                        if (clientUserId === -1) {
                            dispatch(setUserAccStatus(res.data.accStatus));
                            dispatch(setUserRole(res.data.role));
                            dispatch(setDocStatus(res.data.docStatus));
                            dispatch(setInfoStatus(res.data.infoStatus));
                            dispatch(setUserLevel(res.data.level));
                        } else {
                            setClientState({
                                userAccStatus: res.data.accStatus
                            });
                            setClientState({ userRole: res.data.role });
                            setClientState({ docStatus: res.data.docStatus });
                            setClientState({ infoStatus: res.data.infoStatus });
                            setClientState({ level: res.data.level });
                        }
                        successCallback(res);
                    },
                    handleCallFailureError: (res) => {
                        failureErrorCallback(res);
                    }
                });
            };
            getAccStatusRole();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientUserId, isLogin, currentUserId]);

    const userAccStatus =
        clientUserId === -1 ? currentUserAccStatus : clientState.userAccStatus;
    const userRole =
        clientUserId === -1 ? currentUserRole : clientState.userRole;
    const docStatus =
        clientUserId === -1 ? currentUserDocStatus : clientState.docStatus;
    const infoStatus =
        clientUserId === -1 ? currentUserInfoStatus : clientState.infoStatus;

    return { userAccStatus, userRole, docStatus, infoStatus };
};

export default useAccStatusRole;
