import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DialogContentRemind } from 'components';

import { useOpenDialog, useOpenSnackbar } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useDialogRemoveAccountLink = (successRemoveCallBack = () => {}) => {
    const { t } = useTranslation();
    const { openSuccessSnackbar, openErrorSnackbar } = useOpenSnackbar();
    const [inviteUrlInfo, setInviteUrlInfo] = useState({});
    const { userId } = useSelector((state) => state.user);

    const { inviteUrlId, accountLinkName } = inviteUrlInfo;

    const removeAccountLink = () => {
        handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.removeAccountLink.apiCall(userId, inviteUrlId),
            handleCallSuccess: () => {
                openSuccessSnackbar(t('common.snackbar.removeSuccess'));
                successRemoveCallBack();
            },
            handleCallFailureError: (res) => {
                openErrorSnackbar(
                    res.message || t('common.snackbar.removeFailed')
                );
            }
        });
    };

    const { openDialog, closeDialog } = useOpenDialog({
        name: 'dialogRemoveAccountLink',
        title: t('customerMgmt.accountLink.dialogRemoveAccountLink.title'),
        children: (
            <DialogContentRemind
                reminderMessage={` ${t(
                    'customerMgmt.accountLink.dialogRemoveAccountLink.deleteRemind1'
                )} ${accountLinkName} ${t(
                    'customerMgmt.accountLink.dialogRemoveAccountLink.deleteRemind2'
                )}`}
                cancelButtonLabel={t('common.action.cancel')}
                confirmButtonLabel={t('common.action.confirm')}
                onCancel={() => closeDialog()}
                onConfirm={() => {
                    removeAccountLink();
                    closeDialog();
                }}
            />
        )
    });

    const openDialogRemoveAccountLink = (newInviteUrlInfo) => {
        setInviteUrlInfo(newInviteUrlInfo);

        openDialog();
    };

    return {
        openDialogRemoveAccountLink
    };
};

export default useDialogRemoveAccountLink;
