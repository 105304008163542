import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, useField } from 'formik';

const useFormikFieldProps = (fieldName, helperTextProps = {}) => {
    const { t } = useTranslation();
    const [field, meta, helper] = useField(fieldName);

    const { name, value, onChange } = field;
    const { error, touched } = meta;
    const { setTouched, setValue } = helper;

    const fieldProps = {
        setValue: (value) => setValue(value),
        onChange,
        onBlur: () => setTouched(name, true),
        value,
        helperText: (
            <ErrorMessage
                name={name}
                render={(i18nKey) => t(i18nKey, helperTextProps)}
            />
        ),
        error: !!(touched && error),
        setTouched
    };

    return fieldProps;
};

export default useFormikFieldProps;
