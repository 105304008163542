import { SYSTEM_REFERRAL_LINK_NAME } from 'constants/serverInfos/serverInfos';

export const getResponseList = (responseData) => {
    return responseData?.data?.data?.list ?? [];
};

export const getAccountLinkName = (name, system, t) => {
    return system && SYSTEM_REFERRAL_LINK_NAME[name]
        ? t(SYSTEM_REFERRAL_LINK_NAME[name].i18nKey)
        : name;
};

export const getAppDownloadLink = () => {
    return `${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}/app`;
};
