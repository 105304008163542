import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import cx from 'classnames';

import { Button } from 'components';

import {
    VISIBILITY_LIVE_CHAT,
    useWidgetLiveChat
} from 'utils/providers/WidgetLiveChatProvider/WidgetLiveChatProvider';
import { handleShowWidgetCSS } from 'utils/providers/WidgetLiveChatProvider/utils';

import './ButtonLiveChat.scss';
import { OnlineCsIco } from 'assets/images/common';

const ButtonLiveChat = ({
    theme = 'white',
    widgetClassName = '',
    handleMenuToggle
}) => {
    const { t } = useTranslation();

    const {
        visibility,
        setVisibility,
        chatWidgetElementRef,
        isLiveChatReady,
        hasNewMessage,
        setHasNewMessage,
        setWidgetClassName
    } = useWidgetLiveChat();

    useEffect(() => {
        if (!!widgetClassName) return;

        setWidgetClassName(widgetClassName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetClassName]);

    const maximizeLiveChatVisibility = () => {
        setVisibility(VISIBILITY_LIVE_CHAT.MAXIMIZED);
        handleShowWidgetCSS(chatWidgetElementRef.current);
        handleMenuToggle && handleMenuToggle();
    };

    useEffect(() => {
        if (visibility === VISIBILITY_LIVE_CHAT.MAXIMIZED) {
            setHasNewMessage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibility]);

    return (
        <Button
            variant="text"
            className={cx(['onlineCsBtn', theme])}
            onClick={maximizeLiveChatVisibility}
            disabled={!isLiveChatReady}>
            <OnlineCsIco className="onlineCsIco" />
            <Typography component="span" className="onlineCsTxt">
                {t('common.general.onlineCs')}
            </Typography>
            {hasNewMessage && (
                <Typography className="remind" component="span">
                    {t('common.general.unread')}
                </Typography>
            )}
        </Button>
    );
};

export default ButtonLiveChat;
