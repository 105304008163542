import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import { AmountFormat } from 'components';

import './AppTableStats.scss';

const AppTableStats = ({
    displayTitle = false,
    stats = [],
    labelKey = 'label',
    valueKey = 'value'
}) => {
    const { t } = useTranslation();
    const isDenseList = stats.length > 5;
    return (
        <Box className={cx(['appTableStats', { center: !displayTitle }])}>
            {displayTitle && (
                <Typography component="h3">
                    {t('common.table.layout.stats')}
                </Typography>
            )}
            <Box className="statsList">
                {stats.map((item, index) => (
                    <Box
                        className={cx(['statsItm', isDenseList && 'denseItem'])}
                        key={index}>
                        <Typography>
                            {item[labelKey]}
                            {t('common.general.colon')}
                        </Typography>
                        <Typography component="span">
                            <AmountFormat value={item[valueKey]} />
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default AppTableStats;
