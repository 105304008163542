import { useState } from 'react';

import { useSnackbar } from 'notistack';

import { AppSnackbar } from 'layouts';

import { MUI_SEVERITY } from 'constants/muiEnums';
import { useStaleFree } from 'hooks';

const useOpenSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');
    const [props, setProps] = useState({});

    const openSnackWithState = () => {
        const { disableCloseButton, ...restProps } = props;
        enqueueSnackbar(message, {
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            autoHideDuration: 3000,
            content: (key, message) => (
                <AppSnackbar
                    severity={severity}
                    id={key}
                    message={message}
                    disableCloseButton={disableCloseButton}
                />
            ),
            ...restProps
        });
    };

    const openSnackbar = useStaleFree(() => openSnackWithState());

    return {
        openSnackbar: (msg, sev, props = {}) => {
            setMessage(msg);
            sev && setSeverity(sev);
            setProps(props);
            openSnackbar();
        },
        openInfoSnackbar: (msg, props = {}) => {
            setMessage(msg);
            setSeverity(MUI_SEVERITY.INFO);
            setProps(props);
            openSnackbar();
        },
        openSuccessSnackbar: (msg, props = {}) => {
            setMessage(msg);
            setSeverity(MUI_SEVERITY.SUCCESS);
            setProps(props);
            openSnackbar();
        },
        openWarningSnackbar: (msg, props = {}) => {
            setMessage(msg);
            setSeverity(MUI_SEVERITY.WARNING);
            setProps(props);
            openSnackbar();
        },
        openErrorSnackbar: (msg, props = {}) => {
            setMessage(msg);
            setSeverity(MUI_SEVERITY.ERROR);
            setProps(props);
            openSnackbar();
        },
        closeSnackbar
    };
};

export default useOpenSnackbar;
