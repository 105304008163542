import { useEffect, useRef } from 'react';

const useDidUpdateEffect = (callback, deps = []) => {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) {
            return callback();
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useDidUpdateEffect;
