import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const useUserSources = () => {
    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [API.common.getUserSources.queryKey],
        apiCall: API.common.getUserSources.apiCall
    });

    return {
        userSources: isSuccess ? getResponseList(data) : []
    };
};

export default useUserSources;
