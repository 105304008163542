import { Link } from 'react-router-dom';

import { Button as MuiButton, Typography } from '@mui/material';
import cx from 'classnames';

import './Button.scss';

const Button = ({
    label = '',
    className = '',
    startIcon = null,
    endIcon = null,
    iconLabel = '',
    onClick = () => {},
    disabled = false,
    color = 'primary', // 'primary', 'secondary'
    variant = 'outlined', // text, contained, outlined, gradient
    buttonRef = null,
    children,
    linkProps = { to: null, state: {}, target: '_self' },
    ...props
}) => {
    const ButtonWrapper =
        linkProps.to && !disabled ? LinkWrapper : NormalWrapper;

    return (
        <ButtonWrapper {...linkProps}>
            <MuiButton
                ref={buttonRef}
                className={cx(['btn', className])}
                color={color}
                variant={variant}
                startIcon={startIcon}
                endIcon={endIcon}
                onClick={(event) => {
                    if (!linkProps.to) {
                        event.stopPropagation();
                        onClick();
                    }
                }}
                disabled={disabled}
                {...props}>
                {children || <Typography>{label}</Typography>}
            </MuiButton>
        </ButtonWrapper>
    );
};

const LinkWrapper = ({ to, state, target, children }) => {
    const isExternalLink = to.includes('http://') || to.includes('https://');

    if (isExternalLink) {
        return (
            <a href={to} target={target}>
                {children}
            </a>
        );
    }

    return (
        <Link to={to} state={state} target={target}>
            {children}
        </Link>
    );
};

const NormalWrapper = ({ children }) => {
    return <>{children}</>;
};

export default Button;
