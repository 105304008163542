import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button } from 'components';
import { AppTableFooterButton } from 'layouts';
import { CLIENT_GROUP_COLOR_SET } from 'pages/CustomerMgmt/constant/grouopColor';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useCloseDialog } from 'hooks';
import { useClientGroup } from 'hooks/apis';

import { appendItemToList } from 'utils/common';

import SelectItemClientGroup from '../SelectItemClientGroup';
import './ClientGroupSelect.scss';
import { CrossIco } from 'assets/images/actions';

const ClientGroupSelect = ({
    defaultGroupId,
    handleSelectClientGroup = null,
    extraClientGroupItem = {}
}) => {
    const { t } = useTranslation();
    const { closeDialog } = useCloseDialog();
    const [isCreatingNewClientGroup, setIsCreatingNewClientGroup] =
        useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(defaultGroupId);
    const {
        clientGroupList,
        handleGetClientGroup,
        handleEditClientGroup,
        handleCreateClientGroup,
        handleDeleteClientGroup
    } = useClientGroup();

    useEffect(() => {
        setSelectedGroupId(defaultGroupId);
    }, [defaultGroupId]);

    const handleSelectGroup = (id) => {
        if (handleSelectClientGroup) {
            handleSelectClientGroup(id);
            setSelectedGroupId(id);
        }
    };

    const defaultGroupInfoValues = {
        id: null,
        name: t('customerMgmt.clientGroupForm.defaultGroupName'),
        color: CLIENT_GROUP_COLOR_SET[0]
    };

    const onDeleteClientGroup = async (contactGroupId) => {
        const onDeleteSuccess = () => {
            setIsCreatingNewClientGroup(false);
        };
        await handleDeleteClientGroup(contactGroupId, onDeleteSuccess);
    };

    const onCreateClientGroup = async (values) => {
        const onCreateSuccess = () => {
            setIsCreatingNewClientGroup(false);
            handleGetClientGroup();
        };

        await handleCreateClientGroup(values, onCreateSuccess);
    };

    const onCancelCreateClientGroup = () => {
        setIsCreatingNewClientGroup(false);
    };

    const extraClientGroupList = appendItemToList(
        clientGroupList,
        extraClientGroupItem,
        'id'
    );

    const isExtraItemIncluded =
        extraClientGroupList.length > clientGroupList.length;

    return (
        <Box className="clientGroupSelectWrapper">
            {extraClientGroupList.map((groupInfo) => {
                return (
                    <SelectItemClientGroup
                        key={groupInfo.id}
                        groupInfo={groupInfo}
                        selectedId={selectedGroupId}
                        handleSelectGroup={handleSelectGroup}
                        handleModifyGroup={handleEditClientGroup}
                        handleDeleteGroup={onDeleteClientGroup}
                        initItemAccessType={INFO_ACCESS_TYPE.READ}
                        isExtraItem={
                            isExtraItemIncluded &&
                            groupInfo.id === extraClientGroupItem.id
                        }
                    />
                );
            })}

            {isCreatingNewClientGroup && (
                <SelectItemClientGroup
                    handleModifyGroup={onCreateClientGroup}
                    handleDeleteGroup={onCancelCreateClientGroup}
                    initItemAccessType={INFO_ACCESS_TYPE.NEW}
                    groupInfo={defaultGroupInfoValues}
                />
            )}

            <AppTableFooterButton
                label={t('common.action.add')}
                onClick={() => {
                    setIsCreatingNewClientGroup(true);
                }}
                BtnIco={<CrossIco />}
            />

            <Box className="btnGroupWrapper">
                <Button
                    label={t('common.action.confirm')}
                    className="btnConfirm"
                    onClick={closeDialog}
                    variant="contained"
                />
            </Box>
        </Box>
    );
};

export default ClientGroupSelect;
