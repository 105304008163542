import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { useTradingProducts } from 'hooks';

const useProductsDetail = (accountType, commissionSettings) => {
    const { getTradingProducts } = useTradingProducts(accountType);

    useEffect(() => {
        if (isEmpty(commissionSettings) || isEmpty(commissionSettings.products))
            return;

        commissionSettings.products.forEach((productInfo) => {
            if (productInfo.symbolGroup) {
                getTradingProducts(accountType, productInfo.symbolGroup);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commissionSettings]);

    return;
};

export default useProductsDetail;
