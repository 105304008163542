import React from 'react';

import { Divider } from '@mui/material';
import { Box } from '@mui/material';

import './StepperCircleLineVertical.scss';

const StepperCircleLineVertical = ({ stepNames }) => {
    return (
        <Box className="stepper stepperLineVertical">
            {stepNames.map((stepName, index) => {
                const isLastStep = index === stepNames.length - 1;
                return (
                    <Box key={index} className="step">
                        <Box className="stepItm">
                            <Box className="stepIndex">{index + 1}</Box>
                            <Box className="stepName">{stepName}</Box>
                        </Box>
                        {!isLastStep && (
                            <Divider
                                className="stepVerticalLine"
                                orientation="vertical"
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};
export default StepperCircleLineVertical;
