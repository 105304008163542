import { useTranslation } from 'react-i18next';

import { TextField } from 'components';

const FilterTextSearch = ({
    formikFieldName,
    label,
    placeholder,
    className,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <TextField
            fullWidth
            name={formikFieldName}
            label={label}
            placeholder={placeholder ?? t('common.placeholder.pleaseEnter')}
            wrapperClassName="filterFieldWrapper"
            className={className}
            {...props}
        />
    );
};

export default FilterTextSearch;
