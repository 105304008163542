import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useAcTypesList = ({ tradeLoginId = '' }) => {
    const { userId } = useSelector((state) => state.user);

    const [availableAccountTypes, setAvailableAccountTypes] = useState([]);

    useEffect(() => {
        getAcTypesList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAcTypesList = () => {
        const payload = {
            tradeLoginId
        };

        handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.getInviteUrlAcTypesList.apiCall(
                    userId,
                    payload
                ),
            handleCallSuccess: (res) => {
                setAvailableAccountTypes(res?.data?.allowedType);
            },
            handleCallFailure: (err) => {}
        });
    };

    return { availableAccountTypes };
};

export default useAcTypesList;
