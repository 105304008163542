import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DEFAULT_TABLE_PAGE_SIZE } from 'constants/appTableInfos';
import { USER_ROLE } from 'constants/authInfos';
import { defaultAppFilterDateRange } from 'constants/filterInfos';
import { useOpenSnackbar } from 'hooks';

import API from 'services';
import { isManagerLevel } from 'utils/user/authInfos';
import { handleAsyncCall } from 'utils/utils';

const apiCallCommissionDetails = {
    [USER_ROLE.MAJORDOMO]: API.commissionMgmt.getManagerDetails.apiCall,
    [USER_ROLE.MANAGER]: API.commissionMgmt.getManagerDetails.apiCall,
    [USER_ROLE.IB]: API.commissionMgmt.getIBCommissionDetails.apiCall
};

const useCommissionDetails = () => {
    const { t } = useTranslation();

    const { userId, userRole } = useSelector((state) => state.user);
    const { openErrorSnackbar } = useOpenSnackbar();

    const [commissionInfos, setCommissionInfos] = useState({});

    const [commissionPayload, setCommissionPayload] = useState({
        pageCurrent: 1,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        startDate: defaultAppFilterDateRange[0],
        endDate: defaultAppFilterDateRange[1]
    });
    const [isFetching, setIsFetching] = useState(false);

    const getCommissionInfos = () => {
        setIsFetching(true);

        if (!isManagerLevel(userRole) && userRole !== USER_ROLE.IB) return;

        handleAsyncCall({
            asyncCall: () =>
                apiCallCommissionDetails[userRole](userId, commissionPayload),
            handleCallSuccess: (res) => {
                setCommissionInfos(res.data);
                setIsFetching(false);
            },
            handleCallFailureError: (err) => {
                openErrorSnackbar(
                    err.message || t('common.snackbar.errorOccurred')
                );
                setIsFetching(false);
            }
        });
    };

    useEffect(() => {
        getCommissionInfos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commissionPayload]);

    return {
        commissionInfos,
        commissionPayload,
        setCommissionPayload,
        isFetching
    };
};

export default useCommissionDetails;
