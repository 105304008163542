import axiosConfig from 'axios/axiosConfig';

const commissionMgmt = {
    getCommissionSummary: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/tradeAccount/${userId}/commission/summary`, {
                params
            }),
        queryKey: 'commissionMgmt.getCommissionSummary'
    },
    getIBCommissionDetails: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/tradeAccount/${userId}/commission/detail`, {
                params
            }),
        queryKey: 'commissionMgmt.getIBCommissionDetails'
    },
    getManagerDetails: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/tradeAccount/${userId}/commission/mgrDetail`, {
                params
            }),
        queryKey: 'commissionMgmt.getManagerDetails'
    }
};

export default commissionMgmt;
