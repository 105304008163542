import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { FILTER_LEVEL } from 'constants/filterInfos';
import {
    configFilterGroupList,
    setIsRestoreCachedParams
} from 'store/filter/slice';

export const baseFilterLists = {
    [FILTER_LEVEL.BASIC]: [],
    [FILTER_LEVEL.ADVANCED]: []
};

const useAppFilter = ({
    filterGroupId,
    filterLists,
    isRestoreCachedParams = false
}) => {
    const dispatch = useDispatch();

    const initFilterGroup = () => {
        dispatch(
            configFilterGroupList({
                filterGroupId,
                filterLists
            })
        );
    };

    const restoreFilterGroup = () => {
        dispatch(
            setIsRestoreCachedParams({
                filterGroupId,
                isRestoreCachedParams
            })
        );
    };

    useEffect(() => {
        if (!filterGroupId || isEmpty(filterLists)) return;

        if (isRestoreCachedParams) {
            restoreFilterGroup();
        } else {
            initFilterGroup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterGroupId, filterLists, isRestoreCachedParams]);

    return { initFilterGroup };
};

export default useAppFilter;
