import isEmpty from 'lodash/isEmpty';

import { useTradeStatuses } from 'hooks';

const PlainTextTradeStatusesField = ({ tradeStatus }) => {
    const { tradeStatuses } = useTradeStatuses();
    const tradeStatusLabel = tradeStatuses.find(
        ({ key }) => tradeStatus === key
    );
    if (isEmpty(tradeStatuses)) return '---';

    return tradeStatusLabel?.value ?? '---';
};

export default PlainTextTradeStatusesField;
