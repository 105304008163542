export const STATIC_PATH_NAME = {
    // root paths
    CustomerMgmt: '/clients-mgmt',
    UserInfo: '/user-info',
    FundMgmt: '/fund-mgmt',
    Reports: '/reports',
    Home: '/home',

    //subPaths
    LeadClientDetail: 'client',
    RegisteredClientDetail: 'registered-client',
    DepositedClientDetail: 'deposited-client',
    Leads: 'client-list',
    RegisteredClients: 'registered-clients',
    DepositedClients: 'deposited-clients',
    PersonalInformation: 'personal-information',
    ReferralLinks: 'referral-links',
    ReferralLinksAlias: 'referral-links/list',
    ReferralLinkSubList: 'referral-links/sublist',
    Reward: 'reward',
    Deposits: 'deposits',
    TradingHistory: 'trading-history',
    SalesPerformance: 'sales-performance'
};

export const PAGE_FULL_STATIC_PATH = {
    Leads: STATIC_PATH_NAME.CustomerMgmt + '/' + STATIC_PATH_NAME.Leads,
    RegisteredClients:
        STATIC_PATH_NAME.CustomerMgmt +
        '/' +
        STATIC_PATH_NAME.RegisteredClients,
    DepositedClients:
        STATIC_PATH_NAME.CustomerMgmt + '/' + STATIC_PATH_NAME.DepositedClients,
    PersonalInformation:
        STATIC_PATH_NAME.UserInfo + '/' + STATIC_PATH_NAME.PersonalInformation,
    ReferralLink:
        STATIC_PATH_NAME.CustomerMgmt + '/' + STATIC_PATH_NAME.ReferralLinks,
    ReferralLinkAlias:
        STATIC_PATH_NAME.CustomerMgmt +
        '/' +
        STATIC_PATH_NAME.ReferralLinksAlias,
    ReferralLinkSubList:
        STATIC_PATH_NAME.CustomerMgmt +
        '/' +
        STATIC_PATH_NAME.ReferralLinkSubList,
    Reward: STATIC_PATH_NAME.UserInfo + '/' + STATIC_PATH_NAME.Reward,
    Deposits: STATIC_PATH_NAME.FundMgmt + '/' + STATIC_PATH_NAME.Deposits,
    TradingHistory:
        STATIC_PATH_NAME.Reports + '/' + STATIC_PATH_NAME.TradingHistory,
    SalesPerformance:
        STATIC_PATH_NAME.Home + '/' + STATIC_PATH_NAME.SalesPerformance
};
