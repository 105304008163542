import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import { Button, NoContentBoard, NumFormat } from 'components';
import { AppCard } from 'layouts';

import { DATE_FORMAT } from 'constants/dateFormats';
import { useFundFlowRecord } from 'hooks';

import { unixToDateString } from 'utils/dateConversion';

import {
    DepositSignIco,
    TimeCheckSignIco,
    TimeFastSignIco,
    WithdrawSignIco
} from 'assets/images/common';

const DepositRecord = ({ tradeLoginId, accessiblePayUrl }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { fundFlowData, getFundFlowRecords } = useFundFlowRecord();

    useEffect(() => {
        getFundFlowRecords({
            searchTradeLoginIds: tradeLoginId,
            tradeType: 'DEPOSIT',
            pageSize: 5
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeLoginId]);

    return (
        <Box className="depositRecord">
            <AppCard title={t('common.sidebar.depositRecord.title')}>
                <Box className="fundflowList">
                    {fundFlowData.list.length > 0 ? (
                        <>
                            {fundFlowData.list.map((item, index) => (
                                <Box className="fundflowItm" key={index}>
                                    <Box className="leftSide">
                                        <Box
                                            className={cx('merchantIcon', {
                                                orangeBg:
                                                    item.gatewayTypeLogo ===
                                                    null
                                            })}>
                                            {item.gatewayTypeLogo ? (
                                                <img
                                                    src={`${accessiblePayUrl}/img/gateway/${item.gatewayTypeLogo}`}
                                                    alt=""
                                                />
                                            ) : (
                                                <DepositSignIco />
                                            )}
                                        </Box>
                                        <Box className="detail">
                                            <Box className="method">
                                                <Typography>
                                                    {item.gatewayTypeName
                                                        ? item.gatewayTypeName
                                                        : t(
                                                              'common.tradeAccount.finance.deposit'
                                                          )}
                                                </Typography>
                                                <Box className="sign">
                                                    <Box className="signItm">
                                                        {item.tradeType ===
                                                        'DEPOSIT' ? (
                                                            <DepositSignIco fill="#f39200" />
                                                        ) : (
                                                            <WithdrawSignIco fill="#ff0000" />
                                                        )}
                                                    </Box>
                                                    <Box className="signItm">
                                                        {item.status === 3 ? (
                                                            <TimeCheckSignIco />
                                                        ) : (
                                                            <TimeFastSignIco />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography className="date">
                                                {unixToDateString(
                                                    item.createTime,
                                                    DATE_FORMAT.yMdHm,
                                                    0
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        className="amount"
                                        component="h3">
                                        <NumFormat
                                            value={item.amount}
                                            renderText={(value) =>
                                                item.amount > 0
                                                    ? `+${value}`
                                                    : value
                                            }
                                        />
                                    </Typography>
                                </Box>
                            ))}
                            <Button
                                className="checkMore"
                                color="secondary"
                                label={t('common.action.checkMore')}
                                onClick={() =>
                                    navigate('/fund-mgmt/funds-history')
                                }
                            />
                        </>
                    ) : (
                        <NoContentBoard labelType="noData" />
                    )}
                </Box>
            </AppCard>
        </Box>
    );
};

export default DepositRecord;
