import React, { useState } from 'react';

import { QRCodeBoard } from 'components';

import { useOpenDialog } from 'hooks';

const useQrCodeDialog = ({ dialogTitle }) => {
    const [qrCodeDialog, setQrCodeDialog] = useState({
        subTitle: '',
        qrCodeValue: ''
    });

    const { openDialog: openQrCodeDialog, closeDialog } = useOpenDialog({
        title: dialogTitle,
        subTitle: qrCodeDialog.subTitle,
        name: 'qrCodeDialog',
        variant: 'blueHeader',
        children: <QRCodeBoard codeValue={qrCodeDialog.qrCodeValue} />
    });

    const openDialog = ({ subTitle, qrCodeValue }) => {
        setQrCodeDialog({
            subTitle,
            qrCodeValue
        });
        openQrCodeDialog();
    };

    return {
        openDialog,
        closeDialog
    };
};

export default useQrCodeDialog;
