import React from 'react';

import { FormLabel, FormControl as MuiFormControl } from '@mui/material';
import cx from 'classnames';

import FormHelperText from 'components/Fields/FormHelperText/FormHelperText';

const FormControl = ({
    label = '',
    fullWidth = false,
    helperText = '',
    variant = 'filled',
    disabled = false,
    children,
    error = null,
    className = ''
}) => (
    <MuiFormControl
        className={cx(['inputFormControl', className])}
        fullWidth={fullWidth}
        variant={variant}
        disabled={disabled}
        error={error}>
        <FormLabel>{label}</FormLabel>
        {children}
        {error && <FormHelperText error={error} helperText={helperText} />}
    </MuiFormControl>
);

export default FormControl;
