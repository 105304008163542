import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { ACCOUNT_TYPE } from 'constants/serverInfos/serverInfos';
import { useAccountTypes } from 'hooks';

export const FILTER_ACCOUNT_TYPES = 'accountTypes';

const useFilterAccountTypes = (filterName = FILTER_ACCOUNT_TYPES) => {
    const { t } = useTranslation();
    const { accountTypes } = useAccountTypes();

    const itemSelectAll = {
        value: '',
        label: t('common.general.all')
    };

    const renderAccountType = accountTypes.map(({ code, display }) => {
        return {
            label: t(ACCOUNT_TYPE[display]?.i18nKey || '---'),
            value: code
        };
    });

    const accountTypesFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.tradeAccount.accountTypeDisplay'),
        items: [itemSelectAll, ...renderAccountType],
        itemValueKey: 'value',
        itemLabelKey: 'label',
        defaultValue: itemSelectAll.value
    };

    return { accountTypesFilterInfo };
};

export default useFilterAccountTypes;
