import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Tab } from '@mui/material';

import { ScrollSelect } from 'components';

import { USER_ROLE } from 'constants/authInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { setQueryTradeLoginId } from 'store/user/slice';

import { hasNoTradeLoginId } from 'utils/payloadParsing';
import { isManagerLevel } from 'utils/user/authInfos';
import { getUserRoleBrief } from 'utils/user/getUserRoleBrief';

const selectAllItem = {
    tradeLoginId: VALUES_ALL_TRADE_LOGIN_IDS.APP,
    role: ''
};

export const ACCOUNT_LIST_TYPES = {
    SELF: 'SELF',
    SELF_AND_UNDER_IB: 'SELF_AND_UNDER_IB'
};

const ScrollSelectTradeAccounts = ({
    defaultTradeLoginId = undefined,
    handleSelectTradeLoginId = () => {},
    isTriggerInitHandleSelect = false,
    showOnlyManagerAndIB = true,
    showSelectAll = true,
    showEvenOnlyOneAccount = false,
    showCurrOnly = false,
    tradeAccounts = [],
    isHideUI = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isListReady = tradeAccounts.length > 0;

    let filteredTradeAccounts = tradeAccounts;

    if (showOnlyManagerAndIB) {
        filteredTradeAccounts = filteredTradeAccounts.filter(
            ({ role }) => role === USER_ROLE.IB || isManagerLevel(role)
        );
    }

    useEffect(() => {
        if (!isListReady) return;

        let initTradeLoginId = defaultTradeLoginId;

        if (hasNoTradeLoginId(defaultTradeLoginId)) {
            initTradeLoginId = showSelectAll
                ? selectAllItem.tradeLoginId
                : filteredTradeAccounts[0]?.tradeLoginId;
        }

        dispatch(setQueryTradeLoginId(initTradeLoginId));
        if (!isTriggerInitHandleSelect) return;

        const isInitHandleSelect = true;

        handleSelectTradeLoginId(initTradeLoginId, isInitHandleSelect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        defaultTradeLoginId,
        tradeAccounts,
        isListReady,
        isTriggerInitHandleSelect,
        showSelectAll
    ]);

    const onSelectTradeLoginId = (tradeLoginId) => {
        const isInitHandleSelect = false;
        dispatch(setQueryTradeLoginId(tradeLoginId));

        handleSelectTradeLoginId(tradeLoginId, isInitHandleSelect);
    };

    const isMoreThanOneAccounts =
        filteredTradeAccounts && filteredTradeAccounts.length > 1;
    const hasOnlyOneAccount =
        filteredTradeAccounts && filteredTradeAccounts.length < 2;

    const isShowSelect =
        (showEvenOnlyOneAccount && hasOnlyOneAccount) || isMoreThanOneAccounts;

    if (!isListReady || !isShowSelect) return null;

    let selectItemList = filteredTradeAccounts;

    if (showSelectAll) {
        selectItemList = [selectAllItem, ...selectItemList];
    }

    const selectedIndex = selectItemList.findIndex(
        ({ tradeLoginId }) => tradeLoginId === defaultTradeLoginId
    );

    if (showCurrOnly) {
        selectItemList = selectItemList.filter(
            ({ tradeLoginId }) => tradeLoginId === defaultTradeLoginId
        );
    }

    if (selectItemList.length < 1 || isHideUI) return null;

    return (
        <ScrollSelect
            defaultValue={
                selectedIndex < 0
                    ? selectItemList[0].tradeLoginId
                    : defaultTradeLoginId
            }
            itemList={selectItemList}
            onSelectItem={onSelectTradeLoginId}
            renderTab={({ item, index }) => {
                const userRoleTxt = getUserRoleBrief(item.role);

                return (
                    <Tab
                        key={index}
                        value={item.tradeLoginId}
                        className="tabItem"
                        label={
                            index === 0 && showSelectAll ? (
                                <Box className="selectContent">
                                    {t('common.filter.all')}
                                </Box>
                            ) : (
                                <Box className="selectContent">
                                    {item.tradeLoginId}
                                    {userRoleTxt && (
                                        <Box
                                            component="span"
                                            className="roleLabel">
                                            {userRoleTxt}
                                        </Box>
                                    )}
                                </Box>
                            )
                        }
                    />
                );
            }}
        />
    );
};

export default ScrollSelectTradeAccounts;
