import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useRewardInfo = () => {
    const { userId } = useSelector((state) => state.user);
    const [rewardInfo, setRewardInfo] = useState({});

    const getRewardInfo = () => {
        handleAsyncCall({
            asyncCall: () => API.reward.getRewardInfo.apiCall(userId),
            handleCallSuccess: (res) => {
                setRewardInfo(res?.data?.info ?? {});
            },
            handleCallFailure: () => {}
        });
    };

    useEffect(() => {
        userId && getRewardInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return { rewardInfo, getRewardInfo, setRewardInfo };
};

export default useRewardInfo;
