import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, List, ListItem, Typography } from '@mui/material';
import cx from 'classnames';

import { Button } from 'components';

import { LANGUAGE_INFO } from 'constants/languageInfo';

import API from 'services';
import { KEY, getItemSync, setItemSync } from 'utils/localStorage';
import { handleAsyncCall } from 'utils/utils';

import './LanguageSwitch.scss';
import {
    BristishFlagIco,
    ChinaFlagIco,
    ThailandFlagIco,
    VietnamFlagIco
} from 'assets/images/common';

const LanguageSwitch = ({ theme = 'white' }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currLanguage = getItemSync(KEY.LANG);
    const [searchParams] = useSearchParams();

    const { userId, isLogin } = useSelector((state) => state.user);

    const handleLangUpdate = (lang) => {
        const matchedLang = Object.values(LANGUAGE_INFO).find(
            (langInfo) => langInfo.localeKey === lang
        );

        if (matchedLang) {
            if (searchParams.has('lang')) {
                searchParams.set('lang', lang);
                navigate(
                    {
                        search: searchParams.toString()
                    },
                    { replace: true }
                );
            }

            setItemSync(KEY.LANG, lang);
            window.location.reload();
        }
    };

    const langChange = (lang) => {
        const payload = {
            language: lang
        };

        if (!isLogin) {
            handleLangUpdate(lang);
        } else {
            handleAsyncCall({
                asyncCall: () =>
                    API.common.modifyLanguage.apiCall(userId, payload),
                handleCallFinal: () => {
                    handleLangUpdate(lang);
                }
            });
        }
    };

    const langDropdownItm = [
        {
            lang: LANGUAGE_INFO['en-US'].localeKey,
            labelWrapKey: 'common.language.enUs',
            icon: BristishFlagIco,
            onClick: () => {
                langChange(LANGUAGE_INFO['en-US'].localeKey);
            }
        },
        {
            lang: LANGUAGE_INFO['zh-CN'].localeKey,
            labelWrapKey: 'common.language.zhCn',
            icon: ChinaFlagIco,
            onClick: () => {
                langChange(LANGUAGE_INFO['zh-CN'].localeKey);
            }
        },
        {
            lang: LANGUAGE_INFO['th'].localeKey,
            labelWrapKey: 'common.language.th',
            icon: ThailandFlagIco,
            onClick: () => {
                langChange(LANGUAGE_INFO['th'].localeKey);
            }
        },
        {
            lang: LANGUAGE_INFO['vi'].localeKey,
            labelWrapKey: 'common.language.vi',
            icon: VietnamFlagIco,
            onClick: () => {
                langChange(LANGUAGE_INFO['vi'].localeKey);
            }
        }
    ];

    const selectedItem =
        langDropdownItm.find((item) => item.lang === currLanguage) ||
        langDropdownItm[0];

    return (
        <Box className="languageSwitch">
            <Button variant="text">
                <img className="flagIco" src={selectedItem.icon} alt="" />
                <Typography
                    component="span"
                    className={cx(['langName', theme])}>
                    {t(selectedItem.labelWrapKey + '.brief')}
                </Typography>
                <KeyboardArrowDown className={cx(['arrowDownIco', theme])} />
            </Button>
            <List className="langMenu">
                {langDropdownItm.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            className={cx('langBtn', {
                                selected: currLanguage === item.lang
                            })}
                            onClick={() => {
                                item.onClick && item.onClick();
                            }}>
                            <img
                                src={item.icon}
                                alt=""
                                style={{
                                    width: '24px',
                                    marginRight: '10px'
                                }}
                            />
                            {t(item.labelWrapKey + '.full')}
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default LanguageSwitch;
