import { Trans } from 'react-i18next';

const InlineTextLink = ({ i18nKey, toPath = '', defaults = '---' }) => {
    return (
        <Trans i18nKey={i18nKey} defaults={defaults}>
            termsPlacebo
            <a
                className="appLink"
                href={toPath}
                target="_blank"
                rel="noreferrer noopener">
                linkPlacebo
            </a>
        </Trans>
    );
};

export default InlineTextLink;
