import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useOpenSnackbar } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const defaultFundFlowData = { list: [] };

const useFundFlowRecord = () => {
    const { t } = useTranslation();

    const { userId } = useSelector((state) => state.user);
    const { openErrorSnackbar } = useOpenSnackbar();

    const [fundFlowData, setFundFlowData] = useState(defaultFundFlowData);
    const [isFetching, setIsFetching] = useState(false);

    const getFundFlowRecords = (params) => {
        setIsFetching(true);

        handleAsyncCall({
            asyncCall: () =>
                API.fundingMgmt.getFundFlowRecords.apiCall(userId, params),
            handleCallSuccess: (res) => {
                setFundFlowData(res.data);
                setIsFetching(false);
            },
            handleCallFailureError: (err) => {
                openErrorSnackbar(
                    err.message || t('common.snackbar.errorOccurred')
                );
                setIsFetching(false);
            }
        });
    };

    return {
        fundFlowData,
        getFundFlowRecords,
        setIsFetching,
        isFetching
    };
};

export default useFundFlowRecord;
