import isEmpty from 'lodash/isEmpty';

import { useTradeTypes } from 'hooks';

const PlainTextTradeTypesField = ({ tradeType }) => {
    const { tradeTypes } = useTradeTypes();
    const tradeTypesLabel = tradeTypes.find(({ key }) => tradeType === key);

    if (isEmpty(tradeTypes)) return '---';

    return tradeTypesLabel?.value ?? '---';
};

export default PlainTextTradeTypesField;
