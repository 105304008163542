import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

const AppContainer = ({ pageName = '', children, className }) => {
    return (
        <Box className={cx(['appContainer', pageName, className])}>
            {children}
        </Box>
    );
};

export default AppContainer;
