import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useAccountTypes = () => {
    const [accountTypes, setAccountTypes] = useState([]);

    useEffect(() => {
        handleAsyncCall({
            asyncCall: () => API.system.getAccountTypes.apiCall(),
            handleCallSuccess: (res) => {
                setAccountTypes(res.data.list);
            },
            handleCallFailure: () => {}
        });
    }, []);

    return { accountTypes };
};

export default useAccountTypes;
