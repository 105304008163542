import { Box } from '@mui/material';

import IndicatorColorCircle from 'components/Indicators/IndicatorColorCircle/IndicatorColorCircle';

import './SelectItemDropDownClientGroup.scss';

const SelectItemDropDownClientGroup = ({ color, name, size = '22px' }) => {
    return (
        <Box className="selectedItemWrapper" key="id">
            {color && <IndicatorColorCircle color={color} side={size} />}
            <Box className="groupNameTxt">{name}</Box>
        </Box>
    );
};

export default SelectItemDropDownClientGroup;
