import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { FILTER_LEVEL, FILTER_TYPE } from 'constants/filterInfos';
import { useAppTableRetrieveValues } from 'hooks';
import { configFilterGroupValues } from 'store/filter/slice';

const useConfigAppFilterValues = (filterGroupId, setIsShowAdvancedFilter) => {
    const dispatch = useDispatch();

    const [localFilterGroupValues, setLocalFilterGroupValues] = useState({
        [FILTER_LEVEL.BASIC]: {},
        [FILTER_LEVEL.ADVANCED]: {}
    });
    const isRestoredFilter = useRef(false);

    const {
        initFilterGroupValues,
        submittedFilterGroupValues,
        isSubmittedFilterGroupEmpty,
        isRestoreCachedParams,
        filterLists
    } = useAppTableRetrieveValues(filterGroupId);

    const configInitFilterGroupValues = () => {
        const getInitFilterValues = (filterList) => {
            if (isEmpty(filterList)) return {};

            let initFilterGroupValues = {};

            const setFilterDefaultValues = (filterInfo) => {
                const { filterType, filterName } = filterInfo;
                switch (filterType) {
                    case FILTER_TYPE.MULTI_SELECT:
                    case FILTER_TYPE.SINGLE_SELECT:
                        const {
                            defaultValue,
                            items,
                            itemValueKey = 'value'
                        } = filterInfo;

                        const defaultItem = items.find(
                            (item) => defaultValue === item[itemValueKey]
                        );

                        if (defaultItem) {
                            initFilterGroupValues[filterName] =
                                defaultItem[itemValueKey];
                        }

                        break;
                    case FILTER_TYPE.DATE_RANGE:
                        const [startDateFilterName, endDateFilterName] =
                            filterName;

                        if (filterInfo.defaultValue) {
                            initFilterGroupValues[startDateFilterName] =
                                filterInfo.defaultValue[0];
                            initFilterGroupValues[endDateFilterName] =
                                filterInfo.defaultValue[1];
                        }

                        break;
                    case FILTER_TYPE.DATE_CALENDAR:
                    case FILTER_TYPE.TEXT_SEARCH:
                    case FILTER_TYPE.COMBO_BOX:
                        initFilterGroupValues[filterName] =
                            filterInfo.defaultValue;
                        break;

                    default:
                        throw new Error(
                            `Unsupported filterType type: ${filterType}`
                        );
                }
            };

            filterList.forEach((filterInfo) =>
                setFilterDefaultValues(filterInfo)
            );

            return initFilterGroupValues;
        };

        const initFilterBasicGroupValues = getInitFilterValues(
            filterLists[FILTER_LEVEL.BASIC]
        );

        const initFilterAdvancedValues = getInitFilterValues(
            filterLists[FILTER_LEVEL.ADVANCED]
        );

        const newInitFilterGroupValues = {
            [FILTER_LEVEL.BASIC]: initFilterBasicGroupValues,
            [FILTER_LEVEL.ADVANCED]: initFilterAdvancedValues
        };

        const submittedFilterGroupValues = {
            [FILTER_LEVEL.BASIC]: initFilterBasicGroupValues,
            [FILTER_LEVEL.ADVANCED]: {}
        };

        dispatch(
            configFilterGroupValues({
                filterGroupId,
                initFilterGroupValues: newInitFilterGroupValues,
                submittedFilterGroupValues
            })
        );

        setLocalFilterGroupValues(submittedFilterGroupValues);
        setIsShowAdvancedFilter(false);

        isRestoredFilter.current = true;
    };

    const restoreFilterGroupValues = () => {
        setLocalFilterGroupValues(submittedFilterGroupValues);
        if (!isEmpty(submittedFilterGroupValues?.[FILTER_LEVEL.ADVANCED])) {
            setIsShowAdvancedFilter(true);
        }
        isRestoredFilter.current = true;
    };

    useEffect(() => {
        if (isEmpty(filterLists) || isRestoredFilter.current) return;

        if (isRestoreCachedParams && !isSubmittedFilterGroupEmpty) {
            restoreFilterGroupValues();
        } else {
            configInitFilterGroupValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterGroupId, isRestoreCachedParams, filterLists]);

    return {
        localFilterGroupValues,
        setLocalFilterGroupValues,
        initFilterGroupValues,
        filterLists
    };
};

export default useConfigAppFilterValues;
