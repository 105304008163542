import { SnackbarProvider } from 'notistack';

import BreakpointProvider from './BreakpointProvider';
import DialogProvider from './DialogProvider';
import RouteHistoryProvider from './RouteHistoryProvider';
import WidgetLiveChatProvider from './WidgetLiveChatProvider/WidgetLiveChatProvider';

const AppProvider = ({ children }) => {
    return (
        <RouteHistoryProvider>
            <BreakpointProvider>
                <SnackbarProvider maxSnack={3}>
                    <DialogProvider>
                        <WidgetLiveChatProvider>
                            {children}
                        </WidgetLiveChatProvider>
                    </DialogProvider>
                </SnackbarProvider>
            </BreakpointProvider>
        </RouteHistoryProvider>
    );
};

export default AppProvider;
