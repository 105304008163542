import { cloneElement, useEffect, useRef, useState } from 'react';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import './TabControl.scss';

const TAB_CONTROL_VARIANT = {
    PAGE_TAB: 'pageTab',
    CARD_TAB: 'cardTab',
    HEADER_TAB: 'headerTab',
    SEGMENT_TAB: 'segmentTab'
};

const TabDivider = <Tab className="divider" />;

const TabControl = ({
    tabInfoList,
    tabsClassName,
    tabContentClassName,
    valueKey = 'value',
    labelKey = 'label',
    variant,
    handleChange = null,
    controlledValue = null,
    scrollingDisabled = false,
    renderOnce = false,
    isHideTabs = false
}) => {
    const [tabValue, setTabValue] = useState(
        controlledValue || tabInfoList[0][valueKey]
    );

    useEffect(() => {
        if (isNil(controlledValue)) return;

        setTabValue(controlledValue);
    }, [controlledValue]);

    const currTabIndexRef = useRef(0);

    const isNearSelectedTab = (index) =>
        index === currTabIndexRef.current + 1 ||
        index === currTabIndexRef.current - 1;

    const displayList = tabInfoList.flatMap((item, index) =>
        index < tabInfoList.length - 1 ? [item, TabDivider] : [item]
    );

    const tabInfo = tabInfoList.find((info) => tabValue === info[valueKey]);

    const onTabChange = (_event, newValue) => {
        const tabIndex = tabInfoList.findIndex(
            ({ value }) => value === newValue
        );

        setTabValue(newValue);
        handleChange && handleChange(newValue, tabIndex);
    };

    return (
        <>
            {!isHideTabs && (
                <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    variant={scrollingDisabled ? 'fullWidth' : 'scrollable'}
                    scrollButtons="auto"
                    className={cx(['tabControl', variant, tabsClassName])}
                    ScrollButtonComponent={(props) => {
                        if (props.direction === 'left' && !props.disabled) {
                            return (
                                <IconButton {...props} className="arrowBack">
                                    <ArrowBack />
                                </IconButton>
                            );
                        } else if (
                            props.direction === 'right' &&
                            !props.disabled
                        ) {
                            return (
                                <IconButton {...props} className="arrowForward">
                                    <ArrowForward />
                                </IconButton>
                            );
                        } else {
                            return null;
                        }
                    }}
                    TabIndicatorProps={{
                        children: <span className="tabIndicator" />
                    }}>
                    {displayList.map((item, index) =>
                        index % 2 === 1 ? (
                            cloneElement(item, {
                                key: index,
                                className: cx([
                                    item.props.className,
                                    {
                                        hidden:
                                            variant !==
                                                TAB_CONTROL_VARIANT.PAGE_TAB &&
                                            isNearSelectedTab(index)
                                    }
                                ])
                            })
                        ) : (
                            <Tab
                                key={index}
                                value={item[valueKey]}
                                label={item[labelKey]}
                                className="tab"
                                tabIndex={index / 2}
                                onClick={() =>
                                    (currTabIndexRef.current = index)
                                }
                                disableRipple={
                                    variant === TAB_CONTROL_VARIANT.SEGMENT_TAB
                                }
                            />
                        )
                    )}
                </Tabs>
            )}
            {renderOnce
                ? tabInfoList.map((item, index) => {
                      return (
                          <Box
                              className={cx([
                                  'tabContentWrapper',
                                  tabContentClassName
                              ])}
                              hidden={tabValue !== item.value}
                              key={index}>
                              {item.ContentComponent}
                          </Box>
                      );
                  })
                : tabInfo?.ContentComponent && (
                      <Box
                          className={cx([
                              'tabContentWrapper',
                              tabContentClassName
                          ])}>
                          {tabInfo.ContentComponent}
                      </Box>
                  )}
        </>
    );
};

export default TabControl;
