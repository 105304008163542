import * as yup from 'yup';

export const YUP_SCHEMAS = [
    yup.BooleanSchema,
    yup.DateSchema,
    yup.MixedSchema,
    yup.NumberSchema,
    yup.ArraySchema,
    yup.ObjectSchema,
    yup.StringSchema
];
