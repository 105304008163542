import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { KeyboardArrowDown } from '@mui/icons-material';
import { List, ListItem, Typography } from '@mui/material';
import cx from 'classnames';
import isArray from 'lodash/isArray';

import { Button } from 'components';

import { AUTH_PROCESS } from 'constants/authInfos';
import { useAuthStatus } from 'hooks';
import { logout } from 'store/user/reducer';

import { checkIsUserAuthorized, displayUserName } from 'utils/utils';

import './NavBtn.scss';

const NavBtn = ({ navInfo, handleMenuToggle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { isApproving } = useAuthStatus();

    const { displayName, userId, userRole } = useSelector(
        (state) => state.user
    );

    const { icon, subRoutes, path: rootPath, title, isOnMenu } = navInfo;

    const domainUrl = window.location;

    const isPathMatch = (domainUrl + location.pathname).includes(
        domainUrl + rootPath
    );

    return (
        <>
            <Button
                className={cx('navBtn', {
                    selected: isPathMatch
                })}
                startIcon={icon}
                endIcon={
                    subRoutes && <KeyboardArrowDown className="arrowDown" />
                }
                label={
                    <Typography component="span" className="menuLabel">
                        {rootPath === '/user-info'
                            ? displayUserName(displayName, t)
                            : t(title)}
                    </Typography>
                }
                variant="text"
                onClick={() => {
                    if (!subRoutes) {
                        handleMenuToggle && handleMenuToggle();
                        navigate(rootPath);
                    }
                }}
            />

            {subRoutes && isOnMenu && (
                <List className="hoverMenu">
                    {Object.values(subRoutes).map((subRoute, j) => {
                        if (isApproving && !subRoute.isOnApprovingAccountMenu) {
                            return null;
                        }

                        const isShowPage = checkIsUserAuthorized(
                            subRoute.permissionFor,
                            userRole
                        );

                        const subPaths = isArray(subRoute.path)
                            ? subRoute.path
                            : [subRoute.path];

                        const menuOnPath = isArray(subRoute.menuOnPaths)
                            ? subRoute.menuOnPaths
                            : [];

                        const isSelected = [...subPaths, ...menuOnPath].some(
                            (subPath) => {
                                return matchPath(
                                    {
                                        path: `${rootPath}/${subPath}`
                                    },
                                    location.pathname
                                );
                            }
                        );

                        return (
                            subRoute.isOnMenu &&
                            isShowPage && (
                                <ListItem key={j} selected={isSelected}>
                                    <Link
                                        className="submenuLabel"
                                        onClick={() => {
                                            if (subPaths[0] === 'logout') {
                                                dispatch(
                                                    logout(userId)
                                                ).finally(() => {
                                                    navigate(
                                                        AUTH_PROCESS.LOGIN.path
                                                    );
                                                });
                                            }
                                            handleMenuToggle &&
                                                handleMenuToggle();
                                        }}
                                        to={
                                            subPaths[0] === 'logout'
                                                ? '#'
                                                : `${rootPath}/${subPaths[0]}`
                                        }
                                        data-at={Object.keys(subRoutes)[j]}>
                                        {t(subRoute.title)}
                                    </Link>
                                </ListItem>
                            )
                        );
                    })}
                </List>
            )}
        </>
    );
};

export default NavBtn;
