import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CLIENT_TYPE_INFO } from 'constants/client/clientTypeInfo';
import useOpenSnackbar from 'hooks/useOpenSnackbar';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import useGetClientGroup from './cacheable/useGetClientGroup';

const useClientGroup = () => {
    const { t } = useTranslation();
    const { openSuccessSnackbar, openErrorSnackbar } = useOpenSnackbar();
    const { clientTypeKey } = useSelector((state) => state.client);

    const { clientGroupList, handleGetClientGroup } = useGetClientGroup();

    const handleCreateClientGroup = async (
        { groupColor, groupName },
        onCreateSuccess = () => {}
    ) => {
        const payload = {
            name: groupName,
            color: groupColor,
            type: CLIENT_TYPE_INFO[clientTypeKey].type
        };

        await handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.createClientGrouping.apiCall(payload),
            handleCallSuccess: () => {
                handleGetClientGroup();
                onCreateSuccess();
                openSuccessSnackbar(t('common.snackbar.addSuccess'));
            },
            handleCallFailureError: () => {
                openErrorSnackbar(t('common.snackbar.addFailed'));
            }
        });
    };

    const handleEditClientGroup = async (
        { groupId, groupColor, groupName },
        onEditSuccess = () => {}
    ) => {
        const payload = {
            contactGroupId: groupId,
            name: groupName,
            color: groupColor
        };

        await handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.editClientGrouping.apiCall(payload),
            handleCallSuccess: () => {
                handleGetClientGroup();
                onEditSuccess();
                openSuccessSnackbar(t('common.snackbar.updateSuccess'));
            },
            handleCallFailureError: () => {
                openErrorSnackbar(t('common.snackbar.updateFailed'));
            }
        });
    };

    const handleDeleteClientGroup = async (
        contactGroupId,
        onDeleteSuccess = () => {}
    ) => {
        const payload = {
            contactGroupId
        };

        await handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.deleteClientGrouping.apiCall(payload),
            handleCallSuccess: () => {
                handleGetClientGroup();
                onDeleteSuccess();
                openSuccessSnackbar(t('common.snackbar.removeSuccess'));
            },
            handleCallFailureError: (res) => {
                openErrorSnackbar(
                    res.message || t('common.snackbar.removeFailed')
                );
            }
        });
    };

    return {
        clientGroupList,
        handleEditClientGroup,
        handleGetClientGroup,
        handleCreateClientGroup,
        handleDeleteClientGroup
    };
};

export default useClientGroup;
