import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import './AppSideContainer.scss';

const AppSideContainer = ({ className, children }) => {
    return (
        <Box className={cx(['appSideContainer', className])}>{children}</Box>
    );
};

export default AppSideContainer;
