import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { useDeepEqualMemo } from 'hooks/general';

const useAppTableRetrieveValues = (filterGroupId) => {
    const {
        initFilterValuesGroups,
        filterListsGroups,
        submittedFilterValuesGroups,
        flatSubmittedFilterGroupValues,
        isRestoreCachedParamsGroups,
        pageValuesGroups
    } = useSelector((state) => state.filter);

    const filterListsMemo = useDeepEqualMemo(
        filterListsGroups[filterGroupId] || {}
    );

    const initFilterGroupValuesMemo = useDeepEqualMemo(
        initFilterValuesGroups[filterGroupId] || {}
    );

    const submittedFilterGroupValuesMemo = useDeepEqualMemo(
        submittedFilterValuesGroups[filterGroupId] || {}
    );

    const flatFilterGroupValuesMemo = useDeepEqualMemo(
        flatSubmittedFilterGroupValues[filterGroupId] || {}
    );

    const pageValuesMemo = useDeepEqualMemo(
        pageValuesGroups[filterGroupId] || {}
    );

    const isRestoreCachedParams =
        isRestoreCachedParamsGroups[filterGroupId] || false;

    return {
        initFilterGroupValues: initFilterGroupValuesMemo,
        isInitFilterGroupEmpty: isEmpty(flatFilterGroupValuesMemo),
        submittedFilterGroupValues: submittedFilterGroupValuesMemo,
        isSubmittedFilterGroupEmpty: isEmpty(submittedFilterGroupValuesMemo),
        flatFilterGroupValues: flatFilterGroupValuesMemo,
        isFlatFilterGroupEmpty: isEmpty(flatFilterGroupValuesMemo),
        pageValues: pageValuesMemo,
        isPageValuesEmpty: isEmpty(pageValuesMemo),
        filterLists: filterListsMemo,
        isRestoreCachedParams
    };
};

export default useAppTableRetrieveValues;
