import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import './PasswordFieldStrengthRemind.scss';

const PasswordFieldStrengthRemind = ({ strengthInfo }) => {
    const { t } = useTranslation();
    return (
        <Box className="passwordFieldStrengthRemind">
            <Box
                className="strengthTxt"
                style={{
                    color: strengthInfo.color
                }}>
                {t('common.tooltip.passwordStrength.strength.title') + ': '}
                {t(
                    'common.tooltip.passwordStrength.strength.' +
                        strengthInfo.i18nKey
                )}
            </Box>
            <Box className="strengthBarWrapper">
                <Box
                    className="strengthBar"
                    style={{
                        backgroundColor: strengthInfo.color,
                        width: strengthInfo.barLength
                    }}
                />
            </Box>

            <Box className="tooltipHints">
                {`${t('common.validate.passwordFormatInvalid')} ${t(
                    'common.tooltip.passwordStrength.hints'
                )}`}
            </Box>
        </Box>
    );
};

export default PasswordFieldStrengthRemind;
