import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useAccStatusList } from 'hooks/apis';

export const FILTER_ACCOUNT_STATUS = 'accStatus';

const useFilterAccountStatus = (filterName = FILTER_ACCOUNT_STATUS) => {
    const { t } = useTranslation();
    const { accStatusList } = useAccStatusList();

    const itemSelectAll = {
        key: '',
        value: t('common.general.all')
    };

    const accountStatusFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.user.accountStatus.name'),
        items: [itemSelectAll, ...accStatusList],
        itemValueKey: 'key',
        itemLabelKey: 'value',
        defaultValue: ''
    };
    return { accountStatusFilterInfo };
};

export default useFilterAccountStatus;
