import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useRewardCategoryKeys = () => {
    const { userId } = useSelector((state) => state.user);

    const [categoryKeys, setCategoryKeys] = useState([]);

    const getRewardCategoryKeys = useCallback(() => {
        handleAsyncCall({
            asyncCall: () => API.reward.getItemCategory.apiCall(userId),
            handleCallSuccess: (res) => setCategoryKeys(res.data.list ?? [])
        });
    }, [userId]);

    useEffect(() => {
        getRewardCategoryKeys();
    }, [getRewardCategoryKeys]);

    return { categoryKeys, getRewardCategoryKeys };
};

export default useRewardCategoryKeys;
