import { lazy } from 'react';

const PerformancePages = {
    HistoricalPerformance: lazy(() =>
        import('./HistoricalPerformance/HistoricalPerformance')
    ),
    PerformanceHome: lazy(() => import('./PerformanceHome/PerformanceHome')),
    WithdrawalRecord: lazy(() => import('./WithdrawalRecord/WithdrawalRecord'))
};

export const PERFORMANCE_PATH = {
    HOME: '/home/sales-performance',
    HISTORICAL_PERFORMANCE: '/historical-performance',
    WITHDRAWAL_RECORD: '/withdrawal-record'
};

export const PERFORMANCE_ROUTE = {
    PERFORMANCE_HOME: {
        path: PERFORMANCE_PATH.HOME,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        element: <PerformancePages.PerformanceHome />
    },
    HISTORICAL_PERFORMANCE: {
        path: `${PERFORMANCE_PATH.HOME}${PERFORMANCE_PATH.HISTORICAL_PERFORMANCE}`,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        element: <PerformancePages.HistoricalPerformance />
    },
    WITHDRAWAL_RECORD: {
        path: `${PERFORMANCE_PATH.HOME}${PERFORMANCE_PATH.WITHDRAWAL_RECORD}`,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        element: <PerformancePages.WithdrawalRecord />
    }
};
