import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useEmployments = () => {
    const [employments, setEmployments] = useState([]);

    const getEmployments = () =>
        handleAsyncCall({
            asyncCall: () => API.common.getEmploymentList.apiCall(),
            handleCallSuccess: (res) => setEmployments(res.data.list)
        });

    useEffect(() => {
        getEmployments();
    }, []);

    return { employments, getEmployments };
};

export default useEmployments;
