import React, { useState } from 'react';

import { Box, Link, Typography } from '@mui/material';

import { Button, QRCodeBoard } from 'components';

import { useOpenDialog } from 'hooks';

import './DownloadList.scss';

const DownloadList = ({ downloadInfos }) => {
    const [qrCodeDialog, setQrCodeDialog] = useState({
        title: '',
        value: ''
    });

    const { openDialog: openQrCodeDialog } = useOpenDialog({
        title: qrCodeDialog.title,
        name: 'downloadQrCodeDialog',
        variant: 'blueHeader',
        children: <QRCodeBoard codeValue={qrCodeDialog.value} />
    });
    const showQrCodeDialog = (label, link) => () => {
        setQrCodeDialog({
            title: label,
            value: link
        });
        openQrCodeDialog();
    };

    return (
        <Box className="downloadList">
            {downloadInfos.map(({ icon, label, link, isQrCode }, index) =>
                isQrCode ? (
                    <Button
                        key={index}
                        onClick={showQrCodeDialog(label, link)}
                        className="downloadItem">
                        <Box className="platformIco">
                            <img src={icon} alt="" />
                        </Box>
                        <Typography>{label}</Typography>
                    </Button>
                ) : (
                    <Link
                        className="downloadItem"
                        underline="none"
                        href={link}
                        target="_blank"
                        key={index}>
                        <Box className="platformIco">
                            <img src={icon} alt="" />
                        </Box>
                        <Typography>{label}</Typography>
                    </Link>
                )
            )}
        </Box>
    );
};

export default DownloadList;
