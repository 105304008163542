// home
export { ReactComponent as MoneyBagIco } from './icn_money_bag.svg';
export { ReactComponent as MultipleUserIco } from './icn_multiple_user.svg';
export { ReactComponent as RadioIco } from './icn_radio.svg';
export { ReactComponent as SingleDayCalendarIco } from './icn_single_day_calendar.svg';
export { ReactComponent as TreeChartIco } from './icn_tree_chart.svg';
export { ReactComponent as RankingIco } from './icn_ranking.svg';
export { ReactComponent as OrangeRightArrowIco } from './icn_orange_right_arrow.svg';
export { ReactComponent as OrangeDownArrowIco } from './icn_orange_down_arrow.svg';
export { ReactComponent as TitleMedalChiefIco } from './titleMedal/icn_title_medal_chief.svg';
export { ReactComponent as TitleMedalSeniorIco } from './titleMedal/icn_title_medal_senior.svg';
export { ReactComponent as TitleMedalGeneralIco } from './titleMedal/icn_title_medal_general.svg';

// Medal
export { ReactComponent as CircleMedal1Ico } from './medal/circle/icn_circle_medal_1.svg';
export { ReactComponent as CircleMedal2Ico } from './medal/circle/icn_circle_medal_2.svg';
export { ReactComponent as CircleMedal3Ico } from './medal/circle/icn_circle_medal_3.svg';

export { ReactComponent as DiamondMedal1Ico } from './medal/diamond/icn_diamond_medal_1.svg';
export { ReactComponent as DiamondMedal2Ico } from './medal/diamond/icn_diamond_medal_2.svg';
export { ReactComponent as DiamondMedal3Ico } from './medal/diamond/icn_diamond_medal_3.svg';

export { ReactComponent as StarMedal1Ico } from './medal/star/icn_star_medal_1.svg';
export { ReactComponent as StarMedal2Ico } from './medal/star/icn_star_medal_2.svg';
export { ReactComponent as StarMedal3Ico } from './medal/star/icn_star_medal_3.svg';

export { ReactComponent as TriangleMedal1Ico } from './medal/triangle/icn_triangle_medal_1.svg';
export { ReactComponent as TriangleMedal2Ico } from './medal/triangle/icn_triangle_medal_2.svg';
export { ReactComponent as TriangleMedal3Ico } from './medal/triangle/icn_triangle_medal_3.svg';
