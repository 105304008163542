import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { ComboBoxField, SelectDropdownField, TextField } from 'components';
import CompleteModifyBoard from 'pages/UserInfo/components/CompleteModifyBoard/CompleteModifyBoard';
import DialogForm from 'pages/UserInfo/components/DialogForm/DialogForm';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useCityList } from 'hooks';

import {
    commonTextValidation,
    mixedValidation,
    numberValidation
} from 'utils/yup';

import './BankCardForm.scss';
import useBankCardCompletedModify from './hooks/useBankCardCompletedModify';

const BankCardForm = ({
    bankNames,
    states,
    handleSubmit,
    handleCloseDialog,
    infoAccessType,
    bankAccount = {},
    disabled = false,
    showCheckButton = false
}) => {
    const { t } = useTranslation();

    const {
        stateId: accountStateId,
        cityId: accountCityId,
        bankId: accountBankId,
        bankName: accountBankName,
        branch: accountBranchName,
        bankNumber: accountBankNumber,
        userName: accountUsername,
        ...bankAccountRest
    } = bankAccount;

    const { displayName } = useSelector((state) => state.user);
    const { cities, getCities } = useCityList(accountStateId);
    const selectedStateRef = useRef({});

    const { setResultedBankNumber, resultInfos, redirectBankCardPage } =
        useBankCardCompletedModify({
            states,
            bankNames
        });

    const fieldLabels = {
        state: t('common.user.communication.state'),
        city: t('common.user.communication.city'),
        bank: t('common.user.bank.bankName'),
        branchName: t('common.user.bank.branchName'),
        payeeAccountNumber: t('common.user.bank.payeeBankAccount'),
        payeeName: t('common.user.bank.payeeName')
    };

    const initialState =
        states.find((state) => state.stateId === accountStateId) ?? '';
    const initialValues = useMemo(
        () => ({
            state: isEmpty(selectedStateRef.current)
                ? initialState
                : selectedStateRef.current,
            city: cities.find((city) => city.cityId === accountCityId) ?? '',
            bank:
                accountBankName ??
                bankNames.find((bank) => bank.bankId === accountBankId)
                    ?.value ??
                '',
            branchName: accountBranchName ?? '',
            payeeAccountNumber: accountBankNumber ?? '',
            payeeName: accountUsername ?? displayName,
            ...bankAccountRest
        }),
        [
            accountBankId,
            accountBankName,
            accountBankNumber,
            accountBranchName,
            accountCityId,
            accountUsername,
            bankAccountRest,
            bankNames,
            cities,
            displayName,
            initialState
        ]
    );

    const validationSchema = yup.object({
        state: mixedValidation,
        city: mixedValidation,
        bank: mixedValidation,
        branchName: commonTextValidation,
        payeeAccountNumber: numberValidation,
        payeeName: commonTextValidation
    });

    const handleStateIdChange = (setFieldValue, setFieldTouched) => (event) => {
        const state = event.target.value;
        selectedStateRef.current = state;
        getCities(state.stateId);
        setFieldValue('city', '');
        setFieldTouched('city', true);
    };

    const handleSubmitSuccess = (resultedBankNumber) => {
        setResultedBankNumber(resultedBankNumber);
    };

    const renderFillFormFields = ({
        setFieldTouched,
        setFieldValue,
        values
    }) => (
        <>
            <SelectDropdownField
                isRequired
                fullWidth
                label={fieldLabels.state}
                name="state"
                items={states}
                itemLabelKey="value"
                handleChange={handleStateIdChange(
                    setFieldValue,
                    setFieldTouched
                )}
                disabled={disabled}
            />
            <SelectDropdownField
                isRequired
                fullWidth
                disabled={values.state === '' || disabled}
                label={fieldLabels.city}
                name="city"
                items={cities || []}
                itemLabelKey="value"
            />
            <ComboBoxField
                isRequired
                fullWidth
                allowCustomOpt
                label={fieldLabels.bank}
                name="bank"
                options={bankNames}
                optionLabelKey="value"
                value={values.bank}
                defaultValue={initialValues.bank}
                disabled={disabled}
            />
            <TextField
                isRequired
                fullWidth
                name="branchName"
                label={fieldLabels.branchName}
                disabled={disabled}
            />
            <TextField
                isRequired
                fullWidth
                name="payeeAccountNumber"
                label={fieldLabels.payeeAccountNumber}
                disabled={disabled}
            />
            <TextField
                disabled
                fullWidth
                isRequired
                name="payeeName"
                label={fieldLabels.payeeName}
            />
        </>
    );

    const renderCompleteModifyBoard = () => (
        <CompleteModifyBoard
            title={
                infoAccessType === INFO_ACCESS_TYPE.NEW
                    ? t('common.status.success')
                    : t('common.status.modificationSuccess')
            }
            handleCloseDialog={handleCloseDialog}
            resultInfos={resultInfos}
            handleClickCheck={redirectBankCardPage}
            showCheckButton={showCheckButton}
        />
    );

    return (
        <DialogForm
            className="bankCardForm"
            initialValues={initialValues}
            validationSchema={validationSchema}
            fieldLabels={fieldLabels}
            infoAccessType={infoAccessType}
            handleSubmit={handleSubmit}
            handleSubmitSuccess={handleSubmitSuccess}
            renderFillFormFields={renderFillFormFields}
            renderCompleteModifyBoard={renderCompleteModifyBoard}
        />
    );
};

export default BankCardForm;
