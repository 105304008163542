import React from 'react';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import queryClient from 'reactQueryConfig';

import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';

import { theme } from 'theme';
import RouteSwitch from 'utils/RouteSwitch';

import 'styles/common/common.scss';
import 'styles/common/dialogs/index.scss';

import { store } from './store';

function App() {
    const { t } = useTranslation();

    document.title = t('common.title');

    return (
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <QueryClientProvider client={queryClient}>
                    <RouteSwitch />
                </QueryClientProvider>
            </ReduxProvider>
        </ThemeProvider>
    );
}

export default App;
