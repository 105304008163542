import QRCode from 'qrcode.react';

import { BrandIconImg } from 'assets/images/common';

const AppQRCodeImage = ({
    codeValue,
    size = 120,
    includeMargin = false,
    className
}) => {
    const logoSize = size * (includeMargin ? 0.22 : 0.25);

    return (
        <QRCode
            renderAs="canvas"
            value={codeValue}
            size={size}
            level="H"
            imageSettings={{
                src: BrandIconImg,
                width: logoSize,
                height: logoSize,
                excavate: true
            }}
            className={className}
            includeMargin={includeMargin}
        />
    );
};

export default AppQRCodeImage;
