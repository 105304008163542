import React, { Suspense } from 'react';

import { TabControl } from 'components';
import AppContainer from 'layouts/AppContainer/AppContainer';
import { Loading } from 'pages';

import './PageLayoutTopTab.scss';

const PageLayoutTopTab = ({
    pageName = '',
    tabInfoList,
    valueKey,
    labelKey,
    controlledValue,
    handleChange,
    children,
    isShowTab = true
}) => {
    return (
        <AppContainer pageName={pageName} className="pageLayoutTopTab">
            {isShowTab && (
                <TabControl
                    tabsClassName="appPageTab"
                    variant="pageTab"
                    tabInfoList={tabInfoList}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    controlledValue={controlledValue}
                    handleChange={handleChange}
                />
            )}
            <Suspense fallback={<Loading />}>{children}</Suspense>
        </AppContainer>
    );
};

export default PageLayoutTopTab;
