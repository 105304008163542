import React from 'react';

import { Box, TableCell } from '@mui/material';
import cx from 'classnames';

const AppTableCellSticky = ({ children, classNames, style, ...props }) => {
    const { renderCell, stickyType, ...boxProps } = props;

    return (
        <Box
            className={cx(['stickyTableCell', classNames])}
            style={style}
            {...boxProps}>
            {children}
        </Box>
    );
};

const AppTableCellRegular = ({ children, classNames, style, ...props }) => {
    const { renderCell, ...tableCellProps } = props;

    return (
        <TableCell className={classNames} style={style} {...tableCellProps}>
            {children}
        </TableCell>
    );
};

const AppTableCell = ({
    field,
    isStickyCell = false,
    children,
    classNames,
    ...props
}) => {
    const CellGroupWrapper = isStickyCell
        ? AppTableCellSticky
        : AppTableCellRegular;

    return (
        <CellGroupWrapper
            classNames={cx([field, 'tableCell', classNames])}
            {...props}>
            <Box component="span" className="tableCellContent">
                {children}
            </Box>
        </CellGroupWrapper>
    );
};

export default AppTableCell;
