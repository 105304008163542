import React from 'react';
import { useTranslation } from 'react-i18next';

import isObject from 'lodash/isObject';

import BankCardForm from 'pages/UserInfo/BankCard/BankCardForm/BankCardForm';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useOpenDialog, useOpenSnackbar } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useDialogAddBankCard = ({
    bankNames,
    states,
    showCheckButton = false,
    onSubmitSuccess = () => {}
}) => {
    const { t } = useTranslation();
    const { openErrorSnackbar } = useOpenSnackbar();

    const addBankCard = (formValues, handleSubmitSuccessCallBack) => {
        const bankNameMatched =
            bankNames.find((bank) => bank.value === formValues.bank) ||
            formValues.bank;

        const payload = {
            ...(isObject(bankNameMatched)
                ? { bankId: bankNameMatched.bankId }
                : { bankName: bankNameMatched }),
            cityId: formValues.city.cityId,
            branchName: formValues.branchName,
            bankNumber: formValues.payeeAccountNumber
        };

        return handleAsyncCall({
            asyncCall: () => API.user.addBankAccount.apiCall(payload),
            handleCallSuccess: () => {
                onSubmitSuccess();
                handleSubmitSuccessCallBack(payload.bankNumber);
            },
            handleCallFailureError: (res) => openErrorSnackbar(res.message)
        });
    };

    const {
        openDialog: openAddBankCardDialog,
        closeDialog: closeDialogAddBankCard
    } = useOpenDialog({
        title: t('userInfo.bankCard.addBankCard'),
        name: 'addBankCardDialog confirmDialog',
        children: (
            <BankCardForm
                bankNames={bankNames}
                states={states}
                handleSubmit={addBankCard}
                handleCloseDialog={() => closeDialogAddBankCard()}
                showCheckButton={showCheckButton}
                infoAccessType={INFO_ACCESS_TYPE.NEW}
            />
        )
    });

    return {
        openAddBankCardDialog,
        closeDialogAddBankCard
    };
};

export default useDialogAddBankCard;
