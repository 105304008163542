import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Link } from '@mui/material';

import { GradientCard } from 'components';
import { AppCardHeader } from 'layouts';

import { APP_DOWNLOAD_LINK } from 'constants/serverInfos/serverInfos';
import { useQrCodeDialog } from 'hooks';

import './DownloadMobileApp.scss';
import { ApkImg, AppStoreImg, GooglePlayImg } from 'assets/images/common';

const DownloadMobileApp = () => {
    const { t } = useTranslation();

    const { openDialog } = useQrCodeDialog({
        dialogTitle: t('common.sidebar.downloadMobileApp.downloadByQrCode')
    });

    const appDownloadInfos = [
        {
            subTitle: 'Android',
            qrCodeValue: `${window.location.origin}${APP_DOWNLOAD_LINK.ANDROID}`,
            imageSource: GooglePlayImg
        },
        {
            subTitle: 'iOS',
            qrCodeValue: `${window.location.origin}${APP_DOWNLOAD_LINK.IOS}`,
            imageSource: AppStoreImg
        },
        {
            subTitle: 'Android',
            qrCodeValue: `${window.location.origin}${APP_DOWNLOAD_LINK.ANDROID}`,
            imageSource: ApkImg
        }
    ];

    return (
        <Box className="downloadMobileApp">
            <GradientCard>
                <AppCardHeader
                    title={t('common.sidebar.downloadMobileApp.title')}
                    titleColor="white"
                />
                <Grid container spacing={6}>
                    {appDownloadInfos.map(
                        ({ subTitle, qrCodeValue, imageSource }, index) => (
                            <Grid item mobile={6} key={index}>
                                <Link
                                    className="appImg"
                                    underline="none"
                                    onClick={() =>
                                        openDialog({
                                            subTitle,
                                            qrCodeValue
                                        })
                                    }
                                    target="_blank">
                                    <img src={imageSource} alt="" />
                                </Link>
                            </Grid>
                        )
                    )}
                </Grid>
            </GradientCard>
        </Box>
    );
};

export default DownloadMobileApp;
