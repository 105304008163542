import React, { useEffect, useRef } from 'react';

import { ButtonIco, GradientCard, Swiper } from 'components';
import { ActionGroup, AppCard, AppTableContainer } from 'layouts';

import { CELL_GROUP_STICKY_TYPE } from 'constants/appTableInfos';
import { useWindowSize } from 'hooks';

import './CardManagementBoard.scss';
import AddCardFooterButton from './components/AddCardFooterButton/AddCardFooterButton';
import { RubbishBinIco, UpdateIco, ViewIco } from 'assets/images/actions';

const CardManagementBoard = ({
    title,
    addButtonLabel,
    accountList,
    cardTableInfo,
    renderCard,
    onCardView,
    onCardEdit,
    onCardDelete,
    onCardAdd
}) => {
    const swiperRef = useRef(null);
    const { isDesktop } = useWindowSize();

    useEffect(() => {
        swiperRef.current?.resetActiveIndex();
    }, [accountList.length]);

    const tableActionGroup = {
        renderCell: (rowData) => (
            <ActionGroup className="tableActions">
                <ButtonIco
                    BtnIco={<ViewIco />}
                    onClick={() => onCardView(rowData)}
                />
                <ButtonIco
                    BtnIco={<UpdateIco />}
                    onClick={() => onCardEdit(rowData)}
                />
                <ButtonIco
                    BtnIco={<RubbishBinIco />}
                    onClick={() => onCardDelete(rowData)}
                />
            </ActionGroup>
        )
    };
    const tableInfos = isDesktop
        ? [...Object.values(cardTableInfo), tableActionGroup]
        : [
              {
                  ...tableActionGroup,
                  stickyType: CELL_GROUP_STICKY_TYPE.STICKY_LEFT
              },
              ...Object.values(cardTableInfo)
          ];

    return (
        <AppCard className="cardManagementBoard" title={title}>
            {accountList && accountList.length > 0 && (
                <Swiper
                    ref={swiperRef}
                    renderCards={(activeIndex) =>
                        accountList.map((account, index) => (
                            <GradientCard key={index} className="noPadding">
                                {renderCard(account, index, activeIndex)}
                            </GradientCard>
                        ))
                    }
                />
            )}

            <AppTableContainer
                withTableHead={false}
                tableInfos={tableInfos}
                rowsData={accountList}
                TableFooter={
                    <AddCardFooterButton
                        label={addButtonLabel}
                        onClick={() => onCardAdd()}
                    />
                }
            />
        </AppCard>
    );
};

export default CardManagementBoard;
