import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useIncomeSources = () => {
    const [incomeSources, setIncomeSources] = useState([]);

    const getIncomeSources = () =>
        handleAsyncCall({
            asyncCall: () => API.common.getIncomeSourceList.apiCall(),
            handleCallSuccess: (res) => setIncomeSources(res.data.list)
        });

    useEffect(() => {
        getIncomeSources();
    }, []);

    return { incomeSources, getIncomeSources };
};

export default useIncomeSources;
