import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Box, Table, TableContainer } from '@mui/material';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { DEFAULT_TABLE_PAGE_SIZE } from 'constants/appTableInfos';

import './AppTableContainer.scss';
import RowCollapsibleProvider from './RowCollapsibleProvider/RowCollapsibleProvider';
import AppTableBody from './components/AppTableBody/AppTableBody';
import AppTableHead from './components/AppTableHead/AppTableHead';
import AppTablePagination from './components/AppTablePagination/AppTablePagination';
import { groupTableColInfosByStickyType } from './utils';

const AppTableContainer = ({
    tableInfos = [],
    rowsData = [],
    rowKey,
    TableHeader = null,
    TableFooter = null,
    renderExtraRow = () => {},
    renderRowCollapsible = null,
    withTableHead = true,
    page,
    handlePagingChange = () => {},
    totalPage = -1,
    totalCount = 0,
    rowsPerPage = DEFAULT_TABLE_PAGE_SIZE,
    rowClickPath = '',
    rowClickQueryParam = '',
    viewMethod = () => {}
}) => {
    const tableCellGroupInfos = groupTableColInfosByStickyType(tableInfos);
    const hasRowCollapsible = !isNil(renderRowCollapsible);

    return (
        <Box sx={{ width: '100%' }} className="tableWrapper">
            {TableHeader}
            <TableContainer className="appTableContainer">
                <ScrollContainer
                    hideScrollbars={false}
                    ignoreElements={'.tableCellContent'}>
                    <Table
                        className={cx([
                            'appTable',
                            { appTableWithHeader: !!TableHeader }
                        ])}
                        stickyHeader
                        aria-label="sticky table">
                        <RowCollapsibleProvider
                            rowsData={rowsData}
                            renderRowCollapsible={renderRowCollapsible}>
                            {withTableHead && (
                                <AppTableHead
                                    tableCellGroupInfos={tableCellGroupInfos}
                                    hasRowCollapsible={hasRowCollapsible}
                                />
                            )}
                            <AppTableBody
                                rowsData={rowsData}
                                rowKey={rowKey}
                                tableCellGroupInfos={tableCellGroupInfos}
                                renderExtraRow={renderExtraRow}
                                rowClickPath={rowClickPath}
                                rowClickQueryParam={rowClickQueryParam}
                                viewMethod={viewMethod}
                            />
                        </RowCollapsibleProvider>
                    </Table>
                </ScrollContainer>
            </TableContainer>
            {TableFooter}
            {totalPage > 0 && (
                <AppTablePagination
                    totalPage={totalPage}
                    totalCount={totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePagingChange={handlePagingChange}
                />
            )}
        </Box>
    );
};
export default AppTableContainer;
