import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Link } from '@mui/material';

import { GradientCard } from 'components';
import { AppCardHeader } from 'layouts';

import { getMT5AutochartistLink } from 'utils/utils';

import './DownloadAutochartist.scss';
import { AutochartistImg, InterfaceImg } from 'assets/images/common';

const DownloadAutochartist = () => {
    const { t } = useTranslation();

    return (
        <Box className="downloadAutochartist">
            <Link
                underline="none"
                href={getMT5AutochartistLink()}
                target="_blank">
                <GradientCard className="sideContent">
                    <Box className="rightGp">
                        <AppCardHeader
                            title={t(
                                'common.sidebar.downloadAutochartist.title'
                            )}
                            titleColor="white"
                        />
                        <Box className="autochartistImg">
                            <img src={AutochartistImg} alt="" />
                        </Box>
                    </Box>
                    <Box className="interfaceImg">
                        <img src={InterfaceImg} alt="" />
                    </Box>
                </GradientCard>
            </Link>
        </Box>
    );
};

export default DownloadAutochartist;
