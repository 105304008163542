import { useSelector } from 'react-redux';

import { CLIENT_TYPE_INFO } from 'constants/client/clientTypeInfo';

import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const FIELD_NAME = 'groupId';

const useGetClientGroup = () => {
    const { clientTypeKey } = useSelector((state) => state.client);

    const payload = {
        type: CLIENT_TYPE_INFO[clientTypeKey]?.type
    };

    const { isSuccess, data, refetch } = useCacheableQuery({
        queryKeys: [FIELD_NAME, clientTypeKey],
        apiCall: () => API.customerMgmt.getClientGrouping.apiCall(payload),
        enabled: !!clientTypeKey
    });

    const handleGetClientGroup = async () => {
        await refetch();
    };

    return {
        clientGroupList:
            clientTypeKey && isSuccess ? getResponseList(data) : [],
        handleGetClientGroup
    };
};

export default useGetClientGroup;
