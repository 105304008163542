import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Pagination, TablePagination } from '@mui/material';

import { DEFAULT_PAGE_CURRENT } from 'constants/appTableInfos';

import './AppTablePagination.scss';

export const PAGING_EVENTS = {
    CHANGE_PAGE: 'CHANGE_PAGE',
    CHANGE_ROW_PER_PAGE: 'CHANGE_ROW_PER_PAGE'
};

const AppTablePagination = ({
    totalPage,
    totalCount,
    page,
    rowsPerPage,
    handlePagingChange
}) => {
    const { t } = useTranslation();
    const [jumpPage, setJumpPage] = useState(page);

    const handleChangePage = (event, page) => {
        const payloadPaging = {
            pageCurrent: page,
            pageSize: rowsPerPage
        };

        handlePagingChange(payloadPaging, PAGING_EVENTS.CHANGE_PAGE);
    };

    const handleChangeRowsPerPage = (event) => {
        const payloadPaging = {
            pageCurrent: DEFAULT_PAGE_CURRENT,
            pageSize: event.target.value
        };
        handlePagingChange(payloadPaging, PAGING_EVENTS.CHANGE_ROW_PER_PAGE);
    };

    useEffect(() => {
        setJumpPage(page);
    }, [page]);

    const getRowsPerPageOptions = () => {
        return [10, 20, 30, 40].map((rowsPerPage) => ({
            label: `${rowsPerPage} ${t('common.table.layout.rowsPerPage')}`,
            value: rowsPerPage
        }));
    };

    const getLabelDisplayedRows = () =>
        `${t('common.table.layout.total')} ${totalCount} ${t(
            'common.table.layout.ofRecord'
        )} ${page} / ${totalPage} ${t('common.table.layout.page')}`;

    return (
        <Box className="paginationWrapper">
            <Box className="paginationLabel onlyDesktop">
                {getLabelDisplayedRows()}
            </Box>
            <Pagination
                className="paginationPageNavigation"
                onChange={handleChangePage}
                count={totalPage}
                shape="rounded"
                page={page}
            />
            <Box className="paginationSelect onlyDesktop">
                <TablePagination
                    component="div"
                    rowsPerPageOptions={getRowsPerPageOptions()}
                    count={totalPage * rowsPerPage}
                    page={page - 1}
                    rowsPerPage={rowsPerPage}
                    onPageChange={() => {}}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={() => null}
                    labelRowsPerPage={<></>}
                    labelDisplayedRows={() => null}
                />
            </Box>

            <Box className="paginationJumpTo onlyDesktop">
                {t('common.table.layout.jumpTo')}

                <input
                    className="inputJumpTo"
                    type="number"
                    value={jumpPage}
                    onChange={(event) => {
                        const inputPage = event.target.value;
                        if (Number(inputPage) < 1 && inputPage !== '') {
                            event.preventDefault();
                            return;
                        }

                        if (inputPage >= 0 && inputPage <= totalPage) {
                            setJumpPage(inputPage);
                            if (inputPage !== '')
                                handleChangePage(event, inputPage);
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default AppTablePagination;
