import axios from 'axios';

const axiosThirdPartyConfig = axios.create({
    baseURL: process.env.REACT_APP_CONTENTS_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default axiosThirdPartyConfig;
