import axiosConfig from 'axios/axiosConfig';

const userAPI = {
    getAccStatus: {
        apiCall: (userId) => axiosConfig.get(`/user/${userId}/accStatus`),
        queryKey: 'user.getAccStatus'
    },

    // Bank
    getBankAccountList: {
        apiCall: (params) => axiosConfig.get('/bankAccount/list', { params }),
        queryKey: 'user.getBankAccountList'
    },
    addBankAccount: {
        apiCall: (payload) => axiosConfig.post('/bankAccount/add', payload),
        queryKey: 'user.addBankAccount'
    },
    editBankAccount: {
        apiCall: (payload) => axiosConfig.post('/bankAccount/edit', payload),
        queryKey: 'user.editBankAccount'
    },
    deleteBankAccount: {
        apiCall: (payload) => axiosConfig.post('/bankAccount/delete', payload),
        queryKey: 'user.deleteBankAccount'
    },
    // Wallet
    getWalletAccountList: {
        apiCall: (params) =>
            axiosConfig.get('/usdtWalletAccount/list', {
                params
            }),
        queryKey: 'user.getWalletAccountList'
    },
    addWalletAccount: {
        apiCall: (payload) =>
            axiosConfig.post('/usdtWalletAccount/add', payload),
        queryKey: 'user.addWalletAccount'
    },
    editWalletAccount: {
        apiCall: (payload) =>
            axiosConfig.post('/usdtWalletAccount/edit', payload),
        queryKey: 'user.editWalletAccount'
    },
    deleteWalletAccount: {
        apiCall: (payload) =>
            axiosConfig.post('/usdtWalletAccount/delete', payload),
        queryKey: 'user.deleteWalletAccount'
    },
    getUserBasicInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/info`, { params }),
        queryKey: 'user.getUserBasicInfo'
    },
    getUserMarketInfo: {
        apiCall: (userId) => axiosConfig.get(`/user/${userId}/marketInfo`),
        queryKey: 'user.getUserMarketInfo'
    },
    getUserRegistrationInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/registrationInfo`, { params }),
        queryKey: 'user.getUserRegistrationInfo'
    },
    getUserPhone: {
        apiCall: (params) => axiosConfig.get('/user/phone/info', { params }),
        queryKey: 'user.getUserPhone'
    },
    getUserEmail: {
        apiCall: (params) => axiosConfig.get('/user/email/info', { params }),
        queryKey: 'user.getUserEmail'
    }
};

export default userAPI;
