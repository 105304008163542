import React, { forwardRef, useState } from 'react';
import { useImperativeHandle } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import PropTypes from 'prop-types';

import './Swiper.scss';

/**
 * @type React.FC<Swiper_PropTypes>
 */

const Swiper = forwardRef(({ renderCards = () => {}, ...props }, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleChange = (currIndex) => {
        setActiveIndex(currIndex);
    };

    useImperativeHandle(ref, () => ({
        resetActiveIndex: () => setActiveIndex(0)
    }));

    return (
        <Carousel
            selectedItem={activeIndex}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            swipeable
            emulateTouch
            onChange={handleChange}
            {...props}>
            {renderCards(activeIndex)}
        </Carousel>
    );
});

const Swiper_PropTypes = {
    renderCards: PropTypes.func
};

Swiper.propTypes = Swiper_PropTypes;

export default Swiper;
