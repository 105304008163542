export const getConvertedPath = (path, prefixPath) => {
    if (!Array.isArray(path)) {
        return `${prefixPath}${path}`;
    }

    const convertedPath = [];
    path.forEach((aPath) => {
        convertedPath.push(`${prefixPath}${aPath}`);
    });

    return convertedPath;
};

export const getRouteProps = ({ path, render, element }) => {
    return {
        path,
        render,
        element
    };
};
