import { Box } from '@mui/material';

import './SectionNotes.scss';

const SectionNotes = ({ title, contents }) => {
    return (
        <Box className="sectionNotes">
            <Box className="title">{title}</Box>
            <ol className="descriptionList">
                {contents.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ol>
        </Box>
    );
};

export default SectionNotes;
