import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { VALUE_APP_SELECT_ALL } from 'constants/parseValues';
import { useSubManagerAndIB } from 'hooks/apis';

const FILTER_MANAGER_TRADE_LOGIN_IDS = 'managerTradeLoginIds';

const useFilterManager = (filterName = FILTER_MANAGER_TRADE_LOGIN_IDS) => {
    const { t } = useTranslation();
    const { managerList } = useSubManagerAndIB();

    const selectItems = managerList.map(({ tradeLoginId, displayName }) => ({
        label: tradeLoginId ? `${tradeLoginId} (${displayName})` : displayName,
        value: tradeLoginId
    }));

    const selectAllItem = {
        label: t('common.general.all'),
        value: VALUE_APP_SELECT_ALL
    };

    const managerTradeLoginIdFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.COMBO_BOX,
        label: t('common.tradeAccount.managerTradeLoginId'),
        options: [selectAllItem, ...selectItems],
        optionValueKey: 'value',
        optionLabelKey: 'label',
        defaultValue: [VALUE_APP_SELECT_ALL]
    };

    return { managerTradeLoginIdFilterInfo };
};
export default useFilterManager;
