import { IconButton, InputAdornment } from '@mui/material';

import { EyeIco, EyeOffIco } from 'assets/images/common';

const AdornmentReveal = ({ isShowField, setIsShowField }) => {
    const onClickShowSecret = (event) => {
        event.preventDefault();
        setIsShowField(!isShowField);
    };

    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={onClickShowSecret}>
                {isShowField ? <EyeIco /> : <EyeOffIco />}
            </IconButton>
        </InputAdornment>
    );
};

export default AdornmentReveal;
