import { lazy } from 'react';

const Pages = {
    ForgotPassword: lazy(() => import('./ForgotPassword/ForgotPassword')),
    Login: lazy(() => import('./Login/Login')),
    ResetPassword: lazy(() => import('./ResetPassword/ResetPassword')),
    ResetTradePassword: lazy(() =>
        import('./ResetTradePassword/ResetTradePassword')
    )
};

const routes = {
    ForgotPassword: {
        path: '/forgot-password',
        element: <Pages.ForgotPassword />
    },
    Login: {
        path: '/login',
        element: <Pages.Login />
    },
    ResetPassword: {
        path: '/reset-password',
        element: <Pages.ResetPassword />
    },
    ResetTradePassword: {
        path: '/reset-trade-password',
        element: <Pages.ResetTradePassword />
    }
};

export default routes;
