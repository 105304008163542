import { SelectDropdownField } from 'components';

const FilterSelect = ({ formikFieldName, filterInfos, ...props }) => {
    const {
        label,
        items,
        itemValueKey,
        itemLabelKey,
        renderLabel = null,
        forceMuiSelect,
        defaultValue
    } = filterInfos;

    return (
        <SelectDropdownField
            fullWidth
            name={formikFieldName}
            className="filterFieldWrapper"
            label={label}
            items={items}
            itemValueKey={itemValueKey}
            itemLabelKey={itemLabelKey}
            renderLabel={renderLabel}
            defaultValue={defaultValue}
            forceMuiSelect={forceMuiSelect}
            {...props}
        />
    );
};

export default FilterSelect;
