import { useTranslation } from 'react-i18next';

import { SelectItemDropDownClientGroup } from 'components';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useClientGroup } from 'hooks/apis';
import useSelectDropdownField from 'hooks/useSelectDropdownField';

export const FILTER_GROUP_ID = 'groupId';

const useFilterGroupId = (filterName = FILTER_GROUP_ID) => {
    const { t } = useTranslation();
    const { clientGroupList } = useClientGroup();
    const isRenderMuiSelect = useSelectDropdownField();

    const allItemOption = { id: '', name: t('common.general.all'), color: '' };

    const groupIdFilterInfo = {
        filterName: filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.sales.groupId'),
        items: [allItemOption, ...clientGroupList],
        itemValueKey: 'id',
        renderLabel: ({ color, name }) =>
            isRenderMuiSelect ? (
                <SelectItemDropDownClientGroup color={color} name={name} />
            ) : (
                name
            ),
        defaultValue: ''
    };

    return { groupIdFilterInfo };
};

export default useFilterGroupId;
