import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCellTwoContentsWrapper } from 'layouts';

import { DATE_FORMAT } from 'constants/dateFormats';
import { WALLET_ACCOUNT_STATUS } from 'constants/serverInfos/serverInfos';

import API from 'services';
import { unixToDateString } from 'utils/dateConversion';
import { handleAsyncCall } from 'utils/utils';

const useTableWalletStatus = (userId, withInitCall = true) => {
    const { t } = useTranslation();
    const [walletAccountList, setWalletAccountList] = useState([]);

    // TODO: add paging handling on payload
    const payload = useMemo(
        () => ({
            userId
            // pageCurrent: 1,
            // pageSize: 100
        }),
        [userId]
    );
    const getWalletAccountList = useCallback(() => {
        // TODO: connect wallet account list api

        handleAsyncCall({
            asyncCall: () => API.user.getWalletAccountList.apiCall(payload),
            handleCallSuccess: (res) => {
                setWalletAccountList(res.data.list || []);
            }
        });
    }, [payload]);

    useEffect(() => {
        if (!withInitCall) return;
        getWalletAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableColumInfos = {
        walletName: {
            field: 'walletName',
            label: `${t('common.user.wallet.walletName')} (${t(
                'common.user.bank.accountNumberLastFourDigit'
            )})`,
            renderCell: ({ accountName, walletAddress }) => {
                return (
                    <TableCellTwoContentsWrapper>
                        {accountName}
                        {walletAddress.slice(-4)}
                    </TableCellTwoContentsWrapper>
                );
            }
        },
        updateTime: {
            field: 'updateTime',
            label: t('common.date.updateTime'),
            renderCell: ({ updateTime }) => {
                return updateTime
                    ? unixToDateString(updateTime, DATE_FORMAT.yMdHm, 0)
                    : '---';
            }
        },
        walletAccountStatus: {
            field: 'walletAccountStatus',
            label: t('common.general.status'),
            renderCell: ({ status }) => {
                const { i18nKey, StatusIcon } =
                    Object.values(WALLET_ACCOUNT_STATUS).find(
                        ({ serverKey }) => serverKey === status
                    ) || {};

                const accountStatusTxt = t(i18nKey);

                return (
                    <TableCellTwoContentsWrapper>
                        <StatusIcon />
                        {accountStatusTxt}
                    </TableCellTwoContentsWrapper>
                );
            }
        }
    };

    return { walletAccountList, tableColumInfos, getWalletAccountList };
};

export default useTableWalletStatus;
