import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useSubTradeAccountList = () => {
    const { userId } = useSelector((state) => state.user);
    const [subTradeAccounts, setSubTradeAccounts] = useState([]);

    useEffect(() => {
        handleAsyncCall({
            asyncCall: () => API.common.getSubTradeAccountList.apiCall(userId),
            handleCallSuccess: (res) => {
                setSubTradeAccounts(res.data.list);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return { subTradeAccounts };
};

export default useSubTradeAccountList;
