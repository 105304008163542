import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LiveChatWidget } from '@livechat/widget-react';

import {
    LANGUAGE_INFO,
    SYSTEM_FALLBACK_LANGUAGE
} from 'constants/languageInfo';

import './WidgetLiveChat.scss';
import { handleHideWidgetCSS, handleShowWidgetCSS } from './utils';

const liveChatLicenseId = process.env.REACT_APP_LIVE_CHAT_LICENSE_ID;

export const VISIBILITY_LIVE_CHAT = {
    MAXIMIZED: 'maximized',
    MINIMIZED: 'minimized',
    HIDDEN: 'hidden'
};

const WidgetLiveChatContext = createContext();

const WidgetLiveChatProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const { displayName, isLogin } = useSelector((state) => state.user);
    const [visibility, setVisibility] = useState(VISIBILITY_LIVE_CHAT.HIDDEN);
    const [widgetClassName, setWidgetClassName] = useState('');
    const [isLiveChatReady, setIsLiveChatReady] = useState(false);
    const [hasNewMessage, setHasNewMessage] = useState(false);
    const chatWidgetElementRef = useRef(null);

    const contextValue = {
        isLiveChatReady,
        visibility,
        setVisibility,
        setWidgetClassName,
        chatWidgetElementRef,
        hasNewMessage,
        setHasNewMessage
    };

    const [openChatWidgetWithInitMessages, setOpenWidgetWithInitMessages] =
        useState(false);

    const langGroupId =
        LANGUAGE_INFO[i18n.language || SYSTEM_FALLBACK_LANGUAGE]
            ?.liveChatGroupId || process.env.REACT_APP_LIVE_CHAT_EN_GROUP_ID;

    const handleNewEvent = (event) => {
        switch (event.type) {
            case 'message':
            case 'rich_message':
                const isRemindIncomeMessage =
                    visibility !== VISIBILITY_LIVE_CHAT.MAXIMIZED &&
                    !event?.greeting?.uniqueId;

                if (isRemindIncomeMessage) {
                    setHasNewMessage(true);
                }

                if (!openChatWidgetWithInitMessages && isRemindIncomeMessage) {
                    setOpenWidgetWithInitMessages(true);
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const element = document.getElementById('chat-widget-container');
        if (element && widgetClassName && isLiveChatReady) {
            element.classList.add(widgetClassName);
        }
    }, [isLiveChatReady, widgetClassName]);

    useEffect(() => {
        if (isLiveChatReady && openChatWidgetWithInitMessages) {
            setVisibility(VISIBILITY_LIVE_CHAT.MAXIMIZED);
        }
    }, [isLiveChatReady, openChatWidgetWithInitMessages]);

    const handleVisibility = (data) => {
        switch (data.visibility) {
            case VISIBILITY_LIVE_CHAT.MAXIMIZED:
                handleShowWidgetCSS(chatWidgetElementRef.current);
                return;
            case VISIBILITY_LIVE_CHAT.MINIMIZED:
            case VISIBILITY_LIVE_CHAT.HIDDEN:
                // handleHideWidgetCSS(chatWidgetElementRef.current);
                setVisibility(VISIBILITY_LIVE_CHAT.HIDDEN);
                return;
            default:
                return;
        }
    };

    const handleReady = () => {
        const element = document.getElementById('chat-widget-container');

        if (element) {
            element.classList.add('chat-widget-wrapper');
            handleHideWidgetCSS(element);
            chatWidgetElementRef.current = element;
        }

        setIsLiveChatReady(true);
    };

    const chatDisplayName = isLogin ? displayName : ' ';

    return (
        <WidgetLiveChatContext.Provider value={contextValue}>
            {children}

            <LiveChatWidget
                license={liveChatLicenseId.toString()}
                visibility={visibility}
                onNewEvent={handleNewEvent}
                onReady={handleReady}
                onVisibilityChanged={handleVisibility}
                customerName={chatDisplayName}
                customerEmail={' '}
            />
        </WidgetLiveChatContext.Provider>
    );
};

export default WidgetLiveChatProvider;

export const useWidgetLiveChat = () => useContext(WidgetLiveChatContext);
