import { useWindowSize } from 'hooks';

const useSelectDropdownField = () => {
    const { isDesktop, isLaptop } = useWindowSize();
    const isRenderMuiSelect = isDesktop || isLaptop;

    return isRenderMuiSelect;
};

export default useSelectDropdownField;
