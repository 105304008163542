import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { Button, PlainTextMaskableField } from 'components';
import { AppCard } from 'layouts';

import { PAGE_FULL_STATIC_PATH } from 'constants/routes';
import { unmaskFieldInfo } from 'hooks';

import { getProfilePictureImage } from 'utils/layouts/sideContent';
import { isManagerLevel } from 'utils/user/authInfos';
import { displayUserName } from 'utils/utils';

import './BasicRegInfo.scss';
import { LinkIco } from 'assets/images/common';

const BasicRegInfo = () => {
    const { t } = useTranslation();
    const {
        userEmail,
        phoneCode,
        phone,
        title,
        userId,
        displayName,
        userRole
    } = useSelector((state) => state.user);

    const ProfilePicImg = getProfilePictureImage(title);

    const userData = [
        {
            label: t('common.user.username.name'),
            value: displayName
        },
        {
            label: t('common.user.communication.email'),
            renderCell: () => (
                <PlainTextMaskableField
                    maskedValue={userEmail}
                    fieldName={unmaskFieldInfo.userEmail.fieldName}
                    unmaskApiParam={{
                        userId
                    }}
                    isUnmaskable={true}
                    valueComponent="h4"
                />
            )
        },
        {
            label: t('common.user.communication.mobile'),
            renderCell: () => {
                return (
                    <PlainTextMaskableField
                        valuePrefix={phoneCode ? `+${phoneCode} ` : ''}
                        maskedValue={phone}
                        fieldName={unmaskFieldInfo.userPhone.fieldName}
                        unmaskApiParam={{
                            userId
                        }}
                        isUnmaskable={true}
                        valueComponent="h4"
                    />
                );
            }
        }
    ];

    return (
        <Box className="basicRegInfo">
            <AppCard title={t('common.sidebar.basicRegInfo.title')}>
                <Box className="basicInfoContent">
                    <Box className="profilePic">
                        <img src={ProfilePicImg} alt="" />
                    </Box>
                    <Box className="greeting">
                        <Typography component="h4">
                            {`${t(
                                'common.sidebar.basicInfo.hello'
                            )} ${displayUserName(displayName, t)}!`}
                        </Typography>
                    </Box>
                    {isManagerLevel(userRole) ? (
                        <Box className="quickBtnWrapper">
                            <Button
                                variant="contained"
                                startIcon={<LinkIco />}
                                className="quickBtn"
                                linkProps={{
                                    to: PAGE_FULL_STATIC_PATH.ReferralLink
                                }}>
                                {t('common.navbar.clientMgmt.accountLink')}
                            </Button>
                        </Box>
                    ) : (
                        <Box className="detailList">
                            {userData.map(
                                ({ label, value, renderCell }, index) => (
                                    <Box className="detailItm" key={index}>
                                        <Typography component="h6">
                                            {label}
                                        </Typography>
                                        {renderCell ? (
                                            renderCell()
                                        ) : (
                                            <Typography component="h4">
                                                {value}
                                            </Typography>
                                        )}
                                    </Box>
                                )
                            )}
                        </Box>
                    )}
                </Box>
            </AppCard>
        </Box>
    );
};

export default BasicRegInfo;
