import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';
import * as yup from 'yup';

import { AppForm, Button, ButtonIco, TextField } from 'components';
import IndicatorColorCircle from 'components/Indicators/IndicatorColorCircle/IndicatorColorCircle';
import { CLIENT_GROUP_COLOR_SET } from 'pages/CustomerMgmt/constant/grouopColor';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useWindowSize } from 'hooks';

import { commonTextValidation } from 'utils/yup';

import './SelectItemClientGroup.scss';
import { EditIco } from 'assets/images/actions';
import { CircleCheckIco } from 'assets/images/common';

const UNASSIGNED_GROUP_ID = -1;

const FORM_SUBMIT_TYPE = {
    DELETE: 'DELETE',
    COMPLETE: 'COMPLETE'
};

const SelectItemClientGroup = ({
    groupInfo,
    selectedId,
    handleSelectGroup,
    handleModifyGroup = null,
    handleDeleteGroup = null,
    initItemAccessType = INFO_ACCESS_TYPE.READ,
    isExtraItem
}) => {
    const { t } = useTranslation();
    const { isDesktopDown } = useWindowSize();
    const submitTypeRef = useRef(null);

    const [itemAccessType, setItemAccessType] = useState(initItemAccessType);
    const [isSelectingGroupColor, setIsSelectingGroupColor] = useState(false);
    const groupNameFieldRef = useRef(null);

    const [localGroupInfo, setLocalGroupInfo] = useState(groupInfo || {});

    useEffect(() => {
        setLocalGroupInfo(groupInfo || {});
    }, [groupInfo]);

    const { id: groupId, name: groupName, color: groupColor } = localGroupInfo;

    useEffect(() => {
        if (
            itemAccessType === INFO_ACCESS_TYPE.EDIT ||
            itemAccessType === INFO_ACCESS_TYPE.NEW
        ) {
            groupNameFieldRef.current && groupNameFieldRef.current.focus();
        }
    }, [itemAccessType]);

    const handleShowGroupColorSet = () => {
        if (itemAccessType === INFO_ACCESS_TYPE.READ) return;
        setIsSelectingGroupColor(true);
    };

    const isItemSelected = groupId === selectedId;
    const isItemModifying =
        itemAccessType === INFO_ACCESS_TYPE.NEW ||
        itemAccessType === INFO_ACCESS_TYPE.EDIT;

    const renderContent = (submitForm, isSubmitting) => {
        switch (itemAccessType) {
            case INFO_ACCESS_TYPE.NEW:
            case INFO_ACCESS_TYPE.EDIT:
                return {
                    ButtonModify: !isExtraItem &&
                        typeof handleModifyGroup === 'function' && (
                            <Button
                                label={t('common.action.complete')}
                                className="btnComplete"
                                variant="text"
                                disabled={isExtraItem || isSubmitting}
                                onClick={() => {
                                    setIsSelectingGroupColor(false);
                                    submitTypeRef.current =
                                        FORM_SUBMIT_TYPE.COMPLETE;
                                    submitForm();
                                }}
                            />
                        ),
                    ButtonSelect: !isExtraItem &&
                        typeof handleDeleteGroup === 'function' && (
                            <Button
                                label={t('common.action.delete')}
                                className="btnDelete"
                                variant="text"
                                onClick={() => {
                                    submitTypeRef.current =
                                        FORM_SUBMIT_TYPE.DELETE;
                                    submitForm();
                                }}
                                disabled={isExtraItem || isSubmitting}
                            />
                        )
                };
            case INFO_ACCESS_TYPE.READ:
            default:
                return {
                    ButtonModify: isExtraItem ? (
                        <Box className={'nonUserGroupTxt'}>
                            ({t('customerMgmt.clientGroupForm.nonUserGroup')})
                        </Box>
                    ) : (
                        typeof handleModifyGroup === 'function' && (
                            <ButtonIco
                                onClick={() => {
                                    setItemAccessType(INFO_ACCESS_TYPE.EDIT);
                                }}
                                BtnIco={<EditIco />}
                            />
                        )
                    ),
                    ButtonSelect: (
                        <Box
                            className={['selectHolder']}
                            onClick={() =>
                                !isItemSelected &&
                                !isExtraItem &&
                                handleSelectGroup(groupId)
                            }>
                            {selectedId === groupId && <CircleCheckIco />}
                        </Box>
                    )
                };
        }
    };

    const initialFormValues = {
        groupId,
        groupName,
        groupColor
    };

    const validationSchema = yup.object({
        groupName: commonTextValidation
    });

    const onSubmitModifyGroup = async (values) => {
        const onModifySuccess = () => {
            setItemAccessType(INFO_ACCESS_TYPE.READ);
        };

        await handleModifyGroup(values, onModifySuccess);
    };

    const circleSide = isDesktopDown ? '20px' : '32px';

    return (
        <AppForm
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            handleSubmit={async (values) => {
                if (submitTypeRef.current === FORM_SUBMIT_TYPE.DELETE) {
                    await handleDeleteGroup(values.groupId);
                } else if (
                    submitTypeRef.current === FORM_SUBMIT_TYPE.COMPLETE
                ) {
                    await onSubmitModifyGroup(values);
                }
            }}
            render={({ values, setFieldValue, submitForm, isSubmitting }) => {
                const { ButtonSelect, ButtonModify } = renderContent(
                    submitForm,
                    isSubmitting
                );
                return (
                    <Box
                        key={groupId}
                        className={cx([
                            'selectItemClientGroupWrapper',
                            isItemSelected && 'selectedItem',
                            isItemModifying && 'modifyingItem',
                            isExtraItem && 'extraItem'
                        ])}>
                        <Box className="clientGroupInfoBoxWrapper">
                            <Box className="clientGroupInfoWrapper">
                                {isSelectingGroupColor ? (
                                    <ClientGroupColorSet
                                        circleSide={circleSide}
                                        handleSelectColor={(selectedColor) => {
                                            setFieldValue(
                                                'groupColor',
                                                selectedColor
                                            );
                                            setIsSelectingGroupColor(false);
                                        }}
                                    />
                                ) : (
                                    <ClientGroupDetail
                                        circleSide={circleSide}
                                        itemAccessType={itemAccessType}
                                        groupName={groupName}
                                        groupColor={values.groupColor}
                                        handleSelectGroupName={(groupName) => {
                                            setFieldValue(
                                                'groupName',
                                                groupName
                                            );
                                        }}
                                        groupNameFieldRef={groupNameFieldRef}
                                        handleClickColorCircle={
                                            handleShowGroupColorSet
                                        }
                                    />
                                )}
                            </Box>
                            {groupId !== UNASSIGNED_GROUP_ID && ButtonModify}
                        </Box>
                        <Box className="selectSectionWrapper">
                            {ButtonSelect}
                        </Box>
                    </Box>
                );
            }}
        />
    );
};

const ClientGroupColorSet = ({ handleSelectColor, circleSide }) => {
    return (
        <Box className="colorSetWrapper">
            {CLIENT_GROUP_COLOR_SET.map((colorCode) => (
                <IndicatorColorCircle
                    side={circleSide}
                    color={colorCode}
                    key={colorCode}
                    onClick={handleSelectColor}
                />
            ))}
        </Box>
    );
};

const ClientGroupDetail = ({
    itemAccessType,
    groupColor,
    groupNameFieldRef,
    handleClickColorCircle,
    circleSide
}) => {
    return (
        <>
            <IndicatorColorCircle
                color={groupColor}
                side={circleSide}
                onClick={handleClickColorCircle}
            />

            <TextField
                type="text"
                name="groupName"
                className="clientGroupField"
                inputRef={groupNameFieldRef}
                disabled={itemAccessType === INFO_ACCESS_TYPE.READ}
            />
        </>
    );
};

export default SelectItemClientGroup;
