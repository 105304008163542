import { Box } from '@mui/material';
import cx from 'classnames';

import { AppFormSubmitButton, Button } from 'components';

import './ButtonGroupProcess.scss';

const ButtonGroupProcess = ({
    labelTop,
    onClickTop,
    classNameTop,
    labelBottom,
    onClickBottom,
    classNameBottom,
    isShowBottom = true,
    typeTop = null
}) => {
    const ButtonTop = typeTop === 'submit' ? AppFormSubmitButton : Button;

    return (
        <Box className="btnGroupWrapper btnGroupProcessWrapper">
            <ButtonTop
                label={labelTop}
                variant="contained"
                className={cx(['btnTop', classNameTop])}
                color="primary"
                type={typeTop}
                onClick={() => typeTop !== 'submit' && onClickTop()}
            />
            {isShowBottom && (
                <Button
                    label={labelBottom}
                    variant="contained"
                    className={cx(['btnBottom', classNameBottom])}
                    onClick={onClickBottom}
                    color="secondary"
                />
            )}
        </Box>
    );
};

export default ButtonGroupProcess;
