import { Box, InputLabel as MuiInputLabel } from '@mui/material';

import './InputLabel.scss';

const InputLabel = ({ label, isRequired }) => {
    return (
        label && (
            <Box className="inputLabel">
                {isRequired && <Box className="asterisk">*</Box>}
                <MuiInputLabel className="label">{label}</MuiInputLabel>
            </Box>
        )
    );
};

export default InputLabel;
