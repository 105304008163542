export const maskValue = (value, isInfoHidden, mask = '***') => {
    return isInfoHidden ? mask : value;
};

export const maskPersonalValue = (value) => {
    const valueLength = value?.length;
    const subValue = value?.substring(0, valueLength / 2);
    return subValue + '***';
};

export const maskEmailValue = (value) => {
    return value?.replace(/^(.{2})[^@]+/, '$1***');
};

export const maskPhoneValue = (value) => {
    const subValue = value?.substring(0, 5);
    return subValue + '****';
};
