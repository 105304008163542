import { DATE_RANGE_LAST_YEAR } from './dateInfos';

export const FILTER_LEVEL = {
    BASIC: 'basic',
    ADVANCED: 'advanced'
};

export const FILTER_TYPE = {
    SINGLE_SELECT: 'SINGLE_SELECT',
    MULTI_SELECT: 'MULTI_SELECT',
    TEXT_SEARCH: 'TEXT_SEARCH',
    DATE_RANGE: 'DATE_RANGE',
    DATE_CALENDAR: 'DATE_CALENDAR',
    COMBO_BOX: 'COMBO_BOX'
};

export const DEFAULT_FILTER_GROUP = {
    [FILTER_LEVEL.BASIC]: [],
    [FILTER_LEVEL.ADVANCED]: []
};

export const defaultAppFilterDateRange = DATE_RANGE_LAST_YEAR;
