export const DATE_FORMAT = {
    yM: 'yyyy-MM',
    yMd: 'yyyy-MM-dd',
    yMdWithoutSeparator: 'yyyyMMdd',
    yMdHm: 'yyyy-MM-dd HH:mm',
    yMdHms: 'yyyy-MM-dd HH:mm:ss',
    ymdHms: 'yyyymmddHHmmss',
    Hms: 'HH:mm:ss',
    Hm: 'HH:mm',
    yyyy: 'yyyy',
    MM: 'MM', // 01, 02, ..., 12
    MMM: 'MMM', // Jan, Feb, ..., Dec
    MMMM: 'MMMM' // January, February, ..., December
};
