import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormHelperText } from '@mui/material';

import SelectDropdownField from 'components/Fields/SelectDropdownField/SelectDropdownField';

import { useCountries } from 'hooks';

const SelectCountryField = ({
    clientTypeKey,
    handleChange = () => {},
    isShowCountrySupport = false,
    fullWidth = true,
    wrapperWidth = '100%',
    ...props
}) => {
    const { t } = useTranslation();
    const { countries, getSelectedCountryInfo } = useCountries();
    const [isCountrySupported, setIsCountrySupported] = useState(true);

    if (countries.length < 1) return null;

    return (
        <React.Fragment key="country">
            <SelectDropdownField
                label={t('common.user.communication.countryAndRegion')}
                name="country"
                fullWidth={fullWidth}
                wrapperWidth={'100%'}
                items={countries}
                itemValueKey="code"
                itemLabelKey="displayName"
                handleChange={(event) => {
                    const selectedCountry = event.target.value;

                    const selectedCountryInfo =
                        getSelectedCountryInfo(selectedCountry);

                    setIsCountrySupported(selectedCountryInfo.isCountrySupport);
                    handleChange(selectedCountryInfo);
                }}
                {...props}
            />
            {isShowCountrySupport && (
                <FormHelperText>
                    {!isCountrySupported &&
                        t('registration.createAccount.statement')}
                </FormHelperText>
            )}
        </React.Fragment>
    );
};

export default SelectCountryField;
