export const sortItemByAscendingValue = (key) => (first, second) => {
    if (first[key] > second[key]) {
        return 1;
    }
    if (first[key] < second[key]) {
        return -1;
    }
    return 0;
};

export const sortItemByDescendingValue = (key) => (first, second) => {
    if (first[key] < second[key]) {
        return 1;
    }
    if (first[key] > second[key]) {
        return -1;
    }
    return 0;
};

export const parseSections = (
    rawList,
    parseSectionKey,
    sortingKey,
    sortingFunction,
    itemNumberInRow = 1
) => {
    const sortedSections = {};
    rawList.forEach((item) => {
        const sectionKey = parseSectionKey(item);
        if (!sortedSections[sectionKey]) {
            sortedSections[sectionKey] = [];
        }
        sortedSections[sectionKey] = [...sortedSections[sectionKey], item];
        sortedSections[sectionKey].sort(sortingFunction(sortingKey));
    });
    const sections = Object.entries(sortedSections).map(([key, value]) => ({
        sectionLabel: key,
        data: value
    }));
    sections.sort(sortingFunction('sectionLabel'));

    if (itemNumberInRow > 1) {
        for (const section of sections) {
            let tmpData = [];
            let tmpArray = [];
            let index = 0;
            section.data.forEach((item) => {
                if (index < itemNumberInRow) {
                    tmpArray = [...tmpArray, item];
                    index += 1;
                } else {
                    tmpData = [...tmpData, tmpArray];
                    tmpArray = [item];
                    index = 1;
                }
            });
            tmpData = [...tmpData, tmpArray];
            section.data = tmpData;
        }
    }
    return sections;
};
