import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import AdornmentReveal from 'components/Fields/Adornments/AdornmentReveal/AdornmentReveal';

import { useUnmaskFieldValue } from 'hooks';

import './PlainTextMaskableField.scss';

const PlainTextMaskableField = ({
    initShowField = false,
    maskedValue = '---',
    valuePrefix = '',
    wrapperWidth = 'auto',
    label,
    fieldName,
    unmaskApiParam,
    isUnmaskable,
    valueComponent
}) => {
    const [isShowField, setIsShowField] = useState(initShowField);

    const { unmaskedValue, getUnmaskFieldValue } =
        useUnmaskFieldValue(maskedValue);

    useEffect(() => {
        if (isShowField) {
            getUnmaskFieldValue(fieldName, unmaskApiParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowField, maskedValue]);

    return (
        <Box
            className="appFieldWrapper plainTextMaskableField"
            sx={{ width: wrapperWidth }}>
            {label && <Box className="fieldLabel">{label}</Box>}
            <Box className="fieldValueWrapper">
                <Box className="fieldValue" component={valueComponent}>
                    {valuePrefix + (isShowField ? unmaskedValue : maskedValue)}
                </Box>

                {isUnmaskable && (
                    <AdornmentReveal
                        isShowField={isShowField}
                        setIsShowField={setIsShowField}
                    />
                )}
            </Box>
        </Box>
    );
};

export default PlainTextMaskableField;
