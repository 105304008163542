import React, { useEffect, useState } from 'react';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Tabs } from '@mui/material';
import cx from 'classnames';

import './ScrollSelect.scss';

const ScrollSelect = ({
    itemList = [],
    defaultValue,
    onSelectItem = () => {},
    renderTab = () => null,
    className = ''
}) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleSelectItem = (_event, newValue) => {
        onSelectItem(newValue);
        setValue(newValue);
    };

    return (
        <Box className={cx(['scrollSelect', className])}>
            <Tabs
                value={value}
                onChange={handleSelectItem}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable"
                ScrollButtonComponent={(props) => {
                    const { slotProps, ...iconButtonProps } = props;

                    if (props.direction === 'left' && !props.disabled) {
                        return (
                            <IconButton
                                {...iconButtonProps}
                                className="arrowBack">
                                <ArrowBack />
                            </IconButton>
                        );
                    } else if (props.direction === 'right' && !props.disabled) {
                        return (
                            <IconButton
                                {...iconButtonProps}
                                className="arrowForward">
                                <ArrowForward />
                            </IconButton>
                        );
                    } else {
                        return null;
                    }
                }}>
                {itemList.map((item, index) => renderTab({ item, index }))}
            </Tabs>
        </Box>
    );
};

export default ScrollSelect;
