import React from 'react';

import { Box } from '@mui/material';

import './Loading.scss';
import { LoadingImg } from 'assets/images/common';

// import LoadingImg from 'assets/images/common/loading.gif';

const Loading = () => {
    return (
        <Box className="loadingWrapper">
            <Box className="loading">
                <img src={LoadingImg} alt="loading..." />
            </Box>
        </Box>
    );
};

export default Loading;
