import isEmpty from 'lodash/isEmpty';

import { useAccStatusList } from 'hooks';

const PlainTextAccStatusField = ({ accStatus }) => {
    const { accStatusList } = useAccStatusList();

    if (isEmpty(accStatusList)) return '---';

    const accStatusLabel = accStatusList.find(({ key }) => accStatus === key);
    return accStatusLabel?.value ?? '---';
};

export default PlainTextAccStatusField;
