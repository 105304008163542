import React, { Fragment, useContext } from 'react';

import { TableBody, TableCell, TableRow } from '@mui/material';

import { NoContentBoard } from 'components';
import RowCollapsibleContext from 'layouts/AppTableContainer/RowCollapsibleProvider/RowCollapsibleContext';

import AppTableRow from '../AppTableRow/AppTableRow';
import RowCollapsible from '../RowCollapsible/RowCollapsible';

const AppTableBody = ({
    tableCellGroupInfos,
    rowsData,
    rowKey,
    rowClickPath = '',
    rowClickQueryParam = '',
    renderExtraRow = () => {},
    viewMethod = () => {}
}) => {
    const hasData = rowsData.length > 0;

    const { hasRowCollapsible } = useContext(RowCollapsibleContext);

    return (
        <TableBody className="appTableBody">
            {hasData ? (
                <>
                    {rowsData.map((rowData, rowIndex) => {
                        return (
                            <Fragment
                                key={
                                    rowKey
                                        ? rowData[rowKey] + rowIndex.toString()
                                        : rowIndex
                                }>
                                <AppTableRow
                                    tableCellGroupInfos={tableCellGroupInfos}
                                    rowData={rowData}
                                    rowIndex={rowIndex}
                                    rowClickPath={rowClickPath}
                                    rowClickQueryParam={rowClickQueryParam}
                                    viewMethod={viewMethod}
                                />
                                {hasRowCollapsible && (
                                    <RowCollapsible
                                        rowData={rowData}
                                        rowIndex={rowIndex}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                    {renderExtraRow({ tableCellGroupInfos })}
                </>
            ) : (
                <TableRow className="appTableRow">
                    <TableCell colSpan="40">
                        <NoContentBoard />
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

export default AppTableBody;
