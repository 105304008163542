import { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { TextField } from 'components';

import './NumberField.scss';
import { DOT_KEY, ENTER_KEY, MINUS_KEY, NUMBER_KEYS } from './constants.js';

const NumberField = ({
    label,
    name,
    isInteger = false,
    canBeNegative = false,
    ...props
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyBeforeInput = (event) => {
            let allowedKeys = [...NUMBER_KEYS];
            if (!isInteger) {
                allowedKeys = [...allowedKeys, DOT_KEY];
            }
            if (canBeNegative) {
                allowedKeys = [...allowedKeys, MINUS_KEY];
            }
            if (
                event.inputType === 'insertText' &&
                !allowedKeys.includes(event.data)
            ) {
                event.preventDefault();
            }
        };
        const handleKey = (event) => {
            if (event.key === ENTER_KEY) {
                event.preventDefault();
            }
        };

        inputRef.current?.addEventListener('beforeinput', handleKeyBeforeInput);
        inputRef.current?.addEventListener('keydown', handleKey);
    }, [canBeNegative, isInteger]);

    return (
        <Box className="numberField">
            <TextField
                inputRef={inputRef}
                label={label}
                name={name}
                type="number"
                inputProps={{
                    inputMode: isInteger
                        ? 'numeric'
                        : canBeNegative
                        ? 'text' // iOS Safari doesn't show '-' key on numeric keyboard
                        : 'decimal'
                }}
                {...props}
            />
        </Box>
    );
};

export default NumberField;
