import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMore } from '@mui/icons-material';
import { NativeSelect } from '@mui/material';
import cx from 'classnames';

import { getIsUnselected } from '../../utils';
import './MuiNativeSelect.scss';

const MuiNativeSelect = ({
    name,
    items,
    fieldValue,
    itemLabelKey,
    itemValueKey,
    wrapperProps,
    handleChange,
    handleBlur,
    renderLabel,
    onClick,
    withoutFormikHook,
    placeholder,
    disabled
}) => {
    const { t } = useTranslation();

    const isUnselected = getIsUnselected(fieldValue);

    const selectedIndex = (() => {
        switch (typeof fieldValue) {
            case 'object':
                return items.indexOf(fieldValue);
            default:
                return items.findIndex(
                    (item) => item[itemValueKey] === fieldValue
                );
        }
    })();

    const handleSelectChange = (event) => {
        const index = event.target.value;
        const item = items[index];
        const value = itemValueKey ? item[itemValueKey] : item;
        event.target = { value };

        handleChange && handleChange(event);
        !withoutFormikHook &&
            wrapperProps.setFieldValue(name, event.target.value);
    };

    const handleSelectBlur = (event) => {
        handleBlur && handleBlur(event);
        !withoutFormikHook && wrapperProps.handleBlur(event);
    };

    return (
        <NativeSelect
            value={selectedIndex === -1 ? '' : selectedIndex} // `value === ''` to enable placeholder being shown
            onChange={handleSelectChange}
            onBlur={handleSelectBlur}
            className={cx('muiNativeSelect', [
                selectedIndex === -1 && 'placeholderSelect'
            ])}
            name={name}
            onClick={onClick}
            IconComponent={ExpandMore}>
            {isUnselected && (
                <option disabled hidden value="" className="placeholderTxt">
                    {disabled
                        ? ''
                        : placeholder === ''
                        ? t('common.placeholder.pleaseSelect')
                        : placeholder}
                </option>
            )}
            {items.map((item, index) => (
                <option key={index} value={index}>
                    {renderLabel
                        ? // render for text/number only, not for react Component
                          renderLabel(item)
                        : item[itemLabelKey]}
                </option>
            ))}
        </NativeSelect>
    );
};

export default MuiNativeSelect;
