import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useUserSources } from 'hooks';

export const FILTER_SOURCE_CODE = 'sourceCode';

const useFilterSourceCode = (filterName = FILTER_SOURCE_CODE) => {
    const { t } = useTranslation();
    const { userSources } = useUserSources();

    const itemAll = {
        key: '',
        value: t('common.general.all')
    };

    const sourceCodeFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.sales.sourceCode'),
        items: [itemAll, ...userSources],
        itemLabelKey: 'value',
        itemValueKey: 'key',
        defaultValue: ''
    };

    return { sourceCodeFilterInfo };
};

export default useFilterSourceCode;
