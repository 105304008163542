import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { defaultTablePageValues } from 'constants/appTableInfos';

const initialFilterState = {
    filterListsGroups: {},
    initFilterValuesGroups: {},
    submittedFilterValuesGroups: {},
    flatSubmittedFilterGroupValues: {},
    isRestoreCachedParamsGroups: {},
    pageValuesGroups: {}
};

const filterSlice = createSlice({
    name: 'filter',
    initialState: initialFilterState,
    reducers: {
        configFilterGroupList: (state, action) => {
            const { filterGroupId, filterLists } = action.payload;

            state.filterListsGroups = {
                ...state.filterListsGroups,
                [filterGroupId]: filterLists
            };

            state.isRestoreCachedParamsGroups = {
                ...state.isRestoreCachedParamsGroups,
                [filterGroupId]: false
            };
        },
        configFilterGroupValues: (state, action) => {
            const {
                filterGroupId,
                initFilterGroupValues,
                submittedFilterGroupValues
            } = action.payload;

            state.initFilterValuesGroups = {
                ...state.initFilterValuesGroups,
                [filterGroupId]: initFilterGroupValues
            };

            state.submittedFilterValuesGroups = {
                ...state.submittedFilterValuesGroups,
                [filterGroupId]: submittedFilterGroupValues
            };

            state.isRestoreCachedParamsGroups = {
                ...state.isRestoreCachedParamsGroups,
                [filterGroupId]: false
            };

            state.pageValuesGroups = {
                ...state.pageValuesGroups,
                [filterGroupId]: defaultTablePageValues
            };
        },
        setSubmittedFilterGroupValues: (state, action) => {
            const {
                filterGroupId,
                filterGroupValues,
                flatFilterGroupValues,
                pageValues
            } = action.payload;

            if (!isEmpty(filterGroupValues)) {
                state.submittedFilterValuesGroups = {
                    ...state.submittedFilterValuesGroups,
                    [filterGroupId]: filterGroupValues
                };
            }

            if (!isEmpty(flatFilterGroupValues)) {
                state.flatSubmittedFilterGroupValues = {
                    ...state.flatSubmittedFilterGroupValues,
                    [filterGroupId]: flatFilterGroupValues
                };
            }

            if (!isEmpty(pageValues)) {
                state.pageValuesGroups = {
                    ...state.pageValuesGroups,
                    [filterGroupId]: pageValues
                };
            }
        },
        setIsRestoreCachedParams: (state, action) => {
            const { filterGroupId, isRestoreCachedParams } = action.payload;

            state.isRestoreCachedParamsGroups = {
                ...state.isRestoreCachedParamsGroups,
                [filterGroupId]: isRestoreCachedParams
            };
        },
        resetFilterGroup: (state, action) => {
            const filterGroupId = action.payload;

            state.filterListsGroups = {
                ...state.filterListsGroups,
                [filterGroupId]: undefined
            };
            state.submittedFilterValuesGroups = {
                ...state.submittedFilterValuesGroups,
                [filterGroupId]: undefined
            };

            state.submittedFilterValuesGroups = {
                ...state.submittedFilterValuesGroups,
                [filterGroupId]: undefined
            };

            state.flatSubmittedFilterGroupValues = {
                ...state.flatSubmittedFilterGroupValues,
                [filterGroupId]: undefined
            };
            state.pageValues = {
                ...state.pageValues,
                [filterGroupId]: defaultTablePageValues
            };
        },
        resetAllFilters: () => ({ ...initialFilterState })
    }
});

export default filterSlice;

export const {
    configFilterGroupList,
    configFilterGroupValues,
    setSubmittedFilterGroupValues,
    resetFilterGroup,
    setIsRestoreCachedParams,
    resetAllFilters
} = filterSlice.actions;
