import { CURRENT_LANGUAGE } from 'i18nConfig';

import { LANGUAGE_INFO } from 'constants/languageInfo';

import axiosConfig from 'axios/axiosConfig';
import axiosThirdPartyConfig from 'axios/axiosThirdPartyConfig';

const getSupportedLanguage = (languageKey) => {
    if (LANGUAGE_INFO[CURRENT_LANGUAGE].isThirdPartySupported) {
        return LANGUAGE_INFO[CURRENT_LANGUAGE][languageKey];
    }
    return LANGUAGE_INFO['en-US'][languageKey];
};

const thirdParty = {
    // News
    getNewsList: {
        apiCall: (pageIndex, itemsPerPage) => {
            const language = getSupportedLanguage('contentsKey');
            return axiosThirdPartyConfig.get(
                `/news-api/${language}/post/filterfull/GeneralFeed/${pageIndex}/${itemsPerPage}/Empty`
            );
        },
        queryKey: 'thirdParty.getNewsList'
    },
    getNewsDetail: {
        apiCall: (newsId) => {
            const language = getSupportedLanguage('contentsKey');
            return axiosThirdPartyConfig.get(
                `/news-api/${language}/post/${newsId}`
            );
        },
        queryKey: 'thirdParty.getNewsDetail'
    },
    // Economic Calendar
    getEconomicDates: {
        apiCall: (startDate, endDate, params) => {
            const language = getSupportedLanguage('serverKey');
            return axiosThirdPartyConfig.get(
                `/calendar-api/${language}/api/v1/eventDates/${startDate}/${endDate}`,
                {
                    params
                }
            );
        },
        queryKey: 'thirdParty.getEconomicDates'
    },
    // Autochartist
    getMarketReport: {
        apiCall: (params) => {
            return axiosConfig.get('/autochartist/market', { params });
        },
        queryKey: 'thirdParty.getMarketReport'
    },
    getTradingOpportunities: {
        apiCall: (params) => {
            return axiosConfig.get('/autochartist/trading', { params });
        },
        queryKey: 'thirdParty.getTradingOpportunities'
    }
};

export default thirdParty;
