import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, InputAdornment, TextField } from '@mui/material';
import cx from 'classnames';

import { FormControl, InputLabel } from 'components';
import FieldRightAdornmentContainer from 'components/Fields/Adornments/FieldRightAdornment/FieldRightAdornment';
import { AppFieldWrapper } from 'layouts';

import { useFormikFieldProps } from 'hooks';

import './MobileField.scss';

const MobileField = ({
    phoneCodeName,
    mobileNumberName,
    label,
    handleBlur = null,
    handleChange = null,
    handleClick = null,
    className = '',
    fullWidth = false,
    wrapperClassName = '',
    isRequired = false
}) => {
    const { t } = useTranslation();

    const phoneCodeProps = useFormikFieldProps(phoneCodeName);
    const mobileNumberProps = useFormikFieldProps(mobileNumberName);

    return (
        <AppFieldWrapper className={cx('mobileField', wrapperClassName)}>
            <InputLabel label={label} isRequired={isRequired} />
            <FormControl
                error={phoneCodeProps.error || mobileNumberProps.error}
                helperText={
                    (phoneCodeProps.error && phoneCodeProps.helperText) ||
                    (mobileNumberProps.error && mobileNumberProps.helperText)
                }
                className={className}
                fullWidth={fullWidth}>
                <Box className="mobileFieldWrapper">
                    <TextField
                        fullWidth
                        className="phoneCode"
                        name="phoneCode"
                        type="text"
                        value={phoneCodeProps.value}
                        onBlur={(event) => {
                            handleBlur && handleBlur(event);
                            phoneCodeProps.onBlur(event);
                        }}
                        onChange={(event) => {
                            handleChange && handleChange(event);
                            phoneCodeProps.onChange(event);
                        }}
                        onClick={(event) => {
                            handleClick && handleClick(event);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +
                                </InputAdornment>
                            )
                        }}
                        inputProps={{ readOnly: true }}
                        error={phoneCodeProps.error || mobileNumberProps.error}
                    />
                    <TextField
                        fullWidth
                        className="mobileNumber"
                        name="mobile"
                        type="text"
                        value={mobileNumberProps.value}
                        onBlur={(event) => {
                            handleBlur && handleBlur(event);
                            mobileNumberProps.onBlur(event);
                        }}
                        onChange={(event) => {
                            handleChange && handleChange(event);
                            mobileNumberProps.onChange(event);
                        }}
                        onClick={(event) => {
                            handleClick && handleClick(event);
                        }}
                        error={phoneCodeProps.error || mobileNumberProps.error}
                        InputProps={{
                            endAdornment: (
                                <FieldRightAdornmentContainer
                                    value={mobileNumberProps.value}
                                    clearValue={() =>
                                        mobileNumberProps.setValue('')
                                    }
                                />
                            )
                        }}
                        placeholder={t('common.placeholder.pleaseEnter')}
                    />
                </Box>
            </FormControl>
        </AppFieldWrapper>
    );
};

export default MobileField;
