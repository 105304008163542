import enUS from 'date-fns/locale/en-US';
import th from 'date-fns/locale/th';
import vi from 'date-fns/locale/vi';
import zhCN from 'date-fns/locale/zh-CN';

export const LANGUAGE_INFO = {
    'en-US': {
        localeKey: 'en-US',
        serverKey: 'en-US',
        liveChatGroupId: process.env.REACT_APP_LIVE_CHAT_EN_GROUP_ID,
        contentsKey: 'en',
        isThirdPartySupported: true,
        dateLocale: enUS,
        autochartistOfficialKey: 'en'
    },
    'zh-CN': {
        localeKey: 'zh-CN',
        serverKey: 'zh-CN',
        liveChatGroupId: process.env.REACT_APP_LIVE_CHAT_CN_GROUP_ID,
        contentsKey: 'zh',
        isThirdPartySupported: true,
        dateLocale: zhCN,
        autochartistOfficialKey: 'zh'
    },
    th: {
        localeKey: 'th',
        serverKey: 'th',
        liveChatGroupId: process.env.REACT_APP_LIVE_CHAT_EN_GROUP_ID,
        contentsKey: 'th',
        isThirdPartySupported: false,
        dateLocale: th,
        autochartistOfficialKey: 'th'
    },
    vi: {
        localeKey: 'vi',
        serverKey: 'vi',
        liveChatGroupId: process.env.REACT_APP_LIVE_CHAT_EN_GROUP_ID,
        contentsKey: 'vi',
        isThirdPartySupported: false,
        dateLocale: vi,
        autochartistOfficialKey: 'vi'
    }
};

export const SYSTEM_FALLBACK_LANGUAGE = LANGUAGE_INFO['zh-CN'].localeKey;
