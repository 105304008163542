import { LazyTabPages as TabPages } from 'pages';

import { CLIENT_TYPE_INFO } from 'constants/client/clientTypeInfo';
import { STATIC_PATH_NAME } from 'constants/routes';

export const leadDetailPaths = [
    `${STATIC_PATH_NAME.Leads}/detail/:userId`,
    `${STATIC_PATH_NAME.Leads}/detail/:userId/:tailPath`
];
export const depositedClientDetailPaths = [
    `${STATIC_PATH_NAME.DepositedClients}/:userId/:searchTradeLoginId`,
    `${STATIC_PATH_NAME.DepositedClients}/:userId/:searchTradeLoginId/:tailPath`
];
export const registeredClientDetailPaths = [
    `${STATIC_PATH_NAME.RegisteredClients}/:userId/register`,
    `${STATIC_PATH_NAME.RegisteredClients}/:userId/:searchTradeLoginId`,
    `${STATIC_PATH_NAME.RegisteredClients}/:userId/:searchTradeLoginId/:tailPath`
];

const clientDetailRoutes = {
    CLIENT_TRADE_DETAIL: {
        path: 'trading-account-information',
        clientFor: [
            CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key,
            CLIENT_TYPE_INFO.REGISTERED_CLIENT.key
        ],
        title: 'customerMgmt.clientDetail.clientTradeAccounts.tabName',
        element: <TabPages.ClientTradeAccounts />
    },
    ACCOUNT_INFO: {
        path: 'information',
        clientFor: [
            CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key,
            CLIENT_TYPE_INFO.REGISTERED_CLIENT.key
        ],
        title: 'customerMgmt.clientDetail.accountInfos.tabName',
        element: <TabPages.ClientAccountInfos />
    },
    TRADE_REPORTS: {
        path: 'trading-statement',
        clientFor: [CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key],
        title: 'customerMgmt.clientDetail.tradeReports.title',
        element: <TabPages.ClientTradeReports />
    },
    FUND_FLOW: {
        path: 'funds-history',
        clientFor: [CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key],
        title: 'customerMgmt.clientDetail.fundFlow.title',
        element: <TabPages.ClientFundFlow />
    },
    FOLLOW_LOG: {
        path: 'follow-up-record',
        clientFor: [
            CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key,
            CLIENT_TYPE_INFO.REGISTERED_CLIENT.key,
            CLIENT_TYPE_INFO.LEAD_CLIENT.key
        ],
        title: 'customerMgmt.clientDetail.followLog.title',
        element: <TabPages.ClientFollowInfos />
    },
    ACCOUNT_LINK: {
        path: 'referral-link',
        clientFor: [CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key],
        title: 'customerMgmt.clientDetail.accountLink.title',
        element: <TabPages.ClientAccountLink />
    }
};

export const getClientDetailRoutes = (clientTypeKey) => {
    const clientDetailRoutesByClientType = {};
    let isSetParentRoute = false;

    Object.entries(clientDetailRoutes).forEach(([routeName, routeInfo]) => {
        if (routeInfo.clientFor.includes(clientTypeKey)) {
            let currRouteInfo = routeInfo;

            if (!isSetParentRoute) {
                currRouteInfo = {
                    ...currRouteInfo,
                    path: [routeInfo.path, '']
                };

                isSetParentRoute = true;
            }

            clientDetailRoutesByClientType[routeName] = currRouteInfo;
        }
    });

    return clientDetailRoutesByClientType;
};

export const getTabPageRouteList = (clientTypeKey, t) => {
    const tabPageRouteList = [];

    Object.entries(clientDetailRoutes).forEach(([routeName, { clientFor }]) => {
        if (clientFor.includes(clientTypeKey)) {
            const routeInfo = clientDetailRoutes[routeName];

            tabPageRouteList.push({
                ...routeInfo,
                title: t(routeInfo.title)
            });
        }
    });

    return tabPageRouteList;
};

export default clientDetailRoutes;
