import { useTranslation } from 'react-i18next';

import {
    FILTER_LEVEL,
    FILTER_TYPE,
    defaultAppFilterDateRange
} from 'constants/filterInfos';
import { useAppFilter } from 'hooks';
import { useFilterManager } from 'hooks/filters';

export const FILTER_NAMES_ORDERS = {
    SEARCH_TRADE_LOGIN_IDS: 'searchTradeLoginIds',
    OPEN_PERIOD: ['startDate', 'endDate'],
    MANAGER_TRADE_LOGIN_IDS: 'managerTradeLoginIds'
};

const useFilterGroupOrders = ({
    filterGroupId = 'FILTER_GROUP_ORDERS',
    filterNameLists
}) => {
    const { t } = useTranslation();
    const { managerTradeLoginIdFilterInfo } = useFilterManager();

    const filterListsInfos = {
        [FILTER_NAMES_ORDERS.SEARCH_TRADE_LOGIN_IDS]: {
            filterName: FILTER_NAMES_ORDERS.SEARCH_TRADE_LOGIN_IDS,
            filterType: FILTER_TYPE.TEXT_SEARCH,
            label: t('common.tradeAccount.tradeLoginId'),
            defaultValue: ''
        },
        [FILTER_NAMES_ORDERS.OPEN_PERIOD]: {
            filterName: FILTER_NAMES_ORDERS.OPEN_PERIOD,
            filterType: FILTER_TYPE.DATE_RANGE,
            label: t('common.date.openDate'),
            defaultValue: defaultAppFilterDateRange
        },
        [FILTER_NAMES_ORDERS.MANAGER_TRADE_LOGIN_IDS]: {
            ...managerTradeLoginIdFilterInfo,
            filterName: FILTER_NAMES_ORDERS.MANAGER_TRADE_LOGIN_IDS
        }
    };

    const filterLists = {
        [FILTER_LEVEL.BASIC]: [],
        [FILTER_LEVEL.ADVANCED]: []
    };

    Object.entries(filterNameLists).forEach(([filterLevel, filterFields]) => {
        filterFields.forEach((filterName) => {
            filterLists[filterLevel].push(filterListsInfos[filterName]);
        });
    });

    const { initFilterGroup } = useAppFilter({
        filterGroupId,
        filterLists
    });

    return { initFilterGroupOrders: initFilterGroup };
};

export default useFilterGroupOrders;
