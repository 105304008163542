import { useCurrentBreakpointName } from 'react-socks';

import { BREAKPOINT } from 'utils/providers/BreakpointProvider';

const useWindowSize = () => {
    const breakPointName = useCurrentBreakpointName();

    const isMobile = breakPointName === BREAKPOINT.MOBILE;
    const isTablet = breakPointName === BREAKPOINT.TABLET;
    const isLaptop = breakPointName === BREAKPOINT.LAPTOP;

    const isDesktopDown =
        breakPointName && breakPointName !== BREAKPOINT.DESKTOP;
    const isDesktop = breakPointName === BREAKPOINT.DESKTOP;

    return {
        isMobile,
        isTablet,
        isLaptop,
        isDesktopDown,
        isDesktop
    };
};
export default useWindowSize;
