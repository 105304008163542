import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getObjectWithUndefinedValues = (obj) => {
    let undefinedValuesObject = {};
    Object.entries(obj).forEach(([key, value]) => {
        undefinedValuesObject[key] = isArray(value) ? [] : undefined;
    });

    return undefinedValuesObject;
};

export const appendItemToList = (list, extraItem, key) => {
    if (isEmpty(extraItem)) return list;

    const isExtraItemIncluded = list.find(
        (item) => item[key] === extraItem[key]
    );
    return isExtraItemIncluded ? list : [...list, extraItem];
};
