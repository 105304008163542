import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { NumFormat } from 'components';
import { AppCard } from 'layouts';

import './StatusCard.scss';

const StatusCard = ({ value, label }) => {
    const { t } = useTranslation();

    return (
        <AppCard className="statusCard">
            <Typography component="h2">{label}</Typography>
            <Grid container>
                <Grid item mobile={4}>
                    <Typography component="h4" className="blue">
                        <NumFormat value={value?.yesterday} decimalScale={2} />
                    </Typography>
                    <Typography>{t('common.date.yesterday')}</Typography>
                </Grid>
                <Grid item mobile={4}>
                    <Typography component="h4" className="green">
                        <NumFormat
                            value={value?.currentMonth}
                            decimalScale={2}
                        />
                    </Typography>
                    <Typography>{t('common.date.currentMonth')}</Typography>
                </Grid>
                <Grid item mobile={4}>
                    <Typography component="h4">
                        <NumFormat value={value?.lastMonth} decimalScale={2} />
                    </Typography>
                    <Typography>{t('common.date.lastMonth')}</Typography>
                </Grid>
            </Grid>
        </AppCard>
    );
};

export default StatusCard;
