import { Dashboard } from '@mui/icons-material';

import Pages from 'pages';
import AUTH_ROUTES from 'pages/Authentication/routes';
import { PERFORMANCE_ROUTE } from 'pages/Home/SalesPerformance/routes';
import registerRoutes from 'pages/Registration/routes';
import { RewardRoutes } from 'pages/Reward/routes';

import { USER_ROLE } from 'constants/authInfos';
import { CLIENT_TYPE_INFO } from 'constants/client/clientTypeInfo';
import { STATIC_PATH_NAME } from 'constants/routes';

import { isManagerLevel } from 'utils/user/authInfos';

import clientDetailRoutes, {
    depositedClientDetailPaths,
    getClientDetailRoutes,
    leadDetailPaths,
    registeredClientDetailPaths
} from '../pages/CustomerMgmt/routes';
import {
    AssetMgmtIco,
    ClientMgmtIco,
    HomeIco,
    IncomeIco,
    ProfileIco,
    TradeReportIco
} from 'assets/images/common';

export const getRouteInfos = ({ userRole }) => {
    return {
        Home: {
            path: '/home/client-portal',
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            title: 'common.navbar.home.title',
            permissionFor: [USER_ROLE.CUSTOMER],
            isOnMenu: true,
            element: <Pages.Dashboard />,
            icon: <HomeIco />
        },
        IbHome: {
            path: '/home',
            permissionFor: [USER_ROLE.IB],
            title: 'common.navbar.home.title',
            isOnMenu: true,
            icon: <HomeIco />,
            subRoutes: {
                IbHome: {
                    path: ['ib-portal', ''],
                    title: 'common.navbar.home.ibDashboard',
                    element: <Pages.IbDashboard />,
                    isOnMenu: true
                },
                Dashboard: {
                    path: 'client-portal',
                    title: 'common.navbar.home.dashboard',
                    element: <Pages.Dashboard />,
                    isOnMenu: true
                }
            }
        },
        ManagerHome: {
            path: '/home/manager-portal',
            permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
            title: 'common.navbar.home.title',
            element: <Pages.ManagerDashboard />,
            isOnMenu: true,
            icon: <HomeIco />
        },
        SalesPerformance: {
            path: '/home/sales-performance',
            permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
            element: <Pages.SalesPerformance />,
            subRoutes: PERFORMANCE_ROUTE
        },
        CustomerMgmt: {
            permissionFor: [
                USER_ROLE.MAJORDOMO,
                USER_ROLE.MANAGER,
                USER_ROLE.IB
            ],
            title: 'common.navbar.clientMgmt.title',
            icon: <ClientMgmtIco />,
            path: STATIC_PATH_NAME.CustomerMgmt,
            isOnMenu: true,
            subRoutes: {
                CustomerMgmtRoot: {
                    path: '',
                    element: <Pages.PageNotFound />
                },
                AccountLink: {
                    path: [
                        STATIC_PATH_NAME.ReferralLinks,
                        STATIC_PATH_NAME.ReferralLinksAlias
                    ],
                    title: 'common.navbar.clientMgmt.accountLink',
                    element: <Pages.AccountLink />,
                    isOnMenu: true
                },
                AccountLinkSubList: {
                    path: STATIC_PATH_NAME.ReferralLinkSubList,
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    element: <Pages.AccountLink />
                },
                AccountLinkDetail: {
                    path: 'referral-links/detail/:inviteUrlId',
                    title: 'common.navbar.clientMgmt.accountLink',
                    element: <Pages.AccountLinkDetail />
                },
                CreateAccountLink: {
                    path: 'referral-links/add/:tradeLoginId',
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    element: <Pages.CreateAccountLink />
                },
                EditAccountLink: {
                    path: 'referral-links/edit/:inviteUrlId',
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    element: <Pages.EditAccountLink />
                },
                Leads: {
                    path: STATIC_PATH_NAME.Leads,
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    title: 'common.navbar.clientMgmt.lead',
                    element: <Pages.Leads />,
                    isOnMenu: true,
                    menuOnPaths: leadDetailPaths
                },
                LeadDetail: {
                    path: leadDetailPaths,
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    element: <Pages.LeadDetail />,
                    subRoutes: getClientDetailRoutes(
                        CLIENT_TYPE_INFO.LEAD_CLIENT.key
                    )
                },
                RegisteredClients: {
                    path: STATIC_PATH_NAME.RegisteredClients,
                    permissionFor: [
                        USER_ROLE.MAJORDOMO,
                        USER_ROLE.MANAGER,
                        USER_ROLE.IB
                    ],
                    title: 'common.navbar.clientMgmt.registeredClient',
                    element: <Pages.RegisteredClients />,
                    isOnMenu: true,
                    menuOnPaths: registeredClientDetailPaths
                },
                RegisteredClientDetail: {
                    path: registeredClientDetailPaths,
                    permissionFor: [
                        USER_ROLE.MAJORDOMO,
                        USER_ROLE.MANAGER,
                        USER_ROLE.IB
                    ],
                    element: <Pages.RegisteredClientDetail />,
                    subRoutes: getClientDetailRoutes(
                        CLIENT_TYPE_INFO.REGISTERED_CLIENT.key
                    )
                },
                DepositedClients: {
                    path: STATIC_PATH_NAME.DepositedClients,
                    permissionFor: [
                        USER_ROLE.MAJORDOMO,
                        USER_ROLE.MANAGER,
                        USER_ROLE.IB
                    ],
                    title: 'common.navbar.clientMgmt.depositedClient',
                    element: <Pages.DepositedClients />,
                    subRoutes: clientDetailRoutes,
                    isOnMenu: true,
                    menuOnPaths: depositedClientDetailPaths
                },
                DepositedClientDetail: {
                    path: depositedClientDetailPaths,
                    permissionFor: [
                        USER_ROLE.MAJORDOMO,
                        USER_ROLE.MANAGER,
                        USER_ROLE.IB
                    ],
                    element: <Pages.DepositedClientDetail />,
                    subRoutes: getClientDetailRoutes(
                        CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key
                    )
                },
                ClientRelationTree: {
                    path: 'relation-map',
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    title: 'common.navbar.clientMgmt.relationTree',
                    element: <Pages.ClientRelationTree />,
                    isOnMenu: true
                }
            }
        },
        CommissionMgmt: {
            permissionFor: [
                USER_ROLE.MAJORDOMO,
                USER_ROLE.MANAGER,
                USER_ROLE.IB
            ],
            title: isManagerLevel(userRole)
                ? 'common.navbar.commissionMgmt.titleCommission'
                : 'common.navbar.commissionMgmt.titleIncome',
            icon: <IncomeIco />,
            path: isManagerLevel(userRole) ? '/my-commission' : '/my-revenue',
            isOnMenu: true,
            subRoutes: {
                CommissionMgmtRoot: {
                    path: '',
                    element: <Pages.PageNotFound />
                },
                CommissionReport: {
                    path: ['income-report'],
                    permissionFor: [USER_ROLE.IB],
                    title: 'common.navbar.commissionMgmt.incomeReport',
                    element: <Pages.CommissionReport />,
                    isOnMenu: true
                },
                CommissionDetail: {
                    path: isManagerLevel(userRole)
                        ? ['commission-details']
                        : ['income-details'],
                    permissionFor: [
                        USER_ROLE.MAJORDOMO,
                        USER_ROLE.MANAGER,
                        USER_ROLE.IB
                    ],
                    title: isManagerLevel(userRole)
                        ? 'common.navbar.commissionMgmt.commissionDetail'
                        : 'common.navbar.commissionMgmt.incomeDetail',
                    element: <Pages.CommissionDetail />,
                    isOnMenu: true
                }
            }
        },
        TraderReports: {
            title: 'common.navbar.traderReports.title',
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            icon: <TradeReportIco />,
            path: STATIC_PATH_NAME.Reports,
            isOnMenu: true,
            subRoutes: {
                TraderReportsMgmt: {
                    path: '',
                    element: <Pages.PageNotFound />
                },
                Orders: {
                    path: 'opening-orders',
                    title: 'common.navbar.traderReports.orders',
                    isOnMenu: true,
                    element: <Pages.Orders />
                },
                TradeHistory: {
                    path: STATIC_PATH_NAME.TradingHistory,
                    title: 'common.navbar.traderReports.tradeHistory',
                    isOnMenu: true,
                    element: <Pages.TradeHistory />
                },
                FundFlow: {
                    path: 'funds-history',
                    title: 'common.navbar.fundingMgmt.fundFlow',
                    permissionFor: [USER_ROLE.MAJORDOMO, USER_ROLE.MANAGER],
                    isOnMenu: true,
                    element: <Pages.FundFlow />
                },
                Statement: {
                    path: 'statement',
                    title: 'common.navbar.traderReports.statement',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    isOnMenu: true,
                    element: <Pages.Statement />
                },
                TradeProduct: {
                    path: 'products',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.traderReports.tradeProduct',
                    isOnMenu: true,
                    element: <Pages.TradeProduct />
                },
                RollingAndHolidaySchedule: {
                    path: 'rolling-and-holiday-schedule',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.traderReports.rollingAndHolidaySchedule',
                    isOnMenu: true,
                    element: <Pages.RollingAndHolidaySchedule />
                }
            }
        },
        FundingMgmt: {
            permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
            title: 'common.navbar.fundingMgmt.title',
            icon: <AssetMgmtIco />,
            path: STATIC_PATH_NAME.FundMgmt,
            isOnMenu: true,
            subRoutes: {
                FundingMgmtRoot: {
                    path: '',
                    element: <Pages.PageNotFound />
                },
                Deposit: {
                    path: STATIC_PATH_NAME.Deposits,
                    element: <Pages.Deposit />,
                    title: 'common.navbar.fundingMgmt.deposit',
                    isOnMenu: true
                },
                Withdraw: {
                    path: 'withdrawals',
                    element: <Pages.Withdraw />,
                    title: 'common.navbar.fundingMgmt.withdraw',
                    isOnMenu: true
                },
                Transfer: {
                    path: 'transfer',
                    element: <Pages.Transfer />,
                    title: 'common.navbar.fundingMgmt.transfer',
                    isOnMenu: true
                },
                FundFlow: {
                    path: 'funds-history',
                    title: 'common.navbar.fundingMgmt.fundFlow',
                    element: <Pages.FundFlow />,
                    isOnMenu: true
                }
            }
        },
        UserInfo: {
            path: STATIC_PATH_NAME.UserInfo,
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            title: 'common.navbar.fundingMgmt.title',
            isOnMenu: true,
            icon: <ProfileIco className="profileIco" />,
            subRoutes: {
                PersonalInformation: {
                    path: STATIC_PATH_NAME.PersonalInformation,
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.personalInformation',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    element: <Pages.PersonalInformation />,
                    isOnMenu: true
                },
                PasswordModification: {
                    path: 'change-password',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.passwordModification',
                    element: <Pages.PasswordModification />,
                    isOnMenu: true
                },
                // EmailModification: {
                //     path: 'email-modification',
                //     title: 'common.navbar.userInfo.emailModification',
                //     permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                //     element: <Pages.EmailModification />,
                //     isOnMenu: true
                // },
                RegistrationInformation: {
                    path: 'documents',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.registrationInformation',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    element: <Pages.RegistrationInformation />,
                    isOnMenu: true
                },
                BankCard: {
                    path: 'bank-card',
                    title: 'common.navbar.userInfo.bankCard',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    element: <Pages.BankCard />,
                    isOnMenu: true
                },
                Wallet: {
                    path: 'wallet',
                    title: 'common.navbar.userInfo.wallet',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    element: <Pages.Wallet />,
                    isOnMenu: true
                },
                Autochartist: {
                    path: 'autochartist',
                    isOnMenu: true,
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.tradingTool',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    element: <Pages.Autochartist />
                },
                Reward: {
                    path: STATIC_PATH_NAME.Reward,
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.rewardStore',
                    permissionFor: [USER_ROLE.IB, USER_ROLE.CUSTOMER],
                    subRoutes: RewardRoutes,
                    element: <Pages.Reward />,
                    isOnMenu: true,
                    menuOnPaths: Object.values(RewardRoutes).map(
                        ({ path }) => `${STATIC_PATH_NAME.Reward}/${path}`
                    )
                },
                Logout: {
                    path: 'logout',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    title: 'common.navbar.userInfo.logout',
                    isOnMenu: true
                }
            }
        },
        Status: {
            path: '/status',
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            subRoutes: {
                NotEnoughInformation: {
                    path: 'not-enough-information',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    element: <Pages.NotEnoughInformation />
                },
                AuditFailed: {
                    path: 'audit-failed',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    element: <Pages.AuditFailed />
                },
                Auditing: {
                    path: 'auditing',
                    isOnApprovingAccountMenu: true,
                    withoutRegStatusFallBack: true,
                    element: <Pages.Auditing />
                }
            }
        },
        Registration: {
            path: Object.entries(registerRoutes).map(([_, { path }]) => path),
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            element: <Pages.Registration />,
            icon: <Dashboard />
        },
        Authentication: {
            path: [
                AUTH_ROUTES.ResetPassword.path,
                AUTH_ROUTES.ResetTradePassword.path
            ],
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            element: <Pages.Authentication />
        },
        Main: {
            path: ['/', '/login'],
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true
        },
        DownloadPages: {
            path: '/download',
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true
        },
        PageNotFound: {
            path: '*',
            isOnApprovingAccountMenu: true,
            withoutRegStatusFallBack: true,
            element: <Pages.PageNotFound />
        }
    };
};
