import { Cancel } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';

import './FieldRightAdornmentContainer.scss';

const FieldRightAdornmentContainer = ({ value, clearValue }) => {
    return (
        value !== '' && (
            <InputAdornment className="fieldRightAdornment" position="end">
                <Box className="btnRightAdornment" onClick={clearValue}>
                    <Cancel className="cancelIco" />
                </Box>
            </InputAdornment>
        )
    );
};

export default FieldRightAdornmentContainer;
