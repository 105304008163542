import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { hasNoTradeLoginId } from 'utils/payloadParsing';
import { isManagerLevel } from 'utils/user/authInfos';
import { handleAsyncCall } from 'utils/utils';

const useSubManagerAndIB = (includeIb = false) => {
    const [managerList, setIBAndDirectManagerList] = useState([]);
    const { queryTradeLoginId, userRole, userId } = useSelector(
        (state) => state.user
    );

    const [isLoadSuccess, setIsLoadSuccess] = useState(false);

    useEffect(() => {
        if (!isManagerLevel(userRole) || hasNoTradeLoginId(queryTradeLoginId))
            return;

        const params = {
            includeIb,
            tradeLoginId: queryTradeLoginId
        };

        handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.getManagerSubTradeAccounts.apiCall(
                    userId,
                    params
                ),
            handleCallSuccess: (res) => {
                setIBAndDirectManagerList(res.data.list || []);
                setIsLoadSuccess(true);
            },
            handleCallFailureError: () => {}
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryTradeLoginId, userRole, userId]);

    return {
        managerList,
        isLoadSuccess
    };
};

export default useSubManagerAndIB;
