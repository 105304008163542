import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useDailyTotal = ({ dateRange, totalType }) => {
    const { startDate, endDate } = dateRange;
    const { userId, queryTradeLoginId } = useSelector((state) => state.user);

    const [dailyTotalData, setDailyTotalData] = useState({});

    const getDailyStat = useCallback(() => {
        const payload = {
            tradeLoginId: queryTradeLoginId,
            type: totalType,
            startDate,
            endDate
        };

        handleAsyncCall({
            asyncCall: () => API.ibMgmt.getDailyTotal.apiCall(userId, payload),
            handleCallSuccess: (res) => {
                setDailyTotalData(res.data);
            }
        });
    }, [endDate, queryTradeLoginId, startDate, totalType, userId]);

    useEffect(() => {
        const isDateRangeAvailable = startDate && endDate;
        isDateRangeAvailable && getDailyStat();
    }, [endDate, getDailyStat, startDate]);

    return { dailyTotalData, getDailyStat };
};

export default useDailyTotal;
