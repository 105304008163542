import axiosConfig from 'axios/axiosConfig';

const reward = {
    getRewardInfo: {
        apiCall: (userId) => axiosConfig.get(`/reward/user/${userId}/info`),
        queryKey: 'reward.getRewardInfo'
    },
    getMissionList: {
        apiCall: (userId) =>
            axiosConfig.get(`/reward/user/${userId}/mission/list`),
        queryKey: 'reward.getMissionList'
    },
    getLevelList: {
        apiCall: () => axiosConfig.get('/reward/level/list'),
        queryKey: 'reward.getLevelList'
    },
    getItemCategory: {
        apiCall: (userId) =>
            axiosConfig.get(`/reward/user/${userId}/item/category`),
        queryKey: 'reward.getItemCategory'
    },
    getItemList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/reward/user/${userId}/item/list`, {
                params
            }),
        queryKey: 'reward.getItemList'
    },
    getItemInfo: {
        apiCall: (userId, itemId) =>
            axiosConfig.get(`/reward/user/${userId}/item/${itemId}/info`),
        queryKey: 'reward.getItemInfo'
    },
    exchangeItem: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/reward/user/${userId}/item/exchange`, payload),
        queryKey: 'reward.exchangeItem'
    },
    getRewardRecordList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/reward/user/${userId}/point/record/list`, {
                params
            }),
        queryKey: 'reward.getRewardRecordList'
    },
    getRewardRecordDetail: {
        apiCall: (userId, recordId) =>
            axiosConfig.get(
                `/reward/user/${userId}/point/record/${recordId}/info`
            ),
        queryKey: 'reward.getRewardRecordDetail'
    }
};

export default reward;
