import { useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const useCityList = (initialStateId) => {
    const [stateId, setStateId] = useState(initialStateId);
    const { country } = useSelector((state) => state.user);

    const params = {
        stateId
    };

    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [API.common.getCityList.queryKey, country, stateId],
        apiCall: () => API.common.getCityList.apiCall(params),
        enabled: !!country && !!stateId
    });

    const getCities = (newStateId) => {
        if (!newStateId) return;
        setStateId(newStateId);
    };

    return {
        cities: isSuccess ? getResponseList(data) : [],
        getCities
    };
};

export default useCityList;
