import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';

import AppQRCodeImage from '../AppQRCodeImage/AppQRCodeImage';
import './QRCodeAccountLink.scss';
import { getQRCodeInfos } from './utils';

const QRCodeAccountLink = ({ codeValue, accountType }) => {
    const { t } = useTranslation();

    const { QRCodeFrameImg, commissionTypeNameKey, colorClassName } =
        getQRCodeInfos(accountType, t);
    const displayAccountType = `${commissionTypeNameKey}　`; // Fix for last chinese character being chopped by Windows Firefox

    return (
        <Box className="qrCodeBoardWrapper">
            <Box
                component="section"
                className={cx(['qrCodeWrapper', colorClassName])}>
                <Box className="qrCodeInnerWrapper">
                    <Box
                        className="qrCodeImageWrapper"
                        sx={{ background: `url(${QRCodeFrameImg})` }}>
                        <Box className="commissionTypeNameTxt">
                            {t(displayAccountType)}
                        </Box>
                        <Box className="commissionQRCodeWrapper">
                            {codeValue && (
                                <AppQRCodeImage
                                    codeValue={codeValue}
                                    includeMargin
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default QRCodeAccountLink;
