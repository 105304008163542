import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import SelectDropdownField from 'components/Fields/SelectDropdownField/SelectDropdownField';

import { useRatingCode } from 'hooks';

const SelectRatingCodeField = ({
    handleChange = () => {},
    fullWidth = true,
    wrapperWidth = '100%',
    ...props
}) => {
    const { t } = useTranslation();
    const { ratingCodes } = useRatingCode();

    if (isEmpty(ratingCodes)) {
        return null;
    }

    return (
        <SelectDropdownField
            label={t('common.sales.ratingCode')}
            name="ratingCode"
            fullWidth={fullWidth}
            wrapperWidth={'100%'}
            items={ratingCodes}
            itemLabelKey="value"
            itemValueKey="key"
            handleChange={(event) => {
                const ratingCode = event.target.value;
                handleChange(ratingCode);
            }}
            {...props}
        />
    );
};

export default SelectRatingCodeField;
