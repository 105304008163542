import { Box } from '@mui/material';
import cx from 'classnames';

import './FieldActionWrapper.scss';

const FieldActionWrapper = ({ className, children }) => {
    const [FieldComponent, BtnComponent] = children;
    return (
        <Box className={cx(['fieldActionWrapper', className])}>
            <Box className="fieldWrapper">{FieldComponent}</Box>

            <Box className="btnWrapper">{BtnComponent}</Box>
        </Box>
    );
};

export default FieldActionWrapper;
