import React from 'react';

import { COLOR } from 'constants/appInfos';
import {
    ACC_STATUS,
    DOC_STATUS,
    INFO_STATUS
} from 'constants/serverInfos/serverInfos';

import {
    CircleCheckBoldIco,
    CircleCrossIco,
    CircleExclamationMarkIco,
    CircleSubtractIco
} from 'assets/images/common';

const SIDE = 18;

export const getRegisterStatusInfos = (
    userAccStatus,
    docStatus,
    infoStatus
) => {
    let uploadCertInfo = {};
    let personalInfo = {};

    switch (docStatus) {
        default:
        case DOC_STATUS.NOT_SUBMITTED:
            uploadCertInfo = {
                StatusIcon: <CircleCrossIco width={SIDE} height={SIDE} />,
                i18nKey: 'common.user.accountStatus.status.notSubmitted'
            };
            break;
        case DOC_STATUS.PENDING:
            if (
                userAccStatus === ACC_STATUS.REJECTED ||
                userAccStatus === ACC_STATUS.MANAGER_REJECTED
            ) {
                uploadCertInfo = {
                    StatusIcon: (
                        <CircleSubtractIco width={SIDE} height={SIDE} />
                    ),
                    i18nKey: 'common.user.accountStatus.status.rejected'
                };
            } else {
                uploadCertInfo = {
                    StatusIcon: (
                        <CircleExclamationMarkIco width={SIDE} height={SIDE} />
                    ),
                    i18nKey: 'common.user.accountStatus.status.pending'
                };
            }
            break;
        case DOC_STATUS.APPROVED:
            uploadCertInfo = {
                StatusIcon: (
                    <CircleCheckBoldIco
                        width={SIDE}
                        height={SIDE}
                        fill={COLOR.GREEN}
                    />
                ),
                i18nKey: 'common.user.accountStatus.status.approved'
            };
            break;
    }

    switch (infoStatus) {
        default:
        case INFO_STATUS.NOT_SUBMITTED:
            personalInfo = {
                StatusIcon: <CircleCrossIco width={SIDE} height={SIDE} />,
                i18nKey: 'common.user.accountStatus.status.notSubmitted'
            };
            break;
        case INFO_STATUS.PENDING:
            personalInfo = {
                StatusIcon: (
                    <CircleExclamationMarkIco width={SIDE} height={SIDE} />
                ),
                i18nKey: 'common.user.accountStatus.status.pending'
            };
            break;
        case INFO_STATUS.APPROVED:
            personalInfo = {
                StatusIcon: (
                    <CircleCheckBoldIco
                        width={SIDE}
                        height={SIDE}
                        fill={COLOR.GREEN}
                    />
                ),
                i18nKey: 'common.user.accountStatus.status.approved'
            };
            break;
    }

    return { uploadCertInfo, personalInfo };
};
