import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

const useCacheableQuery = ({
    queryKeys = [],
    apiCall = () => {},
    enabled = true
}) => {
    const queryResult = useQuery(queryKeys, apiCall, {
        staleTime: 5 * 1000 * 60,
        enabled: !isEmpty(queryKeys) && enabled
    });

    return queryResult;
};

export default useCacheableQuery;
