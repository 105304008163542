import axiosConfig from 'axios/axiosConfig';

const ibMgmtAPI = {
    getDailyTotal: {
        apiCall: (userId, params) =>
            axiosConfig.get(`user/${userId}/dailyTotal`, { params }),
        queryKey: 'ibMgmt.getDailyTotal'
    },
    getDailyStat: {
        apiCall: (userId, params) =>
            axiosConfig.get(`user/${userId}/dailyStat`, { params }),
        queryKey: 'ibMgmt.getDailyStat'
    }
};

export default ibMgmtAPI;
