import storage from 'local-storage-fallback';
import isEqual from 'lodash/isEqual';
import SecureLS from 'secure-ls';

if (!('localStorage' in window)) {
    window.localStorage = storage;
}

export const KEY = {
    TOKEN: 'TOKEN',
    CREDENTIAL: 'CREDENTIAL',
    LANG: 'i18nextLng'
};

const ls = new SecureLS({
    encodingType: 'des',
    isCompression: false,
    encryptionSecret: 'my-secret-key'
});

export const setItemSync = (key, data, encrypt) => {
    try {
        if (encrypt) ls.set(key, data);
        else localStorage.setItem(key, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const getItemSync = (key, fallbackValue, encrypt) => {
    let data = fallbackValue;
    try {
        if (encrypt) return ls.get(key) || fallbackValue;
        else return localStorage.getItem(key) || fallbackValue;
    } catch (e) {
        return data;
    }
};

export const setItemAsync = (key, data, encrypt) =>
    new Promise((resolve) => {
        setItemSync(key, data, encrypt);
        let checkingInterval = setInterval(() => {
            const localStorageValue = getItemSync(key, {}, encrypt);
            if (isEqual(localStorageValue, data)) {
                clearInterval(checkingInterval);
                resolve();
            } else {
                setItemSync(key, data, encrypt);
            }
        }, 500);
    });
