import React from 'react';
import { useTranslation } from 'react-i18next';

import WalletForm from 'pages/UserInfo/Wallet/WalletForm/WalletForm';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useOpenDialog, useOpenSnackbar } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useDialogAddWallet = ({
    showCheckButton = false,
    onSubmitSuccess = () => {}
}) => {
    const { t } = useTranslation();
    const { openErrorSnackbar } = useOpenSnackbar();

    const addWalletAccount = (formValues, handleSubmitSuccessCallBack) => {
        const payload = {
            accountName: formValues.accountName,
            walletChain: formValues.walletChain,
            walletAddress: formValues.walletAddress
        };

        return handleAsyncCall({
            asyncCall: () => API.user.addWalletAccount.apiCall(payload),
            handleCallSuccess: () => {
                onSubmitSuccess();
                handleSubmitSuccessCallBack(payload.walletAddress);
            },
            handleCallFailureError: (res) => openErrorSnackbar(res.message)
        });
    };

    const {
        openDialog: openAddWalletDialog,
        closeDialog: closeAddWalletDialog
    } = useOpenDialog({
        title: t('userInfo.wallet.addWallet'),
        name: 'addWalletDialog confirmDialog',
        children: (
            <WalletForm
                handleSubmit={addWalletAccount}
                handleCloseDialog={() => closeAddWalletDialog()}
                showCheckButton={showCheckButton}
                infoAccessType={INFO_ACCESS_TYPE.NEW}
            />
        )
    });

    return {
        openAddWalletDialog,
        closeAddWalletDialog
    };
};

export default useDialogAddWallet;
