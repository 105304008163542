import React from 'react';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

const ColItem = ({ label, value = '---', isValueBottom = true, className }) => {
    const Value = <Typography className="valueTxt">{value}</Typography>;

    return (
        <Box className={cx(['colItemWrapper', className])}>
            {!isValueBottom && Value}
            <Typography className="labelTxt">{label}</Typography>
            {isValueBottom && Value}
        </Box>
    );
};

export default ColItem;
