import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import { Close } from '@mui/icons-material';
import { Box, Divider, SwipeableDrawer } from '@mui/material';
import cx from 'classnames';

import { ButtonLiveChat, LanguageSwitch } from 'components';

import { useAuthStatus } from 'hooks';
import { getRouteInfos } from 'routes/routes';

import { checkIsUserAuthorized } from 'utils/utils';

import './AppNavbar.scss';
import NavBtn from './components/NavBtn/NavBtn';
import { LogoWhite } from 'assets/images/common';

const AppNavbar = () => {
    const { userRole } = useSelector((state) => state.user);
    const [menuOpen, setMenuOpen] = useState(false);
    const { isApproving } = useAuthStatus();

    const routeInfos = getRouteInfos({ userRole });

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Box component="nav">
            <Box className="navWrapper">
                <Link className="logo" to="/">
                    <img src={LogoWhite} alt="logo" />
                </Link>
                <Breakpoint laptop down>
                    <Box
                        className={cx('menuBtn', { menuOpen: menuOpen })}
                        onClick={handleMenuToggle}>
                        <Box component="span" className="line1"></Box>
                        <Box component="span" className="line2"></Box>
                        <Box component="span" className="line3"></Box>
                    </Box>
                </Breakpoint>
                <Breakpoint desktop only>
                    <Box className="navItmList">
                        {Object.entries(routeInfos).map(([navName, item]) => {
                            const {
                                isOnMenu,
                                permissionFor,
                                isOnApprovingAccountMenu
                            } = item;

                            if (isApproving && !isOnApprovingAccountMenu) {
                                return null;
                            }

                            const isShowPage = checkIsUserAuthorized(
                                permissionFor,
                                userRole
                            );

                            return (
                                isOnMenu &&
                                isShowPage && (
                                    <Box
                                        className="navItm"
                                        key={`nav${navName}`}>
                                        <NavBtn navInfo={item} />
                                    </Box>
                                )
                            );
                        })}
                    </Box>
                </Breakpoint>
            </Box>
            <Breakpoint laptop down>
                <SwipeableDrawer
                    anchor="left"
                    variant="temporary"
                    open={menuOpen}
                    onOpen={handleMenuToggle}
                    onClose={handleMenuToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    className="menuDrawer">
                    <Close
                        className="drawerCloseBtn"
                        onClick={handleMenuToggle}
                    />
                    <Box className="navItmList">
                        {Object.entries(routeInfos).map(([navName, item]) => {
                            const { isOnMenu, permissionFor } = item;

                            const isShowPage = checkIsUserAuthorized(
                                permissionFor,
                                userRole
                            );

                            return (
                                isOnMenu &&
                                isShowPage && (
                                    <Box
                                        className="navItm"
                                        key={`nav${navName}`}>
                                        <NavBtn
                                            navName={navName}
                                            navInfo={item}
                                            handleMenuToggle={handleMenuToggle}
                                        />
                                    </Box>
                                )
                            );
                        })}
                    </Box>
                    <Divider variant="middle" className="divider" />
                    <Box className="navItm">
                        <ButtonLiveChat
                            widgetClassName="appHeaderWidget"
                            handleMenuToggle={handleMenuToggle}
                        />
                    </Box>
                    <Box className="navItm">
                        <LanguageSwitch />
                    </Box>
                </SwipeableDrawer>
            </Breakpoint>
        </Box>
    );
};

export default AppNavbar;
