import { useState } from 'react';

import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const FIELD_NAME = 'tradingProducts';

const useTradingProducts = (
    initAccountType,
    initSymbol,
    isSymbolRequired = true
) => {
    const [params, setParams] = useState({
        accountType: initAccountType,
        symbolGroup: initSymbol
    });

    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [FIELD_NAME, params.accountType, params.symbolGroup],
        apiCall: () => API.traderReports.getMt5ProductList.apiCall(params),
        enabled: isSymbolRequired ? Boolean(params.symbolGroup) : true
    });

    const getTradingProducts = (accountType, symbolGroup) => {
        setParams({
            accountType,
            symbolGroup
        });
    };

    return {
        tradingProducts: isSuccess ? getResponseList(data) : [],
        getTradingProducts
    };
};

export default useTradingProducts;
