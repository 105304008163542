import { useCopyToClipboard } from 'hooks';

import ButtonIco from '../ButtonIco/ButtonIco';

const ButtonIcoCopy = ({
    copyValue,
    hoverTitle = '',
    className = '',
    BtnIco
}) => {
    const { handleCopy } = useCopyToClipboard();

    return (
        <ButtonIco
            hoverTitle={hoverTitle}
            className={className}
            onClick={() => {
                handleCopy(copyValue);
            }}
            BtnIco={BtnIco}
        />
    );
};

export default ButtonIcoCopy;
