import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PAGE_FULL_STATIC_PATH } from 'constants/routes';
import { updateUserBasicInfo } from 'store/user/reducer';

const useUpdateUserBasicInfo = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { userId } = useSelector((state) => state.user);
    const { isFillingInformation } = useSelector((state) => state.registration);
    const isFirstMount = useRef(true);

    useEffect(() => {
        const isSpecificPath =
            pathname.includes(PAGE_FULL_STATIC_PATH.PersonalInformation) ||
            pathname.includes('/home');

        if (
            userId &&
            (isFirstMount.current ||
                isFillingInformation ||
                (!isFillingInformation && isSpecificPath))
        ) {
            updateUserBasicInfo(userId, dispatch);
            isFirstMount.current = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isFillingInformation, pathname]);

    return;
};

export default useUpdateUserBasicInfo;
