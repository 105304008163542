import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

const AppFieldWrapper = ({ className, children, width = 'auto' }) => {
    return (
        <Box className={cx(['appFieldWrapper', className])} sx={{ width }}>
            {children}
        </Box>
    );
};

export default AppFieldWrapper;
