import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import StepIndex from './StepIndex/StepIndex';
import './StepperDot.scss';

const StepperDot = ({
    stepInfos,
    currRouteName,
    textColor = 'black',
    userAccStatus = 1,
    isStatus
}) => {
    const currStepIndex = stepInfos.findIndex(
        ({ stepRouteName }) => currRouteName === stepRouteName
    );

    return (
        <Box className="stepper stepperDot">
            {stepInfos.map(({ stepName }, index) => {
                const isCompletedStep = index < currStepIndex;
                const isCurrStep = index === currStepIndex;
                return (
                    <Box
                        key={index}
                        className={cx([
                            'step',
                            { currStep: isCurrStep },
                            { completedStep: isCompletedStep }
                        ])}>
                        <Box className="stepHorizontalLine" />
                        <Box className="stepConnector">
                            <StepIndex
                                isStepHighLight={isCompletedStep || isCurrStep}
                                isCurrStep={isCurrStep}
                                isCompletedStep={isCompletedStep}
                                isStatus={isStatus}
                                userAccStatus={userAccStatus}
                                index={index}
                            />
                        </Box>

                        <Box className={cx(['stepName', textColor])}>
                            {stepName}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default StepperDot;
