import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMore } from '@mui/icons-material';
import { MenuItem, Select, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { VALUE_UNSELECTED } from '../../constants';
import { getIsUnselected } from '../../utils';
import './MuiSelect.scss';

const MuiSelect = ({
    name,
    fieldValue,
    defaultValue,
    wrapperProps,
    itemLabelKey,
    itemValueKey,
    items,
    handleChange,
    handleBlur,
    onClick,
    renderLabel,
    withoutFormikHook,
    displayEmpty,
    placeholder,
    disabled
}) => {
    const { t } = useTranslation();

    const selectRef = useRef(null);
    const isUnselected = getIsUnselected(fieldValue);
    const [selectDropdownWidth, setSelectDropdownWidth] = useState(0);

    useEffect(() => {
        if (!isEmpty(selectRef.current)) {
            setSelectDropdownWidth(selectRef.current.offsetWidth);
        }
    }, []);

    return (
        <Select
            className="muiSelect"
            ref={selectRef}
            autoComplete="off"
            MenuProps={{
                className: 'selectMenuModal',
                MenuListProps: {
                    sx: {
                        minWidth: selectDropdownWidth ?? 0
                    }
                }
            }}
            value={isUnselected ? VALUE_UNSELECTED : fieldValue}
            defaultValue={defaultValue}
            onChange={(event) => {
                handleChange && handleChange(event);
                !withoutFormikHook && wrapperProps.handleChange(event);
            }}
            onBlur={(event) => {
                handleBlur && handleBlur(event);
                !withoutFormikHook && wrapperProps.handleBlur(event);
            }}
            displayEmpty={displayEmpty}
            name={name}
            IconComponent={ExpandMore}
            onClick={onClick}>
            {isUnselected && (
                <MenuItem disabled value="" className="placeholder">
                    <Typography className="placeholderText">
                        {disabled
                            ? ''
                            : placeholder === ''
                            ? t('common.placeholder.pleaseSelect')
                            : placeholder}
                    </Typography>
                </MenuItem>
            )}
            {items.map((item, index) => (
                <MenuItem
                    className="selectMenuItem"
                    key={index}
                    value={itemValueKey ? item[itemValueKey] : item}>
                    {renderLabel ? renderLabel(item) : item[itemLabelKey]}
                </MenuItem>
            ))}
        </Select>
    );
};

export default MuiSelect;
