import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import './NoContentBoard.scss';
import { NoContentImg } from 'assets/images/common';

const NoContentBoard = ({ labelType }) => {
    const { t } = useTranslation();

    return (
        <Box className="noContentBoard">
            <img src={NoContentImg} className="image" alt="noContentImage" />
            <Box className="label">
                {labelType === 'noData'
                    ? t('common.status.noData')
                    : t('common.status.noInformation')}
            </Box>
        </Box>
    );
};

export default NoContentBoard;
