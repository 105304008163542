import React from 'react';

import FieldFormikWrapper from 'components/Fields/FieldWrapper/FieldFormikWrapper';
import FieldPlainWrapper from 'components/Fields/FieldWrapper/FieldPlainWrapper';

import { DATE_FORMAT } from 'constants/dateFormats';
import { DATE_DEFINE, DATE_RANGE_UNSELECT } from 'constants/dateInfos';

import { dateObjToDateString } from 'utils/dateConversion';

import { checkIsDisabledCalendar } from '../utils';

const DateRangePickerWrapper = ({
    startDateName,
    endDateName,
    withoutFormikHook,
    value,
    onChange,
    setAnchorEl,
    children
}) => {
    const FieldWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;

    const parseChildrenProps = (startDateProps, endDateProps) => {
        const startDateString = value?.startDate || startDateProps.value;
        const endDateString = value?.endDate || endDateProps.value;

        const disabledCalendar = checkIsDisabledCalendar(
            startDateString,
            endDateString
        );

        const calendarDateRange = {
            startDate: disabledCalendar
                ? DATE_DEFINE.UNSELECT_DATE.dateObj
                : new Date(startDateString),
            endDate: disabledCalendar
                ? DATE_DEFINE.UNSELECT_DATE.dateObj
                : new Date(endDateString),
            key: 'selection'
        };

        const handleDateRangeChange = (item) => {
            const { startDate, endDate } = item.selection;

            let newStartDateString = dateObjToDateString(
                startDate,
                DATE_FORMAT.yMd
            );
            let newEndDateString = dateObjToDateString(
                endDate,
                DATE_FORMAT.yMd
            );

            const disabledCalendarUpdated = checkIsDisabledCalendar(
                newStartDateString,
                newEndDateString
            );

            if (disabledCalendarUpdated) {
                newStartDateString = DATE_RANGE_UNSELECT[0];
                newEndDateString = DATE_RANGE_UNSELECT[1];
            }

            if (withoutFormikHook) {
                typeof onChange === 'function' &&
                    onChange({
                        startDate: newStartDateString,
                        endDate: newEndDateString
                    });
            } else {
                startDateProps.setValue(newStartDateString);
                endDateProps.setValue(newEndDateString);
            }

            if (disabledCalendarUpdated) {
                setAnchorEl(null);
            }

            return;
        };

        return {
            startDateString,
            endDateString,
            calendarDateRange,
            handleDateRangeChange,
            disabledCalendar
        };
    };

    return (
        <FieldWrapper name={startDateName}>
            {(startDateProps) => (
                <FieldWrapper name={endDateName}>
                    {(endDateProps) => {
                        const childrenProps = parseChildrenProps(
                            startDateProps,
                            endDateProps
                        );
                        return children(childrenProps);
                    }}
                </FieldWrapper>
            )}
        </FieldWrapper>
    );
};

export default DateRangePickerWrapper;
