import React from 'react';

import { TableHead, TableRow } from '@mui/material';
import cx from 'classnames';

import { CELL_GROUP_STICKY_TYPE } from 'constants/appTableInfos';

import AppTableCell from '../AppTableCell/AppTableCell';
import AppTableCellGroupWrapper from '../AppTableCellGroupWrapper/AppTableCellGroupWrapper';

const AppTableHead = ({ tableCellGroupInfos, hasRowCollapsible }) => {
    return (
        <TableHead className="appTableHead">
            <TableRow className="appTableHeadRow">
                {tableCellGroupInfos.map((tableCellGroup, index) => {
                    const cellGroupType = tableCellGroup[0].stickyType;

                    const isStickyCell =
                        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_LEFT ||
                        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_RIGHT;
                    return (
                        <AppTableCellGroupWrapper
                            key={index}
                            cellGroupType={cellGroupType}>
                            {tableCellGroup.map(
                                (
                                    { field, label, className, ...props },
                                    index
                                ) => {
                                    return (
                                        <AppTableCell
                                            field={field}
                                            isStickyCell={isStickyCell}
                                            key={index}
                                            classNames={cx([
                                                'tableCellHead',
                                                className
                                            ])}
                                            {...props}>
                                            {label}
                                        </AppTableCell>
                                    );
                                }
                            )}
                            {hasRowCollapsible && (
                                <AppTableCell classNames="tableCellHead" />
                            )}
                        </AppTableCellGroupWrapper>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default AppTableHead;
