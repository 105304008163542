import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isArray from 'lodash/isArray';

import API from 'services';
import { getAccountLinkName } from 'utils/serverInfos';
import { handleAsyncCall } from 'utils/utils';

const useTradeAccountDetailList = (
    userId,
    tradeLoginId,
    withConvertedLinkName = true
) => {
    const { t } = useTranslation();
    const [tradeAccountDetails, setTradeAccountDetails] = useState([]);

    const getTradeAccountDetails = () => {
        const payload = {
            tradeLoginId
        };

        handleAsyncCall({
            asyncCall: () =>
                API.customerMgmt.getTradeAccounts.apiCall(userId, payload),
            handleCallSuccess: (res) => {
                if (isArray(res.data.list) && withConvertedLinkName) {
                    res.data.list = res.data.list.map(
                        ({ inviteUrlName, ...accountInfos }) => {
                            return {
                                ...accountInfos,
                                inviteUrlName,
                                accountLinkName: getAccountLinkName(
                                    inviteUrlName,
                                    accountInfos.systemInviteUrl,
                                    t
                                )
                            };
                        }
                    );
                }
                setTradeAccountDetails(res.data.list ?? []);
            },
            handleCallFailure: () => {}
        });
    };

    useEffect(() => {
        getTradeAccountDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, tradeLoginId]);

    return { tradeAccountDetails, getTradeAccountDetails };
};

export default useTradeAccountDetailList;
