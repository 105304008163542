import { Card, CardContent } from '@mui/material';
import cx from 'classnames';

import { AppCardHeader } from 'layouts';

import './AppCard.scss';

const AppCard = ({
    title,
    className = '',
    RightActionGroup = null,
    children,
    fullHeight = true,
    cardContentClassName='cardContentMargin'
}) => {
    return (
        <Card
            display="flex"
            className={cx(['card', { fullHeight: fullHeight }, className])}>
            {(title || RightActionGroup) && (
                <AppCardHeader
                    title={title}
                    RightActionGroup={RightActionGroup}
                />
            )}
            <CardContent className={cx(['cardContent', cardContentClassName])}>{children}</CardContent>
        </Card>
    );
};

export default AppCard;
