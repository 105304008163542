import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import './GradientCard.scss';

const GradientCard = ({
    className = '',
    bgColor,
    animation = false,
    children,
    ...props
}) => {
    return (
        <Box
            className={cx(['gradientCard', bgColor, { animation: animation }])}
            {...props}>
            <Box className={cx(['cardContent', className])}>{children}</Box>
        </Box>
    );
};

export default GradientCard;
