import authentication from './authentication';
import commissionMgmt from './commissionMgmt';
import common from './common';
import contents from './contents';
import customerMgmt from './customerMgmt';
import dashboard from './dashboard';
import fundingMgmt from './fundingMgmt';
import ibMgmt from './ibMgmt';
import registration from './registration';
import report from './report';
import reward from './reward';
import system from './system';
import thirdParty from './thirdParty';
import traderReports from './traderReports';
import user from './user';

class APICall {
    authentication = authentication;
    registration = registration;
    dashboard = dashboard;
    customerMgmt = customerMgmt;
    commissionMgmt = commissionMgmt;
    fundingMgmt = fundingMgmt;
    traderReports = traderReports;
    user = user;
    common = common;
    system = system;
    ibMgmt = ibMgmt;
    contents = contents;
    thirdParty = thirdParty;
    reward = reward;
    report = report;
}

const API = new APICall();

export default API;
