export const AUTH_PROCESS = {
    LOGIN: { name: 'LOGIN', path: '/login' },
    FORGOT_PASSWORD: {
        name: 'FORGOT_PASSWORD',
        path: '/forgot-password'
    }
};

export const USER_ROLE = {
    MAJORDOMO: 'MAJORDOMO',
    MANAGER: 'MANAGER',
    IB: 'IB',
    CUSTOMER: 'CUSTOMER'
};
