import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import isArray from 'lodash/isArray';

import { ACCOUNT_LINK_LIST_TYPE } from 'pages/CustomerMgmt/AccountLinks/constants';

import useOpenSnackbar from 'hooks/useOpenSnackbar';

import { getAccountLinkName } from 'utils/serverInfos';
import { handleAsyncCall } from 'utils/utils';

const useAccountLinkList = (
    listType = ACCOUNT_LINK_LIST_TYPE.SELF_LIST.type,
    withConvertedLinkName = true
) => {
    const { t } = useTranslation();
    const { userId } = useSelector((state) => state.user);
    const [accountLinkData, setAccountLinkData] = useState({});
    const { openErrorSnackbar } = useOpenSnackbar();

    const getAccountLinkList = ({
        tradeLoginId,
        searchTradeLoginId,
        ...payload
    }) => {
        const apiCall = ACCOUNT_LINK_LIST_TYPE[listType]?.apiCall;

        const parsedPayload = {
            tradeLoginId,
            searchTradeLoginId,
            ...payload
        };

        handleAsyncCall({
            asyncCall: () => apiCall && apiCall(userId, parsedPayload),
            handleCallSuccess: (res) => {
                if (isArray(res.data.list) && withConvertedLinkName) {
                    res.data.list = res.data.list.map(
                        ({ name, ...accountLinkInfos }) => {
                            return {
                                ...accountLinkInfos,
                                name,
                                accountLinkName: getAccountLinkName(
                                    name,
                                    accountLinkInfos.system,
                                    t
                                )
                            };
                        }
                    );
                }
                setAccountLinkData(res.data ?? {});
            },
            handleCallFailureError: (res) => {
                setAccountLinkData({});
                res.message && openErrorSnackbar(res.message);
            }
        });
    };

    return {
        getAccountLinkList,
        accountLinkData
    };
};

export default useAccountLinkList;
