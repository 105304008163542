import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { VALUE_APP_SELECT_ALL } from 'constants/parseValues';

export const parseArrayToQueryString = (array) => {
    return array.toString();
};

export const hasNoTradeLoginId = (tradeLoginId) => {
    const isFalsyValue = !tradeLoginId;
    const isSelectAll = tradeLoginId === VALUES_ALL_TRADE_LOGIN_IDS.APP;

    return !isSelectAll && isFalsyValue;
};

export const parseTradeLoginIdForApiCall = (tradeLoginId) => {
    return tradeLoginId === VALUES_ALL_TRADE_LOGIN_IDS.APP
        ? VALUES_ALL_TRADE_LOGIN_IDS.API_PAYLOAD
        : tradeLoginId;
};

export const parseValuesArrayToString = (payload = {}) => {
    let parsedValues = {};

    Object.entries(payload).forEach(([key, value]) => {
        const parsedValue = Array.isArray(value)
            ? parseArrayToQueryString(value)
            : value;

        parsedValues[key] = parsedValue;
    });

    return parsedValues;
};

export const parseValuesSelectAll = (payload = {}) => {
    let parsedValues = {};
    Object.entries(payload).forEach(([key, value]) => {
        parsedValues[key] = value === VALUE_APP_SELECT_ALL ? null : value;
    });

    return parsedValues;
};
