import { useContext } from 'react';

import RowCollapsibleContext from 'layouts/AppTableContainer/RowCollapsibleProvider/RowCollapsibleContext';

import { CELL_GROUP_STICKY_TYPE } from 'constants/appTableInfos';

import AppTableCell from '../AppTableCell/AppTableCell';
import AppTableCellGroupWrapper from '../AppTableCellGroupWrapper/AppTableCellGroupWrapper';
import DynamicArrowIco from '../DynamicArrowIco/DynamicArrowIco';

const AppTableCellGroup = ({ rowIndex, tableCellGroup = [], rowData = [] }) => {
    const cellGroupType = tableCellGroup[0].stickyType;
    const renderValue = (field) => rowData[field] ?? '---';

    const isStickyCell =
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_LEFT ||
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_RIGHT;
    const stickyCellClass =
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_LEFT
            ? tableCellGroup[0].className
            : cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_RIGHT
            ? tableCellGroup[tableCellGroup.length - 1].className
            : '';
    const stickyCellIndex =
        cellGroupType === CELL_GROUP_STICKY_TYPE.STICKY_LEFT
            ? 0
            : tableCellGroup.length - 1;
    const stickyCellClassName =
        typeof stickyCellClass === 'function'
            ? stickyCellClass({ rowData, colIndex: stickyCellIndex, rowIndex })
            : stickyCellClass;

    const {
        hasRowCollapsible,
        toggleRowCollapsible,
        checkCollapsibleExpanded
    } = useContext(RowCollapsibleContext);

    return (
        <AppTableCellGroupWrapper
            cellGroupType={cellGroupType}
            isStickyCell={isStickyCell}
            className={stickyCellClassName}>
            {tableCellGroup.map(
                (
                    { field, className, renderCell, align = 'left', ...props },
                    colIndex
                ) => {
                    return (
                        <AppTableCell
                            field={field}
                            classNames={
                                typeof className === 'function'
                                    ? className({ rowData, colIndex, rowIndex })
                                    : className
                            }
                            isStickyCell={isStickyCell}
                            key={colIndex}
                            align={align}
                            {...props}>
                            {typeof renderCell === 'function'
                                ? renderCell(rowData, colIndex, rowIndex)
                                : renderValue(field)}
                        </AppTableCell>
                    );
                }
            )}
            {hasRowCollapsible && (
                <AppTableCell className="arrowIcoCell">
                    <DynamicArrowIco
                        isPointingRight={!checkCollapsibleExpanded(rowIndex)}
                        onClick={() => toggleRowCollapsible(rowIndex)}
                    />
                </AppTableCell>
            )}
        </AppTableCellGroupWrapper>
    );
};

export default AppTableCellGroup;
