import { getI18n } from 'react-i18next';

import { Typography } from '@mui/material';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import { DATE_FORMAT } from 'constants/dateFormats';
import { LANGUAGE_INFO } from 'constants/languageInfo';
import { WEEKDAY } from 'constants/weekdays';

export const dateObjToUnix = (dateObj) => {
    return Math.floor(dateObj.getTime());
};

export const unixToDateObj = (dateUnix) => {
    return new Date(dateUnix);
};

export const unixToDateString = (
    dateUnix,
    dateFormat = DATE_FORMAT.yMd,
    timeZone = null,
    customOptions = {}
) => {
    let { localeDetection = false, ...options } = customOptions;

    if (localeDetection) {
        options.locale = LANGUAGE_INFO[getI18n().language]?.dateLocale;
    }

    return timeZone === 0
        ? formatInTimeZone(dateUnix, '', dateFormat, options) // UTC time
        : timeZone === 1
        ? formatInTimeZone(dateUnix, 'Europe/London', dateFormat, options) // London time
        : format(new Date(dateUnix), dateFormat, options); // local time
};

export const unixToTime = (dateUnix, dateFormat = DATE_FORMAT.yMdHm) => {
    const dateString = unixToDateString(dateUnix, dateFormat);
    return dateString.split(' ')[1] ?? dateString;
};

export const dateObjToDateString = (dateObj, dateFormat) => {
    const dateUnix = Math.floor(dateObj.getTime());

    return unixToDateString(dateUnix, dateFormat);
};

export const formattedStringToUnix = (dateString, format = DATE_FORMAT.yMd) => {
    return dateObjToUnix(parse(dateString, format, new Date()));
};

export const calDayDifferenceByDayStrings = (dateRange) => {
    const [startDate, endDate] = dateRange;

    const unixDaysToDays = (unixDays) =>
        Math.floor(unixDays / 1000 / 60 / 60 / 24);

    return (
        unixDaysToDays(
            formattedStringToUnix(endDate) - formattedStringToUnix(startDate)
        ) + 1
    );
};

export const formatDateToStringFromAPI = (
    dateString,
    isTwoLines = true,
    format = [DATE_FORMAT.yMd, DATE_FORMAT.Hms]
) => {
    if (!dateString) return '---';

    const ymdString = unixToDateString(dateString, format[0], 0);
    const hmsString = unixToDateString(dateString, format[1], 0);

    return (
        <>
            <Typography>
                {ymdString} {!isTwoLines && hmsString}
            </Typography>
            {isTwoLines && <Typography>{hmsString}</Typography>}
        </>
    );
};

export const getWeekdayFromDateString = (dateString, t) => {
    const dateObject = new Date(dateString);
    const dayKey = dateObject.getDay();
    const weekday = Object.values(WEEKDAY).find(
        (weekday) => weekday.dayKey === dayKey
    );

    return t(weekday.i18nKey);
};

export const getDateStringFromIsoDateString = (
    isoDate,
    dateFormat = DATE_FORMAT.yMd
) => {
    const newDate = new Date(parseISO(isoDate));
    return dateObjToDateString(newDate, dateFormat);
};
