import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useTradeTypes } from 'hooks';

export const FILTER_TRADE_TYPES = 'tradeTypes';

const useFilterTradeTypes = (filterName = FILTER_TRADE_TYPES) => {
    const { t } = useTranslation();
    const { tradeTypes } = useTradeTypes();

    const itemSelectAll = {
        key: '',
        value: t('common.general.all')
    };

    const tradeTypesFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.filter.type'),
        items: [itemSelectAll, ...tradeTypes],
        itemValueKey: 'key',
        itemLabelKey: 'value',
        defaultValue: ''
    };

    return { tradeTypesFilterInfo };
};

export default useFilterTradeTypes;
