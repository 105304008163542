import { Navigate } from 'react-router-dom';

import Pages from 'pages';
import AUTH_ROUTES from 'pages/Authentication/routes';
import REGISTER_ROUTES from 'pages/Registration/routes';

const routes = {
    Authentication: {
        path: Object.values(AUTH_ROUTES).map(({ path }) => path),
        element: <Pages.Authentication />
    },
    Registration: {
        path: [
            REGISTER_ROUTES.CreateAccount.path,
            REGISTER_ROUTES.UploadCertificate.path,
            REGISTER_ROUTES.CreateTradingAccount.path,
            REGISTER_ROUTES.Completed.path
        ],
        element: <Pages.Registration />
    },
    Home: {
        path: ['/', '*'],
        element: <Navigate to={AUTH_ROUTES.Login.path} />
    },
    PublicMsg: {
        path: '/public-message',
        element: <Pages.PublicMessage />
    }
};

export default routes;
