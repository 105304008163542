export const getElementAsync = (elementGetter) =>
    new Promise((resolve) => {
        const getterInterval = setInterval(() => {
            const element = elementGetter();

            if (element) {
                clearInterval(getterInterval);
                resolve(element);
            }
        }, 50);
    });
