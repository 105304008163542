import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContentRemind } from 'components';

import { useOpenDialog } from 'hooks';

const useDialogResetCommission = (setAccountLinkFormValues) => {
    const { t } = useTranslation();

    const resetAccountLinkValues = () => {
        setAccountLinkFormValues &&
            setAccountLinkFormValues((oldValues) => ({
                ...oldValues,
                setting: {}
            }));
    };

    const { openDialog, closeDialog } = useOpenDialog({
        name: 'dialogResetCommission',
        title: t('common.general.reminder'),
        children: (
            <DialogContentRemind
                reminderMessage={t(
                    'customerMgmt.accountLink.dialogResetCommission.remind'
                )}
                cancelButtonLabel={t('common.action.cancel')}
                confirmButtonLabel={t('common.action.confirm')}
                onCancel={() => closeDialog()}
                onConfirm={() => {
                    resetAccountLinkValues();
                    closeDialog();
                }}
            />
        )
    });

    return {
        openDialogResetCommission: openDialog
    };
};

export default useDialogResetCommission;
