import { useFormikContext } from 'formik';

import { useFormikFieldProps } from 'hooks';

const FieldFormikWrapper = ({ children, ...props }) => {
    const fieldProps = useFormikFieldProps(props.name, props.helperTextProps);
    const formikProps = useFormikContext();
    const formikFieldProps = {
        helperText: fieldProps.helperText,
        error: fieldProps.error,
        value: fieldProps.value,
        handleChange: (event) => {
            fieldProps.onChange(event);
        },
        handleBlur: (event) => {
            fieldProps.onBlur(event);
        },
        clearValue: () => fieldProps.setValue(''),
        setValue: (value) => fieldProps.setValue(value)
    };

    return <>{children({ ...props, ...formikFieldProps, ...formikProps })}</>;
};

export default FieldFormikWrapper;
