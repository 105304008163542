import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import { Box } from '@mui/material';

import { AppFooter, AppHeader, AppNavbar } from 'layouts';
import { Loading } from 'pages';

import {
    useAccStatusRole,
    useSnackbarOnNetworkDisconnection,
    useUpdateUserBasicInfo
} from 'hooks';
import useRegistrationStatus from 'hooks/useRegistrationStatus';
import { getRouteInfos } from 'routes/routes';
import { setIsFillingInformation } from 'store/registration/slice';

import AppRouter from 'utils/routers/AppRouter/AppRouter';
import { getIsAuthenticationPage, getIsRegistrationPage } from 'utils/utils';

import './AppDashboard.scss';

const AppDashboard = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { userRole } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useSnackbarOnNetworkDisconnection();

    useUpdateUserBasicInfo();
    useAccStatusRole();
    const registrationStatus = useRegistrationStatus();

    const isRegistrationPage = getIsRegistrationPage(location);
    const isAuthenticationPage = getIsAuthenticationPage(location);

    useEffect(() => {
        if (!isRegistrationPage) {
            dispatch(setIsFillingInformation(false));
        }
    }, [dispatch, isRegistrationPage]);

    const routeInfos = useMemo(
        () => getRouteInfos({ userRole, registrationStatus }),
        [userRole, registrationStatus]
    );

    if (!userRole) {
        return <Loading />;
    }

    const isContentPage = !isRegistrationPage && !isAuthenticationPage;

    return (
        <>
            {isContentPage ? (
                <>
                    <Breakpoint desktop only>
                        <AppHeader />
                    </Breakpoint>
                    <Box className="navbar">
                        <AppNavbar />
                    </Box>
                    <Box component="main" className="mainWrapper">
                        <Box className="contentWrapper">
                            <AppRouter routeInfos={routeInfos} />
                        </Box>
                        <AppFooter />
                    </Box>
                </>
            ) : (
                <AppRouter routeInfos={routeInfos} />
            )}
        </>
    );
};

export default AppDashboard;
