import { useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useTradeAccountInfo = () => {
    const [tradeAccountInfo, setTradeAccountInfo] = useState({});

    const getTradeAccountInfo = (tradeLoginId) => {
        if (!tradeLoginId) return;

        handleAsyncCall({
            asyncCall: () =>
                API.common.getTradeAccountInfo.apiCall(tradeLoginId),
            handleCallSuccess: (res) => {
                setTradeAccountInfo(res.data.info || {});
            },
            handleCallFailure: () => {}
        });
    };

    return { tradeAccountInfo, getTradeAccountInfo };
};

export default useTradeAccountInfo;
