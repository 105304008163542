import { createTheme } from '@mui/material';

import { COLOR } from 'constants/appInfos';

import styles from 'styles/styles.module.scss';

export const theme = createTheme({
    palette: {
        primary: {
            main: COLOR.ORANGE
        },
        secondary: {
            main: COLOR.DENIM
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    height: '43px',
                    boxSizing: 'border-box'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    marginLeft: 0,
                    marginRight: 0
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        },

        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start'
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginLeft: '5px',
                    '& .MuiFormControlLabel-root': {
                        marginBottom: '5px'
                        // '@media (min-width:960px)': {
                        //     marginBottom: '50px'
                        // }
                        // [theme.breakpoints.down('md')]: {
                        //     backgroundColor: theme.palette.secondary.main,
                        //   },
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                action: {
                    padding: '0 0 0 16px'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        }
    },

    spacing: 2,
    breakpoints: {
        values: {
            mobile: Number(styles.MOBILE_BREAKPOINT),
            tablet: Number(styles.TABLET_BREAKPOINT),
            laptop: Number(styles.LAPTOP_BREAKPOINT),
            desktop: Number(styles.DESKTOP_BREAKPOINT)
        }
    },
    typography: {
        fontFamily: `'Arial', 'NotoSansSC'`
    },
    overrides: {}
});

export const APP_FONT_FACE_CSS = `
    @font-face { font-family: NotoSansSC; font-weight: 400; font-display: swap; src: local('NotoSansSC')};
    @font-face { font-family: NotoSansSC; font-weight: 500; font-display: swap; src: local('NotoSansSC')};
    @font-face { font-family: NotoSansSC; font-weight: 700; font-display: swap; src: local('NotoSansSC')};
    @font-face { font-family: NotoSansSC; font-weight: 900; font-display: swap; src: local('NotoSansSC')};
`;

export const Z_INDEX = {
    Z_INDEX_WITHIN_COMPONENT_999: Number(styles.Z_INDEX_WITHIN_COMPONENT_999),
    Z_INDEX_WITHIN_COMPONENT_99: Number(styles.Z_INDEX_WITHIN_COMPONENT_99),
    Z_INDEX_WITHIN_COMPONENT_9: Number(styles.Z_INDEX_WITHIN_COMPONENT_9),
    Z_INDEX_WITHIN_COMPONENT_1: Number(styles.Z_INDEX_WITHIN_COMPONENT_1),
    Z_INDEX_WITHIN_COMPONENT_0: Number(styles.Z_INDEX_WITHIN_COMPONENT_0),
    'Z_INDEX_WITHIN_COMPONENT_-1': Number(styles['Z_INDEX_WITHIN_COMPONENT_-1'])
};
