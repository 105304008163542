import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { useWindowSize } from 'hooks';

import ActionGroupPopoverMenu from './components/ActionGroupPopOverMenu';

const ActionGroup = ({ children }) => {
    const { isDesktop } = useWindowSize();
    const compactedChildren = compact(
        isArray(children) ? children : [children]
    );

    if (isEmpty(compactedChildren)) return null;

    const isSingleAction = compactedChildren.length === 1;

    return isDesktop || isSingleAction ? (
        <>{compactedChildren}</>
    ) : (
        <ActionGroupPopoverMenu>{compactedChildren}</ActionGroupPopoverMenu>
    );
};

export default ActionGroup;
