import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FILTER_TYPE } from 'constants/filterInfos';
import { VALUE_APP_SELECT_ALL } from 'constants/parseValues';
import { useParentTraderList } from 'hooks/apis';

import { hasNoTradeLoginId } from 'utils/payloadParsing';

export const FILTER_PARENT_TRADE_LOGIN_ID = 'parentTradeLoginId';

const useFilterParentTrader = (filterName = FILTER_PARENT_TRADE_LOGIN_ID) => {
    const { t } = useTranslation();
    const { parentTraderList, getParentTradeList } = useParentTraderList();
    const { queryTradeLoginId } = useSelector((state) => state.user);

    useEffect(() => {
        if (hasNoTradeLoginId(queryTradeLoginId)) return;
        getParentTradeList(queryTradeLoginId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryTradeLoginId]);

    const selectItems = parentTraderList.map(
        ({ tradeLoginId, displayName }) => ({
            label: `${tradeLoginId} (${displayName})`,
            value: tradeLoginId
        })
    );

    const selectAllItem = {
        label: t('common.general.all'),
        value: VALUE_APP_SELECT_ALL
    };

    const parentTradeLoginIdFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.COMBO_BOX,
        label: t('common.tradeAccount.parentTradeLoginId'),
        options: [selectAllItem, ...selectItems],
        optionValueKey: 'value',
        optionLabelKey: 'label',
        defaultValue: [VALUE_APP_SELECT_ALL],
        allowMultipleSelections: false
    };

    return { parentTradeLoginIdFilterInfo };
};

export default useFilterParentTrader;
