import { Box } from '@mui/material';

import './IndicatorColorCircle.scss';

const IndicatorColorCircle = ({ color, side, onClick = () => {} }) => {
    return (
        <Box
            sx={{ backgroundColor: color, width: side, height: side }}
            className="indicatorColorCircle"
            onClick={() => onClick(color)}
        />
    );
};

export default IndicatorColorCircle;
