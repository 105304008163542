import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useTradeCategories } from 'hooks';

export const FILTER_TRADE_CATEGORIES = 'tradeCategories';

const useFilterTradeCategories = (filterName = FILTER_TRADE_CATEGORIES) => {
    const { t } = useTranslation();
    const { tradeCategories } = useTradeCategories();

    const itemSelectAll = {
        key: '',
        value: t('common.general.all')
    };

    const tradeCategoriesFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.filter.method'),
        items: [itemSelectAll, ...tradeCategories],
        itemValueKey: 'key',
        itemLabelKey: 'value',
        defaultValue: ''
    };

    return { tradeCategoriesFilterInfo };
};

export default useFilterTradeCategories;
