export const PERSONAL_TITLE = {
    MISTER: {
        i18nKey: 'common.user.personalTitle.mister',
        serverKey: 1,
        key: 'MISTER'
    },
    MISSES: {
        i18nKey: 'common.user.personalTitle.misses',
        serverKey: 3,
        key: 'MISSES'
    }
};
