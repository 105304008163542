// user info
export { default as BankCardBgImg } from './img_bank_card_background.png';
export { default as BankCardVisaImg } from './img_bank_card_visa.png';
export { default as WalletCardBgImg } from './img_wallet_card_background.png';

export { ReactComponent as AccountCardIco } from './icn_account_card.svg';

// autochartist
export { default as BellImg } from './autochartist/userGuide/img_bell.png';
export { default as MagnetImg } from './autochartist/userGuide/img_magnet.png';
export { default as ConnectionImg } from './autochartist/userGuide/img_connection.png';
export { default as ChartIncreaseImg } from './autochartist/userGuide/img_chart_increase.png';
export { default as IllustrationAnalysisImg } from './autochartist/userGuide/img_illustration_analysis.png';
export { default as IllustrationCTraderImg } from './autochartist/userGuide/img_illustration_cTrader.png';
export { default as IllustrationMT5Img } from './autochartist/userGuide/img_illustration_mt5.png';

// reward
export { default as RedPacketRewardImg } from './reward/img_red_packet_reward.png';
export { ReactComponent as ReminderShieldIco } from './reward/icn_reminder_shield.svg';
export { ReactComponent as GiftRedemptionIco } from './reward/mission/icn_gift_redemption.svg';
export { ReactComponent as PocketRedemptionIco } from './reward/mission/icn_pocket_redemption.svg';
export { ReactComponent as PointExpiredIco } from './reward/mission/icn_point_expired.svg';
export { ReactComponent as PointLoginIco } from './reward/mission/icn_point_login.svg';
export { ReactComponent as PointRegisteredIco } from './reward/mission/icn_point_registered.svg';
export { ReactComponent as PointChatIco } from './reward/mission/icn_point_chat.svg';
export { ReactComponent as ActivationAccIco } from './reward/mission/icn_activation_account.svg';
export { ReactComponent as PointTradeVolumeIco } from './reward/mission/icn_point_trade_volume.svg';
export { ReactComponent as PointDepositIco } from './reward/mission/icn_point_deposit.svg';
export { ReactComponent as PointInvitationIco } from './reward/mission/icn_point_invitation.svg';

export { default as BadgeCrystalImg } from './reward/badges/img_badge_crystal_halo.png';
export { default as BadgeSilverImg } from './reward/badges/img_badge_silver.png';
export { default as BadgeDiamondImg } from './reward/badges/img_badge_diamond.png';
export { default as BadgeGoldImg } from './reward/badges/img_badge_gold.png';
export { default as BadgePlatinumImg } from './reward/badges/img_badge_platinum.png';
export { default as BadgeCrystalHaloImg } from './reward/badges/img_badge_crystal.png';
export { default as BadgeSilverHaloImg } from './reward/badges/img_badge_silver_halo.png';
export { default as BadgeDiamondHaloImg } from './reward/badges/img_badge_diamond_halo.png';
export { default as BadgeGoldHaloImg } from './reward/badges/img_badge_gold_halo.png';
export { default as BadgePlatinumHaloImg } from './reward/badges/img_badge_platinum_halo.png';

export { ReactComponent as CheckListIco } from './reward/navigate/icn_check_list.svg';
export { ReactComponent as DocumentSharpIco } from './reward/navigate/icn_document_sharp.svg';
export { ReactComponent as HouseCoinIco } from './reward/navigate/icn_house_coin.svg';
