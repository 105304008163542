import React from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { SelectDropdownField } from 'components';

import {
    SELECT_DROPDOWN_TYPE,
    SELECT_DROPDOWN_VARIANT
} from 'constants/selectDropDown';
import { useFormikFieldProps } from 'hooks';
import { useClientGroup } from 'hooks/apis';

import { appendItemToList } from 'utils/common';

import './SelectClientGroupPopUpField.scss';
import useDialogClientGroupSelect from './components/SelectItemClientGroup/ClientGroupSelectDialog/useDialogClientGroupSelect';
import SelectItemDropDownClientGroup from './components/SelectItemDropDownClientGroup/SelectItemDropDownClientGroup';

const FIELD_NAME = 'groupId';

const FieldPlainWrapper = ({ children, ...props }) => {
    return <>{children(props)}</>;
};

const FieldFormikWrapper = ({ children, ...props }) => {
    const fieldProps = useFormikFieldProps(FIELD_NAME);

    return <>{children({ fieldProps, ...props })}</>;
};

const SelectClientGroupPopUpField = ({
    currGroupId = null,
    extraClientGroupItem = {},
    handleSelectClientGroup = () => {},
    withoutFormikHook = false,
    variant = SELECT_DROPDOWN_VARIANT.FORM,
    disabled = true
}) => {
    const { t } = useTranslation();
    const { clientGroupList } = useClientGroup();

    const { openClientGroupSelectDialog } = useDialogClientGroupSelect({
        handleSelectClientGroup,
        extraClientGroupItem
    });

    const SelectClientGroupPopUpFieldWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;

    if (isEmpty(clientGroupList)) return null;

    return (
        <SelectClientGroupPopUpFieldWrapper fieldName={FIELD_NAME}>
            {({ fieldProps }) => {
                const groupId = fieldProps ? fieldProps.value : currGroupId;

                const extraClientGroupList = appendItemToList(
                    clientGroupList,
                    extraClientGroupItem,
                    'id'
                );
                return (
                    <SelectDropdownField
                        withoutFormikHook={withoutFormikHook}
                        name="groupId"
                        value={groupId}
                        selectType={SELECT_DROPDOWN_TYPE.POP_UP}
                        fullWidth={true}
                        label={
                            variant === SELECT_DROPDOWN_VARIANT.TABLE
                                ? null
                                : t('common.sales.groupId')
                        }
                        onClick={() => {
                            !disabled && openClientGroupSelectDialog(groupId);
                        }}
                        className={cx([
                            'selectClientGroupPopUpField',
                            disabled && 'unselected'
                        ])}
                        disabled={true}
                        wrapperWidth={'100%'}
                        items={extraClientGroupList}
                        itemLabelKey="name"
                        itemValueKey="id"
                        renderLabel={({ color, name }) => (
                            <SelectItemDropDownClientGroup
                                color={color}
                                name={name}
                            />
                        )}
                        forceMuiSelect={true}
                    />
                );
            }}
        </SelectClientGroupPopUpFieldWrapper>
    );
};

export default SelectClientGroupPopUpField;
