import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useBusinesses = () => {
    const [businesses, setBusinesses] = useState([]);

    const getBusinesses = () =>
        handleAsyncCall({
            asyncCall: () => API.common.getBusinessList.apiCall(),
            handleCallSuccess: (res) => setBusinesses(res.data.list)
        });

    useEffect(() => {
        getBusinesses();
    }, []);

    return { businesses, getBusinesses };
};

export default useBusinesses;
