import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useCountries = () => {
    const [countries, setCountries] = useState([]);

    const getCountries = () => {
        handleAsyncCall({
            asyncCall: () => API.common.getCountryList.apiCall(),
            handleCallSuccess: (res) => setCountries(res.data.list)
        });
    };

    useEffect(() => {
        getCountries();
    }, []);

    const getSelectedCountryInfo = (targetCountry) => {
        const targetCountryInfo = countries.find(
            (countryData) => countryData.code === targetCountry
        );

        return targetCountryInfo;
    };

    return {
        countries,
        getCountries,
        getSelectedCountryInfo
    };
};

export default useCountries;
