import { useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { APP_DEFAULT_INFINITE_SCROLL_PAGE_SIZE } from 'constants/appInfos';

import useOpenSnackbar from './useOpenSnackbar';

const LIST_MERGE_METHOD = {
    APPEND: 'APPEND',
    REPLACE: 'REPLACE'
};

const useInfiniteLoading = ({
    getItems,
    isValidPayload = true,
    initPage = 1,
    pageSize = APP_DEFAULT_INFINITE_SCROLL_PAGE_SIZE,
    disableSnackbar = false
}) => {
    const [list, setList] = useState([]);
    const currPage = useRef(initPage);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { openErrorSnackbar } = useOpenSnackbar();

    const fetchingQueueRef = useRef([]);
    const fetchIntervalsRef = useRef({});

    const fetchItems = async (page, itemCombineMethod) => {
        if (!isValidPayload) {
            return;
        }

        let res = null;
        let error = null;
        try {
            res = await getItems({
                pageCurrent: page,
                pageSize,
                refresh
            });
            if (res instanceof Error) {
                error = res;
                res = null;
            }
        } catch (err) {
            error = err;
        }

        if (!error) {
            const { list: appendList, totalPage, pageCurrent } = res.data;

            currPage.current = pageCurrent;
            if (totalPage) {
                setHasNextPage(totalPage > currPage.current);
            } else {
                setHasNextPage(true);
            }

            const updatedList =
                itemCombineMethod === LIST_MERGE_METHOD.APPEND
                    ? [...list, ...appendList]
                    : appendList;
            if (!isEqual(updatedList, list)) {
                setList(updatedList);
            }
        } else {
            if (
                !disableSnackbar &&
                error.message &&
                !error.isApiCallAuthFailed
            ) {
                openErrorSnackbar(error.message);
            }
        }
        setIsLoading(false);
    };

    const fetchNextPage = () => {
        if (!hasNextPage) {
            return;
        }
        const pageToLoad = currPage.current + 1;
        const fetchId = currPage;

        fetchingQueueRef.current = [...fetchingQueueRef.current, fetchId];
        if (!fetchIntervalsRef.current[fetchId]) {
            fetchIntervalsRef.current[fetchId] = setInterval(async () => {
                setIsLoading(true);
                if (fetchingQueueRef.current[0] === fetchId) {
                    clearInterval(fetchIntervalsRef.current[fetchId]);
                    delete fetchIntervalsRef.current[fetchId];
                    try {
                        await fetchItems(pageToLoad, LIST_MERGE_METHOD.APPEND);
                    } finally {
                        fetchingQueueRef.current.shift();
                    }
                }
            }, 200);
        }
    };

    const refresh = () => {
        const pageToLoad = 1;
        fetchItems(pageToLoad, LIST_MERGE_METHOD.REPLACE);
    };

    const clearList = () => {
        setList([]);
        setHasNextPage(false);
        setIsLoading(false);
        currPage.current = 1;
    };

    const hasData = !isEmpty(list);

    return {
        list,
        hasData,
        hasNextPage,
        isLoading,
        fetchNextPage,
        refresh,
        clearList
    };
};

export default useInfiniteLoading;
