import axiosContentsConfig from 'axios/axiosContentsConfig';

const contents = {
    getKeywords: {
        apiCall: (params) =>
            axiosContentsConfig.get('/keywords', {
                params
            }),
        queryKey: 'contents.getKeywords'
    },
    getRollingTimes: {
        apiCall: (params) =>
            axiosContentsConfig.get('/rolling-times', { params }),
        queryKey: 'contents.getRollingTimes'
    },
    getTradingSchedules: {
        apiCall: (params) =>
            axiosContentsConfig.get('/trading-schedules', {
                params
            }),
        queryKey: 'contents.getTradingSchedules'
    },
    getPromotionBanner: {
        apiCall: (params) => axiosContentsConfig.get('/banners', { params }),
        queryKey: 'contents.getPromotionBanner'
    }
};

export default contents;
