import { useSelector } from 'react-redux';

import { ACC_STATUS } from 'constants/serverInfos/serverInfos';

const useAuthStatus = () => {
    const { userAccStatus, isLogin } = useSelector((state) => state.user);

    const isApproving =
        ![ACC_STATUS.MANAGER_APPROVED, ACC_STATUS.APPROVED].includes(
            userAccStatus
        ) && isLogin;

    return { isApproving };
};

export default useAuthStatus;
