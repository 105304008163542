import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-use-clipboard';

import { useOpenSnackbar } from 'hooks';

const useCopyToClipboard = () => {
    const { t } = useTranslation();
    const { openSnackbar } = useOpenSnackbar();
    const [copyValue, setCopyValue] = useState(null);
    const [isCopied, setCopied] = useClipboard(copyValue, {
        successDuration: 1000
    });
    useEffect(() => {
        if (!copyValue) return;
        setCopied();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyValue]);

    useEffect(() => {
        isCopied && openSnackbar(t('common.snackbar.copySuccess'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopied]);

    const handleCopy = (newCopyValue) => {
        if (newCopyValue !== copyValue) {
            setCopyValue(newCopyValue);
        } else {
            setCopied();
        }
    };

    return { handleCopy };
};

export default useCopyToClipboard;
