import { createSlice } from '@reduxjs/toolkit';

import { REGISTRATION_TYPE } from 'constants/registration';

const initialRegistrationState = {
    isFillingInformation: false,
    registrationType: REGISTRATION_TYPE.NORMAL,
    queries: {},
    queryString: ''
};

const registrationSlice = createSlice({
    name: 'registration',
    initialState: initialRegistrationState,
    reducers: {
        setIsFillingInformation: (state, action) => {
            state.isFillingInformation = action.payload;
        },
        setRegistrationType: (state, action) => {
            state.registrationType = action.payload;
        },
        setQueries: (state, action) => {
            state.queries = action.payload;
        },
        setQueryString: (state, action) => {
            state.queryString = action.payload;
        },
        resetRegistrationState: () => ({ ...initialRegistrationState })
    }
});

export default registrationSlice;

export const {
    resetRegistrationState,
    setIsFillingInformation,
    setRegistrationType,
    setQueries,
    setQueryString
} = registrationSlice.actions;
