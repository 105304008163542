import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { useAppTableRetrieveValues, useDeepEqualMemo } from 'hooks';
import { setSubmittedFilterGroupValues } from 'store/filter/slice';

const useAppTableQueryParams = (
    filterGroupId,
    defaultTableQueryParams,
    isRestoreCachedParams
) => {
    const dispatch = useDispatch();
    const {
        flatFilterGroupValues,
        isFlatFilterGroupEmpty,
        pageValues,
        isPageValuesEmpty
    } = useAppTableRetrieveValues(filterGroupId);

    const tableQueryParams = {
        ...(isFlatFilterGroupEmpty ? {} : flatFilterGroupValues),
        ...(isPageValuesEmpty ? {} : pageValues)
    };

    const tableQueryParamsMemo = useDeepEqualMemo(tableQueryParams);

    const updateTableQueryParams = (tableQueryPayload) => {
        const { pageCurrent, pageSize, ...filterGroupValues } =
            tableQueryPayload;

        dispatch(
            setSubmittedFilterGroupValues({
                filterGroupId,
                flatFilterGroupValues: filterGroupValues,
                pageValues: {
                    pageCurrent,
                    pageSize
                }
            })
        );
    };

    useEffect(() => {
        if (isRestoreCachedParams && !isEmpty(tableQueryParamsMemo)) return;
        updateTableQueryParams(defaultTableQueryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRestoreCachedParams]);

    return {
        tableQueryParams: tableQueryParamsMemo,
        updateTableQueryParams
    };
};

export default useAppTableQueryParams;
