import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import SelectDropdownField from 'components/Fields/SelectDropdownField/SelectDropdownField';

import { useUserSources } from 'hooks';

const SelectSourceCodeField = ({
    clientTypeKey,
    handleChange = () => {},
    variant = 'form',
    fullWidth = true,
    wrapperWidth = '100%',
    name = 'sourceCode',
    ...props
}) => {
    const { t } = useTranslation();
    const { userSources } = useUserSources();

    if (isEmpty(userSources)) {
        return null;
    }

    return (
        <SelectDropdownField
            label={t('common.sales.sourceCode')}
            name={name}
            fullWidth={fullWidth}
            wrapperWidth={'100%'}
            items={userSources}
            itemValueKey="key"
            itemLabelKey="value"
            variant={variant}
            handleChange={(event) => {
                handleChange(event.target.value);
            }}
            {...props}
        />
    );
};

export default SelectSourceCodeField;
