import { useTranslation } from 'react-i18next';

import { TextareaAutosize } from '@mui/material';
import cx from 'classnames';

import { FormControl, InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { useFormikFieldProps } from 'hooks';

import './TextAreaField.scss';

const TextAreaField = ({
    value,
    name,
    label = '',
    placeholder = '',
    width = '200',
    handleChange = null,
    onClick = null,
    handleBlur = null,
    className = '',
    fullWidth = false,
    helperText = '',
    disabled = false,
    minRows = 3,
    wrapperClassName = '',
    isRequired = false
}) => {
    const { t } = useTranslation();

    const fieldProps = useFormikFieldProps(name);

    return (
        <AppFieldWrapper className={cx('textAreaField', wrapperClassName)}>
            <InputLabel label={label} isRequired={isRequired} />
            <FormControl
                fullWidth={fullWidth}
                helperText={helperText || fieldProps.helperText}
                error={fieldProps.error}
                disabled={disabled}
                className={cx(['textAreaWrapper', className])}>
                <TextareaAutosize
                    value={value ? value : fieldProps.value}
                    placeholder={
                        placeholder === ''
                            ? t('common.placeholder.pleaseEnter')
                            : placeholder
                    }
                    style={{ width }}
                    minRows={minRows}
                    className={cx({
                        redOutlined: fieldProps.error
                    })}
                    onChange={(event) => {
                        handleChange && handleChange(event);
                        fieldProps.setValue(event.target.value);
                    }}
                    onBlur={(event) => {
                        handleBlur && handleBlur(event);
                        fieldProps.onBlur(event);
                    }}
                    onClick={onClick}
                />
            </FormControl>
        </AppFieldWrapper>
    );
};

export default TextAreaField;
