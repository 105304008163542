import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import API from 'services';
import { getAccountLinkName } from 'utils/serverInfos';
import { handleAsyncCall } from 'utils/utils';

const useAccountLinkDetail = ({
    inviteUrlId,
    withConvertedLinkName = true
}) => {
    const { t } = useTranslation();
    const { userId } = useSelector((state) => state.user);
    const [accountLinkDetail, setAccountLinkDetail] = useState({
        settings: {},
        info: {}
    });

    useEffect(() => {
        const getAccountLinkDetail = () => {
            handleAsyncCall({
                asyncCall: () =>
                    API.customerMgmt.getAccountLinkDetail.apiCall(
                        userId,
                        inviteUrlId
                    ),
                handleCallSuccess: (res) => {
                    if (res.data?.info && withConvertedLinkName) {
                        const { system, name } = res.data.info;
                        res.data.info.accountLinkName = getAccountLinkName(
                            name,
                            system,
                            t
                        );
                    }
                    setAccountLinkDetail(res.data);
                },
                handleCallFailure: () => {}
            });
        };

        if (!inviteUrlId) return;
        getAccountLinkDetail();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteUrlId]);

    return {
        commissionSettings: accountLinkDetail.settings,
        accountLinkInfo: accountLinkDetail.info
    };
};

export default useAccountLinkDetail;
