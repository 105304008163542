import cx from 'classnames';

import { ComboBoxField } from 'components';

import { useTradingPlatforms } from 'hooks';

const SelectComboTradePlatformField = ({
    label,
    handleSelectPlatform = () => {},
    wrapperClassName,
    disabled = false
}) => {
    const { platforms } = useTradingPlatforms();

    const onChange = (event, selectedPlatforms) => {
        handleSelectPlatform(selectedPlatforms);
    };

    return (
        <ComboBoxField
            label={label}
            name="platforms"
            optionValueKey="key"
            optionLabelKey="value"
            options={platforms}
            onChange={onChange}
            wrapperClassName={cx([
                'selectComboTradePlatformField',
                wrapperClassName
            ])}
            isRequired
            fullWidth
            allowMultipleSelections
            disabled={disabled}
            maxTags={3}
            mobileMaxTags={3}
        />
    );
};

export default SelectComboTradePlatformField;
