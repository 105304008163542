import { Box } from '@mui/material';
import cx from 'classnames';

import { ACC_STATUS } from 'constants/serverInfos/serverInfos';

import './StepIndex.scss';
import {
    CircleCheckedIco,
    CircleErrorIco,
    CircleLoadingIco
} from 'assets/images/common';
import { FileGreyIco, FilePrimaryIco } from 'assets/images/stepper';

const StepIndex = ({
    isStepHighLight,
    isStatus,
    isCurrStep,
    isCompletedStep,
    userAccStatus,
    index
}) => {
    return (
        <>
            {isStatus ? (
                <Box
                    className={cx([
                        'stepperDotStepIndex',
                        { circle: !isCompletedStep && !isCurrStep }
                    ])}>
                    {isStepHighLight ? (
                        isCompletedStep ? (
                            <CircleCheckedIco className="statusIco" />
                        ) : userAccStatus === ACC_STATUS.MANAGER_REJECTED ||
                          userAccStatus === ACC_STATUS.REJECTED ? (
                            <CircleErrorIco className="statusIco" />
                        ) : (
                            <CircleLoadingIco className="statusIco" />
                        )
                    ) : (
                        <>
                            <FileGreyIco className="fileIco" />
                            <Box className="stepIndexTxt">{index + 1}</Box>
                        </>
                    )}
                </Box>
            ) : (
                <Box
                    className={cx(
                        'stepperDotStepIndex',
                        { circle: !isCurrStep },
                        { orangeCircle: isCurrStep || isCompletedStep }
                    )}>
                    {isStepHighLight ? (
                        <FilePrimaryIco className="fileIco" />
                    ) : (
                        <FileGreyIco className="fileIco" />
                    )}
                    <Box className="stepIndexTxt">{index + 1}</Box>
                </Box>
            )}
        </>
    );
};

export default StepIndex;
