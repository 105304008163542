export const handleHideWidgetCSS = (element) => {
    if (!element) return;
    element.classList.add('chat-widget-hidden');
    element.style.setProperty('visibility', 'hidden', 'important');
};

export const handleShowWidgetCSS = (element) => {
    if (!element) return;
    element.classList.remove('chat-widget-hidden');
    element.style.setProperty('visibility', 'visible', 'important');
};
