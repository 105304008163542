import React from 'react';

import { useSnackbarOnNetworkDisconnection } from 'hooks';

import PublicRouter from 'utils/routers/PublicRouter';

const AppPublic = () => {
    useSnackbarOnNetworkDisconnection();

    return <PublicRouter />;
};

export default AppPublic;
