import { Box } from '@mui/material';

import './PlainTextField.scss';

const PlainTextField = ({
    label,
    value,
    wrapperWidth = 'auto',
    renderValue = null
}) => {
    const valueFinal = renderValue ? renderValue(value) : value;

    return (
        <Box
            className="appFieldWrapper plainTextField"
            sx={{ width: wrapperWidth }}>
            <Box className="fieldLabel">{label}</Box>
            <Box className="fieldValue">{valueFinal || '---'}</Box>
        </Box>
    );
};

export default PlainTextField;
