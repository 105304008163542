import axiosConfig from 'axios/axiosConfig';

const customerMgmtAPI = {
    /*=============================================
    =                 Account Link              =
    =============================================*/
    getAccountLinkList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/list`, { params }),
        queryKey: 'customerMgmt.getAccountLinkList'
    },
    getAccountLinkSubList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/sublist`, { params }),
        queryKey: 'customerMgmt.getAccountLinkSubList'
    },
    getAccountLinkDetail: {
        apiCall: (userId, inviteUrlId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/${inviteUrlId}/info`, {
                params
            }),
        queryKey: 'customerMgmt.getAccountLinkDetail'
    },
    getDefaultCommissionInfo: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/default`, { params }),
        queryKey: 'customerMgmt.getDefaultCommissionInfo'
    },
    getInviteUrlAcTypesList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/config`, { params }),
        queryKey: 'customerMgmt.getInviteUrlAcTypesList'
    },
    addAccountLink: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/inviteUrl/add`, payload),
        queryKey: 'customerMgmt.addAccountLink'
    },
    editAccountLink: {
        apiCall: (userId, inviteUrlId, payload) =>
            axiosConfig.post(
                `/user/${userId}/inviteUrl/${inviteUrlId}/edit`,
                payload
            ),
        queryKey: 'customerMgmt.editAccountLink'
    },
    removeAccountLink: {
        apiCall: (userId, inviteUrlId) =>
            axiosConfig.post(`/user/${userId}/inviteUrl/${inviteUrlId}/delete`),
        queryKey: 'customerMgmt.removeAccountLink'
    },
    /*=============================================
    =                 Client Details              =
    =============================================*/
    getClientFollowLogMsgs: {
        apiCall: (params) => axiosConfig.get('/followLog/list', { params }),
        queryKey: 'customerMgmt.getClientFollowLogMsgs'
    },
    addClientFollowLogMsg: {
        apiCall: (payload) => axiosConfig.post('/followLog/add', payload),
        queryKey: 'customerMgmt.addClientFollowLogMsg'
    },
    removeFollowLogMsg: {
        apiCall: (payload) => axiosConfig.post('/followLog/delete', payload),
        queryKey: 'customerMgmt.removeFollowLogMsg'
    },
    getClientGrouping: {
        apiCall: (params) => axiosConfig.get('/contactGroup/list', { params }),
        queryKey: 'customerMgmt.getClientGrouping'
    },
    editClientGrouping: {
        apiCall: (payload) => axiosConfig.post('/contactGroup/edit', payload),
        queryKey: 'customerMgmt.editClientGrouping'
    },
    createClientGrouping: {
        apiCall: (payload) => axiosConfig.post('/contactGroup/add', payload),
        queryKey: 'customerMgmt.createClientGrouping'
    },
    deleteClientGrouping: {
        apiCall: (payload) => axiosConfig.post('/contactGroup/delete', payload),
        queryKey: 'customerMgmt.deleteClientGrouping'
    },
    /*=============================================
    =                LEAD                         =
    =============================================*/
    getLeadClientList: {
        apiCall: (params) => axiosConfig.get('/contact/list', { params }),
        queryKey: 'customerMgmt.getLeadClientList'
    },
    getLeadClientInfo: {
        apiCall: (contactId) => axiosConfig.get(`/contact/${contactId}/info`),
        queryKey: 'customerMgmt.getLeadClientInfo'
    },
    getTradeAccounts: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/tradeAccount/list`, { params }),
        queryKey: 'customerMgmt.getTradeAccounts'
    },
    addLeadClientInfo: {
        apiCall: (payload) => axiosConfig.post('/contact/add', payload),
        queryKey: 'customerMgmt.addLeadClientInfo'
    },
    editLeadClientInfo: {
        apiCall: (payload) => axiosConfig.post('/contact/edit', payload),
        queryKey: 'customerMgmt.editLeadClientInfo'
    },
    downloadLeadsTemplate: {
        apiCall: () =>
            axiosConfig.get('/contact/excelTemplate', {
                responseType: 'blob'
            }),
        queryKey: 'customerMgmt.downloadLeadsTemplate'
    },
    uploadLeadList: {
        apiCall: (payload) =>
            axiosConfig.post('/contact/import', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }),
        queryKey: 'customerMgmt.uploadLeadList'
    },
    getContactPhone: {
        apiCall: (params) => axiosConfig.get('/contact/phone/info', { params }),
        queryKey: 'customerMgmt.getContactPhone'
    },
    getContactEmail: {
        apiCall: (params) => axiosConfig.get('/contact/email/info', { params }),
        queryKey: 'customerMgmt.getContactEmail'
    },
    exportLeadList: {
        apiCall: (params) =>
            axiosConfig.get('/contact/export', {
                params,
                responseType: 'blob'
            }),
        queryKey: 'customerMgmt.exportLeadList'
    },
    /*=============================================
    =       Registered Client + Deposited Client  =
    =============================================*/
    // manager call only
    getManagerSubTradeAccounts: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/subManagerAndIb`, {
                params
            }),
        queryKey: 'customerMgmt.getManagerSubTradeAccounts'
    },
    // ib call only
    getIBSubTradeAccount: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/subIb`, {
                params
            }),
        queryKey: 'customerMgmt.getIBSubTradeAccount'
    },
    getClientList: {
        apiCall: (tradeLoginId, params) =>
            axiosConfig.get(`/tradeAccount/${tradeLoginId}/client/list`, {
                params
            }),
        queryKey: 'customerMgmt.getClientList'
    },
    getClientAccountLink: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/user/${userId}/inviteUrl/copy`, { params }),
        queryKey: 'customerMgmt.getClientAccountLink'
    },
    sendClientInviteEmail: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/inviteUrl/invite`, payload),
        queryKey: 'customerMgmt.sendClientInviteEmail'
    },
    editClientInfo: {
        apiCall: (payload) => axiosConfig.post('/user/edit', payload),
        queryKey: 'customerMgmt.editClientInfo'
    },
    /*=============================================
    =                 Relation Tree              =
    =============================================*/
    getUserRelationTree: {
        apiCall: (tradeLoginId) =>
            axiosConfig.get(`/tradeAccount/${tradeLoginId}/agentRelation`),
        queryKey: 'customerMgmt.getUserRelationTree'
    }
};

export default customerMgmtAPI;
