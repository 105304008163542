import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from 'services';
import { hasNoTradeLoginId } from 'utils/payloadParsing';
import { handleAsyncCall } from 'utils/utils';

const useDailyStatChart = ({
    dateRange,
    chartType,
    chartMethod = '',
    tradeLoginId = ''
}) => {
    const { startDate, endDate } = dateRange;
    const { userId, queryTradeLoginId } = useSelector((state) => state.user);
    const [dailyChartDataList, setDailyChartDataList] = useState([]);

    useEffect(() => {
        const getDailyStat = () => {
            const tradeLoginIdPayload =
                chartMethod === 'tradeLoginId'
                    ? tradeLoginId
                    : queryTradeLoginId;
            if (hasNoTradeLoginId(tradeLoginIdPayload)) return;
            const payload = {
                tradeLoginId: tradeLoginIdPayload,
                type: chartType,
                startDate,
                endDate
            };
            handleAsyncCall({
                asyncCall: () =>
                    API.ibMgmt.getDailyStat.apiCall(userId, payload),
                handleCallSuccess: (res) => {
                    setDailyChartDataList(res.data.list);
                }
            });
        };

        const isDateRangeAvailable = startDate && endDate;
        isDateRangeAvailable && getDailyStat();
    }, [
        chartMethod,
        chartType,
        endDate,
        startDate,
        queryTradeLoginId,
        tradeLoginId,
        userId
    ]);

    return { dailyChartDataList };
};

export default useDailyStatChart;
