import React from 'react';

import cx from 'classnames';
import { Form, Formik } from 'formik';

const AppForm = ({
    initialValues,
    handleSubmit,
    render,
    validationSchema = null,
    initialTouched = null,
    className = '',
    formikRef = null
}) => (
    <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        initialTouched={initialTouched}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnBlur
        enableReinitialize>
        {(formik) => (
            <Form className={cx(['appFormWrapper', className])}>
                {render(formik)}
            </Form>
        )}
    </Formik>
);

export default AppForm;
