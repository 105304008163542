import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { AppCard } from 'layouts';
import AccountLinkInfoForm from 'pages/CustomerMgmt/components/AccountLinkInfoForm/AccountLinkInfoForm';
import CommissionTableForm from 'pages/CustomerMgmt/components/ComissionTableForm/CommissionTableForm';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { PAGE_FULL_STATIC_PATH } from 'constants/routes';
import { useOpenSnackbar } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import StepperModifyAccountLink, {
    stepNames
} from './StepperModifyAccountLink/StepperModifyAccountLink';

const scrollToTop = () => window.scrollTo(0, 0);

const ProcessAccountLinkModify = ({
    title,
    initAccountLinkInfo = {},
    initCommissionSettings = {},
    availableAccountTypes = [],
    onSubmitModify,
    accessType
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userId } = useSelector((state) => state.user);

    const [currStepName, setCurrStepName] = useState(stepNames.SETTINGS);
    const [accountLinkFormValues, setAccountLinkFormValues] = useState({
        info: {},
        setting: {}
    });
    const { openErrorSnackbar } = useOpenSnackbar();
    const [defaultCommissionSettings, setDefaultCommissionSettings] = useState(
        {}
    );
    const [selectedAccountType, setSelectedAccountType] = useState('');

    useEffect(() => {
        const getDefaultCommissionSettings = () => {
            const payload = {
                tradeLoginId: initAccountLinkInfo.tradeLoginId,
                accountType: accountLinkFormValues.info.accountType
            };

            handleAsyncCall({
                asyncCall: () =>
                    API.customerMgmt.getDefaultCommissionInfo.apiCall(
                        userId,
                        payload
                    ),
                handleCallSuccess: (res) => {
                    setDefaultCommissionSettings(res.data.settings || {});
                },
                handleCallFailureError: (res) => {
                    openErrorSnackbar(
                        res.message ||
                            t(
                                'customerMgmt.accountLink.addAccountLink.fetchFailed'
                            )
                    );
                    navigate(PAGE_FULL_STATIC_PATH.ReferralLink);
                }
            });
        };

        if (accessType === INFO_ACCESS_TYPE.NEW) {
            getDefaultCommissionSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountLinkFormValues]);

    const handleSubmitCommissionInfo = async (newCommissionSettings) => {
        const newAccountLinkFormValues = {
            ...accountLinkFormValues,
            setting: newCommissionSettings
        };

        setAccountLinkFormValues(newAccountLinkFormValues);
        await onSubmitModify(newAccountLinkFormValues);
    };

    const renderStep = () => {
        switch (currStepName) {
            case stepNames.SETTINGS:
                return (
                    <AppCard title={title} className="accountLinkDetailBoard">
                        <AccountLinkInfoForm
                            accessType={accessType}
                            initAccountLinkInfo={initAccountLinkInfo}
                            availableAccountTypes={availableAccountTypes}
                            accountLinkInfoValues={accountLinkFormValues.info}
                            commissionValues={accountLinkFormValues.setting}
                            setAccountLinkFormValues={setAccountLinkFormValues}
                            onNextStep={(cachedAccountLinkInfo) => {
                                setAccountLinkFormValues({
                                    ...accountLinkFormValues,
                                    info: cachedAccountLinkInfo
                                });
                                scrollToTop();
                                setCurrStepName(stepNames.COMMISSION_INFO);
                            }}
                            onCancel={() => {
                                navigate(-1);
                            }}
                        />
                    </AppCard>
                );
            case stepNames.COMMISSION_INFO:
                return (
                    <CommissionTableForm
                        isReadOnly={false}
                        accountLinkInfo={
                            isEmpty(accountLinkFormValues.info)
                                ? initAccountLinkInfo
                                : accountLinkFormValues.info
                        }
                        initAccountLinkInfo={initAccountLinkInfo}
                        initCommissionSettings={
                            accessType === INFO_ACCESS_TYPE.NEW
                                ? defaultCommissionSettings
                                : initCommissionSettings
                        }
                        commissionValues={accountLinkFormValues.setting}
                        selectedAccountType={selectedAccountType}
                        onConfirm={handleSubmitCommissionInfo}
                        onGoBack={(cachedCommissionSettings) => {
                            setAccountLinkFormValues({
                                ...accountLinkFormValues,
                                setting: cachedCommissionSettings
                            });
                            setSelectedAccountType(
                                accountLinkFormValues.info.accountType
                            );
                            scrollToTop();
                            setCurrStepName(stepNames.SETTINGS);
                        }}
                    />
                );
            default:
                return null;
        }
    };

    if (
        isEmpty(initAccountLinkInfo) ||
        (accessType === INFO_ACCESS_TYPE.NEW &&
            isEmpty(defaultCommissionSettings)) ||
        (accessType !== INFO_ACCESS_TYPE.NEW && isEmpty(initCommissionSettings))
    )
        return null;

    return (
        <Box className="processAccountLinkModifyWrapper">
            <StepperModifyAccountLink currRouteName={currStepName} />
            {renderStep()}
        </Box>
    );
};

export default ProcessAccountLinkModify;
