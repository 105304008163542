import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Grid } from '@mui/material';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { AppForm, AppFormSubmitButton, Button } from 'components';

import { FILTER_LEVEL, FILTER_TYPE } from 'constants/filterInfos';
import { setSubmittedFilterGroupValues } from 'store/filter/slice';

import { getObjectWithUndefinedValues } from 'utils/common';
import {
    parseValuesArrayToString,
    parseValuesSelectAll
} from 'utils/payloadParsing';

import './AppFilterGroupContainer.scss';
import FilterCalendar from './components/FilterCalendar/FilterCalendar';
import FilterComboBox from './components/FilterComboBox/FilterComboBox';
import FilterDateRange from './components/FilterDateRange/FilterDateRange';
import FilterSelect from './components/FilterSelect/FilterSelect';
import FilterTextSearch from './components/FilterTextSearch/FilterTextSearch';
import useConfigAppFilterValues from './hook';
import { SearchIco } from 'assets/images/actions';

const AppFilterGroupContainer = ({
    filterGroupId,
    onFilterSubmit,
    isFetching = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formikRef = useRef(null);
    const filterGroupFormRef = useRef(null);
    const [isShowAdvancedFilter, setIsShowAdvancedFilter] = useState(false);

    const {
        localFilterGroupValues,
        setLocalFilterGroupValues,
        initFilterGroupValues,
        filterLists
    } = useConfigAppFilterValues(filterGroupId, setIsShowAdvancedFilter);

    const renderFilter = useCallback((filterInfos, filterLevel) => {
        const { filterType, filterName } = filterInfos;

        const formikFieldName =
            typeof filterName === 'string' && `${filterLevel}.${filterName}`;

        let FilterComponent = null;

        switch (filterType) {
            case FILTER_TYPE.MULTI_SELECT:
            case FILTER_TYPE.SINGLE_SELECT:
                FilterComponent = (
                    <FilterSelect
                        key={formikFieldName}
                        formikFieldName={formikFieldName}
                        filterInfos={filterInfos}
                    />
                );
                break;
            case FILTER_TYPE.TEXT_SEARCH:
                FilterComponent = (
                    <FilterTextSearch
                        key={formikFieldName}
                        formikFieldName={formikFieldName}
                        label={filterInfos.label}
                    />
                );
                break;
            case FILTER_TYPE.DATE_RANGE:
                const [startDateName, endDateName] = filterName;
                const formikFieldNames = [
                    `${filterLevel}.${startDateName}`,
                    `${filterLevel}.${endDateName}`
                ];

                FilterComponent = (
                    <FilterDateRange
                        key={startDateName}
                        formikFieldNames={formikFieldNames}
                        label={filterInfos.label}
                        allowDisable={filterInfos.allowDisable}
                    />
                );
                break;
            case FILTER_TYPE.DATE_CALENDAR:
                FilterComponent = (
                    <FilterCalendar
                        key={formikFieldName}
                        formikFieldName={formikFieldName}
                        label={filterInfos.label}
                        view={filterInfos.view}
                    />
                );
                break;
            case FILTER_TYPE.COMBO_BOX:
                FilterComponent = (
                    <FilterComboBox
                        key={formikFieldName}
                        formikFieldName={formikFieldName}
                        filterInfos={filterInfos}
                        label={filterInfos.label}
                        optionValueKey={filterInfos.optionValueKey}
                        optionLabelKey={filterInfos.optionLabelKey}
                        options={filterInfos.options}
                        allowMultipleSelections={
                            filterInfos.allowMultipleSelections
                        }
                    />
                );
                break;
            default:
                break;
        }

        if (!FilterComponent) return null;

        return (
            <Grid
                item
                mobile={12}
                tablet={6}
                desktop={4}
                key={Array.isArray(filterName) ? filterName[0] : filterName}
                className="filterFieldGrid">
                {FilterComponent}
            </Grid>
        );
    }, []);

    if (
        !filterGroupId ||
        isEmpty(localFilterGroupValues?.[FILTER_LEVEL.BASIC]) ||
        isEmpty(filterLists?.[FILTER_LEVEL.BASIC])
    ) {
        return null;
    }

    const handleFilterReset = (setFormikValues) => {
        setLocalFilterGroupValues(initFilterGroupValues);
        setFormikValues(initFilterGroupValues);
    };

    const handleToggleAdvancedFilter = (values, setValues) => {
        setIsShowAdvancedFilter((isShowAdvancedFilterCurr) => {
            const isShowAdvancedFilterNext = !isShowAdvancedFilterCurr;

            if (isShowAdvancedFilterNext) {
                setValues({
                    [FILTER_LEVEL.BASIC]: values[FILTER_LEVEL.BASIC],
                    [FILTER_LEVEL.ADVANCED]:
                        localFilterGroupValues[FILTER_LEVEL.ADVANCED]
                });
            } else {
                setLocalFilterGroupValues(values);
                setValues({
                    [FILTER_LEVEL.BASIC]: values[FILTER_LEVEL.BASIC],
                    [FILTER_LEVEL.ADVANCED]: {}
                });
            }

            if (!isShowAdvancedFilterNext && filterGroupFormRef) {
                const isCurrentYBelowFilterContainer =
                    window.scrollY > filterGroupFormRef.current.offsetTop;

                if (isCurrentYBelowFilterContainer) {
                    window.scrollTo({
                        top: filterGroupFormRef.current.offsetTop - 90
                    });
                }
            }

            return isShowAdvancedFilterNext;
        });
    };

    const handleFilterSubmit = async (newFilterGroupValues) => {
        if (isFetching) return;

        dispatch(
            setSubmittedFilterGroupValues({
                filterGroupId,
                filterGroupValues: newFilterGroupValues
            })
        );

        let flatSubmittedFilterGroupValues = {
            ...newFilterGroupValues[FILTER_LEVEL.BASIC],
            ...newFilterGroupValues[FILTER_LEVEL.ADVANCED]
        };

        if (!isShowAdvancedFilter) {
            // if the advanced filter is not used,
            // its related submit values are set to be undefined or empty array
            flatSubmittedFilterGroupValues = {
                ...flatSubmittedFilterGroupValues,
                ...getObjectWithUndefinedValues(
                    initFilterGroupValues[FILTER_LEVEL.ADVANCED]
                )
            };
        }

        flatSubmittedFilterGroupValues = parseValuesArrayToString(
            flatSubmittedFilterGroupValues
        );

        flatSubmittedFilterGroupValues = parseValuesSelectAll(
            flatSubmittedFilterGroupValues
        );

        await onFilterSubmit(flatSubmittedFilterGroupValues);
    };

    const {
        [FILTER_LEVEL.BASIC]: filterBasicList,
        [FILTER_LEVEL.ADVANCED]: filterAdvancedList
    } = filterLists;

    const filterGridCommonProps = {
        container: true,
        spacing: 8
    };

    return (
        <Box className="filterGroupFormWrapper" ref={filterGroupFormRef}>
            <AppForm
                className="filterGroupForm"
                initialValues={localFilterGroupValues}
                handleSubmit={handleFilterSubmit}
                formikRef={formikRef}
                render={({ values, setValues, isSubmitting }) => {
                    return (
                        <>
                            <Box className="filterGroupWrapper">
                                {!isEmpty(filterBasicList) && (
                                    <Grid
                                        key="filterBasicList"
                                        {...filterGridCommonProps}>
                                        {filterBasicList.map((filterInfos) =>
                                            renderFilter(
                                                filterInfos,
                                                FILTER_LEVEL.BASIC
                                            )
                                        )}
                                    </Grid>
                                )}
                                {!isEmpty(filterAdvancedList) && (
                                    <Grid
                                        key="filterAdvancedList"
                                        className={cx('advancedFilterList', {
                                            collapsed: !isShowAdvancedFilter
                                        })}
                                        {...filterGridCommonProps}>
                                        {filterAdvancedList.map((filterInfos) =>
                                            renderFilter(
                                                filterInfos,
                                                FILTER_LEVEL.ADVANCED
                                            )
                                        )}
                                    </Grid>
                                )}
                            </Box>

                            <Box className="btnGroupWrapper">
                                <Box className="btnSubGroupWrapper btnSubGroupPrimaryWrapper">
                                    <AppFormSubmitButton
                                        color="primary"
                                        variant="contained"
                                        label={t('common.action.search')}
                                        disabled={isFetching}
                                        isFetching={isFetching} // added this props because `isSubmitting` resume after handleSubmit completed
                                        className="btnSearch"
                                    />
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        label={t('common.action.reset')}
                                        disabled={isSubmitting}
                                        onClick={() =>
                                            handleFilterReset(setValues)
                                        }
                                        className="btnReset"
                                    />
                                </Box>

                                {!isEmpty(filterAdvancedList) && (
                                    <Box className="btnSubGroupWrapper">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className="btnExpand btnIcoLeft"
                                            label={
                                                isShowAdvancedFilter
                                                    ? t('common.action.hide')
                                                    : t('common.action.expand')
                                            }
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                handleToggleAdvancedFilter(
                                                    values,
                                                    setValues
                                                )
                                            }
                                            startIcon={<SearchIco />}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default AppFilterGroupContainer;
