import { Box } from '@mui/material';

import './Remarks.scss';

const Remarks = ({ title, remarkList = [] }) => {
    return (
        <Box className="remark">
            <Box>{title}</Box>
            <Box>
                {remarkList.length > 1 ? (
                    <ol>
                        {remarkList.map((remarkTxt, index) => (
                            <li key={index}>{remarkTxt}</li>
                        ))}
                    </ol>
                ) : (
                    <span className="singleRemark">{remarkList[0]}</span>
                )}
            </Box>
        </Box>
    );
};

export default Remarks;
