import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import { Box, Divider, Link } from '@mui/material';
import cx from 'classnames';

import { ButtonLiveChat, LanguageSwitch } from 'components';

import { setPublicMsg } from 'store/user/slice';

import './AppLogoBar.scss';
import { LogoBlack, LogoWhite } from 'assets/images/common';

const AppLogoBar = ({ theme = 'white' }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { publicMsg } = useSelector((state) => state.user);

    const leavePublicMsgMode = () => {
        if (publicMsg) {
            dispatch(setPublicMsg(false));
            navigate('/');
        }
    };

    return (
        <Box className="appLogoBar">
            <Link className="logo" onClick={() => leavePublicMsgMode()}>
                <img
                    src={theme === 'white' ? LogoWhite : LogoBlack}
                    alt="logo"
                />
            </Link>
            <Box className="supplement">
                <ButtonLiveChat
                    theme={theme}
                    widgetClassName="appLogoBarWidget"
                />
                <Breakpoint desktop only>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        className={cx(['divider', theme])}
                    />
                </Breakpoint>
                <LanguageSwitch theme={theme} />
            </Box>
        </Box>
    );
};

export default AppLogoBar;
