import axiosConfig from 'axios/axiosConfig';

const registrationAPI = {
    getUserLocation: {
        apiCall: (signal = false) =>
            axiosConfig.get('/system/location', { signal }),
        queryKey: 'registration.getUserLocation'
    },
    checkUserExisting: {
        apiCall: (params) =>
            axiosConfig.get('/user/checkUsernameExist', { params }),
        queryKey: 'registration.checkUserExisting'
    },
    registerUser: {
        apiCall: (payload) => axiosConfig.post('/user/signup', payload),
        queryKey: 'registration.registerUser'
    },
    // Document
    uploadUserDocument: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/document/add`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }),
        queryKey: 'registration.uploadUserDocument'
    },
    getUserDocumentList: {
        apiCall: (userId) => axiosConfig.get(`/user/${userId}/document/list`),
        queryKey: 'registration.getUserDocumentList'
    },
    downloadUserDocument: {
        apiCall: (userId, params, signal = false) =>
            axiosConfig.get(`/user/${userId}/document/view`, {
                params,
                responseType: 'blob',
                signal
            }),
        queryKey: 'registration.downloadUserDocument'
    },
    // Identity Info
    getIdentityInformation: {
        apiCall: (userId) => axiosConfig.get(`/user/${userId}/identityInfo`),
        queryKey: 'registration.getIdentityInformation'
    },
    fillIdentityInformation: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/fillIdentity`, payload),
        queryKey: 'registration.fillIdentityInformation'
    },
    fillPersonalInformation: {
        apiCall: (userId, payload) =>
            axiosConfig.post(`/user/${userId}/fillInfo`, payload),
        queryKey: 'registration.fillPersonalInformation'
    },
    // Trading Account
    checkUserMt5Supporting: {
        apiCall: (params) =>
            axiosConfig.get('/user/checkSupportMT5', { params }),
        queryKey: 'registration.checkUserMt5Supporting'
    },
    createTradingAccount: {
        apiCall: (payload) => axiosConfig.post(`/user/relateAccount`, payload),
        queryKey: 'registration.createTradingAccount'
    }
};

export default registrationAPI;
