import { TRADING_ACCOUNT_TYPE } from 'constants/client/tradingAccountType';

export const getAccountTypeLocale = (accountType, t) => {
    switch (accountType) {
        case TRADING_ACCOUNT_TYPE.STANDARD:
        case TRADING_ACCOUNT_TYPE.MANAGER_STANDARD:
            return t('common.user.accountType.standard');
        case TRADING_ACCOUNT_TYPE.PROFESSIONAL:
            return t('common.user.accountType.professional');
        case TRADING_ACCOUNT_TYPE.ECN:
            return t('common.user.accountType.ecn');
        default:
            return '---';
    }
};
