import { Box, TableCell } from '@mui/material';

import PopoverItemDetail from '../PopoverItemDetail/PopoverItemDetail';

export const CELL_ALIGNMENT = 'center';
export const renderTextCell = (cellInfo, index = 0) => {
    let cellProps = {};
    let value = undefined;

    if (typeof cellInfo === 'object') {
        cellProps = cellInfo.cellProps;

        value = cellInfo.value;
    } else {
        value = cellInfo;
    }

    return (
        <TableCell align={CELL_ALIGNMENT} key={index} {...cellProps}>
            {value}
        </TableCell>
    );
};

export const renderProductColCell = (
    cellInfo,
    index,
    accountType,
    detailIndex
) => {
    const { name, symbolGroup, cellProps } = cellInfo;

    return (
        <TableCell align={CELL_ALIGNMENT} key={index} {...cellProps}>
            <Box className="cellInnerWrapper">
                {name}
                {symbolGroup && (
                    <PopoverItemDetail
                        name={name}
                        accountType={accountType}
                        symbolGroup={symbolGroup}
                        detailIndex={detailIndex}
                    />
                )}
            </Box>
        </TableCell>
    );
};
