import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import {
    AppForm,
    ButtonGroupProcess,
    RadioButtonField,
    ScrollSelectTradeAccountField,
    SelectComboTradePlatformField,
    SelectDropdownField,
    TextField
} from 'components';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { useTradeAccountInfo } from 'hooks';

import { getAccountTypeLocale } from 'utils/client/accountType';
import { hasNoTradeLoginId } from 'utils/payloadParsing';
import { arrayValidation, commonTextValidation } from 'utils/yup';

import useDialogResetCommission from '../DialogResetCommission/useDialogResetCommission';
import './AccountLinkInfoForm.scss';

const validationSchema = yup.object({
    accountLinkName: commonTextValidation,
    platforms: arrayValidation
});

const AccountLinkInfoForm = ({
    onNextStep = () => {},
    onCancel = () => {},
    initAccountLinkInfo = {},
    availableAccountTypes = [],
    accountLinkInfoValues = {},
    setAccountLinkFormValues = null,
    commissionValues,
    accessType
}) => {
    const { t } = useTranslation();
    const { openDialogResetCommission } = useDialogResetCommission(
        setAccountLinkFormValues
    );
    const { tradeAccountInfo, getTradeAccountInfo } = useTradeAccountInfo();

    const infoValues = isEmpty(accountLinkInfoValues)
        ? initAccountLinkInfo
        : accountLinkInfoValues;

    const {
        tradeLoginId,
        accountLinkName,
        permitInvite,
        accountType,
        id,
        platforms
    } = infoValues;

    useEffect(() => {
        getTradeAccountInfo(tradeLoginId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeLoginId]);

    const initialFormValues = {
        tradeLoginId,
        permitInvite,
        accountLinkName,
        accountType,
        id,
        platforms
    };

    return (
        <Box className="formAccountInfoWrapper">
            <AppForm
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                handleSubmit={onNextStep}
                render={({ setFieldValue }) => {
                    return (
                        <>
                            <ScrollSelectTradeAccountField
                                label={t('common.tradeAccount.name')}
                                tradeAccounts={[
                                    {
                                        tradeLoginId,
                                        role: tradeAccountInfo.role
                                    }
                                ]}
                                defaultTradeLoginId={tradeLoginId}
                                isTriggerInitHandleSelect={true}
                                handleSelectTradeLoginId={(
                                    tradeLoginId,
                                    isInitHandleSelect
                                ) => {
                                    if (
                                        hasNoTradeLoginId(tradeLoginId) ||
                                        tradeLoginId === infoValues.tradeLoginId
                                    ) {
                                        return;
                                    }

                                    setFieldValue('tradeLoginId', tradeLoginId);

                                    !isInitHandleSelect &&
                                        !isEmpty(commissionValues) &&
                                        openDialogResetCommission();
                                }}
                                showSelectAll={false}
                                showCurrOnly={
                                    accessType !== INFO_ACCESS_TYPE.NEW
                                }
                            />

                            <TextField
                                fullWidth
                                name="accountLinkName"
                                label={t(
                                    'customerMgmt.accountLink.accountLinkName'
                                )}
                                disabled={accessType === INFO_ACCESS_TYPE.READ}
                                isRequired
                            />

                            <RadioButtonField
                                fullWidth
                                name="permitInvite"
                                label={t(
                                    'customerMgmt.accountLink.assignClientAsUserIB'
                                )}
                                disabled={accessType === INFO_ACCESS_TYPE.READ}
                                items={[
                                    {
                                        itemValue: true,
                                        itemLabel: t(`common.action.agree`)
                                    },
                                    {
                                        itemValue: false,
                                        itemLabel: t(`common.action.disagree`)
                                    }
                                ]}
                                isRequired
                            />

                            <SelectDropdownField
                                fullWidth
                                name="accountType"
                                label={t(
                                    'customerMgmt.accountLink.clientAccountType'
                                )}
                                items={
                                    accessType === INFO_ACCESS_TYPE.NEW
                                        ? availableAccountTypes
                                        : [initAccountLinkInfo]
                                }
                                itemValueKey="accountType"
                                renderLabel={({ accountType }) =>
                                    getAccountTypeLocale(accountType, t)
                                }
                                defaultValue={initAccountLinkInfo.accountType}
                                disabled={accessType !== INFO_ACCESS_TYPE.NEW}
                                isRequired
                            />

                            <SelectComboTradePlatformField
                                label={t('common.tradeAccount.platform')}
                                name="platforms"
                                disabled={accessType !== INFO_ACCESS_TYPE.NEW}
                                isRequired
                            />

                            {accessType !== INFO_ACCESS_TYPE.READ && (
                                <ButtonGroupProcess
                                    labelTop={t('common.action.next')}
                                    typeTop="submit"
                                    labelBottom={t('common.action.cancel')}
                                    onClickBottom={onCancel}
                                />
                            )}
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default AccountLinkInfoForm;
