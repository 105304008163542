import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import { PAGE_FULL_STATIC_PATH } from 'constants/routes';

import './Shortcut.scss';
import {
    ClientMgmtShortcut2Ico,
    ClientMgmtShortcutIco,
    DashboardThumbIco,
    DepositIco,
    FundFlow2Ico,
    FundFlowIco,
    IncomeDetailIco,
    MyIncomeIco,
    OpenAccountIco,
    Order2Ico,
    OrderIco,
    RelationTreeIco,
    TradeHistory2Ico,
    TradeHistoryIco,
    TransferIco,
    WithdrawIco
} from 'assets/images/common';

const Shortcut = ({ displayFor = '' }) => {
    const { t } = useTranslation();

    let shortcutInfos = [];
    if (displayFor === 'ibHome') {
        shortcutInfos = [
            {
                icon: <ClientMgmtShortcutIco />,
                label: t('common.sidebar.basicInfo.shortcut.clientMgmt'),
                link: '/clients-mgmt/registered-clients'
            },
            {
                icon: <MyIncomeIco />,
                label: t('common.sidebar.basicInfo.shortcut.myIcome'),
                link: '/my-revenue/income-report'
            },
            {
                icon: <OpenAccountIco />,
                label: t('common.sidebar.basicInfo.shortcut.openAcLink'),
                link: PAGE_FULL_STATIC_PATH.ReferralLink
            }
        ];
    } else if (displayFor === 'managerHome') {
        shortcutInfos = [
            {
                icon: <ClientMgmtShortcut2Ico />,
                label: t('common.navbar.clientMgmt.title'),
                link: '/clients-mgmt/registered-clients'
            },
            {
                icon: <IncomeDetailIco />,
                label: t('common.navbar.commissionMgmt.commissionDetail'),
                link: '/my-commission/commission-details'
            },
            {
                icon: <RelationTreeIco />,
                label: t('common.navbar.clientMgmt.relationTree'),
                link: '/clients-mgmt/relation-map'
            },
            {
                icon: <TradeHistory2Ico />,
                label: t('common.sidebar.basicInfo.shortcut.tradeHistory'),
                link: '/reports/trading-history'
            },
            {
                icon: <Order2Ico />,
                label: t('common.sidebar.basicInfo.shortcut.order'),
                link: '/reports/opening-orders'
            },
            {
                icon: <FundFlow2Ico />,
                label: t('common.sidebar.basicInfo.shortcut.fundFlow'),
                link: '/reports/funds-history'
            }
        ];
    } else {
        shortcutInfos = [
            {
                icon: <DepositIco />,
                label: t('common.sidebar.basicInfo.shortcut.deposit'),
                link: '/fund-mgmt/deposits'
            },
            {
                icon: <WithdrawIco />,
                label: t('common.sidebar.basicInfo.shortcut.withdraw'),
                link: '/fund-mgmt/withdrawals'
            },
            {
                icon: <TransferIco />,
                label: t('common.sidebar.basicInfo.shortcut.transfer'),
                link: '/fund-mgmt/transfer'
            },
            {
                icon: <TradeHistoryIco />,
                label: t('common.sidebar.basicInfo.shortcut.tradeHistory'),
                link: '/reports/trading-history'
            },
            {
                icon: <OrderIco />,
                label: t('common.sidebar.basicInfo.shortcut.order'),
                link: '/reports/opening-orders'
            },
            {
                icon: <FundFlowIco />,
                label: t('common.sidebar.basicInfo.shortcut.fundFlow'),
                link: '/fund-mgmt/funds-history'
            }
        ];
    }

    return (
        <Box
            className={cx('shortcut', {
                manager: displayFor === 'managerHome'
            })}>
            {displayFor === 'managerHome' && (
                <Box className="dashboardThumb">
                    <img src={DashboardThumbIco} alt="" />
                </Box>
            )}
            <Box className="shortcutHeader">
                <Typography component="h2">
                    {t('common.sidebar.basicInfo.shortcut.title')}
                </Typography>
            </Box>
            <Box className="shortcutList">
                {shortcutInfos.map((info, index) => (
                    <Link className="shortcutItm" to={info.link} key={index}>
                        <Box className="shortcutItmContent">
                            {info.icon}
                            <Typography>{info.label}</Typography>
                        </Box>
                    </Link>
                ))}
            </Box>
        </Box>
    );
};

export default Shortcut;
