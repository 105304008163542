import { Box } from '@mui/material';

import AppQRCodeImage from '../AppQRCodeImage/AppQRCodeImage';
import './QRCodeBoard.scss';
import { CornerImg } from 'assets/images/common';

export default function QRCodeBoard({ codeValue }) {
    return (
        <Box className="qrCodeBoardWrapper">
            <Box component="section" className="qrCodeWrapper">
                <Box className="qrCode">
                    {codeValue !== undefined && (
                        <AppQRCodeImage codeValue={codeValue} />
                    )}
                    <img className="frameTopL" src={CornerImg} alt="" />
                    <img className="frameTopR" src={CornerImg} alt="" />
                    <img className="frameBottomL" src={CornerImg} alt="" />
                    <img className="frameBottomR" src={CornerImg} alt="" />
                </Box>
            </Box>
        </Box>
    );
}
