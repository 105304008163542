import API from 'services';

export const ACCOUNT_LINK_LIST_TYPE = {
    SELF_LIST: {
        type: 'SELF_LIST',
        apiCall: API.customerMgmt.getAccountLinkList.apiCall
    },
    SUB_LIST: {
        type: 'SUB_LIST',
        apiCall: API.customerMgmt.getAccountLinkSubList.apiCall
    }
};
