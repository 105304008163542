import { Box } from '@mui/material';
import cx from 'classnames';

import './SectionDashLineWrapper.scss';

const SectionDashLineWrapper = ({ className, children, ...props }) => {
    return (
        <Box className={cx(['sectionDashLinWrapper', className])} {...props}>
            {children}
        </Box>
    );
};

export default SectionDashLineWrapper;
