import { useDialog } from 'utils/providers/DialogProvider';

const useCloseDialog = (closeCallBack) => {
    const { closeDialog } = useDialog();

    return {
        closeDialog: () => {
            closeCallBack && closeCallBack();
            closeDialog();
        }
    };
};

export default useCloseDialog;
