import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Popover } from '@mui/material';

import { AppTableContainer } from 'layouts';

import { useTradingProducts } from 'hooks';

import './PopoverItemDetail.scss';
import { LightBulbIco } from 'assets/images/common';

const PopoverItemDetail = ({ name, accountType, symbolGroup, detailIndex }) => {
    const { t } = useTranslation();
    const { tradingProducts } = useTradingProducts(accountType, symbolGroup);
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const tableInfos = [
        {
            label: t('common.general.product'),
            field: 'productName'
        },
        {
            label: t('tradeReports.tradeProduct.tradeSymbol'),
            field: 'symbol'
        }
    ];

    return (
        <>
            <Box
                onClick={openMenu}
                onMouseEnter={openMenu}
                onMouseLeave={closeMenu}
                className="btnMoreDetail">
                {detailIndex}
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                disableAutoFocus
                className="popoverCommissionDetail"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}>
                <Box>
                    <Box className="title">
                        <LightBulbIco className="lightBulbIco" />
                        {name}
                    </Box>

                    <AppTableContainer
                        tableInfos={tableInfos}
                        rowsData={tradingProducts}
                    />
                </Box>
            </Popover>
        </>
    );
};

export default PopoverItemDetail;
