import React from 'react';

import { Box } from '@mui/material';

import { AppCard } from 'layouts';

import './ValueIconCard.scss';

const ValueIconCard = ({ value, label, Icon }) => {
    return (
        <AppCard className="valueIconCard">
            <Box className="value">{value ?? '---'}</Box>
            <Box className="label">{label}</Box>
            <Icon className="icon" />
        </AppCard>
    );
};

export default ValueIconCard;
