import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';

import { Button, TextField } from 'components';

import './FieldActionSearch.scss';

const FieldActionSearch = ({
    className,
    value = '',
    handleChange = null,
    fieldName = null,
    handleSearch,
    placeholder,
    disabled = false
}) => {
    const { t } = useTranslation();
    const buttonRef = useRef(null);
    const [localValue, setLocalValue] = useState(value);

    const isUseLocalValue = Boolean(!handleChange);

    const onChange = (newValue) => {
        if (isUseLocalValue) {
            setLocalValue(newValue);
        } else {
            handleChange(newValue);
        }
    };

    return (
        <Box className={cx(['fieldActionSearch', className])}>
            <Box className="fieldWrapper">
                <TextField
                    withoutFormikHook
                    name={fieldName}
                    value={isUseLocalValue ? localValue : value}
                    handleChange={(event) => onChange(event.target.value)}
                    clearValue={() => onChange('')}
                    placeholder={placeholder}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            buttonRef.current && buttonRef.current.click();
                        }
                    }}
                />
            </Box>

            <Box className="btnWrapper">
                <Button
                    buttonRef={buttonRef}
                    label={t('common.action.search')}
                    className="btnSearch"
                    variant="contained"
                    onClick={() =>
                        handleSearch(handleChange ? value : localValue)
                    }
                    disabled={disabled}
                />
            </Box>
        </Box>
    );
};

export default FieldActionSearch;
