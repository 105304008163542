import { createSlice } from '@reduxjs/toolkit';

import { KEY, getItemSync } from 'utils/localStorage';

const initialUserState = {
    isLogin: false,
    wasLogin: false,
    username: undefined,
    email: undefined,
    userId: undefined,
    userRole: undefined,
    userAccStatus: undefined,
    docStatus: undefined,
    infoStatus: undefined,
    tradeLoginId: undefined,
    queryTradeLoginId: undefined,
    platforms: [],
    country: undefined,
    displayName: undefined,
    currency: undefined,
    authToken: '',
    publicMsg: false,
    userLevel: undefined
};

export const getInitialUserState = () => {
    const userState = getItemSync(KEY.CREDENTIAL, {}, 'true');

    return {
        ...initialUserState,
        ...userState
    };
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        ...getInitialUserState()
    },
    reducers: {
        setUserAccStatus: (state, action) => {
            state.userAccStatus = action.payload;
        },
        setDocStatus: (state, action) => {
            state.docStatus = action.payload;
        },
        setInfoStatus: (state, action) => {
            state.infoStatus = action.payload;
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setQueryTradeLoginId: (state, action) => {
            state.queryTradeLoginId = action.payload;
        },
        setUserInfo: (state, action) => ({
            ...state,
            ...action.payload
        }),
        setUserCredential: (state, action) => ({
            ...state,
            ...action.payload
        }),
        resetUserCredential: () => ({ ...initialUserState }),
        setCurrProcess: (state, action) => {
            state.currProcess = action.payload;
        },
        setPublicMsg: (state, action) => {
            state.publicMsg = action.payload;
        },
        setUserLevel: (state, action) => {
            state.userLevel = action.payload;
        }
    }
});

export default userSlice;

export const {
    setUserCredential,
    resetUserCredential,
    setCurrProcess,
    setUserAccStatus,
    setDocStatus,
    setInfoStatus,
    setUserRole,
    setQueryTradeLoginId,
    setUserInfo,
    setPublicMsg,
    setUserLevel
} = userSlice.actions;
