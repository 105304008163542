import { lazy } from 'react';

import {
    CheckListIco,
    DocumentSharpIco,
    HouseCoinIco
} from 'assets/images/userInfo';

export const TabPages = {
    RewardHome: lazy(() => import('./RewardHome/RewardHome')),
    RewardRecord: lazy(() => import('./RewardRecord/RewardRecord')),
    RewardRegulation: lazy(() => import('./RewardRegulation/RewardRegulation')),
    RewardRecordResult: lazy(() =>
        import('./RewardRecordResult/RewardRecordResult')
    ),
    RewardRedemption: lazy(() => import('./RewardRedemption/RewardRedemption'))
};

export const REWARD_RELATIVE_PATH_NAME = {
    REWARD_HOME: '',
    REWARD_DETAIL: 'detail',
    REWARD_REDEMPTION: 'item/:id',
    REWARD_DETAIL_RESULT: 'detail/:rewardId',
    REWARD_REGULATION: 'regulation'
};

export const RewardRoutes = {
    REWARD_HOME: {
        path: REWARD_RELATIVE_PATH_NAME.REWARD_HOME,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        isOnTab: true,
        title: 'common.navbar.userInfo.mallHome',
        element: <TabPages.RewardHome />,
        icon: <HouseCoinIco />,
        menuOnPaths: [REWARD_RELATIVE_PATH_NAME.REWARD_REDEMPTION]
    },
    REWARD_DETAIL: {
        path: REWARD_RELATIVE_PATH_NAME.REWARD_DETAIL,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        isOnTab: true,
        title: 'common.navbar.userInfo.rewardDetail',
        element: <TabPages.RewardRecord />,
        icon: <CheckListIco />,
        menuOnPaths: [REWARD_RELATIVE_PATH_NAME.REWARD_DETAIL_RESULT]
    },
    REWARD_DETAIL_RESULT: {
        path: REWARD_RELATIVE_PATH_NAME.REWARD_DETAIL_RESULT,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        title: 'common.navbar.userInfo.rewardDetail',
        element: <TabPages.RewardRecordResult />
    },
    REWARD_REGULATION: {
        path: REWARD_RELATIVE_PATH_NAME.REWARD_REGULATION,
        withoutRegStatusFallBack: true,
        isOnApprovingAccountMenu: true,
        isOnTab: true,
        title: 'common.navbar.userInfo.rewardRegulation',
        element: <TabPages.RewardRegulation />,
        icon: <DocumentSharpIco />
    },
    REWARD_REDEMPTION: {
        path: REWARD_RELATIVE_PATH_NAME.REWARD_REDEMPTION,
        withoutRegStatusFallBack: true,
        title: 'common.navbar.userInfo.mallHome',
        element: <TabPages.RewardRedemption />
    }
};

export const getTabRewardRouteList = (t = () => {}) => {
    const tabPageRouteList = [];

    Object.entries(RewardRoutes).forEach(([_routeName, routeInfo]) => {
        if (routeInfo.isOnTab) {
            tabPageRouteList.push({
                ...routeInfo,
                title: t(routeInfo.title)
            });
        }
    });

    return tabPageRouteList;
};
