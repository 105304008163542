const hasNumber = (value) => {
    return new RegExp(/[0-9]/).test(value);
};
const hasMixedChars = (value) => {
    return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};
const hasSpecialCharacters = (value) =>
    new RegExp(/[!#$%&*+,.:;<>?@^_~-]/).test(value);
const countSpecialCharacters = (value) =>
    (value.match(new RegExp(/[!#$%&*+,.:;<>?@^_~-]/g)) || []).length;

export const getStrengthLevel = (value) => {
    let strengths = 0;
    if (value.length > 7) {
        strengths++;
    }
    if (strengths > 0) {
        strengths++;
    }
    if (
        strengths > 1 &&
        hasNumber(value) &&
        hasSpecialCharacters(value) &&
        hasMixedChars(value)
    ) {
        strengths++;
    }
    if (strengths > 2 && countSpecialCharacters(value) > 2) {
        strengths++;
    }
    if (
        strengths > 3 &&
        value.length > 12 &&
        countSpecialCharacters(value) > 3
    ) {
        strengths++;
    }

    return strengths;
};

export const getStrengthInfo = (level) => {
    if (level < 2) {
        return { color: 'red', i18nKey: 'tooShort', barLength: '20%' };
    }
    if (level < 3) {
        return { color: 'orange', i18nKey: 'weak', barLength: '35%' };
    }
    if (level < 4) {
        return { color: 'gold', i18nKey: 'okay', barLength: '50%' };
    }
    if (level < 5) {
        return { color: 'blue', i18nKey: 'good', barLength: '75%' };
    }
    if (level < 6) {
        return { color: 'green', i18nKey: 'strong', barLength: '100%' };
    }
};
