import { USER_ROLE } from 'constants/authInfos';

export const getUserRoleBrief = (role) => {
    switch (role) {
        case USER_ROLE.MAJORDOMO:
        case USER_ROLE.MANAGER:
            return 'M';
        case USER_ROLE.IB:
            return 'IB';
        case USER_ROLE.CUSTOMER:
            return 'C';
        default:
            return '';
    }
};
