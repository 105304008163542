import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCityList, useTableBankStatus } from 'hooks';

const useBankCardCompletedModify = ({
    states = [],
    bankNames = [],
    fieldLabels = {}
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { country, userId } = useSelector((state) => state.user);
    const { cities, getCities } = useCityList();
    const { bankAccountList, getAccountList } = useTableBankStatus(
        userId,
        country
    );
    const [resultedBankNumber, setResultedBankNumber] = useState(undefined);

    const tradeAccountInfo =
        bankAccountList.find(
            ({ bankNumber }) =>
                Number(bankNumber) === Number(resultedBankNumber)
        ) || {};

    useEffect(() => {
        if (!resultedBankNumber) return;
        getAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultedBankNumber]);

    useEffect(() => {
        getCities(tradeAccountInfo.stateId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeAccountInfo]);

    const state =
        states?.find((state) => state.stateId === tradeAccountInfo.stateId) ||
        {};

    const city =
        cities?.find((city) => city.cityId === tradeAccountInfo.cityId) || {};

    const bankName =
        bankNames?.find((bank) => bank.bankId === tradeAccountInfo.bankId) ||
        {};

    let resultInfos = [
        {
            value: state.value,
            label: t('common.user.communication.state')
        },
        { value: city.value, label: t('common.user.communication.city') },
        {
            value: bankName.value || tradeAccountInfo.bankName,
            label: t('common.user.bank.bankName')
        },
        {
            value: tradeAccountInfo.branch,
            label: t('common.user.bank.branchName')
        },
        {
            value: tradeAccountInfo.bankNumber,
            label: t('common.user.bank.payeeBankAccount')
        },
        {
            value: tradeAccountInfo.userName,
            label: t('common.user.bank.payeeName')
        }
    ];

    const isResultReady = resultInfos.findIndex(({ value }) => !!value) > -1;

    if (!isResultReady) {
        resultInfos = [];
    }

    const redirectBankCardPage = () => {
        navigate('/user-info/bank-card');
    };

    return {
        resultInfos,
        setResultedBankNumber,
        redirectBankCardPage
    };
};

export default useBankCardCompletedModify;
