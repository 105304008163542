import { setIsFillingInformation } from './slice';

export const redirectRegistrationPages = (navigate) => (dispatch) => {
    dispatch(setIsFillingInformation(true));
    navigate('/registration');
};

export const handleRejectedRegistration = (navigate) => (dispatch) => {
    dispatch(setIsFillingInformation(true));
    navigate('/registration/upload-certificate');
};
