import { useState } from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLE } from 'constants/authInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';

import API from 'services';
import { hasNoTradeLoginId } from 'utils/payloadParsing';
import { handleAsyncCall } from 'utils/utils';

const useParentTraderList = () => {
    const { userRole, userId } = useSelector((state) => state.user);
    const [parentTraderList, setParentTraderList] = useState([]);
    const [isListReady, setIsListReady] = useState(false);

    const getParentTradeList = (
        tradeLoginId = VALUES_ALL_TRADE_LOGIN_IDS.APP
    ) => {
        if (hasNoTradeLoginId(tradeLoginId)) return;

        const managerParams = {
            includeIb: true,
            tradeLoginId
        };

        const ibParams = {
            tradeLoginId
        };

        const getAccountsAPICall = {
            [USER_ROLE.MAJORDOMO]: () =>
                API.customerMgmt.getManagerSubTradeAccounts.apiCall(
                    userId,
                    managerParams
                ),
            [USER_ROLE.MANAGER]: () =>
                API.customerMgmt.getManagerSubTradeAccounts.apiCall(
                    userId,
                    managerParams
                ),
            [USER_ROLE.IB]: () =>
                API.customerMgmt.getIBSubTradeAccount.apiCall(userId, ibParams)
        };

        handleAsyncCall({
            asyncCall: getAccountsAPICall[userRole],
            handleCallSuccess: (res) => {
                setParentTraderList(res.data.list || []);
                setIsListReady(true);
            },
            handleCallFailureError: () => {}
        });
    };

    return {
        parentTraderList,
        getParentTradeList,
        isListReady
    };
};

export default useParentTraderList;
