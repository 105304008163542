import React from 'react';

import { DateRangePickerField } from 'components';

const FilterDateRange = ({
    formikFieldNames,
    label,
    placeholder,
    allowDisable
}) => (
    <DateRangePickerField
        label={label}
        placeholder={placeholder}
        formikFieldNames={formikFieldNames}
        wrapperClassName="filterFieldWrapper"
        allowDisable={allowDisable}
    />
);

export default FilterDateRange;
