import Pages from 'pages';

import { REGISTRATION_STATUS, REGISTRATION_STEP } from 'constants/registration';
import {
    ACC_STATUS,
    DOC_STATUS,
    INFO_STATUS
} from 'constants/serverInfos/serverInfos';

export const getRegistrationStep = (accStatus, docStatus, infoStatus) => {
    const isRejected =
        accStatus === ACC_STATUS.REJECTED ||
        accStatus === ACC_STATUS.MANAGER_REJECTED;
    const isAllDocPending =
        docStatus === DOC_STATUS.PENDING && infoStatus === INFO_STATUS.PENDING;
    const isOnePendingAndOneApproved =
        (docStatus === DOC_STATUS.APPROVED &&
            infoStatus === INFO_STATUS.PENDING) ||
        (docStatus === DOC_STATUS.PENDING &&
            infoStatus === DOC_STATUS.APPROVED);

    switch (true) {
        case isAllDocPending:
        case isOnePendingAndOneApproved:
        case isRejected:
            return REGISTRATION_STEP.REGISTER_COMPLETED;
        case docStatus === DOC_STATUS.PENDING:
        case docStatus === DOC_STATUS.APPROVED:
            return REGISTRATION_STEP.PERSONAL_INFORMATION;
        default:
            return REGISTRATION_STEP.UPLOAD_CERTIFICATE;
    }
};

export const getRegistrationStatus = (accStatus, docStatus, infoStatus) => {
    switch (accStatus) {
        case ACC_STATUS.REGISTERED:
            return REGISTRATION_STATUS.INCOMPLETE_INFORMATION;
        case ACC_STATUS.DOC_INFO_SUBMITTED:
            return docStatus === DOC_STATUS.PENDING &&
                infoStatus === INFO_STATUS.PENDING
                ? REGISTRATION_STATUS.AUDITING
                : REGISTRATION_STATUS.INCOMPLETE_INFORMATION;
        case ACC_STATUS.MANAGER_REJECTED:
        case ACC_STATUS.REJECTED:
        case ACC_STATUS.AUDIT_FAILED:
            return REGISTRATION_STATUS.AUDIT_FAILED;
        case ACC_STATUS.MANAGER_APPROVED:
        case ACC_STATUS.APPROVED:
            return REGISTRATION_STATUS.AUDIT_PASSED;
        default:
            return null;
    }
};

export const getElementByRegStatus = (DefaultElement, registrationStatus) => {
    switch (registrationStatus) {
        case REGISTRATION_STATUS.INCOMPLETE_INFORMATION:
            return <Pages.NotEnoughInformation />;
        case REGISTRATION_STATUS.AUDITING:
            return <Pages.Auditing />;
        case REGISTRATION_STATUS.AUDIT_FAILED:
            return <Pages.AuditFailed />;
        case REGISTRATION_STATUS.AUDIT_PASSED:
            return DefaultElement;
        default:
            return <Pages.NotEnoughInformation />;
    }
};
