import axiosConfig from 'axios/axiosConfig';

const traderReports = {
    getOrderList: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/positionOrder`, { params }),
        queryKey: 'traderReports.getOrderList'
    },
    getOrdersStatistics: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/positionStat`, { params }),
        queryKey: 'traderReports.getOrdersStatistics'
    },
    getTradeHistoryLogs: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/tradeLogs`, { params }),
        queryKey: 'traderReports.getTradeHistoryLogs'
    },
    getTradeHistoryStats: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/tradeStat`, { params }),
        queryKey: 'traderReports.getTradeHistoryStats'
    },
    getTradeHistoryExport: {
        apiCall: (userId, params) =>
            axiosConfig.get(`/pay/${userId}/tradeLogs/export`, {
                params,
                responseType: 'blob'
            }),
        queryKey: 'traderReports.getTradeHistoryExport'
    },
    getMt5ProductList: {
        apiCall: (params) => axiosConfig.get('/system/mt5Symbols', { params }),
        queryKey: 'traderReports.getMt5ProductList'
    },
    getDailyStatement: {
        apiCall: (tradeLoginId, params) =>
            axiosConfig.get(`/pay/${tradeLoginId}/dailyStatement`, { params }),
        queryKey: 'traderReports.getDailyStatement'
    },
    getMonthlyStatement: {
        apiCall: (tradeLoginId, params) =>
            axiosConfig.get(`/pay/${tradeLoginId}/monthlyStatement`, {
                params
            }),
        queryKey: 'traderReports.getMonthlyStatement'
    }
};

export default traderReports;
