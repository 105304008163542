import React from 'react';

import cx from 'classnames';

import { TabControl } from 'components';
import { AppCard } from 'layouts';

import './CardTabControl.scss';

const CardTabControl = ({
    appCardClassName,
    tabInfoList,
    handleChange,
    renderOnce,
    controlledValue = null,
    isHideTabs = false
}) => {
    return (
        <AppCard className={cx(['cardTabControl', appCardClassName])}>
            <TabControl
                tabContentClassName="cardTabContent"
                variant="cardTab"
                tabInfoList={tabInfoList}
                handleChange={handleChange}
                renderOnce={renderOnce}
                controlledValue={controlledValue}
                isHideTabs={isHideTabs}
            />
        </AppCard>
    );
};

export default CardTabControl;
