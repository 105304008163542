import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { useFormikContext } from 'formik';

import { SelectDropdownField, TextField } from 'components';

import { FIELD_TYPE } from 'constants/appFieldsInfos';

import { CELL_ALIGNMENT, renderProductColCell, renderTextCell } from './utils';

export const CELL_INFO_TYPES = {
    TITLE: 'TITLE',
    SUB_TITLE: 'SUB_TITLE',
    DATA: 'DATA'
};

const TableCommissionContainer = ({
    isReadOnly,
    title,
    subTitle,
    data,
    isShowProductCol = true,
    handleSubTitleOffsetHeightChange = () => {},
    minSubTitleCellHeight,
    tableWidth,
    accountType
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();

    const tableRowRef = useRef(null);

    const renderOptionsCell = (cellInfo, index) => {
        const { cellFieldType, name, items, cellProps = {} } = cellInfo;

        switch (cellFieldType) {
            case FIELD_TYPE.SELECT_DROPDOWN_FIELD:
                return (
                    <TableCell
                        align={CELL_ALIGNMENT}
                        key={index}
                        {...cellProps}>
                        <SelectDropdownField
                            name={name}
                            items={items}
                            itemLabelKey="label"
                            itemValueKey="value"
                            disabled={isReadOnly}
                        />
                    </TableCell>
                );
            case FIELD_TYPE.NUMBER_INPUT_FIELD:
                return (
                    <TableCell
                        align={CELL_ALIGNMENT}
                        key={index}
                        {...cellProps}>
                        <TextField
                            inputType="number"
                            name={cellInfo.name}
                            disabled={isReadOnly}
                            showDefaultRightAdornment={false}
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            placeholder={t('common.placeholder.percentage')}
                            handleAfterFormikChange={(event) => {
                                const inputNumber = Number(event.target.value);
                                if (inputNumber < 0) {
                                    setFieldValue(cellInfo.name, 0);
                                    return;
                                }

                                if (inputNumber > 100) {
                                    setFieldValue(cellInfo.name, 100);
                                    return;
                                }
                            }}
                        />
                    </TableCell>
                );
            case FIELD_TYPE.PLAIN_TEXT_FIELD:
            default:
                return renderTextCell(cellInfo, index);
        }
    };

    const tableTitleProps = {
        ...title,
        cellInfoType: CELL_INFO_TYPES.TITLE
    };

    const subTitleOffsetHeight = tableRowRef?.current?.offsetHeight;

    useEffect(() => {
        if (!subTitleOffsetHeight) return;

        handleSubTitleOffsetHeightChange(subTitleOffsetHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subTitleOffsetHeight]);

    let productDetailIndex = 0;

    return (
        <Table sx={{ width: tableWidth }}>
            <TableHead>
                <TableRow className="tableTitleRow">
                    {renderTextCell(tableTitleProps)}
                </TableRow>
                <TableRow
                    className="tableSubTitleRow"
                    ref={tableRowRef}
                    sx={{ height: minSubTitleCellHeight }}>
                    {subTitle
                        .slice(isShowProductCol ? 0 : 1)
                        .map((subTitleData, index) =>
                            renderTextCell(subTitleData, index)
                        )}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((rowInfos, index) => {
                    return (
                        <TableRow key={index} className="tableBodyRow">
                            {rowInfos
                                .slice(isShowProductCol ? 0 : 1)
                                .map((cellInfo, index) => {
                                    const isProductCol =
                                        isShowProductCol && index === 0;

                                    if (isProductCol) {
                                        if (cellInfo.symbolGroup) {
                                            productDetailIndex++;
                                        }

                                        return renderProductColCell(
                                            cellInfo,
                                            index,
                                            accountType,
                                            productDetailIndex
                                        );
                                    } else {
                                        return renderOptionsCell(
                                            cellInfo,
                                            index
                                        );
                                    }
                                })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default TableCommissionContainer;
