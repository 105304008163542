import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { Button } from 'components';
import StepperDot from 'components/Steppers/StepperDot/StepperDot';
import { AppCard } from 'layouts';

import { REGISTRATION_STEP } from 'constants/registration';
import {
    ACC_STATUS,
    DOC_STATUS,
    INFO_STATUS
} from 'constants/serverInfos/serverInfos';
import { redirectRegistrationPages } from 'store/registration/reducer';

import { getRegistrationStep } from 'utils/registration';

import './AcStatusCard.scss';

const ACC_STATUS_STEP = {
    REGISTERED: 'registered',
    UPLOAD_CERT: 'uploadCert',
    PERSONAL_INFO: 'personalInfo',
    APPROVE: 'approve'
};

const AcStatusCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userAccStatus, docStatus, infoStatus } = useSelector(
        (state) => state.user
    );

    if (
        userAccStatus === ACC_STATUS.APPROVED ||
        userAccStatus === ACC_STATUS.MANAGER_APPROVED ||
        (docStatus === DOC_STATUS.APPROVED &&
            infoStatus === INFO_STATUS.APPROVED)
    ) {
        return null;
    }

    const acStatusSteps = [
        {
            stepRouteName: ACC_STATUS_STEP.REGISTERED,
            stepName: t('home.dashboard.acStatus.step.registration')
        },
        {
            stepRouteName: ACC_STATUS_STEP.UPLOAD_CERT,
            stepName: t('common.registration.step.uploadCertificate')
        },
        {
            stepRouteName: ACC_STATUS_STEP.PERSONAL_INFO,
            stepName: t('common.registration.step.personalInformation')
        },
        {
            stepRouteName: ACC_STATUS_STEP.APPROVE,
            stepName:
                userAccStatus === ACC_STATUS.MANAGER_REJECTED ||
                userAccStatus === ACC_STATUS.REJECTED
                    ? t('home.dashboard.acStatus.step.rejected')
                    : t('home.dashboard.acStatus.step.pending')
        }
    ];

    let currentStatus = ACC_STATUS_STEP.UPLOAD_CERT;
    const registrationStep = getRegistrationStep(
        userAccStatus,
        docStatus,
        infoStatus
    );
    switch (registrationStep) {
        default:
        case REGISTRATION_STEP.UPLOAD_CERTIFICATE:
            currentStatus = ACC_STATUS_STEP.UPLOAD_CERT;
            break;
        case REGISTRATION_STEP.PERSONAL_INFORMATION:
            currentStatus = ACC_STATUS_STEP.PERSONAL_INFO;
            break;
        case REGISTRATION_STEP.REGISTER_COMPLETED:
            currentStatus = ACC_STATUS_STEP.APPROVE;
            break;
    }

    return (
        <Box className="acStatusCard">
            <AppCard
                title={
                    userAccStatus === ACC_STATUS.MANAGER_REJECTED ||
                    userAccStatus === ACC_STATUS.REJECTED
                        ? t('home.dashboard.acStatus.status.rejected')
                        : currentStatus === ACC_STATUS_STEP.APPROVE
                        ? t('home.dashboard.acStatus.status.pending')
                        : t('home.dashboard.acStatus.status.processing')
                }>
                <Box className="acStatusContent">
                    <StepperDot
                        stepInfos={acStatusSteps}
                        currRouteName={currentStatus}
                        userAccStatus={userAccStatus}
                        isStatus
                    />

                    {(currentStatus !== ACC_STATUS_STEP.APPROVE ||
                        userAccStatus === ACC_STATUS.MANAGER_REJECTED ||
                        userAccStatus === ACC_STATUS.REJECTED) && (
                        <Button
                            className="btnProcess"
                            variant="contained"
                            label={t('common.action.clickHandle')}
                            onClick={() =>
                                dispatch(redirectRegistrationPages(navigate))
                            }
                        />
                    )}
                </Box>
            </AppCard>
        </Box>
    );
};

export default AcStatusCard;
