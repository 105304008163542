import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useTradingExperiences = () => {
    const [tradingExperiences, setTradingExperiences] = useState([]);

    const getTradingExperiences = () =>
        handleAsyncCall({
            asyncCall: () => API.common.getInvestmentExperienceList.apiCall(),
            handleCallSuccess: (res) => setTradingExperiences(res.data.list)
        });

    useEffect(() => {
        getTradingExperiences();
    }, []);

    return { tradingExperiences, getTradingExperiences };
};

export default useTradingExperiences;
