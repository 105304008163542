import React from 'react';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import { AppCard } from 'layouts';

const DetailCard = ({
    cardData,
    isMultiSection = false,
    backgroundStyle = 'infoCard',
    cardContentClassName
}) => {
    const CardBody = ({ data }) => {
        return data.map(
            (
                { label, value, isTitle, valueClassName, labelClassName },
                index
            ) => (
                <Box key={index} className="info">
                    <Typography
                        className={cx([
                            'label',
                            labelClassName,
                            { title: isTitle }
                        ])}>
                        {label}
                    </Typography>
                    <Typography className={cx(['value', valueClassName])}>
                        {value}
                    </Typography>
                </Box>
            )
        );
    };

    return (
        <AppCard
            className={backgroundStyle}
            cardContentClassName={cardContentClassName}>
            {isMultiSection ? (
                <>
                    {cardData?.map((item, index) => (
                        <Box key={index} className="sectionCardContent">
                            <CardBody data={item} />
                        </Box>
                    ))}
                </>
            ) : (
                <CardBody data={cardData} />
            )}
        </AppCard>
    );
};

export default DetailCard;
