import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { TableCellTwoContentsWrapper } from 'layouts';

import { DATE_FORMAT } from 'constants/dateFormats';
import { BANK_ACCOUNT_STATUS } from 'constants/serverInfos/serverInfos';

import API from 'services';
import { unixToDateString } from 'utils/dateConversion';
import { handleAsyncCall } from 'utils/utils';

const useTableBankStatus = (userId, regCountry) => {
    const { t } = useTranslation();
    const [bankAccountList, setBankAccountList] = useState([]);
    const [bankNames, setBankNames] = useState([]);

    const getAccountList = async (payload) => {
        // TODO: add paging handling on payload
        await handleAsyncCall({
            asyncCall: () => API.user.getBankAccountList.apiCall(payload),
            handleCallSuccess: (res) => {
                setBankAccountList(res.data.list || []);
            }
        });
    };

    useEffect(() => {
        const payload = {
            userId
        };
        getAccountList(payload);
    }, [userId]);

    useEffect(() => {
        const getBankNames = () => {
            handleAsyncCall({
                asyncCall: () =>
                    API.common.getBankList.apiCall({
                        country: regCountry
                    }),
                handleCallSuccess: (res) => setBankNames(res.data.list || [])
            });
        };

        getBankNames();
    }, [regCountry]);

    const tableColumInfos = {
        bankName: {
            field: 'bankName',
            label: `${t('common.user.bank.bankName')} (${t(
                'common.user.bank.accountNumberLastFourDigit'
            )})`,
            renderCell: ({
                bankId: targetBankId,
                bankNumber,
                bankName: customBank
            }) => {
                const { value: bankName } =
                    bankNames.find(({ bankId }) => targetBankId === bankId) ||
                    {};

                return (
                    <Typography>
                        {`${bankName || customBank} (${bankNumber.slice(-4)})`}
                    </Typography>
                );
            }
        },
        updateTime: {
            field: 'updateTime',
            label: t('common.date.updateTime'),
            renderCell: ({ updateTime }) => {
                return (
                    <>
                        {updateTime
                            ? unixToDateString(updateTime, DATE_FORMAT.yMdHm, 0)
                            : '---'}
                    </>
                );
            }
        },
        accountStatus: {
            field: 'accountStatus',
            label: t('common.general.status'),
            renderCell: ({ status }) => {
                const { i18nKey, StatusIcon } =
                    Object.values(BANK_ACCOUNT_STATUS).find(
                        ({ serverKey }) => serverKey === status
                    ) || {};

                const accountStatusTxt = t(i18nKey);

                return (
                    <TableCellTwoContentsWrapper>
                        <StatusIcon />
                        <>{accountStatusTxt}</>
                    </TableCellTwoContentsWrapper>
                );
            }
        }
    };

    return { bankAccountList, getAccountList, tableColumInfos, bankNames };
};

export default useTableBankStatus;
