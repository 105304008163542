import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { GradientCard, Swiper } from 'components';

import { LANGUAGE_INFO } from 'constants/languageInfo';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import './Banner.scss';

const SERVER_ROLE = ['public', 'login', 'iblogin', 'iblogin_first'];

const Banner = () => {
    const { i18n } = useTranslation();
    const { userRole, country, userLevel } = useSelector((state) => state.user);
    const [bannerData, setBannerData] = useState([]);
    const [filterBanner, setFilterBanner] = useState([]);

    useEffect(() => {
        const payload = {
            _sort: 'priority:ASC'
        };

        handleAsyncCall({
            asyncCall: () => API.contents.getPromotionBanner.apiCall(payload),
            handleCallSuccess: (res) => {
                setBannerData(res);
            }
        });
    }, []);

    useEffect(() => {
        bannerData.length > 0 &&
            bannerData.map((item) => {
                if (!SERVER_ROLE.includes(item.role)) {
                    return null;
                }

                let bannerRole = '';

                switch (item.role) {
                    case 'iblogin':
                        bannerRole = 'IB';
                        break;
                    case 'iblogin_first':
                        if (userLevel === 1) {
                            bannerRole = 'IB';
                        }
                        break;
                    case 'login':
                        bannerRole = 'CUSTOMER';
                        break;
                    case 'public':
                        bannerRole = 'public';
                        break;
                    default:
                        break;
                }

                if (
                    item.portalImgs?.length !== 0 &&
                    item.isPortalDisplayable &&
                    (bannerRole === 'public' || bannerRole === userRole)
                ) {
                    const countryRestriction = (item.countryRestriction
                        ?.enable &&
                        item.countryRestriction.countries.map(
                            ({ countryCode }) => {
                                return countryCode === country;
                            }
                        )) || [false];

                    if (
                        item.countryRestriction === null ||
                        item.countryRestriction?.enable === false ||
                        (item.countryRestriction?.enable &&
                            countryRestriction.includes(true))
                    ) {
                        // console.log('2nd checking: ', item.description);
                        const langRestriction = (item.langRestriction?.enable &&
                            item.langRestriction.languages.map(({ lang }) => {
                                return (
                                    lang ===
                                    LANGUAGE_INFO[i18n.language]?.contentsKey
                                );
                            })) || [false];

                        if (
                            item.langRestriction === null ||
                            item.langRestriction?.enable === false ||
                            (item.langRestriction?.enable &&
                                langRestriction.includes(true))
                        ) {
                            // console.log('3rd checking: ', item.description);
                            item.portalImgs?.filter((bannerImg) => {
                                if (
                                    bannerImg.lang ===
                                    LANGUAGE_INFO[i18n.language]?.contentsKey
                                ) {
                                    const clickUrl = item.clickUrls?.filter(
                                        ({ lang }) => {
                                            return (
                                                lang ===
                                                LANGUAGE_INFO[i18n.language]
                                                    ?.contentsKey
                                            );
                                        }
                                    );

                                    setFilterBanner((banners) => [
                                        ...banners,
                                        {
                                            clickUrl: clickUrl[0]?.url,
                                            alternativeText:
                                                bannerImg.img?.alternativeText,
                                            img: bannerImg.img?.url
                                        }
                                    ]);
                                }

                                return null;
                            });
                        }
                    }
                }

                return null;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerData]);

    return (
        filterBanner.length > 0 && (
            <Box className="banner">
                <Swiper
                    autoPlay
                    infiniteLoop
                    onClickItem={(item) => {
                        const bannerSelectedLink = filterBanner[item].clickUrl;
                        if (bannerSelectedLink)
                            window.open(bannerSelectedLink, '_blank');
                    }}
                    renderCards={() =>
                        filterBanner.map((item, index) => {
                            return (
                                <GradientCard className="noPadding" key={index}>
                                    <img
                                        src={`${process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}/api${item.img}`}
                                        alt={item.alternativeText}
                                    />
                                </GradientCard>
                            );
                        })
                    }
                />
            </Box>
        )
    );
};

export default Banner;
