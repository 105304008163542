import React from 'react';

import { Box, FormHelperText as MuiFormHelperText } from '@mui/material';

import './FormHelperText.scss';
import { ErrorIco } from 'assets/images/registration';

const FormHelperText = ({ error = false, helperText = null }) => {
    return (
        <Box className="formHelperText">
            {error && <ErrorIco className="errorIcon" />}
            <MuiFormHelperText>{helperText}</MuiFormHelperText>
        </Box>
    );
};

export default FormHelperText;
