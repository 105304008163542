import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import isObject from 'lodash/isObject';

import { AppForm, Button } from 'components';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';

import ConfirmBoard from './ConfirmBoard/ConfirmBoard';
import { FORM_ACTION } from './constants';

const DialogForm = ({
    initialValues,
    validationSchema,
    fieldLabels,
    infoAccessType,
    handleSubmit,
    handleSubmitSuccess,
    renderFillFormFields,
    renderCompleteModifyBoard,
    className = '',
    confirmBoardReminders = []
}) => {
    const { t } = useTranslation();

    const [formAction, setFormAction] = useState(FORM_ACTION.FILL_FORM);
    const [confirmInfos, setConfirmInfos] = useState([]);

    const confirmFormBeforeSubmit = (formValues) => {
        setConfirmInfos(
            Object.entries(fieldLabels).map(([key, label]) => ({
                label,
                value: isObject(formValues[key])
                    ? formValues[key].value
                    : formValues[key]
            }))
        );
        setFormAction(FORM_ACTION.CONFIRM_FORM);
    };

    const submitSuccessCallback = (result) => {
        setFormAction(FORM_ACTION.SHOW_COMPLETED);
        handleSubmitSuccess(result);
    };

    return (
        <AppForm
            className={className}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={(formValues) =>
                handleSubmit(formValues, submitSuccessCallback)
            }
            render={(formikProps) => {
                const { isValid, values } = formikProps;

                switch (formAction) {
                    case FORM_ACTION.FILL_FORM:
                        return (
                            <>
                                {renderFillFormFields({
                                    ...formikProps,
                                    setFormAction
                                })}
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!isValid}
                                    label={t('common.action.confirm')}
                                    onClick={() =>
                                        infoAccessType === INFO_ACCESS_TYPE.READ
                                            ? handleSubmit()
                                            : confirmFormBeforeSubmit(values)
                                    }
                                />
                            </>
                        );
                    case FORM_ACTION.CONFIRM_FORM:
                        return (
                            <ConfirmBoard
                                confirmInfos={confirmInfos}
                                onEdit={() =>
                                    setFormAction(FORM_ACTION.FILL_FORM)
                                }
                                reminders={confirmBoardReminders}
                            />
                        );
                    case FORM_ACTION.SHOW_COMPLETED:
                        return renderCompleteModifyBoard({
                            ...formikProps,
                            setFormAction
                        });
                    default:
                        return null;
                }
            }}
        />
    );
};

export default DialogForm;
