import React from 'react';

import { Box } from '@mui/material';

import { Button } from 'components';

import './AddCardFooterButton.scss';
import { AccountCardIco } from 'assets/images/userInfo';

const AppTableFooterButton = ({ label, onClick = () => {} }) => {
    return (
        <Box className="addCardFooterButton">
            <Button
                className="button"
                startIcon={<AccountCardIco />}
                label={label}
                color="secondary"
                onClick={onClick}
            />
        </Box>
    );
};

export default AppTableFooterButton;
