import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { useLocation } from 'react-router-dom';

const RouteHistoryContext = createContext();

const RouteHistoryProvider = ({ children }) => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(null);
    const lastSavedLocation = useRef(null); // temp buffer

    useEffect(() => {
        // this function will be called each time the location changes
        setPrevLocation(lastSavedLocation.current);

        lastSavedLocation.current = location;
    }, [location]);

    return (
        <RouteHistoryContext.Provider value={{ prevLocation }}>
            {children}
        </RouteHistoryContext.Provider>
    );
};

export default RouteHistoryProvider;

export const useRouteHistory = () => useContext(RouteHistoryContext);
