import queryClient from 'reactQueryConfig';

import { USER_ROLE } from 'constants/authInfos';
import { resetAccountLinkStore } from 'store/accountLink/slice';
import { resetClientStore } from 'store/client/slice';
import { resetAllFilters } from 'store/filter/slice';
import { redirectRegistrationPages } from 'store/registration/reducer';
import { resetRegistrationState } from 'store/registration/slice';
import { resetAllFillOptions } from 'store/system/slice';

import API from 'services';
import { KEY, getItemSync, setItemSync } from 'utils/localStorage';
import { handleAsyncCall } from 'utils/utils';

import {
    resetUserCredential,
    setQueryTradeLoginId,
    setUserCredential
} from './slice';

const handleUserCredentialSetting = (
    response,
    isLogin,
    dispatch,
    payload = null
) => {
    const { userId, token } = response.data.data;

    const userCredential = {
        isLogin,
        userId,
        authToken: token,
        ...(payload && { country: payload.country })
    };
    setItemSync(KEY.CREDENTIAL, userCredential, 'true');
    dispatch(setUserCredential(userCredential));
};

const tradeDefaultAc = async (userId, dispatch) => {
    try {
        const response = await API.common.getTradeAccountList.apiCall(userId);
        if (response.data.code === 200) {
            const resData = response.data.data.list[0];
            const { tradeLoginId, platform, currency } = resData ?? {};

            const ibData = response.data.data.list.filter(
                (account) => account.role === USER_ROLE.IB
            );

            const userCredential = getItemSync(KEY.CREDENTIAL, {}, 'true');
            const updateUserCredential = {
                ...userCredential,
                tradeLoginId,
                platform,
                currency,
                isLogin: true
            };

            setItemSync(KEY.CREDENTIAL, updateUserCredential, 'true');
            dispatch(setUserCredential(updateUserCredential));
            dispatch(setQueryTradeLoginId(ibData[0]?.tradeLoginId));
        }
        return response;
    } catch (error) {
        console.error(error);
        setItemSync(KEY.CREDENTIAL, {}, 'true');
        dispatch(resetUserCredential());
        return;
    }
};

const handleLoginSuccess = async (response, isLogin, dispatch) => {
    const { userId } = response.data.data;

    queryClient.clear();
    handleUserCredentialSetting(response, isLogin, dispatch);
    await tradeDefaultAc(userId, dispatch);
};
const handleLoginFailure = (error, dispatch) => {
    console.error(error);
    setItemSync(KEY.CREDENTIAL, {}, 'true');
    dispatch(resetUserCredential());
    return;
};

export const loginUser = (payload, onFactorRequired) => async (dispatch) => {
    try {
        const response = await API.authentication.loginUser.apiCall(payload);
        const isLogin = false;

        if (response.data.code === 200) {
            const { factorRequired } = response.data.data;
            if (Boolean(factorRequired)) {
                onFactorRequired(response.data.data);
            } else {
                await handleLoginSuccess(response, isLogin, dispatch);
            }
        }
        return response;
    } catch (error) {
        handleLoginFailure(error, dispatch);
    }
};

export const verifySmsUser = (payload) => async (dispatch) => {
    try {
        const response = await API.authentication.verifySmsCode.apiCall(
            payload
        );
        const isLogin = false;

        if (response.data.code === 200) {
            await handleLoginSuccess(response, isLogin, dispatch);
        }
        return response;
    } catch (error) {
        handleLoginFailure(error, dispatch);
    }
};

export const registerUser = (payload, navigate) => async (dispatch) => {
    try {
        const response = await API.registration.registerUser.apiCall(payload);

        if (response.data.code === 200) {
            handleUserCredentialSetting(response, true, dispatch, payload);
            dispatch(redirectRegistrationPages(navigate));
        }
        return response;
    } catch (error) {
        console.error(error);
        setItemSync(KEY.CREDENTIAL, {}, 'true');
        dispatch(resetUserCredential());
        return;
    }
};

export const loginOneTimeUser =
    (payload, loginFailureCallback = null) =>
    async (dispatch) =>
        handleAsyncCall({
            asyncCall: () =>
                API.authentication.getUserUploadCredential.apiCall(payload),
            handleCallSuccess: (res) => {
                const { userId, token } = res.data;

                if (userId && token) {
                    const userCredential = {
                        userId: res.data.userId,
                        authToken: res.data.token,
                        isLogin: true
                    };
                    setItemSync(KEY.CREDENTIAL, userCredential, 'true');
                    dispatch(setUserCredential(userCredential));
                } else {
                    if (typeof loginFailureCallback === 'function') {
                        loginFailureCallback();
                    }
                }
            },
            handleCallError: (error) => {
                console.error(error);
                setItemSync(KEY.CREDENTIAL, {});
                dispatch(resetUserCredential());
            }
        });

export const logoutOneTimeUser = () => (dispatch) => {
    setItemSync(KEY.CREDENTIAL, {});
    dispatch(resetUserCredential());
};

export const updateUserBasicInfo = async (userId, dispatch) => {
    try {
        const response = await API.user.getUserBasicInfo.apiCall(userId);
        if (response.data.code === 200) {
            const userInfo = response.data.data.info;
            const userCredential = getItemSync(KEY.CREDENTIAL, {}, 'true');
            const updateUserCredential = {
                ...userCredential,
                userEmail: userInfo.email,
                ...userInfo
            };

            setItemSync(KEY.CREDENTIAL, updateUserCredential, 'true');
            dispatch(setUserCredential(updateUserCredential));
        }
        // return response;
    } catch (error) {
        console.error(error);
        // return;
    }
};

export const logout = (userId) => async (dispatch) => {
    await API.authentication.logout.apiCall(userId).finally(() => {
        setItemSync(KEY.CREDENTIAL, {}, 'true');
        dispatch(resetAccountLinkStore());
        dispatch(resetClientStore());
        dispatch(resetAllFilters());
        dispatch(resetRegistrationState());
        dispatch(resetAllFillOptions());
        dispatch(resetUserCredential());
        dispatch(setUserCredential({ wasLogin: true }));

        queryClient.clear();
    });
};
