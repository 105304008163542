import { FIELD_TYPE } from 'constants/appFieldsInfos';

import { isObjectEmpty } from 'utils/common';

import { CELL_INFO_TYPES } from './TableCommissionContainer/TableCommissionContainer';

export const makeSelectItems = (
    maxValue,
    minValue,
    step = 1,
    renderLabel = null
) => {
    let selectItems = [];
    let correctedStep = step < 1 ? 1 : step;

    for (let index = minValue ?? 0; index <= maxValue; index += correctedStep) {
        selectItems.push({
            value: index,
            label: renderLabel ? renderLabel(index) : index
        });
    }
    return selectItems;
};

export const getSharedRows = (
    index,
    commissionData,
    firstSharedRowIndex,
    lastSharedRowIndex,
    rowSpan,
    cellInfoType
) => {
    const commonRowsProps = {
        cellInfoType,
        cellProps: {
            rowSpan
        }
    };

    if (index === firstSharedRowIndex) {
        return commissionData.map((value) => {
            if (typeof value === 'object') {
                return {
                    ...commonRowsProps,
                    ...value
                };
            } else {
                return {
                    ...commonRowsProps,
                    value
                };
            }
        });
    }

    if (index > firstSharedRowIndex && index <= lastSharedRowIndex) {
        return commissionData.map(() => {
            return {
                ...commonRowsProps,
                value: ' ',
                cellProps: {
                    sx: { display: 'none' }
                }
            };
        });
    }

    return commissionData;
};

export const getCommissionTableContent = (
    commissionSettings,
    commissionValues,
    isReadOnly,
    isShowProductCol,
    t
) => {
    const commissionOneIndices = [0, 1];
    const commissionOneFirstIndex = 0;
    const commissionTwoFirstIndex = 2;
    const { products, commission1, commission2 } = commissionSettings;

    if (isObjectEmpty(commission1) || isObjectEmpty(commission2)) return null;

    const tableRows = products.map((productNameAndCode, index) => {
        const isCommissionOneData = commissionOneIndices.includes(index);

        const { target, targetMin, step, max } = isCommissionOneData
            ? commission1
            : commission2;

        const selfCommissionIndex = 0;
        const clientCommissionIndex = 1;
        const selfCommissionProfitIndex = 2;

        let commissionData = [];

        commissionData[selfCommissionIndex] = targetMin;
        commissionData[clientCommissionIndex] = target;
        commissionData[selfCommissionProfitIndex] = target - targetMin;

        if (!isReadOnly) {
            commissionData[clientCommissionIndex] = isCommissionOneData
                ? commissionValues?.commission1
                : commissionValues?.commission2;

            commissionData[selfCommissionProfitIndex] =
                commissionData[clientCommissionIndex] - targetMin;

            const selectItems = makeSelectItems(max, targetMin, step);

            commissionData[clientCommissionIndex] = {
                cellFieldType: FIELD_TYPE.SELECT_DROPDOWN_FIELD,
                name: `commission${isCommissionOneData ? 1 : 2}`,
                items: selectItems
            };
        }

        const commissionOneRowSpan =
            commissionTwoFirstIndex - commissionOneFirstIndex;

        commissionData = getSharedRows(
            index,
            commissionData,
            commissionOneFirstIndex,
            commissionTwoFirstIndex - 1,
            commissionOneRowSpan,
            CELL_INFO_TYPES.DATA
        );

        const commissionTwoRowSpan =
            products.length - commissionOneIndices.length;

        commissionData = getSharedRows(
            index,
            commissionData,
            commissionTwoFirstIndex,
            products.length - 1,
            commissionTwoRowSpan,
            CELL_INFO_TYPES.DATA
        );

        return [productNameAndCode, ...commissionData];
    });

    const titleData = {
        value: t('customerMgmt.accountLink.commission.commissionSetting'),
        cellInfoType: CELL_INFO_TYPES.SUB_TITLE,
        cellProps: {
            colSpan: 3 + (isShowProductCol ? 1 : 0)
        }
    };

    return {
        title: titleData,
        subTitle: [
            t('common.tradeAccount.commodity.name'),
            t('customerMgmt.accountLink.commission.selfTradeCommission'),
            t('customerMgmt.accountLink.commission.lowerLevelTradeCommission'),
            t('customerMgmt.accountLink.commission.selfCommissionProfit')
        ],
        data: tableRows
    };
};

export const checkIsShowDiffCommission = (commissionSettings) => {
    let isShowDiffCommission =
        commissionSettings.canAddDiff && commissionSettings.diff;
    let withDiffValue = undefined;

    if (commissionSettings.diff) {
        const { percentMax, percent, target } = commissionSettings.diff;
        const diffValue = target;
        const selfDiffProfit = percentMax - percent;

        withDiffValue = diffValue !== 0 && selfDiffProfit !== 0;
    }

    return isShowDiffCommission && withDiffValue;
};
