import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useRatingCode } from 'hooks';

export const FILTER_RATING_CODE = 'ratingCode';

const useFilterRatingCode = (filterName = FILTER_RATING_CODE) => {
    const { t } = useTranslation();
    const { ratingCodes } = useRatingCode();

    const itemSelectAll = {
        key: '',
        value: t('common.general.all')
    };

    const ratingCodeFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.sales.ratingCode'),
        items: [itemSelectAll, ...ratingCodes],
        itemValueKey: 'key',
        itemLabelKey: 'value',
        defaultValue: ''
    };

    return { ratingCodeFilterInfo };
};

export default useFilterRatingCode;
