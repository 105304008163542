import { useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useUserRegistrationInfo = (userId, tradeLoginId) => {
    const [registrationInfo, setUserRegistrationInfo] = useState({});

    const getUserRegistrationInfo = () => {
        const payload = {
            tradeLoginId
        };
        handleAsyncCall({
            asyncCall: () =>
                API.user.getUserRegistrationInfo.apiCall(userId, payload),
            handleCallSuccess: (res) => setUserRegistrationInfo(res.data.info)
        });
    };

    return { registrationInfo, getUserRegistrationInfo };
};

export default useUserRegistrationInfo;
