import { TRADING_ACCOUNT_TYPE } from 'constants/client/tradingAccountType';

import { getAccountTypeLocale } from 'utils/client/accountType';

import {
    RoundedGradientBlueFrameImg,
    RoundedGradientGrayFrameImg,
    RoundedGradientRedFrameImg
} from 'assets/images/frames';

export const getQRCodeInfos = (accountType, t) => {
    let QRCodeFrameImg = null;
    let commissionTypeNameKey = '';
    let colorClassName = '';

    switch (accountType) {
        case TRADING_ACCOUNT_TYPE.STANDARD:
            QRCodeFrameImg = RoundedGradientBlueFrameImg;
            commissionTypeNameKey = getAccountTypeLocale(
                TRADING_ACCOUNT_TYPE.STANDARD,
                t
            );
            colorClassName = 'blueFrameQRCodeWrapper';
            break;
        case TRADING_ACCOUNT_TYPE.PROFESSIONAL:
            QRCodeFrameImg = RoundedGradientBlueFrameImg;
            commissionTypeNameKey = getAccountTypeLocale(
                TRADING_ACCOUNT_TYPE.PROFESSIONAL,
                t
            );
            colorClassName = 'blueFrameQRCodeWrapper';
            break;
        case TRADING_ACCOUNT_TYPE.ECN:
            QRCodeFrameImg = RoundedGradientRedFrameImg;
            commissionTypeNameKey = getAccountTypeLocale(
                TRADING_ACCOUNT_TYPE.ECN,
                t
            );
            colorClassName = 'redFrameQRCodeWrapper';
            break;
        default:
            QRCodeFrameImg = RoundedGradientGrayFrameImg;
            commissionTypeNameKey = '';
            colorClassName = 'grayFrameQRCodeWrapper';
            break;
    }

    return {
        QRCodeFrameImg,
        commissionTypeNameKey,
        colorClassName
    };
};
