import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import StepperDot from 'components/Steppers/StepperDot/StepperDot';

import './StepperModifyAccountLink.scss';

export const stepNames = {
    SETTINGS: 'SETTINGS',
    COMMISSION_INFO: 'COMMISSION_INFO'
};

const getStepInfos = (t) => [
    {
        stepRouteName: stepNames.SETTINGS,
        stepName: t('customerMgmt.accountLinkModify.stepper.settings')
    },
    {
        stepRouteName: stepNames.COMMISSION_INFO,
        stepName: t('customerMgmt.accountLinkModify.stepper.commissionInfo')
    }
];

const StepperModifyAccountLink = ({ currRouteName }) => {
    const { t } = useTranslation();
    const stepInfos = getStepInfos(t);

    return (
        <Box className="stepperModifyAccountLinkWrapper">
            <StepperDot
                stepInfos={stepInfos}
                currRouteName={currRouteName}
                textColor="white"
            />
        </Box>
    );
};

export default StepperModifyAccountLink;
