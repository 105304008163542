import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import AUTH_ROUTES from 'pages/Authentication/routes';

import publicRoutes from 'routes/publicRoutes';

const useLoginRedirection = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { isLogin, wasLogin } = useSelector((state) => state.user);

    const { queries } = useSelector((state) => state.registration);
    const isCreatingTradingAccount = !isEmpty(queries);

    const redirectPathRef = useRef('');

    const [searchParams, setSearchParams] = useSearchParams();
    const needRedirectionAfterLogin = searchParams.has('redirect');

    useEffect(() => {
        const setPreviousPathToQueryString = () => {
            const isPreviousPathMain = [
                '/',
                '/login',
                '/home/manager-portal',
                '/home/ib-portal',
                '/home/client-portal'
            ].includes(location.pathname);
            const isPublicPath = Object.values(publicRoutes)
                .flatMap(({ path }) => path)
                .includes(location.pathname);

            if (
                !isPreviousPathMain &&
                !isPublicPath &&
                redirectPathRef.current === ''
            ) {
                redirectPathRef.current = location.pathname;
            }

            if (
                redirectPathRef.current !== '' &&
                location.pathname === AUTH_ROUTES.Login.path
            ) {
                setSearchParams({
                    redirect: redirectPathRef.current
                });
            }
        };

        if (!wasLogin && !isLogin) {
            setPreviousPathToQueryString();
        } else if (needRedirectionAfterLogin) {
            const path = searchParams.get('redirect');
            navigate(path);
        }
    }, [
        isCreatingTradingAccount,
        isLogin,
        location.pathname,
        navigate,
        needRedirectionAfterLogin,
        searchParams,
        setSearchParams,
        wasLogin
    ]);

    return { isCreatingTradingAccount, needRedirectionAfterLogin };
};

export default useLoginRedirection;
