import { useSelector } from 'react-redux';

import { CLIENT_TYPE_INFO } from 'constants/client/clientTypeInfo';

import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const FIELD_NAME = 'ratingCodes';

const apiCallClientRatingItems = {
    [CLIENT_TYPE_INFO.LEAD_CLIENT.key]:
        API.common.getLeadClientContactRatings.apiCall,
    [CLIENT_TYPE_INFO.REGISTERED_CLIENT.key]:
        API.common.getRegisteredClientContactRatings.apiCall,
    [CLIENT_TYPE_INFO.DEPOSITED_CLIENT.key]:
        API.common.getDepositedClientContactRatings.apiCall
};

const useRatingCode = () => {
    const { clientTypeKey } = useSelector((state) => state.client);

    const { isSuccess, data: userSourcesData } = useCacheableQuery({
        queryKeys: [FIELD_NAME, clientTypeKey],
        apiCall: apiCallClientRatingItems[clientTypeKey],
        enabled: !!clientTypeKey
    });

    return {
        ratingCodes:
            clientTypeKey && isSuccess ? getResponseList(userSourcesData) : []
    };
};

export default useRatingCode;
