import React from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { SelectDropdownField, TextField } from 'components';
import CompleteModifyBoard from 'pages/UserInfo/components/CompleteModifyBoard/CompleteModifyBoard';
import DialogForm from 'pages/UserInfo/components/DialogForm/DialogForm';

import { INFO_ACCESS_TYPE } from 'constants/infoAccessType';
import { WALLET_CHAIN } from 'constants/serverInfos/serverInfos';

import { commonTextValidation, parseWalletAddressValidation } from 'utils/yup';

import './WalletForm.scss';
import useWalletCompleteModify from './hooks/useWalletCompleteModify';

const WalletForm = ({
    handleSubmit,
    handleCloseDialog,
    infoAccessType,
    walletAccount = {},
    disabled = false,
    showCheckButton = false
}) => {
    const { t } = useTranslation();

    const fieldLabels = {
        accountName: t('common.user.wallet.accountName'),
        walletChain: t('common.user.wallet.walletChain'),
        walletAddress: t('common.user.wallet.walletAddress')
    };

    const {
        accountName: initialAccountName,
        walletChain: initialWalletChain,
        walletAddress: initialWalletAddress,
        ...walletAccountRest
    } = walletAccount;

    const { setResultedWalletAddress, resultInfos, redirectWalletPage } =
        useWalletCompleteModify({ fieldLabels });

    const initialValues = {
        accountName: initialAccountName ?? '',
        walletChain: initialWalletChain ?? '',
        walletAddress: initialWalletAddress ?? '',
        ...walletAccountRest
    };
    const initialTouched = {
        walletAddress: !!initialWalletAddress
    };
    const validationSchema = yup.object({
        accountName: commonTextValidation,
        walletChain: commonTextValidation,
        walletAddress: parseWalletAddressValidation('walletChain')
    });

    const handleSubmitSuccess = (resultedWalletAddress) => {
        setResultedWalletAddress(resultedWalletAddress);
    };

    const renderFillFormFields = ({ values }) => (
        <>
            <TextField
                isRequired
                fullWidth
                name="accountName"
                label={fieldLabels.accountName}
                disabled={disabled}
            />
            <SelectDropdownField
                isRequired
                fullWidth
                disabled={disabled}
                name="walletChain"
                label={fieldLabels.walletChain}
                items={Object.values(WALLET_CHAIN)}
                itemLabelKey="label"
                itemValueKey="serverKey"
            />
            <TextField
                fullWidth
                isRequired
                disabled={disabled || values.walletChain === ''}
                name="walletAddress"
                label={fieldLabels.walletAddress}
            />
        </>
    );

    const renderCompleteModifyBoard = () => (
        <CompleteModifyBoard
            title={
                infoAccessType === INFO_ACCESS_TYPE.NEW
                    ? t('common.status.addSuccess')
                    : t('common.status.modificationSuccess')
            }
            handleCloseDialog={handleCloseDialog}
            resultInfos={resultInfos}
            handleClickCheck={redirectWalletPage}
            showCheckButton={showCheckButton}
        />
    );

    return (
        <DialogForm
            className="walletForm"
            initialValues={initialValues}
            initialTouched={initialTouched}
            validationSchema={validationSchema}
            fieldLabels={fieldLabels}
            infoAccessType={infoAccessType}
            confirmBoardReminders={[t('common.user.wallet.reminder')]}
            handleSubmit={handleSubmit}
            handleSubmitSuccess={handleSubmitSuccess}
            renderFillFormFields={renderFillFormFields}
            renderCompleteModifyBoard={renderCompleteModifyBoard}
        />
    );
};

export default WalletForm;
