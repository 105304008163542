import { CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';

import { Button } from 'components';

import styles from 'styles/styles.module.scss';

const AppFormButton = (buttonProps) => {
    const { disabled, type, isFetching } = buttonProps;
    const { isSubmitting } = useFormikContext();

    return (
        <Button {...buttonProps} disabled={disabled || isSubmitting}>
            {(isSubmitting && type === 'submit') ||
                (isFetching && type === 'submit' && (
                    <CircularProgress
                        size={24}
                        sx={{ color: styles.COLOR_WHITE }}
                    />
                ))}
        </Button>
    );
};

export default AppFormButton;
