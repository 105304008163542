import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TableContainer } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { Remarks } from 'components';

import { TRADING_ACCOUNT_TYPE } from 'constants/client/tradingAccountType';

import CommissionECNTable from './CommissionECNTable/CommissionECNTable';
import CommissionStandardTable from './CommissionStandardTable/CommissionStandardTable';
import './CommissionTable.scss';
import { checkIsShowDiffCommission } from './utils';

const CommissionTable = ({
    initCommissionSettings = {},
    commissionValues,
    isReadOnly,
    accountType
}) => {
    const { t } = useTranslation();

    if (isEmpty(initCommissionSettings)) return null;

    const renderCommissionTable = () => {
        switch (accountType) {
            case TRADING_ACCOUNT_TYPE.PROFESSIONAL:
            case TRADING_ACCOUNT_TYPE.STANDARD:
                return (
                    <CommissionStandardTable
                        initCommissionSettings={initCommissionSettings}
                        commissionValues={commissionValues}
                        accountType={accountType}
                        isReadOnly={isReadOnly}
                    />
                );
            case TRADING_ACCOUNT_TYPE.ECN:
                return (
                    <CommissionECNTable
                        initCommissionSettings={initCommissionSettings}
                        commissionValues={commissionValues}
                        isReadOnly={isReadOnly}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box className="commissionTableWrapper">
            <TableContainer className="commissionTableContainerWrapper">
                {renderCommissionTable()}
            </TableContainer>
            {(isReadOnly && initCommissionSettings.diff.target !== 0) ||
            checkIsShowDiffCommission(initCommissionSettings) ? (
                <Remarks
                    title={t('customerMgmt.leadClient.commissionTable.title')}
                    remarkList={[
                        t(
                            'customerMgmt.leadClient.commissionTable.commissionRemark1'
                        )
                    ]}
                />
            ) : null}
        </Box>
    );
};

export default CommissionTable;
