import { Box } from '@mui/material';
import cx from 'classnames';

import './ButtonArrowNavigator.scss';
import { ArrowSharpRightIco } from 'assets/images/common';

const ButtonArrowNavigator = ({ Ico, title, onClick, className }) => {
    return (
        <Box className={cx(['btnArrowNavigator', className])} onClick={onClick}>
            <Box className="infoWrapper">
                <Box className="icoWrapper">{Ico}</Box>
                <Box className="labelWrapper">{title}</Box>
            </Box>
            <ArrowSharpRightIco className="arrowIco" />
        </Box>
    );
};

export default ButtonArrowNavigator;
