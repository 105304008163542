import { useTranslation } from 'react-i18next';

import { FILTER_TYPE } from 'constants/filterInfos';
import { useCountries } from 'hooks';

const FILTER_COUNTRY = 'country';

const useFilterCountry = (filterName = FILTER_COUNTRY) => {
    const { t } = useTranslation();
    const { countries } = useCountries();

    const selectAllItem = {
        code: '',
        displayName: t('common.general.all')
    };

    const countryFilterInfo = {
        filterName,
        filterType: FILTER_TYPE.SINGLE_SELECT,
        label: t('common.user.communication.countryAndRegion'),
        items: [selectAllItem, ...countries],
        itemLabelKey: 'displayName',
        itemValueKey: 'code',
        defaultValue: ''
    };

    return { countryFilterInfo };
};

export default useFilterCountry;
