import React from 'react';
import { useTranslation } from 'react-i18next';

import { TRADING_ACCOUNT_TYPE } from 'constants/client/tradingAccountType';

import TableCommissionContainer from '../TableCommissionContainer/TableCommissionContainer';
import { getCommissionTableContent } from '../utils';

const CommissionECNTable = ({
    isReadOnly,
    initCommissionSettings,
    commissionValues
}) => {
    const { t } = useTranslation();
    const isShowProductCol = true;

    return (
        <TableCommissionContainer
            {...getCommissionTableContent(
                initCommissionSettings,
                commissionValues,
                isReadOnly,
                isShowProductCol,
                t
            )}
            isShowProductCol={isShowProductCol}
            accountType={TRADING_ACCOUNT_TYPE.ECN}
        />
    );
};

export default CommissionECNTable;
