import React, { cloneElement } from 'react';

import { Box } from '@mui/material';

import './AppStatusContent.scss';

const AppStatusContent = ({
    title = '',
    renderTitle = null,
    subtitle = null,
    reminders = [],
    children = null,
    StatusImgSrc = null,
    contentMarginTop = 125,
    ButtonList = null
}) => {
    return (
        <Box
            className="appStatusContentWrapper"
            style={{ marginTop: contentMarginTop }}>
            <Box className="mainContentWrapper">
                {StatusImgSrc && (
                    <Box className="statusImgWrapper">
                        <img src={StatusImgSrc} alt="" />
                    </Box>
                )}
                {renderTitle ? (
                    renderTitle()
                ) : (
                    <div className="titleTxt">{title}</div>
                )}
                {subtitle && <div className="subtitleTxt">{subtitle}</div>}
                {reminders.length > 0 && (
                    <Box className="reminderWrapper">
                        {reminders.map((reminderTxt, index) => (
                            <div className="reminderTxt" key={index}>
                                {reminderTxt}
                            </div>
                        ))}
                    </Box>
                )}
            </Box>
            {children}
            {ButtonList && (
                <Box className="buttonListWrapper">
                    {ButtonList.map((ButtonComponent, index) => (
                        <Box className="buttonWrapper" key={index}>
                            {cloneElement(ButtonComponent)}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default AppStatusContent;
