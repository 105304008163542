import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { AmountFormat } from 'components';

import { maskValue } from 'utils/valueProcess';

import './Asset.scss';
import { EyeIco, EyeOffIco } from 'assets/images/common';

const Asset = ({ assetData = {} }) => {
    const { t } = useTranslation();
    const [isInfoHidden, setIsInfoHidden] = useState(true);

    const detailData = [
        {
            label: t('common.table.floatingProfit'),
            value: maskValue(
                <AmountFormat value={assetData.floating} />,
                isInfoHidden
            )
        },
        {
            label: t('common.tradeAccount.finance.balance'),
            value: maskValue(
                <AmountFormat value={assetData.balance} />,
                isInfoHidden
            )
        },
        {
            label: t('common.table.credit'),
            value: maskValue(
                <AmountFormat value={assetData.credit} />,
                isInfoHidden
            )
        },
        {
            label: t('common.table.marginFree'),
            value: maskValue(
                <AmountFormat value={assetData.marginFree} />,
                isInfoHidden
            )
        }
    ];

    return (
        <Box className="asset">
            <Box
                className="invisbleBtn"
                onClick={() => setIsInfoHidden(!isInfoHidden)}>
                {isInfoHidden ? <EyeOffIco /> : <EyeIco />}
            </Box>
            <Box className="balance">
                <Typography component="h4">
                    {t('common.tradeAccount.finance.equity')}
                </Typography>
                <Typography component="h2">
                    {maskValue(
                        <AmountFormat value={assetData.equity} />,
                        isInfoHidden
                    )}
                </Typography>
            </Box>
            <Divider />
            <Grid container spacing={4} className="detailList">
                {detailData.map((item, index) => (
                    <Grid item mobile={6} className="detailItm" key={index}>
                        <Typography component="h5">{item.label}</Typography>
                        <Typography>{item.value}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Asset;
