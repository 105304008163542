import i18nInstance from 'i18nConfig';

import { BOOLEAN_OPTION } from './serverInfos/serverInfos';

export const FIELD_TYPE = {
    CHECKBOX_FIELD: 'CheckboxField',
    SELECT_DROPDOWN_FIELD: 'SelectDropdownField',
    SELECT_POP_UP_FIELD: 'SelectPopUpField',
    TEXT_INPUT_FIELD: 'TextInputField',
    PLAIN_TEXT_FIELD: 'PlainTextField',
    PLAIN_TEXT_MASKED_FIELD: 'PlainTextMaskedField',
    TEXT_AREA_FIELD: 'TextAreaField',
    NUMBER_INPUT_FIELD: 'NumberInputField',
    RADIO_BUTTON_FIELD: 'RadioButtonField',
    DATEPICKER_FIELD: 'DatepickerField',
    PASSWORD_FIELD: 'PasswordField',
    VERIFY_CODE_FIELD: 'VerifyCodeField'
};

export const selectBooleanItems = Object.values(BOOLEAN_OPTION).map(
    ({ i18nKey, value }) => ({
        label: i18nInstance.t(i18nKey),
        value: value
    })
);
