import { getRouteInfos } from '../routes/routes';

const routes = getRouteInfos({ userRole: null });

export const AUDIT_PASSED_PATHS = [
    // CustomerMgmt
    `${routes.CustomerMgmt.path}${routes.CustomerMgmt.subRoutes.AccountLink.path}`,
    `${routes.CustomerMgmt.path}${routes.CustomerMgmt.subRoutes.RegisteredClients.path}`,
    `${routes.CustomerMgmt.path}${routes.CustomerMgmt.subRoutes.DepositedClients.path}`,

    // CommissionMgmt
    `${routes.CommissionMgmt.path}${routes.CommissionMgmt.subRoutes.CommissionReport.path}`,
    `${routes.CommissionMgmt.path}${routes.CommissionMgmt.subRoutes.CommissionDetail.path}`,

    // TradeReports
    `${routes.TraderReports.path}${routes.TraderReports.subRoutes.Orders.path}`,
    `${routes.TraderReports.path}${routes.TraderReports.subRoutes.TradeHistory.path}`,
    `${routes.TraderReports.path}${routes.TraderReports.subRoutes.FundFlow.path}`,
    `${routes.TraderReports.path}${routes.TraderReports.subRoutes.Statement.path}`,

    // FundingMgmt
    `${routes.FundingMgmt.path}${routes.FundingMgmt.subRoutes.Deposit.path}`,
    `${routes.FundingMgmt.path}${routes.FundingMgmt.subRoutes.Withdraw.path}`,
    `${routes.FundingMgmt.path}${routes.FundingMgmt.subRoutes.Transfer.path}`,
    `${routes.FundingMgmt.path}${routes.FundingMgmt.subRoutes.FundFlow.path}`,

    // UserInfo
    `${routes.UserInfo.path}${routes.UserInfo.subRoutes.BankCard.path}`,
    `${routes.UserInfo.path}${routes.UserInfo.subRoutes.Wallet.path}`
];

export const FONTSIZE = {
    BODY: '14px',
    SIXTHEEN: '16px',
    SEVENTEEN: '17px',
    TWENTY: '20px',
    TWENTYFOUR: '24px'
};

export const FONTWEIGHT = {
    LIGHT: '300',
    REGULAR: '400',
    MEDIUM: '500',
    SEMI_BOLD: '600',
    BOLD: '700',
    EXTRA_BOLD: '800',
    BLACK: '900'
};

export const COLOR = {
    ORANGE: '#f39200',
    DENIM: '#030a34',
    BLACK: '#000000',
    WHITE: '#ffffff',
    GREEN: '#00873C'
};

export const APP_DEFAULT_INFINITE_SCROLL_PAGE_SIZE = 15;
