import React from 'react';

import { Box, Divider } from '@mui/material';

import { ButtonLiveChat, LanguageSwitch } from 'components';

import './AppHeader.scss';

const AppHeader = () => {
    return (
        <Box component="header">
            <Box className="headerWrapper">
                <Box className="headerContent">
                    <Box className="headerRight">
                        <ButtonLiveChat widgetClassName="appHeaderWidget" />

                        <Divider
                            orientation="vertical"
                            variant="middle"
                            className="divider"
                        />
                        <LanguageSwitch />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AppHeader;
