import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Link } from '@mui/material';

import { GradientCard } from 'components';
import { AppCardHeader } from 'layouts';

import { DESKTOP_APP_DOWNLOAD_LINK } from 'constants/serverInfos/serverInfos';

import { WindowsAppImg } from 'assets/images/common';

const DownloadDesktopApp = () => {
    const { t } = useTranslation();

    const appDownloadInfos = [
        {
            link: `${window.location.origin}${DESKTOP_APP_DOWNLOAD_LINK.WINDOWS}`,
            imageSource: WindowsAppImg
        }
    ];

    return (
        <Box className="downloadDesktopApp">
            <GradientCard>
                <AppCardHeader
                    title={t('common.sidebar.downloadDesktopApp.title')}
                    titleColor="white"
                />
                <Grid container spacing={6}>
                    {appDownloadInfos.map(({ link, imageSource }, index) => (
                        <Grid item mobile={6} key={index}>
                            <Link
                                className="appImg"
                                underline="none"
                                href={link}
                                target="_blank">
                                <img src={imageSource} alt="" />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </GradientCard>
        </Box>
    );
};

export default DownloadDesktopApp;
