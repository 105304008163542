import React from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';

import ResizeObserver from 'resize-observer-polyfill';

import { NoContentBoard } from 'components';

import { DATE_FORMAT } from 'constants/dateFormats';

import { unixToDateString } from 'utils/dateConversion';
import { parseSections, sortItemByDescendingValue } from 'utils/sectionList';

const DateSectionList = ({
    list,
    dateKey,
    renderSectionHeader,
    renderItem,
    keyFieldName = '',
    exceptKeyFieldName = false,
    renderListHeaderComponent = null,
    renderListFooterComponent = null,
    renderListEmptyComponent = null,
    getListContentRef = null,
    parseDateFormat = DATE_FORMAT.yMd,
    itemNumberInRow = 1,
    ...props
}) => {
    if (!window.ResizeObserver) {
        window.ResizeObserver = ResizeObserver;
    }

    const sectionKeyParser = (item) =>
        unixToDateString(item[dateKey], parseDateFormat);
    const sections = parseSections(
        list,
        sectionKeyParser,
        dateKey,
        sortItemByDescendingValue,
        itemNumberInRow
    );
    const groupCounts = sections.map(({ data }) => data.length);
    const items = sections.flatMap(({ data }) => data);

    return (
        <GroupedVirtuoso
            groupCounts={groupCounts}
            groupContent={(index) =>
                renderSectionHeader(sections[index].sectionLabel)
            }
            itemContent={(index) => renderItem(items[index])}
            computeItemKey={(index) => {
                const randomKey = Math.floor(Math.random() * 10000000);
                return exceptKeyFieldName
                    ? randomKey
                    : items[index]
                    ? `${items[index][keyFieldName]}-${index}`
                    : index;
            }}
            components={{
                Header: renderListHeaderComponent,
                Footer: renderListFooterComponent,
                EmptyPlaceholder:
                    typeof renderListEmptyComponent === 'function'
                        ? renderListEmptyComponent
                        : () => <NoContentBoard labelType="noData" />
            }}
            {...(getListContentRef && {
                scrollerRef: getListContentRef
            })}
            {...props}
        />
    );
};

export default DateSectionList;
