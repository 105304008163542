import React from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import cx from 'classnames';

import { FormControl, InputLabel } from 'components';
import { AppFieldWrapper } from 'layouts';

import { useFormikFieldProps } from 'hooks';

import './CheckboxField.scss';
import { BoxCheckIco, BoxUnCheckIco } from 'assets/images/common';

const CheckboxField = ({
    name,
    items,
    label = '',
    handleBlur = null,
    handleChange = null,
    onClick = null,
    className = '',
    wrapperClassName = '',
    fullWidth = false,
    isRequired = false,
    disabled = false
}) => {
    const fieldProps = useFormikFieldProps(name);

    const onBlur = (event) => {
        const { currentTarget, relatedTarget } = event;
        if (handleBlur && !currentTarget.contains(relatedTarget)) {
            handleBlur(event);
        }
    };

    return (
        <AppFieldWrapper className={cx('checkboxField', wrapperClassName)}>
            <InputLabel label={label} isRequired={isRequired} />
            <FormControl
                error={fieldProps.error}
                helperText={fieldProps.helperText}
                className={className}
                fullWidth={fullWidth}>
                <FormGroup onBlur={onBlur}>
                    {items.map((item, index) => {
                        const checkBoxProps =
                            items.length === 1
                                ? {
                                      checked: fieldProps.value,
                                      onChange: (event) => {
                                          handleChange && handleChange(event);
                                          fieldProps.setValue(
                                              !fieldProps.value
                                          );
                                      }
                                  }
                                : {
                                      checked: fieldProps.value[index],
                                      onChange: (event) => {
                                          handleChange && handleChange(event);
                                          const fieldValue = fieldProps.value;
                                          fieldValue[index] =
                                              event.target.checked;
                                          fieldProps.setValue(fieldValue);
                                      }
                                  };
                        return (
                            <FormControlLabel
                                key={index}
                                label={item.label}
                                control={
                                    <Checkbox
                                        name={name}
                                        onClick={(event) =>
                                            onClick && onClick(event, index)
                                        }
                                        checkedIcon={<BoxCheckIco />}
                                        icon={<BoxUnCheckIco />}
                                        disabled={disabled}
                                        {...checkBoxProps}
                                    />
                                }
                            />
                        );
                    })}
                </FormGroup>
            </FormControl>
        </AppFieldWrapper>
    );
};

export const createCheckboxItem = ({ label }) => ({
    label
});

export default CheckboxField;
