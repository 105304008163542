import axiosConfig from 'axios/axiosConfig';

const systemAPI = {
    getAccountStatusList: {
        apiCall: () => axiosConfig.get('/system/userAccStatuses'),
        queryKey: 'system.getAccountStatusList'
    },
    getTradeTypes: {
        apiCall: () => axiosConfig.get('/system/tradeTypes'),
        queryKey: 'system.getTradeTypes'
    },
    getTradeCategories: {
        apiCall: () => axiosConfig.get('/system/tradeCategories'),
        queryKey: 'system.getTradeCategories'
    },
    getAccountTypes: {
        apiCall: () =>
            axiosConfig.get('/system/accountTypes', {
                params: { demo: false }
            }),
        queryKey: 'system.getAccountTypes'
    },
    getTradeStatus: {
        apiCall: () => axiosConfig.get('/system/tradeStatus'),
        queryKey: 'system.getTradeStatus'
    },
    getTradingPlatforms: {
        apiCall: () => axiosConfig.get('/system/platforms'),
        queryKey: 'system.getTradingPlatforms'
    }
};

export default systemAPI;
