import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPE } from 'constants/appFieldsInfos';
import { TRADING_ACCOUNT_TYPE } from 'constants/client/tradingAccountType';
import { useWindowSize } from 'hooks';

import TableCommissionContainer, {
    CELL_INFO_TYPES
} from '../TableCommissionContainer/TableCommissionContainer';
import {
    checkIsShowDiffCommission,
    getCommissionTableContent,
    getSharedRows,
    makeSelectItems
} from '../utils';

const CommissionStandardTable = ({
    initCommissionSettings,
    commissionValues,
    accountType,
    isReadOnly
}) => {
    const { t } = useTranslation();
    const { isDesktop, isDesktopDown } = useWindowSize();
    const { canAddCommission, canAddRebate, products, rebates } =
        initCommissionSettings;
    const [minSubTitleCellHeight, setMinSubTitleCellHeight] = useState(60);

    const getProductColumnContent = () => {
        const tableRows = products.map((productNameAndCode) => {
            let data = productNameAndCode;
            return [data];
        });

        const subTitleData = {
            value: t('common.tradeAccount.commodity.name'),
            cellInfoType: CELL_INFO_TYPES.SUB_TITLE,
            cellProps: {
                rowSpan: 2
            }
        };
        return {
            title: ' ',
            subTitle: [subTitleData],
            data: tableRows
        };
    };

    const getRebateTableContent = ({ isShowProductCol }) => {
        const tableRows = products.map((productNameAndCode, index) => {
            if (!rebates[index]) return [undefined, '---', '---'];

            const { target, targetMax, min, step } = rebates[index];

            let lowLevelRebate = target;
            let selfRebateProfit = targetMax - lowLevelRebate;

            if (!isReadOnly) {
                lowLevelRebate = commissionValues?.rebates[index];
                selfRebateProfit = targetMax - lowLevelRebate;
                const selectItems = makeSelectItems(targetMax, min, step);

                lowLevelRebate = {
                    cellFieldType: FIELD_TYPE.SELECT_DROPDOWN_FIELD,
                    name: `rebates.${index}`,
                    cellInfoType: CELL_INFO_TYPES.DATA,
                    items: selectItems
                };
            }

            return [productNameAndCode, lowLevelRebate, selfRebateProfit];
        });

        const titleData = {
            value: t('customerMgmt.accountLink.commission.rebateSetting'),
            cellInfoType: CELL_INFO_TYPES.SUB_TITLE,
            cellProps: {
                colSpan: 3 + (isShowProductCol ? 1 : 0)
            }
        };
        return {
            title: titleData,
            subTitle: [
                t('common.tradeAccount.commodity.name'),
                t('customerMgmt.accountLink.commission.lowerLevelDiff'),
                t('customerMgmt.accountLink.commission.selfDiffProfit')
            ],
            data: tableRows
        };
    };

    const getDiffTableContent = ({ isShowProductCol }) => {
        const diffOneFirstIndex = 0;
        const noDiffFirstIndex = 2;

        const { target, percent, percentMax } = initCommissionSettings.diff;

        const tableRows = products.map((productNameAndCode, index) => {
            const DIFF_COL_INDEX = 0;
            const LOWE_LEVEL_PROFIT_PERCENTAGE_COL_INDEX = 1;
            const SELF_DIFF_PROFIT_PERCENTAGE_COL_INDEX = 2;

            const isRowWithDiff =
                index >= diffOneFirstIndex && index < noDiffFirstIndex;

            let commissionData = [];

            if (isRowWithDiff) {
                commissionData[DIFF_COL_INDEX] = target;
                commissionData[LOWE_LEVEL_PROFIT_PERCENTAGE_COL_INDEX] =
                    percentMax - percent; //sub account revenue
                commissionData[SELF_DIFF_PROFIT_PERCENTAGE_COL_INDEX] = percent;
            } else {
                commissionData = ['---', '---', '---'];
            }

            if (!isReadOnly && isRowWithDiff) {
                const { targetMax, targetMin, targetStep } =
                    initCommissionSettings.diff;

                const diffSelectItems = makeSelectItems(
                    targetMax,
                    targetMin,
                    targetStep
                );

                commissionData[DIFF_COL_INDEX] = {
                    name: 'diff.target',
                    cellInfoType: CELL_INFO_TYPES.DATA,
                    cellFieldType: FIELD_TYPE.SELECT_DROPDOWN_FIELD,
                    items: diffSelectItems
                };

                commissionData[LOWE_LEVEL_PROFIT_PERCENTAGE_COL_INDEX] = {
                    name: 'diff.percent',
                    cellInfoType: CELL_INFO_TYPES.DATA,
                    cellFieldType: FIELD_TYPE.NUMBER_INPUT_FIELD
                };
            }

            const diffOneRowSpan = noDiffFirstIndex - diffOneFirstIndex;

            commissionData = getSharedRows(
                index,
                commissionData,
                diffOneFirstIndex,
                noDiffFirstIndex - 1,
                diffOneRowSpan,
                CELL_INFO_TYPES.DATA
            );

            const noDiffRowSpan = products.length - noDiffFirstIndex;

            commissionData = getSharedRows(
                index,
                commissionData,
                noDiffFirstIndex,
                products.length - 1,
                noDiffRowSpan,
                CELL_INFO_TYPES.DATA
            );
            return [productNameAndCode, ...commissionData];
        });

        const titleData = {
            value: t('customerMgmt.accountLink.commission.diffSetting'),
            cellInfoType: CELL_INFO_TYPES.SUB_TITLE,
            cellProps: {
                colSpan: 3 + (isShowProductCol ? 1 : 0)
            }
        };

        return {
            title: titleData,
            subTitle: [
                t('common.tradeAccount.commodity.name'),
                t('customerMgmt.accountLink.commission.diff'),
                t(
                    'customerMgmt.accountLink.commission.lowerLevelProfitPercentage'
                ),
                t(
                    'customerMgmt.accountLink.commission.selfDiffProfitPercentage'
                )
            ],
            data: tableRows
        };
    };

    const handleSubTitleOffsetHeightChange = (subTitleOffsetHeight) => {
        setMinSubTitleCellHeight((oldMinSubTitleCellHeight) => {
            return Math.max(subTitleOffsetHeight, oldMinSubTitleCellHeight);
        });
    };

    const isShowMainProductCol = isDesktop;
    const isShowCommissionProductCol = isDesktopDown;

    return (
        <>
            {isShowMainProductCol && (
                <TableCommissionContainer
                    isReadOnly={isReadOnly}
                    {...getProductColumnContent(initCommissionSettings)}
                    isShowProductCol={true}
                    handleSubTitleOffsetHeightChange={
                        handleSubTitleOffsetHeightChange
                    }
                    tableWidth={'70%'}
                    minSubTitleCellHeight={minSubTitleCellHeight}
                    accountType={accountType}
                />
            )}

            {canAddCommission && (
                <TableCommissionContainer
                    isReadOnly={isReadOnly}
                    {...getRebateTableContent({
                        isShowProductCol: isDesktopDown
                    })}
                    isShowProductCol={isDesktopDown}
                    handleSubTitleOffsetHeightChange={
                        handleSubTitleOffsetHeightChange
                    }
                    minSubTitleCellHeight={minSubTitleCellHeight}
                    accountType={TRADING_ACCOUNT_TYPE.STANDARD}
                />
            )}

            {canAddRebate && (
                <TableCommissionContainer
                    {...getCommissionTableContent(
                        initCommissionSettings,
                        commissionValues,
                        isReadOnly,
                        isShowCommissionProductCol,
                        t
                    )}
                    isShowProductCol={isShowCommissionProductCol}
                    handleSubTitleOffsetHeightChange={
                        handleSubTitleOffsetHeightChange
                    }
                    minSubTitleCellHeight={minSubTitleCellHeight}
                    accountType={TRADING_ACCOUNT_TYPE.STANDARD}
                />
            )}

            {(isReadOnly && initCommissionSettings.diff.target !== 0) ||
            checkIsShowDiffCommission(initCommissionSettings) ? (
                <TableCommissionContainer
                    {...getDiffTableContent({
                        isShowProductCol: isDesktopDown
                    })}
                    isShowProductCol={isDesktopDown}
                    minSubTitleCellHeight={minSubTitleCellHeight}
                    accountType={TRADING_ACCOUNT_TYPE.STANDARD}
                />
            ) : null}
        </>
    );
};

export default CommissionStandardTable;
