import axios from 'axios';

const axiosContentsConfig = axios.create({
    baseURL:
        process.env.NODE_ENV === 'development'
            ? '/contents'
            : `${process.env.REACT_APP_CONTENTS_BASE_URL}/api/contents`,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default axiosContentsConfig;
