import React from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import { AppContainer, AppMainContainer, AppSideContainer } from 'layouts';

import './PageLayoutSideMain.scss';

export const PageLayoutSideMainInner = ({
    fullScreen,
    children,
    sideMaxWidth = '310px',
    sidePosition = 'right', // left or right
    className
}) => {
    return (
        <Box className={cx(['pageLayoutSideMainInner', className])}>
            {sidePosition === 'left' && (
                <AppSideContainer
                    maxWidth={sideMaxWidth}
                    className="leftAppSideContainer">
                    {children[0]}
                </AppSideContainer>
            )}

            <AppMainContainer fullScreen={fullScreen}>
                {children[1]}
            </AppMainContainer>

            {sidePosition === 'right' && (
                <AppSideContainer
                    maxWidth={sideMaxWidth}
                    className="rightAppSideContainer">
                    {children[0]}
                </AppSideContainer>
            )}
        </Box>
    );
};

const PageLayoutSideMain = ({
    fullScreen = false,
    pageName = '',
    sideMaxWidth = '310px',
    className = '',
    sidePosition,
    children
}) => {
    return (
        <AppContainer
            pageName={pageName}
            className={cx('pageLayoutSideMain', className)}>
            <PageLayoutSideMainInner
                fullScreen={fullScreen}
                sideMaxWidth={sideMaxWidth}
                sidePosition={sidePosition}>
                {children}
            </PageLayoutSideMainInner>
        </AppContainer>
    );
};

export default PageLayoutSideMain;
