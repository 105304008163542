import API from 'services';
import { getResponseList } from 'utils/serverInfos';

import useCacheableQuery from './useCachableQuery';

const useTradingPlatforms = () => {
    const { isSuccess, data } = useCacheableQuery({
        queryKeys: [API.system.getTradingPlatforms.queryKey],
        apiCall: API.system.getTradingPlatforms.apiCall
    });

    const platforms = isSuccess ? getResponseList(data) : [];

    return { platforms };
};

export default useTradingPlatforms;
