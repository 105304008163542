import React from 'react';
import {
    BreakpointProvider as BreakpointProviderReactSocks,
    setDefaultBreakpoints
} from 'react-socks';

import styles from 'styles/styles.module.scss';

export const BREAKPOINT = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    LAPTOP: 'laptop',
    DESKTOP: 'desktop'
};

const BreakpointProvider = ({ children }) => {
    setDefaultBreakpoints([
        { [BREAKPOINT.MOBILE]: Number(styles.MOBILE_BREAKPOINT) },
        { [BREAKPOINT.TABLET]: Number(styles.TABLET_BREAKPOINT) },
        { [BREAKPOINT.LAPTOP]: Number(styles.LAPTOP_BREAKPOINT) },
        { [BREAKPOINT.DESKTOP]: Number(styles.DESKTOP_BREAKPOINT) }
    ]);
    return (
        <BreakpointProviderReactSocks>{children}</BreakpointProviderReactSocks>
    );
};

export default BreakpointProvider;
