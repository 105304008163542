import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import format from 'date-fns/format';

import { NumFormat, PlainTextPlatformField } from 'components';

import { defaultTablePageValues } from 'constants/appTableInfos';
import { DATE_FORMAT } from 'constants/dateFormats';
import { defaultAppFilterDateRange } from 'constants/filterInfos';
import { VALUES_ALL_TRADE_LOGIN_IDS } from 'constants/parseValues';
import { ORDER_ACTION } from 'constants/serverInfos/serverInfos';
import { useOpenSnackbar } from 'hooks';

import API from 'services';
import { getAccountTypeLocale } from 'utils/client/accountType';
import {
    calDayDifferenceByDayStrings,
    formatDateToStringFromAPI
} from 'utils/dateConversion';
import { exportExcelFile } from 'utils/filterProcess/fileTransfer';
import { handleAsyncCall } from 'utils/utils';

const initPayload = {
    ...defaultTablePageValues,
    openStartDate: defaultAppFilterDateRange[0],
    openEndDate: defaultAppFilterDateRange[1]
};

const useTableTradeHistory = (
    searchTradeLoginId = VALUES_ALL_TRADE_LOGIN_IDS.API_PAYLOAD
) => {
    const { t } = useTranslation();
    const { openSuccessSnackbar, openErrorSnackbar } = useOpenSnackbar();
    const { userId } = useSelector((state) => state.user);
    const [tradeHistoryData, setTradeHistoryData] = useState([]);
    const [tradeHistoryPayload, setTradeHistoryPayload] = useState({
        ...initPayload,
        searchTradeLoginIds: searchTradeLoginId
    });
    const [isFetching, setIsFetching] = useState(false);

    const getTradeHistoryData = () => {
        setIsFetching(true);

        handleAsyncCall({
            asyncCall: () =>
                API.traderReports.getTradeHistoryLogs.apiCall(
                    userId,
                    tradeHistoryPayload
                ),
            handleCallSuccess: (res) => {
                setTradeHistoryData(res.data);
                setIsFetching(false);
            },
            handleCallFailureError: (err) => {
                openErrorSnackbar(err.message);
                setIsFetching(false);
            }
        });
    };

    useEffect(() => {
        getTradeHistoryData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeHistoryPayload]);

    const exportExcel = () => {
        if (isFetching) return;

        const { openStartDate, openEndDate, startDate, endDate } =
            tradeHistoryPayload;

        const openDateDifferent = calDayDifferenceByDayStrings([
            openStartDate,
            openEndDate
        ]);

        const isClosedDatePayloadIncluded = startDate && endDate;

        const closedDateDifferent = calDayDifferenceByDayStrings([
            startDate,
            endDate
        ]);

        if (
            openDateDifferent > 30 ||
            (isClosedDatePayloadIncluded && closedDateDifferent > 30)
        ) {
            openErrorSnackbar(t('common.snackbar.downloadFailedWithinMonthly'));
            return;
        }

        setIsFetching(true);

        handleAsyncCall({
            asyncCall: () =>
                API.traderReports.getTradeHistoryExport.apiCall(
                    userId,
                    tradeHistoryPayload
                ),
            handleCallSuccess: (res) => {
                openSuccessSnackbar(t('common.snackbar.downloadSuccess'));

                const fieldName = `${t(
                    'tradeReports.tradeHistory.title'
                )}_${format(new Date(), DATE_FORMAT.ymdHms)}`;

                exportExcelFile(res, fieldName);
                setIsFetching(false);
            },
            handleCallFailureError: (res) => {
                openErrorSnackbar(
                    res.message || t('common.snackbar.downloadFailed')
                );
                setIsFetching(false);
            }
        });
    };

    const tradeHistoryColumnsInfos = {
        positionId: {
            field: 'positionId',
            label: t('common.table.orderNo')
        },
        accountTypeDisplay: {
            renderCell: ({ accountType }) =>
                getAccountTypeLocale(accountType, t),
            label: t('common.table.accountType')
        },
        tradeLoginId: {
            field: 'tradeLoginId',
            label: t('common.tradeAccount.tradeLoginId')
        },
        platform: {
            field: 'platform',
            label: t('common.tradeAccount.platform'),
            renderCell: ({ platform }) => (
                <PlainTextPlatformField platform={platform} />
            )
        },
        currency: {
            field: 'currency',
            label: t('common.table.currency')
        },
        action: {
            field: 'action',
            label: t('common.table.type'),
            renderCell: ({ action }) => {
                const { i18nKey } = Object.values(ORDER_ACTION).find(
                    ({ serverKey }) => serverKey === action
                );

                return t(i18nKey);
            }
        },
        symbol: {
            field: 'symbol',
            label: t('common.table.product')
        },
        volume: {
            field: 'volume',
            label: t('common.table.volume'),
            renderCell: ({ volume }) => <NumFormat value={volume} />
        },
        timeOpen: {
            field: 'timeOpen',
            label: t('common.date.openDate'),
            renderCell: ({ timeOpen }) => formatDateToStringFromAPI(timeOpen)
        },
        pricePosition: {
            field: 'pricePosition',
            label: t('common.tradeAccount.finance.openPrice'),
            renderCell: ({ pricePosition }) => (
                <NumFormat value={pricePosition} />
            )
        },
        priceSL: {
            field: 'priceSL',
            label: t('common.table.priceSL'),
            renderCell: ({ priceSL }) => (
                <NumFormat
                    value={priceSL}
                    fixedDecimalScale={priceSL === 0 ? true : false}
                    decimalScale={priceSL === 0 ? 2 : 5}
                />
            )
        },
        priceTP: {
            field: 'priceTP',
            label: t('common.table.priceTP'),
            renderCell: ({ priceTP }) => (
                <NumFormat
                    value={priceTP}
                    fixedDecimalScale={priceTP === 0 ? true : false}
                    decimalScale={priceTP === 0 ? 2 : 5}
                />
            )
        },
        closeDate: {
            field: 'time',
            label: t('common.date.closeDate'),
            renderCell: ({ time }) => formatDateToStringFromAPI(time)
        },
        closingPrice: {
            field: 'price',
            label: t('common.tradeAccount.finance.closingPrice'),
            renderCell: ({ price }) => <NumFormat value={price} />
        },
        commission: {
            field: 'commission',
            label: t('common.tradeAccount.finance.commission'),
            renderCell: ({ commission }) => (
                <NumFormat
                    value={commission}
                    fixedDecimalScale={commission === 0 ? true : false}
                    decimalScale={commission === 0 ? 2 : 5}
                />
            )
        },
        storage: {
            field: 'storage',
            label: t('common.table.interest'),
            renderCell: ({ storage }) => (
                <NumFormat
                    value={storage}
                    fixedDecimalScale={true}
                    decimalScale={2}
                />
            )
        },
        profit: {
            field: 'profit',
            label: t('common.tradeAccount.finance.closedProfit'),
            renderCell: ({ profit }) => (
                <NumFormat
                    value={profit}
                    fixedDecimalScale={true}
                    decimalScale={2}
                />
            )
        },
        parentTradeLoginId: {
            field: 'parentTradeLoginId',
            label: t('common.table.parentIB')
        },
        comment: {
            field: 'comment',
            label: t('common.table.comment'),
            renderCell: ({ comment }) => comment || '---'
        },
        departmentName: {
            field: 'departmentName',
            label: t('common.user.work.department')
        },
        mgrTradeLoginId: {
            field: 'mgrTradeLoginId',
            label: t('common.sales.mgrTradeLoginId')
        }
    };

    const tradeHistoryStatsInfos = [
        {
            field: 'closePL',
            label: t('common.tradeAccount.finance.closedProfit'),
            renderCell: ({ closePL }) => <NumFormat value={closePL} />
        },
        // {
        //     field: 'swap',
        //     label: t('common.tradeAccount.finance.interest'),
        //     renderCell: ({ swap }) => <NumFormat value={swap} />
        // },
        {
            field: 'commission',
            label: t('common.tradeAccount.finance.commission'),
            renderCell: ({ commission }) => <NumFormat value={commission} />
        },
        // {
        //     field: 'netProfit',
        //     label: t('common.tradeAccount.finance.profit'),
        //     renderCell: ({ netProfit }) => <NumFormat value={netProfit} />
        // },
        {
            field: 'deposit',
            label: t('common.tradeAccount.finance.deposit'),
            renderCell: ({ deposit }) => <NumFormat value={deposit} />
        },
        {
            field: 'withdrawal',
            label: t('common.tradeAccount.finance.withdrawal'),
            renderCell: ({ withdrawal }) => <NumFormat value={withdrawal} />
        },
        {
            field: 'credit',
            label: t('common.tradeAccount.finance.creditLimit'),
            renderCell: ({ credit }) => <NumFormat value={credit} />
        }
    ];

    const tradeHistoryStatsItems = [];

    if (tradeHistoryData) {
        tradeHistoryStatsInfos.forEach(({ field, label }) => {
            tradeHistoryStatsItems.push({
                label,
                value: tradeHistoryData[field]
            });
        });
    }

    return {
        tradeHistoryData,
        tradeHistoryColumnsInfos,
        tradeHistoryStatsItems,
        tradeHistoryPayload,
        setTradeHistoryPayload,
        exportExcel,
        isFetching
    };
};

export default useTableTradeHistory;
